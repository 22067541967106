import "./successfulSale.page.scss";
import { Fragment, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

const SuccessfulSalePage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo, shift} = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
            
            if (accountData.useShifts) {
                if (shift !== null) {
                    if(!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
    // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Venta exitosa" : "Successful sale"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Venta exitosa" : "Successful sale"}
                                    />
                                : null}

                            </Fragment> : null}
                        </div>
                    : null }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SuccessfulSalePage;