import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";
import warningIcon2 from "../../../img/warning.svg";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import InventoryEditTable from "../../../appTools/appToolsComponents/inventoryEditTable/inventoryEditTable";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import { useLocation, useNavigate } from "react-router-dom";
import InventoryUpload from "../../../appTools/appToolsComponents/inventoryUpload/inventoryUpload";
import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner from "../../../components/spinner/spinner";

const UploadingProductsPage = () => {

    let observedRef = useRef(null);

    const navigate = useNavigate();

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [showingTable, setShowingTable] = useState(null);

    const [fieldAlert, setFieldAlert] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    const [duplicatedBarcodes, setDuplicatedBarcodes] = useState([]);
    const [duplicatedInTable, setDuplicatedInTables] = useState([]);

    const removeThisDuplicatedFromTable = (barcodeToRemoved) => {
        let temDuplicatedInTable = [...duplicatedInTable];
        let newDuplicatedInTable = temDuplicatedInTable.filter(item => item !== barcodeToRemoved);
        setDuplicatedInTables(newDuplicatedInTable);
    }

    const location = useLocation();

    const [editingRecords, setEditingRecords] = useState(null);
    const [currentRecords, setCurrentRecords] = useState(null);
    const [recordsBeenDisplay, setRecordsBeenDisplay] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [navControlers, setNavControlers] = useState(null);
    const [showPageNav, setShowPageNav] = useState(false);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const onClickPrevious = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber - 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber - 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber - 1,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": true,
        });
    }

    const onClickNext = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber + 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber + 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber + 1,
            "isPrevious": true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const convertFieldIntoString = (record, num) => {
        const newRecord = {
            "number": num,
            "name": record.name,
            "category": record.category,
            "rate": record.rate,
            "description": record.description === null ? "" : record.description,
            "quantity": record.quantity === null ? "" : String(record.quantity),
            "location": record.location === null ? "" : record.location,
            "barcode": record.barcode === null ? "" : String(record.barcode),
            "priceWithoutTax": String(record.priceWithoutTax),
            "itbisPercentage": record.itbisPercentage,
            "itbisAmount": String(record.itbisAmount),
            "finalPrice": String(record.finalPrice),
        }
        return newRecord;
    }

    useEffect(() => {
        if (location.state) {
            if (location.state.currentRecords) {
                setShowingTable(true);
                setCurrentRecords(location.state.currentRecords);
            } else {
                setShowingTable(false);
            }

            if (location.state.editingRecords) {
                const tempRecords = location.state.editingRecords;

                const newTempRecords = [];

                for (let i = 0; i < tempRecords.length; i++) {
                    const newRecord = convertFieldIntoString(tempRecords[i], i + 1)
                    newTempRecords.push(newRecord)
                }

                setEditingRecords(newTempRecords);

                if (newTempRecords.length > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }

                const tempRecordsBeenDisplay = [];

                if (location.state.recordsBeenDisplay) {

                    const startAtIndex = 0;
                    const endAtIndex = startAtIndex + 30;

                    for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                        tempRecordsBeenDisplay.push(newTempRecords[i]);
                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);

                } else {

                    if (location.state.pageNumber) {
                        const startAtIndex = (location.state.pageNumber * 30) - 30;
                        const endAtIndex = startAtIndex + 30;

                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    } else {

                        const startAtIndex = 0;
                        const endAtIndex = startAtIndex + 30;

                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);
                }
            } else {
                setShowPageNav(false)
            }

            if (location.state.pageNumber) {
                setPageNumber(location.state.pageNumber);
            } else {
                setPageNumber(1)
            }

            if (location.state.navControlers) {
                setNavControlers(location.state.navControlers);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": location.state.editingRecords ? Math.ceil(location.state.editingRecords.length / 30) : 1,
                    "isPrevious": false,
                    "isNext": location.state.editingRecords ? (Math.ceil(location.state.editingRecords.length / 30) > 1 ? true : false) : false,
                });
            }

        } else {
            setShowingTable(false);
        }
        // eslint-disable-next-line 
    }, []);

    const [productLimits, setProductLimits] = useState(null);
    const [loading, setLoading] = useState(false);

    const [longProcess, setLongProcess] = useState(false);

    const [loadingLimits, setLoadingLimits] = useState(true);
    const [showConnectionError, setShowConnectionError] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized, showingTable]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const goBack = () => {
        setEditingRecords(null);
        setCurrentRecords(null);
        setRecordsBeenDisplay([]);
        setPageNumber(1);
        setNavControlers(null);
        setShowPageNav(false);
        setShowingTable(false);
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "description"
            case 3:
                return "barcode"
            case 4:
                return "location"
            case 5:
                return "quantity"
            case 6:
                return "category"
            case 7:
                return "rate"
            case 8:
                return "priceWithoutTax"
            case 9:
                return "itbisPercentage"
            case 10:
                return "itbisAmount"
            case 11:
                return "finalPrice"
            default:
                break;
        }
    }

    const checkIfRecordEmpty = (recordToCheck) => {
        if (recordToCheck) {
            const name = recordToCheck[getColumName(1)];
            const desc = recordToCheck[getColumName(2)];
            const barc = recordToCheck[getColumName(3)];
            const loca = recordToCheck[getColumName(4)];
            const quan = recordToCheck[getColumName(5)];
            const pric = recordToCheck[getColumName(8)];
            const aitb = recordToCheck[getColumName(10)];
            const fpri = recordToCheck[getColumName(11)];

            if (
                name === "" &&
                desc === "" &&
                barc === "" &&
                loca === "" &&
                quan === "" &&
                pric === "" &&
                aitb === "" &&
                fpri === ""
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'end', // nearest
                behavior: "smooth"
            });

        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const isBarcodeAlert = (barcode) => {
        if (duplicatedBarcodes.includes(Number(barcode))) {
            return true;
        } else {
            return false;
        }
    }

    const isAnyBarcodeAlert = (editingRecords) => {
        if (editingRecords) {
            let isAnyBarcode = false
            editingRecords.forEach(record => {
                if (isBarcodeAlert(record.barcode.trim())) {
                    isAnyBarcode = true;
                }
            });
            return isAnyBarcode;
        } else {
            return false
        }
    }

    async function getProductLimit() {
        setLoadingLimits(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/products`);
            const snapshot = await getCountFromServer(coll);

            setProductLimits({
                "limit": planSnap.data().products,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().products ? false : true
            });
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoadingLimits(false);
    }

    async function createProducts(recordsToCreate) {
        setLoading(true);

        if (recordsToCreate.length > 1) {
            setLongProcess(true);
        }

        try {
            const res = await firebase.useCreateProducts({
                "recordsToCreate": recordsToCreate,
                "businessId": accountData.id
            });
            
            if (res.msg === "Limit exceeded") {
                setProductLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to create the products`,
                        es: `Error al intentar crear los productos`
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more products to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más productos a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (res.msg === "Quota exceeded") {
                    setProductLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Error trying to create the products`,
                            es: `Error al intentar crear los productos`
                        },
                        code: "Quota exceeded",
                        description: {
                            en : 'The number of products you are trying to add exceeds the established limit.',
                            es : 'La cantidad de productos que estás intentando agregar excede el límite establecido.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else {
                    if (res.msg === "Duplicate barcodes") {
                        setProductLimits({
                            "limit": res.limit,
                            "current": res.current,
                            "createNew": true
                        });
                        
                        setDuplicatedBarcodes(res.duplicatedBarcodes);
                        setAlertData({
                            type: 'error',
                            title: {
                                en: `Error trying to create the products`,
                                es: `Error al intentar crear los productos`
                            },
                            code: "Duplicate barcodes",
                            description: {
                                en : 'It is not possible to assign a barcode that is already associated with another product.',
                                es : 'No es posible asignar un código de barras que ya esté asociado a otro producto.'
                            }
                        });
                        setAlertActive(true);
                        setLoading(false);
                    } else {
                        setAlertData({
                            type : 'success',
                            title: {
                                en : 'The products have been created successfully',
                                es : 'Los productos han sido creados de manera exitosa'
                            },
                            code : '',
                            description : {
                                en : `If you want to add images to these products, you must search for each product separately.`,
                                es : `Si deseas agregar imágenes a estos productos, debe buscar cada producto por separado.`
                            }
                        });
                        setAlertActive(true);
                        navigate('/inventario', { replace: true });
                    }
                    
                }
            }
        } catch (error) {
            setLoading(false);
            setLongProcess(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to create the products`,
                    es: `Error al intentar crear los productos`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const goToPage = (pageToNum) => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = (pageToNum * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageToNum);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageToNum,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const onTryAgain = e => {
        e.preventDefault();
        getProductLimit();
    }

    useEffect(() => {
        getProductLimit();
        // eslint-disable-next-line
    }, []);

    const checkIfDuplicated = (barcode) => {
        if (barcode.trim() !== "") {
            let barcodeCounter = 0;
            for (let i = 0; i < editingRecords.length; i++) {
                const barcodeToCheck = editingRecords[i].barcode.trim();
                if (barcodeToCheck !== "") {
                    if (barcodeToCheck === barcode.trim()) {
                        barcodeCounter++;
                    }
                }
            }
            if (barcodeCounter > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getLastRecordWithBarcodeRepeted = (duplicatedInThisTable) => {
        const array1 = [...duplicatedBarcodes];
        const array2 = [...duplicatedInThisTable];

        const combinedArray = array1.concat(array2)
        const allRepetedBarcodes = [...new Set(combinedArray)];

        for (let i = editingRecords.length - 1; i >= 0; i--) {
            // Retorna el numero primer elemento con codigo de barra repetido
            if (allRepetedBarcodes.includes(editingRecords[i].barcode.trim())) {
                return editingRecords[i].number;
            }
        }

        return false;
    }

    const isRecordEmpty = (record) => {
        if (
            record.barcode.trim() === "" &&
            record.description.trim() === "" &&
            record.itbisAmount.trim() === "" &&
            record.location.trim() === "" &&
            record.name.trim() === "" &&
            record.priceWithoutTax.trim() === "" &&
            record.quantity.trim() === "" &&
            record.finalPrice.trim() === "" 
        ) {
            return true;
        } else {
            return false;
        }
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        let errorRecordNumber = null;

        const barcodeDuplicatedInTable = [];

        const recordsToCreate = [];

        editingRecords.every(record => {
            if (!isRecordEmpty(record)) {
                if (record.name.trim() === "") {
                    setFieldAlert({
                        x: record.number,
                        y: 1,
                        en: "The name cannot be empty",
                        es: "El nombre no puede estar vacío",
                    });
                    setMakeSticky(false);
                    setTimeout(() => {
                        if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                            setMakeSticky(true);
                        }
                    }, 100);
                    errorRecordNumber = record.number;
                    return false;
                } else {
                    if (checkIfDuplicated(record.barcode)) {
                        barcodeDuplicatedInTable.push(record.barcode.trim());
                    } else {
                        if (record.priceWithoutTax.trim() === "") {
                            setFieldAlert({
                                x: record.number,
                                y: 11,
                                en: "The price cannot be empty",
                                es: "El precio no puede estar vacío",
                            });
                            errorRecordNumber = record.number;
                            return false;
                        }
                    }
                }
                recordsToCreate.push(record); 
            }
            
            return true;
        });

        if (barcodeDuplicatedInTable.length > 0) {
            errorRecordNumber = getLastRecordWithBarcodeRepeted(barcodeDuplicatedInTable);
            setDuplicatedInTables(barcodeDuplicatedInTable);
            setFieldAlert({
                x: errorRecordNumber,
                y: 3,
                en: "The barcode cannot be duplicated",
                es: "El código de barras no puede estar repetido",
            });
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        if (isAnyBarcodeAlert(recordsToCreate)) {
            return;
        }

        if (errorRecordNumber) {
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        if (recordsToCreate.length > 0) {
            createProducts(recordsToCreate);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'No records to save',
                    es: 'No hay registros para guardar',
                }
            });
        }
    }

    const showTable = (productLimits) => {
        if (productLimits) {
            if (productLimits.createNew) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        const newState = {
            "editingRecords": editingRecords,
            "pageNumber": pageNumber,
            "navControlers": navControlers,
            "showPageNav": showPageNav,
            "currentRecords": currentRecords,
        }
        navigate(null, { replace: true, state: newState });
        // eslint-disable-next-line
    }, [editingRecords, pageNumber, navControlers, showPageNav, currentRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Import products from Excel' : 'Importar productos desde Excel'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.inventory ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Import products from Excel' : 'Importar productos desde Excel'}
                                        />
                                        : null}
                                    <div className="inven-first-secct-conte">
                                        {showingTable && showTable(productLimits) ? 
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ?
                                                        <div
                                                            className="invent-adding-product-alert-text-p"
                                                            style={{ flexGrow: 4 }}
                                                        >
                                                            <img src={warningIcon} alt="" />
                                                            <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                        </div>
                                                    :
                                                    (isAnyBarcodeAlert(editingRecords) ?
                                                        <div
                                                            className="invent-adding-product-alert-text-p"
                                                            style={{ flexGrow: 4 }}
                                                        >
                                                            <img src={warningIcon} alt="" />
                                                            <p>{appLanguage.en ?
                                                                "It is not possible to assign a barcode that is already associated with another product."
                                                                :
                                                                "No es posible asignar un código de barras que ya esté asociado a otro producto."
                                                            }</p>
                                                        </div>
                                                    : 
                                                        productLimits ? 
                                                            <div
                                                                className="invent-adding-product-alert-text-p" 
                                                                style={{flexGrow: 4}}
                                                            >
                                                                <p>{productLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {productLimits.limit.toLocaleString()} {appLanguage.en ? "products" : "productos"}</p>
                                                            </div>
                                                        : null
                                                    )
                                                }
                                                {loading ? null :
                                                    <div className="inven-first-section-div-btn" style={{ marginBottom: "15px" }}>
                                                        <button
                                                            onClick={goBack}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>
                                                        <button
                                                            onClick={onClickSave}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        : null}
                                    </div>
                                </div>

                                {loadingLimits ? <Spinner /> : 
                                    (showConnectionError ? <ConnectionError onTryAgain={onTryAgain} />  :
                                        (showTable(productLimits) ?
                                            (showingTable ?
                                                <InventoryEditTable
                                                    onScroll={onScroll}
                                                    makeSticky={makeSticky}
                                                    headerHeight={headerHeight}
                                                    loading={loading}
                                                    longProcess={longProcess}
                                                    fieldAlert={fieldAlert}
                                                    setFieldAlert={setFieldAlert}
                                                    editingRecords={editingRecords}
                                                    setEditingRecords={setEditingRecords}
                                                    recordsBeenDisplay={recordsBeenDisplay}
                                                    fieldAlertReference={fieldAlertReference}
                                                    getColumName={getColumName}
                                                    checkIfRecordEmpty={checkIfRecordEmpty}
                                                    duplicatedBarcodes={duplicatedBarcodes}
                                                    duplicatedInTable={duplicatedInTable}
                                                    removeThisDuplicatedFromTable={removeThisDuplicatedFromTable}
                                                    pageNumber={pageNumber}
                                                    navControlers={navControlers}
                                                    showPageNav={showPageNav}
                                                    onClickNext={onClickNext}
                                                    onClickPrevious={onClickPrevious}
                                                />
                                            : 
                                                <InventoryUpload
                                                    headerHeight={headerHeight} 
                                                    isRecordEmpty={isRecordEmpty}
                                                    productLimits={productLimits}
                                                    setShowPageNav={setShowPageNav}
                                                    setNavControlers={setNavControlers}
                                                    setShowingTable={setShowingTable}
                                                    setEditingRecords={setEditingRecords}
                                                    setCurrentRecords={setCurrentRecords}
                                                    setRecordsBeenDisplay={setRecordsBeenDisplay}
                                                />
                                            )
                                        :
                                            <LimitExceeded productLimits={productLimits} showConnectionError={showConnectionError} onTryAgain={onTryAgain}/>
                                        )
                                    )
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const LimitExceeded = ({ productLimits, showConnectionError, onTryAgain }) => {
    const { appLanguage } = useContext(AppContext);
   
    return (
        <Fragment>
            {showConnectionError ? 
                <ConnectionError onTryAgain={onTryAgain} /> 
            :
                <div className="no-customer-yet-main-container">
                    {productLimits ? <Fragment>
                        <img className="no-customer-yet-main-img" src={warningIcon2} alt="No customers yet" />
                        <p><b>{appLanguage.en ? "You cannot add more products" : "No puedes agregar más produtos"}</b></p>
                        <p><b>{productLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {productLimits.limit.toLocaleString()} {appLanguage.en ? "products" : "productos"}</b></p>
                        <p>{appLanguage.en ? `Only ${productLimits.limit.toLocaleString()} products can be created per basic account. You can delete an existing product to add another or upgrade to a more advanced plan.` : `Sólo se pueden crear ${productLimits.limit.toLocaleString()} produtos por plan básica. Puedes eliminar un producto existente para agregar otro o actualizarte a un plan más avanzado.`}</p>
                    </Fragment> : null}
                </div>
            }
        </Fragment>
    )
}

export default UploadingProductsPage;