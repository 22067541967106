import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "./agreeTermsAndCond.scss";

const AgreeTermsAndCond = ({customerAgree, setCustomerAgree}) => {

    const { appLanguage } = useContext(AppContext);

    const customerAgreeHandler = () => {
        setCustomerAgree(!customerAgree);
    }

    return (
        <div className="agree-terms-and-cond-cont">
            <input
                className='agree-terms-and-cond-cont-checkbox'
                type="checkbox"
                checked={customerAgree}
                onChange={customerAgreeHandler}
                name='customerAgree'
                id='customerAgree'
            />
            <div className="agree-terms-and-cond-cont-div">
                {appLanguage.en ? 
                    <p>I agree to HazCuentas <a href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer" className="agree-terms-and-cond-cont-link">terms and conditions.</a></p> 
                : 
                    <p>Acepto los <a href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer" className="agree-terms-and-cond-cont-link">términos y condiciones</a> de HazCuentas.</p>
                }
            </div>
        </div>
        
    )
}

export default AgreeTermsAndCond;