import "./spinner2.scss";

import { AppContext } from '../../context/AppContext';
import { useContext } from 'react';

const Spinner2 = () => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className="more-notes-spinner">
            <div style={appTheme.dark ? {backgroundColor: "rgb(255, 255, 255, 0.50)"} : {backgroundColor: "rgb(0, 0, 0, 0.50)"}} className="more-notes-bounce1"></div>
            <div style={appTheme.dark ? {backgroundColor: "rgb(255, 255, 255, 0.50)"} : {backgroundColor: "rgb(0, 0, 0, 0.50)"}} className="more-notes-bounce2"></div>
            <div style={appTheme.dark ? {backgroundColor: "rgb(255, 255, 255, 0.50)"} : {backgroundColor: "rgb(0, 0, 0, 0.50)"}} className="more-notes-bounce3"></div>
        </div>
    );
}

export default Spinner2;