import { Link } from "react-router-dom";
import './outSideButton.scss';

const OutSideButton = ({text, icon, goTo, onClick, extraCSS, buttonType, replece}) => {
    return (
        <Link 
            to={goTo}
            replace={replece}
            className={`outside-button-container ${extraCSS}`}
            onClick={onClick}
            type={buttonType}
        >   
            {icon ? 
                <img 
                    src={icon} 
                    alt={text}
                    className='outside-button-container-img'
                />   
            : null}
            
            <p className='outside-button-container-text'>{text}</p> 
        </Link>
    )
}

export default OutSideButton;