import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import "./blankBackground.scss";

const BlankBackground = () => {

    const { appTheme } = useContext(AppContext)

    return (
        <div className={`blank-background-inside ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} />  
    )
}

export default BlankBackground;