import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../alert/alertContext';
import { AuthContext } from '../../firebase/context';
import { useNavigate } from 'react-router-dom';
import { SoftAlertContext } from '../soft-alert/softAlertContext';

import firebase from '../../firebase/firebase';

import Spinner from '../spinner/spinner';
import Spinner3 from '../spinner3/spinner3';

import checkIcon from '../../img/check.svg';
import uncheckIcon from '../../img/uncheck.svg';
import emailDarkIcon from '../../icons/email-dark.png';
import emailLightIcon from '../../icons/email-light.png';
import phoneDarkIcon from '../../icons/phone-dark.png';
import phoneLightIcon from '../../icons/phone-light.png';
import crossIcon from "../../icons/cross-white.png";
import saveIcon from "../../icons/check-dark.png";
import deleteIcon from "../../icons/closed-dark.png";
import editIcon from "../../icons/signature-dark.png";

import forbiddenLight from "../../icons/forbidden-light.png";
import forbiddenDark from "../../icons/forbidden-dark.png";

import error404 from "../../img/404-error.png";

import './settingsAuthUserDesc.scss';

const SettingsAuthUserDesc = ({authUser, setAuthUser, loadingPicture, loadingError, pictureData}) => {

    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [edicting, setEdicting] = useState(false);
    const [showSave, setShowSave] = useState(true);
    const [newData, setNewData] = useState(authUser);
    
    const getAnyRol = (roles) => {
        if (roles) {
            if (
                roles.accountant === true ||  
                roles.inventoryManager === true ||  
                roles.salesSupervisor === true ||  
                roles.seller === true
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const [anyRole, setAnyRole] = useState(authUser ? getAnyRol(authUser.roles) : null);

    const [phone, setPhone] = useState(authUser ? authUser.phone : null);

    const [userNameAlert, setUserNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [isSeller, setIsSeller] = useState(authUser ? (authUser.roles ? authUser.roles.seller : false) : false);
    const [isSalesSupervisor, setIsSalesSupervisor] = useState(authUser ? (authUser.roles ? authUser.roles.salesSupervisor : false) : false);
    const [isInventoryManager, setIsInventoryManager] = useState(authUser ? (authUser.roles ? authUser.roles.inventoryManager : false) : false);
    const [isAccountant, setIsAccountant] = useState(authUser ? (authUser.roles ? authUser.roles.accountant : false) : false);

    const isSellerHandler = () => {
        
        const roles = { ...newData.roles };

        setIsSeller(!isSeller);
        roles.seller = !isSeller;

        if (!isSeller) {
            setIsSalesSupervisor(false);
            roles.salesSupervisor = false;
        }
        
        setNewData({
            ...newData,
            "roles": roles,
        });
    }

    const isSalesSupervisorHandler = () => {

        const roles = { ...newData.roles };

        setIsSalesSupervisor(!isSalesSupervisor);
        roles.salesSupervisor = !isSalesSupervisor;

        if (!isSalesSupervisor) {
            setIsSeller(false);
            roles.seller = false;
        }
        
        setNewData({
            ...newData,
            "roles": roles,
        });
    }

    const isInventoryManagerHandler = () => {
        setIsInventoryManager(!isInventoryManager);
        const roles = { ...newData.roles };
        roles.inventoryManager = !isInventoryManager;
        setNewData({
            ...newData,
            "roles": roles,
        });
    }

    const isAccountantHandler = () => {
        setIsAccountant(!isAccountant);
        const roles = { ...newData.roles };
        roles.accountant = !isAccountant;
        setNewData({
            ...newData,
            "roles": roles,
        });
    }

    const onClickEdict = () => {
        setEdicting(true);
    }

    const onClickCancel = () => {
        setEdicting(false);

        setIsSeller(authUser ? authUser.roles.seller : false);
        setIsSalesSupervisor(authUser ? authUser.roles.salesSupervisor : false);
        setIsInventoryManager(authUser ? authUser.roles.inventoryManager : false);
        setIsAccountant(authUser ? authUser.roles.accountant : false);

        setNewData(authUser);
        setUserNameAlert({
            alert: false,
        });
    }

    const onChange = e => {
        if (e.target.name === "name") {
            setUserNameAlert({
                alert: false,
            });
        }

        if (e.target.name === "phone") {

            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            setPhone(phoneFormatted);
            if (phoneFormatted.trim() === "") {
                setNewData({
                    ...newData,
                    [e.target.name]: ""
                });
            }
        } else {
            setNewData({
                ...newData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if (i === 0) {
                if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                    phoneFormatted += phoneValue[i];
                }
            } else {
                if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNewData({
                ...newData,
                [e.target.name]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if (phoneFormatted.trim().length > 0) {
                setNewData({
                    ...newData,
                    [e.target.name]: "+1" + phoneFormatted
                })
                setPhone("+1" + phoneFormatted);
            }

        }
    }

    useEffect(() => {
        setIsSeller(authUser ? (authUser.roles ? authUser.roles.seller : false) : false);
        setIsSalesSupervisor(authUser ? (authUser.roles ? authUser.roles.salesSupervisor : false): false);
        setIsInventoryManager(authUser ? (authUser.roles ? authUser.roles.inventoryManager : false): false);
        setIsAccountant(authUser ? (authUser.roles ? authUser.roles.accountant : false): false);

        setNewData(authUser);
        setPhone(authUser ? authUser.phone : null);
    }, [authUser]);

    useEffect(() => {
        if (edicting) {
            setAnyRole(true)
        } else {
            setAnyRole(authUser ? getAnyRol(authUser.roles) : null);
        }
    }, [edicting, authUser])

    async function updateAuthUser() {
        setLoading(true);
        setAlertActive(false);

        try {
            const docToSend = {
                "roles": newData.roles,
                "email": newData.email.trim(),
                "name": newData.name.trim(),
                "phone": newData.phone,
                "status": newData.status,
            }

            await firebase.useEdictAuthUser({
                "authUid": authUser.uid,
                "businessId": accountData.id,
                "docId": authUser.id,
                "newDoc": docToSend,
            });

            setAuthUser(newData);

            setLoading(false);
            setEdicting(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Updated data',
                    es: 'Datos actualizados'
                }
            });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to update data',
                    es: 'Error al tratar de actualizar los datos'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const updateAuthUserConfirmation = () => {
        if (newData.name.trim() === "") {
            setUserNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        }

        if (newData !== authUser) {
            updateAuthUser();
        }
    }

    useEffect(() => {
        if (authUser && newData) {
            if ((newData === authUser) && (phone === authUser.phone)) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
        // eslint-disable-next-line
    }, [newData, phone]);

    async function removeAuthUser() {
        setLoading(true);
        setAlertActive(false);

        try {
            await firebase.useRemoveAuthUser({
                "authUid": authUser.uid,
                "email": authUser.email,
                "businessId": accountData.id,
                "docId": authUser.id,
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'User successfully deleted',
                    es: 'Usuario eliminado exitosamente'
                }
            });

            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/usuarios-autorizados', { replace: true });
            }
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to remove the user',
                    es: 'Error al tratar de remover al usuario'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const removeAuthUserConfirmation = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Remove user?',
                es: '¿Remover usuario?'
            },
            code: 'confirm-log-out',
            description: {
                en: `Are you sure you want to remove ${authUser ? authUser.name : "..."} from this account?`,
                es: `¿Está seguro de que deseas remover a ${authUser ? authUser.name : "..."} de esta cuenta?`
            }
        });
        setOnAgree(() => removeAuthUser);
        setAlertActive(true);
    }

    return (
        <div className={`settings-auth-user-cont ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            {authUser ?
                <Fragment>
                    {loading ? <Spinner /> :
                        <Fragment>
                            <div className="settings-auth-user-name">
                                {loadingPicture ?
                                    <div className="auth-user-view-profile-yes-img-big-cont unselectable">
                                        <div
                                            style={{
                                                backgroundColor: "rgba(128, 128, 128, 0.13)",
                                                borderRadius: "100%",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                            className="aut-use-view-pro-yes-img-big-con-div"
                                        >
                                            <Spinner3 />
                                        </div>
                                    </div>
                                :   
                                    loadingError ?
                                        <div className='settings-auth-user-name-error-img'>
                                            <img src={error404} alt="Error" />
                                        </div>
                                        :
                                        <div className='settings-auth-user-name-img'>
                                            <img src={pictureData ? pictureData.pictureFile : "/profile.svg"} alt="User" />
                                        </div>
                                        
                                }

                                {edicting ?
                                    <div className="settings-auth-user-edict-name-status-cont">
                                        <div>
                                            <p>{appLanguage.en ? 'Name: ' : 'Nombre: '}</p>
                                            <input
                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                className={`settings-auth-user-contact-input-name ${userNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                name="name"
                                                value={newData ? newData.name : null}
                                                onChange={e => onChange(e)}
                                            />
                                        </div>
                                        <div>
                                            <p>{appLanguage.en ? 'Status: ' : 'Estado:'}</p>
                                            <select
                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                className={`settings-auth-user-contact-input-status ${appTheme.dark ? "input-border-box-dark dar-pri-bg" : "input-border-box-light lig-pri-bg"}`}
                                                name="status"
                                                value={newData ? newData.status : null}
                                                onChange={e => onChange(e)}
                                            >
                                                <option value="active">{appLanguage.en ? 'Allow access' : 'Permitir acceso'}</option>
                                                <option value="block">{appLanguage.en ? 'Block access' : 'Bloquear acceso'}</option>
                                            </select>
                                        </div>
                                    </div>
                                    : <div className='settings-auth-user-view-name-status-cont'>
                                        <p><b>{authUser.name}</b></p>
                                        {authUser.status === "active" ?
                                            <p className='settings-auth-user-name-status-active'> {appLanguage.en ? 'Access granted' : 'Acceso permitido'}</p>
                                            :
                                            <p className='settings-auth-user-name-status-blocked' >{appLanguage.en ? 'Access blocked' : 'Acceso bloqueado'}</p>
                                        }
                                    </div>}
                            </div>
                            <hr className="settings-auth-user-hr" />
                            <div className="settings-auth-user-data">
                                <p><b>{appLanguage.en ? 'Contact:' : 'Contacto:'}</b></p>
                                <div className="settings-auth-user-contact-cont">
                                    <p className='settings-auth-user-contact-p'><img className='settings-auth-user-contact-icon' src={appTheme.dark ? emailDarkIcon : emailLightIcon} alt='Check' />
                                        {authUser.email}
                                    </p>
                                    <p className='settings-auth-user-contact-p'><img className='settings-auth-user-contact-icon' src={appTheme.dark ? phoneDarkIcon : phoneLightIcon} alt='Check' />{edicting ?
                                        <input
                                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                            className={`settings-auth-user-contact-input-phone ${appTheme.dark ? "input-border-box-dark dar-pri-bg" : "input-border-box-light lig-pri-bg"}`}
                                            type='text'
                                            name='phone'
                                            value={phone}
                                            onChange={e => onChange(e)}
                                            inputMode="numeric"
                                            maxLength={20}
                                            onBlur={handleInputBlur}
                                        />
                                        :
                                        authUser.phone
                                    }</p>
                                </div>
                            </div>
                            <hr className="settings-auth-user-hr" />
                            <div className="settings-auth-user-access">
                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "5px"}}><b>{appLanguage.en ? 'Roles assigned to the user:' : 'Roles asignados al usuario:'}</b></p>
                                <div className="settings-auth-user-access-cont">
                                    {anyRole ? 
                                        <div className="settings-auth-user-access-cont-first">
                                            {authUser.roles.seller || edicting ? <Fragment>
                                                <p className='settings-auth-user-access-p'>
                                                    {edicting ?
                                                        <input
                                                            className='settings-auth-user-access-input'
                                                            type="checkbox"
                                                            checked={isSeller}
                                                            onChange={isSellerHandler}
                                                            name='seller'
                                                            id='seller'
                                                        />
                                                        :
                                                        <img className='settings-auth-user-access-icon' src={authUser.roles.seller ? checkIcon : uncheckIcon} alt='Check' />
                                                    }
                                                    <label
                                                        htmlFor='seller'
                                                        style={edicting ? {
                                                            cursor: "pointer",
                                                            userSelect: "none"
                                                        } : null}
                                                    >
                                                        <b>{appLanguage.en ? 'Seller: ' : 'Vendedor: '}</b>
                                                        
                                                    </label>
                                                </p>
                                                <ul style={{marginLeft: "30px", marginBottom: "35px"}}>
                                                    <li>Gestionar clientes (crear, modificar, eliminar)</li>
                                                    <li>Realizar ventas</li>
                                                    <li>Consultar productos</li>
                                                </ul>
                                            </Fragment> : null}

                                            {authUser.roles.salesSupervisor || edicting ? <Fragment>
                                                <p className='settings-auth-user-access-p'>
                                                    {edicting ?
                                                        <input
                                                            className='settings-auth-user-access-input'
                                                            type="checkbox"
                                                            checked={isSalesSupervisor}
                                                            onChange={isSalesSupervisorHandler}
                                                            name='salesSupervisor'
                                                            id='salesSupervisor'
                                                        />
                                                        :
                                                        <img className='settings-auth-user-access-icon' src={authUser.roles.salesSupervisor ? checkIcon : uncheckIcon} alt='Check' />
                                                    }
                                                    <label
                                                        htmlFor='salesSupervisor'
                                                        style={edicting ? {
                                                            cursor: "pointer",
                                                            userSelect: "none"
                                                        } : null}
                                                    >
                                                        <b>{appLanguage.en ? 'Sales Supervisor: ' : 'Supervisor de Ventas: '}</b>
                                                    </label>
                                                </p>
                                                <ul style={{marginLeft: "30px", marginBottom: "35px"}}>
                                                    <li>Gestionar clientes (crear, modificar, eliminar)</li>
                                                    <li>Realizar y cancelar ventas</li>
                                                    <li>Aplicar descuentos y rebajas</li>
                                                    <li>Procesar devoluciones de productos de clientes</li>
                                                    <li>Consultar productos</li>
                                                </ul>
                                            </Fragment> : null}

                                            {authUser.roles.inventoryManager || edicting ? <Fragment>
                                                <p className='settings-auth-user-access-p'>
                                                    {edicting ?
                                                        <input
                                                            className='settings-auth-user-access-input'
                                                            type="checkbox"
                                                            checked={isInventoryManager}
                                                            onChange={isInventoryManagerHandler}
                                                            name='inventoryManager'
                                                            id='inventoryManager'
                                                        />
                                                        :
                                                        <img className='settings-auth-user-access-icon' src={authUser.roles.inventoryManager ? checkIcon : uncheckIcon} alt='Check' />
                                                    }
                                                    <label
                                                        htmlFor='inventoryManager'
                                                        style={edicting ? {
                                                            cursor: "pointer",
                                                            userSelect: "none"
                                                        } : null}
                                                    >
                                                        <b>{appLanguage.en ? 'Inventory Manager: ' : 'Gestor de Inventario: '}</b>
                                                    </label>
                                                </p>
                                                <ul style={{marginLeft: "30px", marginBottom: "35px"}}>
                                                    <li>Gestionar proveedores (crear, modificar, eliminar)</li>
                                                    <li>Realizar y cancelar compras</li>
                                                    <li>Gestionar productos (crear, modificar, eliminar)</li>
                                                    <li>Consultar inventario</li>
                                                    <li>Procesar devoluciones de productos a proveedores</li>
                                                    <li>Procesar perdidas</li>
                                                </ul>
                                            </Fragment> : null}

                                            {authUser.roles.accountant || edicting ? <Fragment>
                                                <p className='settings-auth-user-access-p'>
                                                    {edicting ?
                                                        <input
                                                            className='settings-auth-user-access-input'
                                                            type="checkbox"
                                                            checked={isAccountant}
                                                            onChange={isAccountantHandler}
                                                            name='accountant'
                                                            id='accountant'
                                                        />
                                                        :
                                                        <img className='settings-auth-user-access-icon' src={authUser.roles.accountant ? checkIcon : uncheckIcon} alt='Check' />
                                                    }
                                                    <label
                                                        htmlFor='accountant'
                                                        style={edicting ? {
                                                            cursor: "pointer",
                                                            userSelect: "none"
                                                        } : null}
                                                    >
                                                        <b>{appLanguage.en ? 'Accountant: ' : 'Contador: '}</b>
                                                    </label>
                                                </p>
                                                <ul style={{marginLeft: "30px", marginBottom: "35px"}}>
                                                    <li>Consultar ventas y compras</li>
                                                    <li>Consultar productos</li>
                                                    <li>Acceder a la sección de impuestos</li>
                                                    <li>Consultar métricas del negocio (ganancias, pérdidas, etc.)</li>
                                                </ul>
                                            </Fragment> : null}
                                        </div>
                                    :      
                                        <div style={{ 
                                            display: 'flex', 
                                            width: "100%", 
                                            height: "150px", 
                                            justifyContent: "center", 
                                            alignItems: "center",
                                            flexDirection: "column"
                                        }}>
                                            <img style={{width: "25px", height: "25px", marginBottom: "10px"}} src={appTheme.dark ? forbiddenDark : forbiddenLight} alt="Forbidden" />
                                            <p>{appLanguage.en ? "This user has no assigned roles" : "Esto usurio no tiene roles asignados"}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="settings-auth-user-bottons">
                                {edicting ? <Fragment>
                                    <button
                                        onClick={onClickCancel}
                                        className={isTouchEnabled() ? "edict-auth-user-prof-button-no-hover" : "edict-auth-user-prof-button-hover"}
                                    >
                                        <img src={crossIcon} alt="Cancel" />
                                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                    </button>
                                    <button
                                        onClick={updateAuthUserConfirmation}
                                        disabled={!showSave}
                                        className={isTouchEnabled() ? "edict-auth-user-prof-button-no-hover" : "edict-auth-user-prof-button-hover"}
                                        style={!showSave ? {
                                            backgroundColor: "#3f526698",
                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                            cursor: "default"
                                        } : null}
                                    >
                                        <img src={saveIcon} alt="Save" />
                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                    </button>
                                </Fragment> : <Fragment>
                                    <button
                                        onClick={removeAuthUserConfirmation}
                                        className={isTouchEnabled() ? "edict-auth-user-prof-button-no-hover" : "edict-auth-user-prof-button-hover"}
                                    >
                                        <img src={deleteIcon} alt="Delete" />
                                        {appLanguage.en ? <p>Remove</p> : <p>Remover</p>}
                                    </button>
                                    <button
                                        onClick={onClickEdict}
                                        className={isTouchEnabled() ? "edict-auth-user-prof-button-no-hover" : "edict-auth-user-prof-button-hover"}
                                    >
                                        <img src={editIcon} alt="Save" />
                                        {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                    </button>
                                </Fragment>}
                            </div>
                        </Fragment>
                    }
                </Fragment>
                : null}
        </div>
    );
}

export default SettingsAuthUserDesc;