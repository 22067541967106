import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import SettingsEdictProfile from '../../../components/settings-edict-profile/settingsEdictProfile';
import PictureProfileViewer from '../../../appTools/appToolsComponents/pictureProfileViewer/pictureProfileViewer';

import '../myProfile.page.scss';

const EdictProfilePage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);

    const [ pictureProfileViewerActive, setPictureProfileViewerActive ] = useState(false);

    const [uploadingPicture, setUploadingPicture] = useState(false);

    const onClickPicture = () => {
        setPictureProfileViewerActive(true);
    }

    const observedRef = useRef(null);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return null;
            }
            return observedRef.current.offsetWidth + 12
        } else {
            return null;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    setWidth(observedRef.current.offsetWidth + 12)
                } catch { }
            });

            resizeObserver.observe(observedRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Edict profile' : 'Editar perfil' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    <PictureProfileViewer
                        viewerWidth={ width ? width + 5 : ""} 
                        pictureProfileViewerActive={pictureProfileViewerActive}
                        setPictureProfileViewerActive={setPictureProfileViewerActive}
                        setUploadingPicture={setUploadingPicture}
                    />
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "My Profile" : "Mi perfil"}
                            textLink={"/mi-perfil"}
                            text2={appLanguage.en ? 'Edict profile' : 'Editar perfil'}
                        />
                    : null}
                    <div 
                        ref={observedRef} 
                        className="settings-options-container"
                    >
                        <div className="settings-options-container-item">
                            <SettingsEdictProfile
                                onClickPicture={onClickPicture} 
                                uploadingPicture={uploadingPicture}
                                setUploadingPicture={setUploadingPicture}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EdictProfilePage;