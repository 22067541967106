import './spinner.scss';
import SpinnerOutSideDark from './spinnerOutSideDark';
import SpinnerOutSideLight from './spinnerOutSideLight';
import { AppContext } from '../../context/AppContext';
import { useContext } from 'react';

const Spinner = () => {

    const { appTheme, appLanguage } = useContext(AppContext);

    return (
        <div className="spinner-conainer-general">
            <div>
                {appTheme.dark ? <SpinnerOutSideDark /> : <SpinnerOutSideLight /> }
            </div>
            
            <p>{appLanguage.en ? 'Loading' : 'Cargando' }</p>
        </div>
    );
}

export default Spinner;