import { Fragment, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../alert/alertContext';
import { AuthContext } from '../../firebase/context';
import { useNavigate } from 'react-router-dom';

import firebase from '../../firebase';

import './settingsLeavingCard.scss';

import Spinner from '../spinner/spinner';

import brokenIconDark from '../../icons/broken-link-dark.png';
import brokenIconLight from '../../icons/broken-link-light.png';

import sendIconDark from '../../icons/send-dark.png';

const SettingsLeavingCard = ({ title, businessName }) => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData, userData, eliminateAllCustomers } = useContext(AuthContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [passwordState, setPasswordState] = useState("");

    const [passwordAlert, setPasswordAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if (e.target.name === "password") {
            setPasswordAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        setPasswordState(e.target.value)
    }

    async function leaveAccountFunction() {
        setLoading(true);
        setAlertActive(false);

        try {
            let docId = null;
            
            userData.businessAccounts.forEach(account => {
                if (accountData.id === account.id) {
                    docId = account.doc;
                }
            });

            await firebase.useConfirmPassword(passwordState);
            await firebase.useLeaveAccount({
                "businessId": accountData.id,
                "docId": docId,
            });

            eliminateAllCustomers();

            // if everything is gone sucessfuly
            setAlertActive(false);
            setLoading(false);

            navigate('/elegir-cuenta', { replace: true });

        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to leave account',
                    es: 'Error al intentar abandonar cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const leaveAccountConfirmation = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Leave account',
                es: 'Abandonar cuenta'
            },
            code: 'confirm-log-out',
            description: {
                en: `Are you sure you want to leave "${businessName}"?`,
                es: `¿Estás seguro de que quieres abandonar "${businessName}"?`
            }
        });
        setOnAgree(() => leaveAccountFunction);
        setAlertActive(true);
    }

    const validar = e => {
        e.preventDefault();

        if (passwordState.trim() === "") {
            setPasswordAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;

        } else {
            if (passwordState.length < 8) {
                setPasswordAlert({
                    alert: true,
                    enText: '* Password must be at least 8 characters',
                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                });
                return;
            }

        }

        leaveAccountConfirmation();
    }

    return (
        <Fragment>
            {loading ? <Spinner /> : <Fragment>
                <div className={`settings-leaving-card-container`}>
                    <div className='settings-leaving-card-container-div'>
                        <img className='settings-leaving-card-img' src={appTheme.dark ? brokenIconDark : brokenIconLight} alt='Email' />
                        <p><b>{title}</b></p>
                    </div>
                    <br />
                    <div className='settings-leaving-card-page-div'>
                        <form className={'settings-leaving-card-page-form'} >
                            <label
                                className="settings-leaving-card-input-description"
                                htmlFor="password"
                            >{appLanguage.en ? 'Your profile password : ' : 'La contraseña de tu perfil : '} <b>{passwordAlert.alert ? (
                                appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                            ) : "*"}</b></label>
                            <input
                                style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                className={`settings-leaving-card-input-content ${passwordAlert.alert ? 'settings-leaving-card-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={appLanguage.en ? 'Type password...' : 'Digite contraseña...'}
                                onChange={e => onChange(e)}
                            />
                            <br />
                            <button
                                className={`settings-leaving-card-button-container`}
                                onClick={validar}
                                type="submit"
                            >
                                <img
                                    src={sendIconDark}
                                    alt="Login button"
                                    className='settings-leaving-card-button-container-img'
                                />
                                <p className='settings-leaving-card-button-container-text'>{appLanguage.en ? 'Confirm' : 'Confirmar'}</p>
                            </button>
                        </form>
                    </div>
                </div>
            </Fragment>}
        </Fragment>
    );
}

export default SettingsLeavingCard;