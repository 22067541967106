import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import './settingsDespCard.scss';

const SettingsDespCard= ({text, admin}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`settings-desp-card-container ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <p>{text}</p>
            <br/>
            <p><b>Administrador:</b></p>
            <div 
                className={`admin-view-card-container ${appTheme.dark ? "admin-view-card-container-dar" : "admin-view-card-container-lig"}`}
            >
                <div className='admin-view-card-img-div'>
                    <img className='admin-view-card-img' src={admin.urlPicture ? admin.urlPicture : "/profile.svg"} alt="User" />
                </div> 
                <div className='admin-view-card-div'>
                    <p><b>{admin.name}</b></p>
                    <p>{admin.email}</p>
                </div>
            </div>
        </div>
    );
}

export default SettingsDespCard;