import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";
import magnifyingIcon from "../../../icons/magnifying-glass.png";

import "./customerSearchBox.scss";

const CustomerSearchBox = ({autoFocus, controler, setControler, search}) => {
    const { appTheme, appLanguage } = useContext(AppContext);

    const handleChange = e => {
        setControler({
            ...controler,
            "searchingText": e.target.value,
        });
    }

    let inputRef = null;

    const setRefernce = (inputElement)=>{
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }  
        } catch (error) {
            console.warn(error);
        }
        
        setControler({
            ...controler,
            "searchingText": "",
        });
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            search(e);
        }
    }

    const [searchInputFocus, setSearchInputFocus ] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ){
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ){
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    return (
        <div className="customer-search-box-main-container">
            {controler ? <Fragment>
                <div className="div-search-input-home" style={{ marginTop: 0}}>
                    <input
                        autoFocus={autoFocus}
                        ref={setRefernce} 
                        onBlur={checkSearchInput}
                        onFocus={checkSearchInput}
                        type="search"
                        autoComplete="off"
                        autoCorrect="off"
                        className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                        value={controler.searchingText}
                        onChange={e => handleChange(e)}
                        name="searchingText"
                        onKeyDown={e => handleKeyPress(e)}
                        maxLength={30}
                        placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    />
                    {controler.searchingText === "" ? null : 
                        <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                            <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                            </button>
                        </div>
                    }
                    <button
                        className="search-button-home" 
                        type="submit"
                        onClick={search}
                    >
                        <img src={magnifyingIcon} alt="Search box" />
                    </button>
                </div>
            </Fragment> : null}
        </div>
    );
}

export default CustomerSearchBox;