import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AuthContext } from "../../../firebase/context";

import saleIconDark from "../../../icons/dollar-sign-dark.png";
import moreIconDark from "../../../icons/more-dark.png";

import "./customerViewHeaderMain.scss";
import { AlertContext } from "../../../components/alert/alertContext";

const CustomerViewHeaderMain = ({customerAccount, headerObservedRef}) => {
    const { appLanguage, appTheme, formatPhoneNumber } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setCustomerInShoppingCart, shoppingCart } = useContext(AuthContext);
    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);
  
    const navigate = useNavigate();
    const location = useLocation();

    const copyAccountNumber = () => {
        const text = customerAccount.id;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The record number has been copied',
                    es : 'El número de registro ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyEmail = () => {
        const text = customerAccount.email;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The email has been copied',
                    es : 'El correo ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyPhoneNumber = () => {
        const text = customerAccount.phone;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The phone number has been copied',
                    es : 'El número de teléfono ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyName = () => {
        const text = customerAccount.name;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The name has been copied',
                    es : 'El nombre ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const isThisCustomerInTheShoppingCart = (id) => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                if (shoppingCart.customer.id === id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isCustomerSelected = () => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const selectThisCustomer = () => {
        setCustomerInShoppingCart(customerAccount);
        setAlertActive(false);
        navigate("/carrito-de-compras");
    }

    const onSelectCustomer = () => {
        if (isThisCustomerInTheShoppingCart(customerAccount.id)) {
            setCustomerInShoppingCart(customerAccount);
            navigate("/carrito-de-compras");
        } else {
            if (isCustomerSelected()) {
                setAlertData({
                    type: 'question',
                    title: {
                        en: `Do you want to replace the client?`,
                        es: `¿Quieres reemplazar al cliente?`
                    },
                    code: '',
                    description: {
                        en: `You already have a customer selected in the cart. Do you want to replace it with this one?`,
                        es: `Ya tienes un cliente seleccionado en el carrito. ¿Deseas reemplazarlo con este?`
                    }
                });
                setOnAgree(() => selectThisCustomer);
                setAlertActive(true);
            } else {
                selectThisCustomer();
            }
        }
        
    }

    const onClickMoreDetails = () => {
        if(location.state) {
            navigate("/mas-detalles", {state: location.state})
        }
    }

    return (
        <div 
            ref={headerObservedRef} 
            className="customer-view-main-header-main-container"
        >
            <div 
                className={`customer-view-main-header-main-background ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
            > 
                <div className={`customer-view-main-header-main`}>
                    <div 
                        className="customer-view-main-header-sections customer-view-main-header-sections-no-white-space"
                    >
                        <div className="customer-view-main-header-name-div">
                            <p style={{padding: "3px 8px"}}><b>{appLanguage.en ? "Customer: " : "Cliente: "}</b><span onClick={copyName} className="customer-view-main-header-data-p-hover">{customerAccount.name}</span></p>
                        </div>
                        <div className="customer-view-main-header-name-div">
                            <p style={{padding: "3px 8px"}}><b>{appLanguage.en ? "Record" : "Registro"} #</b><span onClick={copyAccountNumber} className="customer-view-main-header-data-p-hover">{customerAccount.id}</span></p>
                        </div>
                    </div>
                    <div 
                        className="customer-view-main-header-section-no-over-flow"
                    >
                        {customerAccount.email.trim() === "" ? <p className="customer-view-main-header-data-p">{appLanguage.en ? "(no email)" : "(sin correo)"}</p> : <p onClick={copyEmail} className="customer-view-main-header-data-p-hover">{customerAccount.email}</p>}
                        {customerAccount.phone.trim() === "" ? <p className="customer-view-main-header-data-p">{appLanguage.en ? "(no phone)" : "(sin teléfono)"}</p> : <p onClick={copyPhoneNumber} className="customer-view-main-header-data-p-hover">{formatPhoneNumber(customerAccount.phone)}</p>}
                    </div>
                    <div className="customer-view-page-main-buttons-sect">
                        <button onClick={onClickMoreDetails} className="customer-view-page-main-button-elem">
                            <img src={moreIconDark} alt="More options" />
                            <p>{appLanguage.en ? "More" : "Más"}</p>
                        </button>
                        <button onClick={onSelectCustomer} className="customer-view-page-main-button-elem">
                            <img src={saleIconDark} alt="Process transation" />
                            <p>{appLanguage.en ? "Sell" : "Vender"}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerViewHeaderMain;