import { useContext, useState, useRef, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { SideMenuContext } from '../../context/SideMenuContext';
import SoftAlert from '../soft-alert/softAlert';

import aztoniaLogo from '../../img/logo192.png';

import cancelIconDark from '../../icons/cancel-dark.png';
import cancelIconLight from '../../icons/cancel-light.png';

import doctsIconDark from '../../icons/settings-dark.png';
import doctsIconLight from '../../icons/settings-light.png';

import themeIcon from '../../img/moon.svg';
import languageIcon from '../../img/translation.svg';
import helpIcon from '../../img/help.svg';
import termsAndCondictIcon from '../../img/terms-and-conditions.svg';

import SideMenuButton from '../buttons/side-menu-button/sideMenuButton';

import './outsideNav.scss';
import SideMenuButtonOs from '../buttons/side-menu-button-os/sideMenuButtonOs';

const OutsideNav = () => {

    const { appTheme, appLanguage, setAppLanguaje, setAppTheme, getCurrentLanguage } = useContext(AppContext);
    const [ dropDownOpen, setDropDownOpen ] = useState(false);
    const { desktopView } = useContext(SideMenuContext);

    const [ isLanguageOpen, setIsLanguageOpen ] = useState(false);
    const [ isThemeOpen, setIsThemeOpen ] = useState(false);

    const onClickButton = () => {
        dropDownOpen ? setDropDownOpen(false) : setDropDownOpen(true);
    }

    // Data to control Language
    let formLanguageTemp = {};
    const getFormLanguageState = () => {
        formLanguageTemp = {
            asInTheSystme : appLanguage.asInTheSystem,
            en: appLanguage.asInTheSystem ? false : appLanguage.en,
            es : appLanguage.asInTheSystem ? false : (appLanguage.en ? false : true)
        }
        return formLanguageTemp;
    }
    const [ languageformState, setLanguageFormState ] = useState(getFormLanguageState());

    useEffect(() => {
        setLanguageFormState(getFormLanguageState());
    // eslint-disable-next-line
    }, [appLanguage]);

    // Data to control theme
    let formStateTemp ={};
    const getFormThemeState = () => {
        formStateTemp = {
            asInTheSystme : appTheme.asInTheSystem,
            dark: appTheme.asInTheSystem ? false : appTheme.dark,
            light : appTheme.asInTheSystem ? false : (appTheme.dark ? false : true)
        }
        return formStateTemp;
    }
    const [ themeformState, setThemeFormState ] = useState(getFormThemeState());

    useEffect(() => {
        setThemeFormState(getFormThemeState());
    // eslint-disable-next-line
    }, [appTheme]);


    let menuRef = useRef();

    const handleChangeLanguage = event => {
        if(event.target.value === "en") {
            formLanguageTemp.asInTheSystme = false;
            formLanguageTemp.en = true;
            formLanguageTemp.es = false;
        } else {
            if(event.target.value === "es") {
                formLanguageTemp.asInTheSystme = false;
                formLanguageTemp.en = false;
                formLanguageTemp.es = true;
            } else {
                formLanguageTemp.asInTheSystme = true;
                formLanguageTemp.en = false;
                formLanguageTemp.es = false;
            }
        }

        setLanguageFormState(formLanguageTemp);

        if(formLanguageTemp.asInTheSystme === true) {
            setAppLanguaje(getCurrentLanguage());
        } else {
            localStorage.setItem("language", JSON.stringify({
                en: formLanguageTemp.en,
                asInTheSystem: formLanguageTemp.asInTheSystme
            }));
            setAppLanguaje({
                en: formLanguageTemp.en,
                asInTheSystem: formLanguageTemp.asInTheSystme
            })
        }
    }

    const handleChangeTheme = event => {
        if(event.target.value === "light") {
            formStateTemp.asInTheSystme = false;
            formStateTemp.light = true;
            formStateTemp.dark = false;
        } else {
            if(event.target.value === "dark") {
                formStateTemp.asInTheSystme = false;
                formStateTemp.light = false;
                formStateTemp.dark = true;
            } else {
                formStateTemp.asInTheSystme = true;
                formStateTemp.light = false;
                formStateTemp.dark = false;
            }
        }

        setThemeFormState(formStateTemp);

        if(formStateTemp.asInTheSystme === true) {
            let firstTheme = {
                dark: false,
                asInTheSystem: true
            }
            if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                firstTheme.dark = true;
            } else {
                firstTheme.dark = false;
            }
            localStorage.setItem("theme", JSON.stringify(firstTheme));
            setAppTheme(firstTheme);
        } else {
            localStorage.setItem("theme", JSON.stringify({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            }));
            setAppTheme({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            })
        }
    }

    useEffect(() => {
        if(dropDownOpen) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    setDropDownOpen(false);
                };
            };

            document.addEventListener("mousedown", handler);

            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        closeOptions();
    }, [dropDownOpen])

    const onClickTheme = () => {
        setIsThemeOpen(!isThemeOpen);
        setIsLanguageOpen(false);
    }

    const onClickLanguage = () => {
        setIsLanguageOpen(!isLanguageOpen);
        setIsThemeOpen(false);
    }

    const closeOptions = () => {
        setIsThemeOpen(false);
        setIsLanguageOpen(false);
    }

    return (
        <nav className={`outside-nav-container ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${desktopView ? "" : "outside-nav-container-line"}`}>
            <div className={`outside-nav-logo`}>
                <img className="outside-nav-logo-img" src={aztoniaLogo}  alt="HazCuentas"/>
            </div>
            <div className="outside-nav-descrip">
                <a className={`outside-nav-descrip-a ${appTheme.dark ? 'dark-text' : 'light-text' }`} href='https://portal.hazcuentas.com' target='_blank' rel="noreferrer">
                    <b>{appLanguage.en ? 'Learn more about us at ' : 'Conoce más sobre nosotros en '}</b>
                    <br/>
                    https://portal.hazcuentas.com
                </a>
            </div>

            <div className='navigation-container-space' />

            <div  className="outside-nav-settings" ref={menuRef}>
                <button className="outside-nav-settings-button" onClick={onClickButton}>
                    {dropDownOpen ? 
                        <img className='outside-nav-settings-icon-open' src={appTheme.dark ? cancelIconDark : cancelIconLight} alt='Menu' />
                    : 
                        <img className='outside-nav-settings-icon-close' src={appTheme.dark ? doctsIconDark : doctsIconLight} alt='Menu' />
                    }
                </button>

                <span className={`pointing-Up-settings ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg" } ${dropDownOpen ? 'active' : 'inactive' }`} />
                <div className={`dropdown-settings ${appTheme.dark ? "dropdown-settings-dark" : "dropdown-settings-light"}   ${dropDownOpen ? 'active' : 'inactive' }`}>
                    <ul className="side-menu-options">
                        <li className={`side-menu-item ${isLanguageOpen ? 'open' : 'closed'} ${appTheme.dark ? "dark" : "light"}`}>
                            <SideMenuButton 
                                description={appLanguage.en ? 'Language' : 'Idioma'}
                                dark={appTheme.dark}
                                imgIcon={languageIcon}
                                downArrow={true}
                                onClickButton={onClickLanguage}
                                isOpen={isLanguageOpen}
                            />
                            <form className={`side-menu-form ${isLanguageOpen ? 'open' : 'closed'}`}>
                                <div className="side-menu-form-input-container">
                                    <input 
                                        type="radio" 
                                        id="spanishLanguage" 
                                        name="fav_language" 
                                        checked={languageformState.es}
                                        value="es"
                                        onChange={handleChangeLanguage}
                                    />
                                    <label htmlFor="spanishLanguage"><span lang="es">Español</span></label>
                                </div>
                                <div className="side-menu-form-input-container">
                                    <input 
                                        type="radio" 
                                        id="englishLanguage" 
                                        name="fav_language"
                                        checked={languageformState.en} 
                                        value="en"
                                        onChange={handleChangeLanguage}
                                    />
                                    <label htmlFor="englishLanguage"><span lang="en">English</span></label>
                                </div>
                            </form>
                        </li>
                        <li className={`side-menu-item ${isThemeOpen ? 'open' : 'closed'} ${appTheme.dark ? "dark" : "light"}`}>
                            <SideMenuButton 
                                description={appLanguage.en ? 'Theme' : 'Tema'}
                                imgIcon={themeIcon}
                                downArrow={true}
                                isOpen={isThemeOpen}
                                onClickButton={onClickTheme}
                            />
                            <form className={`side-menu-form ${isThemeOpen ? 'open' : 'closed'}`}>
                                <div className="side-menu-form-input-container">
                                    <input  
                                        type="radio" 
                                        id="systemTheme" 
                                        name="AppTheme"
                                        checked={themeformState.asInTheSystme}
                                        value="asInTheSystme"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="systemTheme">{appLanguage.en ? 'Default' : 'Predeterminado'}</label>
                                </div>
                                <div className="side-menu-form-input-container">
                                    <input  
                                        type="radio" 
                                        id="lightTheme" 
                                        name="AppTheme"
                                        checked={themeformState.light}
                                        value="light"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="lightTheme">{appLanguage.en ? 'Light' : 'Claro'}</label>
                                </div>
                                <div className="side-menu-form-input-container">
                                    <input  
                                        type="radio" 
                                        id="darkTheme" 
                                        name="AppTheme"
                                        checked={themeformState.dark}
                                        value="dark"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="darkTheme">{appLanguage.en ? 'Dark' : 'Oscuro'}</label>
                                </div>
                            </form>
                        </li>
                        <li className="side-menu-item">
                            <SideMenuButtonOs 
                                description={appLanguage.en ? 'Help' : 'Ayuda'}
                                imgIcon={helpIcon}
                                onClickButton={closeOptions}
                                goTo={"https://portal.hascuentas.com/ayuda"}
                            />
                        </li>
                        <li className="side-menu-item">
                            <SideMenuButtonOs 
                                description={appLanguage.en ? 'Terms and conditions' : 'Términos y condiciones'}
                                imgIcon={termsAndCondictIcon}
                                onClickButton={closeOptions}
                                goTo={"https://portal.hascuentas.com/terminos-y-condiciones"}
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <SoftAlert />
        </nav>
    )
}

export default OutsideNav;