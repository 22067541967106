import "./saleMoreOptions.scss";

import { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

// eslint-disable-next-line
import checkIconDark from "../../../icons/check-dark.png";

import { AlertContext } from "../../../components/alert/alertContext";

import Spinner from "../../../components/spinner/spinner";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { SaleMoreOptionsContext } from "./saleMoreOptionsContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import GetQuoteComponent from "../getQuoteComponent/getQuoteComponent";

const SaleMoreOptions = ({ pickerWidth }) => {
    const { appTheme, appLanguage, formatRationalNumber, getNumberFromCustomString } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext); 

    const { alertActive } = useContext(AlertContext);
    const { shoppingCart, splitPaymentShoppingCart } = useContext(AuthContext);

    const { 
        saleMoreOptionsActive, 
        setSaleMoreOptionsActive,
        option,
        loading,
        // eslint-disable-next-line
        setLoading,
        splitPayment,setSplitPayment,
        firstAmount, setFirstAmount,
        secondAmount, setSecondAmount,
        thirdAmount, setThirdAmount,
        firstAmountAlert, setFirstAmountAlert,
        secondAmountAlert, setSecondAmountAlert,
    } = useContext(SaleMoreOptionsContext);

    let menuRef = useRef();

    // eslint-disable-next-line
    const navigate = useNavigate();

    useEffect(() => {
        if (saleMoreOptionsActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setSaleMoreOptionsActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setSaleMoreOptionsActive(false);
    }

    const getOptionTitle = () => {
        switch (option) {
            case "applyDiscount":
                return appLanguage.en ? "Apply discount" : "Aplicar descuento";
            case "getQuote":
                return appLanguage.en ? "Get quote" : "Obtener presupuesto";
            case "splitPayments":
                return appLanguage.en ? "Split payments" : "Dividir pagos";
            default:
                return "";
        }
    }

    useEffect(() => {
        switch (option) {
            case "applyDiscount":



                break;
            case "getQuote":


                break;
            case "splitPayments":
                setSplitPayment(2);

                break;
            default:


                break;
        }
    // eslint-disable-next-line
    }, [option]);

    const onClickSplitInTwo = () => {
        if (splitPayment !== 2) {
            setSplitPayment(2);
            setFirstAmount("");
            setSecondAmount("");
            setThirdAmount(""); 
            setFirstAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
            setSecondAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }
    }

    const onClickSplitInThree = () => {
        if (splitPayment !== 3) {
            setSplitPayment(3);
            setFirstAmount("");
            setSecondAmount("");
            setThirdAmount("");
            setFirstAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
            setSecondAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }
    }

    const formatAmount = () => {
        if (firstAmount && firstAmount.trim() !== "") {
            setFirstAmount(formatRationalNumber(firstAmount));
        }
        if (secondAmount && secondAmount.trim() !== "") {
            setSecondAmount(formatRationalNumber(secondAmount));
        }
        if (thirdAmount && thirdAmount.trim() !== "") {
            setThirdAmount(formatRationalNumber(thirdAmount));
        }
    }

    const onChangeFirstAmount = (e) => {
        setFirstAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setSecondAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;

        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }

        setFirstAmount(amount);

        if (splitPayment === 2) {
            const newSecondAmount = shoppingCart.total - getNumberFromCustomString(amount);
            if (newSecondAmount <= 0) {
                setSecondAmount("");
                setThirdAmount("");
                setFirstAmountAlert({
                    alert: true,
                    enText: 'Invalid field',
                    esText: 'Campo no válido'
                });
            } else {
                if (!Number.isNaN(newSecondAmount)) {
                    setSecondAmount(formatRationalNumber(newSecondAmount));
                } else {
                    setSecondAmount("");
                }
                setThirdAmount("");
            }
        }

        if (splitPayment === 3) {
            if (secondAmount && getNumberFromCustomString(secondAmount) > 0 && getNumberFromCustomString(amount) > 0) {
                const newThirdAmount = shoppingCart.total  - getNumberFromCustomString(amount) - getNumberFromCustomString(secondAmount);
                if (newThirdAmount <= 0) {
                    setThirdAmount("");
                    setFirstAmountAlert({
                        alert: true,
                        enText: 'Invalid field',
                        esText: 'Campo no válido'
                    });
                } else {
                    if (typeof newThirdAmount === 'number') {
                        setThirdAmount(formatRationalNumber(newThirdAmount));
                    } else {
                        setThirdAmount("");
                    }
                }
            } else {
                if (getNumberFromCustomString(amount) && getNumberFromCustomString(amount) >= shoppingCart.total) {
                    setFirstAmountAlert({
                        alert: true,
                        enText: 'Invalid field',
                        esText: 'Campo no válido'
                    });
                }
                setThirdAmount("");
            }
        }
    }

    const onChangeSecondAmount = (e) => {
        setFirstAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        
        setSecondAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;

        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }

        setSecondAmount(amount);

        if (splitPayment === 3) {
            if (firstAmount && getNumberFromCustomString(firstAmount) > 0 && getNumberFromCustomString(amount) > 0) {
                const newThirdAmount = shoppingCart.total - getNumberFromCustomString(firstAmount) - getNumberFromCustomString(amount);

                if (newThirdAmount <= 0) {
                    setThirdAmount("");
                    setSecondAmountAlert({
                        alert: true,
                        enText: 'Invalid field',
                        esText: 'Campo no válido'
                    });
                } else {
                    if (typeof newThirdAmount === 'number') {
                        setThirdAmount(formatRationalNumber(newThirdAmount));
                    } else {
                        setThirdAmount("");
                    }
                }
            
            } else {
                setThirdAmount("");
            }
        }
    }

    const onApply = () => {
        if (firstAmount) {
            if (firstAmount.trim() === "" || (parseFloat(firstAmount.split(",").join("")) >= shoppingCart.finalPrice ) || (parseFloat(firstAmount.split(",").join("")) < 0)) {
                setFirstAmountAlert({
                    alert: true,
                    enText: 'Invalid field',
                    esText: 'Campo no válido'
                });
                return;
            }  
        } else {
            setFirstAmountAlert({
                alert: true,
                enText: 'Invalid field',
                esText: 'Campo no válido'
            });
            return;
        }


        if (secondAmount) {
            if (secondAmount.trim() === "" || (parseFloat(secondAmount.split(",").join("")) >= shoppingCart.finalPrice ) || (parseFloat(secondAmount.split(",").join("")) < 0)) {
                setSecondAmountAlert({
                    alert: true,
                    enText: 'Invalid field',
                    esText: 'Campo no válido'
                });
                return;
            }  
        } else {
            setSecondAmountAlert({
                alert: true,
                enText: 'Invalid field',
                esText: 'Campo no válido'
            });
            return;
        }
        
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        splitPaymentShoppingCart({
            "firstAmount": firstAmount,
            "secondAmount": secondAmount,
            "thirdAmount": splitPayment === 3 ? thirdAmount : null,
        });
        
        setSaleMoreOptionsActive(false);

        setSoftAlertActive(true);
        setSoftAlertData({
            type : 'sucess',
            text: {
                en : 'The payment has been divided',
                es : 'El pago ha sido dividido.',
            }
        });
    }

    return (
        <div 
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {saleMoreOptionsActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${saleMoreOptionsActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {shoppingCart && option ? (shoppingCart.items.length > 0 ? 
                    <div className='picker-date-general-despcrition' style={{display: "flex", minHeight: "280px"}}>
                        {loading ?
                            <div style={{marginTop: "40px"}}><Spinner/></div>
                        :
                            <Fragment>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                    <h4 style={{ marginLeft: "15px" }}><span style={{fontWeight: "400"}}><b>{getOptionTitle()}</b>{option === "splitPayments" ? `: RD$ ${formatRationalNumber(shoppingCart.total)}` : ""}</span></h4>
                                    <button
                                        onClick={onClickClose}
                                        className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                    >
                                        <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                                    </button>
                                </div>

                                {option === "applyDiscount" ? 
                                    <div>
                                    
                                    </div> 
                                : null}

                                {option === "getQuote" ? 
                                    <GetQuoteComponent />
                                : null}

                                {option === "splitPayments" ? 
                                    <div  style={{ margin: "8px 15px" }}>

                                        <div className="unselectable">
                                            <div style={{marginLeft: 0, marginBottom: "20px"}} className="coll-payme-me-cont">
                                                <button onClick={onClickSplitInTwo} className={`coll-payme-me-btn-left ${splitPayment === 2 ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Two payments" : "Dos pagos"}</button>
                                                <button onClick={onClickSplitInThree} className={`coll-payme-me-btn-right ${splitPayment === 3 ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Three payments" : "Tres pagos"}</button>
                                            </div>
                                        </div>

                                        <p style={{marginTop: "10px"}}>{appLanguage.en ? "First amount" : "Primer monto"}</p>
                                        <input
                                            value={firstAmount}
                                            onChange={(e) => onChangeFirstAmount(e)}
                                            onBlur={formatAmount}
                                            inputMode="numeric"
                                            maxLength={10}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder="RD$ 0.00"
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black"
                                            }}
                                            className={`cash-in-cash-reg-input ${firstAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        <p style={{marginTop: "10px"}}>{appLanguage.en ? "Second amount" : "Segundo monto"}</p>
                                        <input
                                            value={secondAmount}
                                            onChange={(e) => onChangeSecondAmount(e)}
                                            onBlur={formatAmount}
                                            inputMode="numeric"
                                            maxLength={10}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder="RD$ 0.00"
                                            readOnly={splitPayment === 2 ? true : false}
                                            style={splitPayment === 2 ? {
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                border: "none",
                                                outline: "none",
                                                boxShadow: "none"
                                            } : {
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black"
                                            }}
                                            className={`cash-in-cash-reg-input ${secondAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        {splitPayment === 3 ? <Fragment>
                                            <p style={{marginTop: "10px"}}>{appLanguage.en ? "Third amount" : "Tercer monto"}</p>
                                            <input
                                                value={thirdAmount}
                                                onBlur={formatAmount}
                                                readOnly
                                                inputMode="numeric"
                                                maxLength={10}
                                                autoComplete="off"
                                                autoCorrect="off"
                                                placeholder="RD$ 0.00"
                                                style={{
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                    color: appTheme.dark ? "white" : "black",
                                                    border: "none",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                className={`cash-in-cash-reg-input ${false ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                            />
                                        </Fragment> : null}

                                        <div className="unselectable" style={{ display: "flex", flexDirection: "column" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "30px",
                                                marginBottom: "15px"
                                            }}
                                        >
                                            <button
                                                onClick={onApply}
                                                className="cash-in-cash-reg-bottons-button-save"
                                                style={{ width: "150px", maxWidth: "150px" }}
                                            >
                                                <img src={checkIconDark} style={{ marginRight: "10px" }} alt="End shift" />
                                                {appLanguage.en ? <p>Apply</p> : <p>Aplicar</p>}
                                            </button>
                                        </div>
                                    </div>

                                    </div> 
                                : null}

                            </Fragment>
                        }
                    </div>
                : null) : null}
            </div>
        </div>
    )
}

export default SaleMoreOptions;