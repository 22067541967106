import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { SideMenuContext } from "../../context/SideMenuContext";
import { Helmet } from 'react-helmet-async';

import firebase from "../../firebase";
import { query, collection, limit, where, getDocsFromServer, getCountFromServer, orderBy } from "firebase/firestore";

import Spinner from "../../components/spinner/spinner";
import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";
import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";

import CustomerSearchBox from "../../appTools/appToolsComponents/customerSearchBox/customerSearchBox";

import "./searcher.page.scss";

import emptySearch from "../../img/empty-search.svg";
import searchImg from "../../img/search-file.svg";
import ConnectionError from "../../components/connection-error/connectionError";
import ToolTitle from "../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../icons/bullet-point-light.png';
import GeneralResults from "../../appTools/appToolsComponents/generalResults/generalResults";

const SearcherPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { desktopView, isSideMenuMinimized, windowDimension } = useContext(SideMenuContext);
    
    const [ loading, setLoading ] = useState(false); 
    const [ autoFocus, setAutoFocus ] = useState(null);

    const [ showConnectionError, setShowConnectionError ] = useState(null);
    const [ noActions, setNoActions ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    
    const [ empty, setEmpty ] = useState(null);
    const [ controler, setControler ] = useState(null);

    const [ results, setResults ] = useState({
        products: null,
        customers: null,
        suppliers: null,
        sales: null,
    });

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    // All of this is to avoid requesting data 
    // from the backend twice
    const [ refresh, setRefresh ] = useState(0);

    useEffect(() => {
        if (refresh === 1) {
            setRefresh(0);
            if (controler.searchingText.trim() !== "") {
                triggerSearch();
            }
        }
    // eslint-disable-next-line
    }, [refresh]);

    const [ activeRef, setActiveRef ] = useState(false);

    const [ activeId, setActiveId ] = useState(null);

    const setActiveReference = (inputElement)=> {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if(activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest' 
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const onClickLink = (id) => {
        const newState = {
            "showConnectionError": showConnectionError,
            "noActions": noActions,
            "filter": filter,
            "results": results,
            "empty": empty,
            "controler": controler,
            "activeId": id
        }
        navigate(null, { replace: true, state: newState});
    }

    useEffect(() => {
        blink();
        if(location.state) {
            if (refresh === 0) {
                setRefresh(1)
            }

            try {
                setAutoFocus(false);
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            } catch (error) {
                console.warn(error);
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));
            const res = location.state.results;

            if (itemsDeleted && res) {
                function removeObjectsWithIds(array, idsToRemove) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                }

                let products = null;
                if (res.products) {
                    const newList = removeObjectsWithIds(res.products.list, itemsDeleted);
                    if (newList.length > 0) {
                        products = {
                            counter: newList.length,
                            list: newList
                        } 
                    }
                }

                let customers = null;
                if (res.customers) {
                    const newList = removeObjectsWithIds(res.customers.list, itemsDeleted);
                    if (newList.length > 0) {
                        customers = {
                            counter: newList.length,
                            list: newList
                        } 
                    }
                }

                let suppliers = null;
                if (res.suppliers) {
                    const newList = removeObjectsWithIds(res.suppliers.list, itemsDeleted);
                    if (newList.length > 0) {
                        suppliers = {
                            counter: newList.length,
                            list: newList
                        } 
                    }
                }

                let sales = null;
                if (res.sales) {
                    const newList = removeObjectsWithIds(res.sales.list, itemsDeleted);
                    if (newList.length > 0) {
                        sales = {
                            counter: newList.length,
                            list: newList
                        } 
                    }
                }

                setResults({
                    "products": products,
                    "customers": customers,
                    "suppliers": suppliers,
                    "sales": sales,
                });
            } else {
                if (res) {
                    let products = null;
                    if (res.products) {
                        products = res.products;
                    }

                    let customers = null;
                    if (res.customers) {
                        customers = res.customers;
                    }

                    let suppliers = null;
                    if (res.suppliers) {
                        suppliers = res.suppliers;
                    }

                    let sales = null;
                    if (res.sales) {
                        sales = res.sales;
                    }

                    setResults({
                        "products": products,
                        "customers": customers,
                        "suppliers": suppliers,
                        "sales": sales,
                    });
                }
            }

            setShowConnectionError(location.state.showConnectionError);
            setNoActions(location.state.noActions);
            setFilter(location.state.filter);
            
            setEmpty(location.state.empty);
            setControler(location.state.controler);

            setActiveId(location.state.activeId);
        } else {
            setAutoFocus(true);
            setShowConnectionError(false);
            setNoActions(true);
            setFilter("all");
            setResults({
                products: null,
                customers: null,
                suppliers: null,
                sales: null,
            });
            setEmpty(false);
            setControler({
                "searchingText": "",
                "filter": "all",
            });
        }
    // eslint-disable-next-line 
    }, []);
    
    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }
    
    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    useEffect(() => {
        if ((!results.customers) && (!results.products) && (!results.sales) ) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    // eslint-disable-next-line
    }, [results]);

    async function searchIntoProducts(searchingText) {
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }
    
        setLoading(true);

        let products = null;

        if (accessTo.products) {
            try {
                const productsRef = collection(firebase.db, `accounts/${accountData.id}/products`);
    
                const q = query(productsRef, where("searchKeys", "array-contains", searchingText), orderBy("name"), limit(3));
                const qCounter = query(productsRef, where("searchKeys", "array-contains", searchingText), orderBy("name"));
    
                const snapshot = await getDocsFromServer(q);
                const snapshotCounter = await getCountFromServer(qCounter);
    
                const res = [];
    
                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }
    
                        delete thisDoc.searchKeys;
                        
                        res.push(thisDoc)
                    }
                });
    
                if (snapshotCounter.data().count > 0) {
                    products  = {
                        counter: snapshotCounter.data().count,
                        list: Array.isArray(res) ? res : [],
                    }
                }
    
                await searchIntoCustomers(searchingText, products);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowConnectionError(true);
            }
        } else {
            await searchIntoCustomers(searchingText, products);
        }
    }

    async function searchIntoCustomers(searchingText, products) {
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        let customers = null;

        if (accessTo.customers) {
            try {
                const customersRef = collection(firebase.db, `accounts/${accountData.id}/customers`);

                const q = query(customersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"), limit(3));
                const qCounter = query(customersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"));

                const snapshot = await getDocsFromServer(q);
                const snapshotCounter = await getCountFromServer(qCounter);

                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        res.push(thisDoc)
                    }
                });

                if (snapshotCounter.data().count > 0) {
                    customers  = {
                        counter: snapshotCounter.data().count,
                        list: Array.isArray(res) ? res : [],
                    }
                }

                await searchIntoSuppliers(searchingText, products, customers);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowConnectionError(true);
            }
        } else {
            await searchIntoSuppliers(searchingText, products, customers);
        }
    }

    async function searchIntoSuppliers(searchingText, products, customers) {
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        let suppliers = null;

        if (accessTo.suppliers) {
            try {
                const suppliersRef = collection(firebase.db, `accounts/${accountData.id}/suppliers`);

                const q = query(suppliersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"), limit(3));
                const qCounter = query(suppliersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"));

                const snapshot = await getDocsFromServer(q);
                const snapshotCounter = await getCountFromServer(qCounter);

                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        res.push(thisDoc)
                    }
                });

                if (snapshotCounter.data().count > 0) {
                    suppliers  = {
                        counter: snapshotCounter.data().count,
                        list: Array.isArray(res) ? res : [],
                    }
                }

                await searchIntoSales(searchingText, products, customers, suppliers);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowConnectionError(true);
            }
        } else {
            await searchIntoSales(searchingText, products, customers, suppliers);
        }
    }

    async function searchIntoSales(searchingText, products, customers, suppliers) {
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        let sales = null;

        if (accessTo.sales) {
            try {
                const salesRef = collection(firebase.db, `accounts/${accountData.id}/sales`);

                const q = query(salesRef, where("searchKeys", "array-contains", searchingText), orderBy("date", "desc"), limit(3));
                const qCounter = query(salesRef, where("searchKeys", "array-contains", searchingText), orderBy("date", "desc"));

                const snapshot = await getDocsFromServer(q);
                const snapshotCounter = await getCountFromServer(qCounter);

                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        res.push(thisDoc)
                    }
                });

                if (snapshotCounter.data().count > 0) {
                    sales = {
                        counter: snapshotCounter.data().count,
                        list: Array.isArray(res) ? res : [],
                    }
                }

                setResults({
                    "products": products,
                    "customers": customers,
                    "suppliers": suppliers,
                    "sales": sales
                });
                
                setLoading(false);
                setNoActions(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowConnectionError(true);
            }
        } else {
            setResults({
                "products": products,
                "customers": customers,
                "suppliers": suppliers,
                "sales": sales
            });
            
            setLoading(false);
            setNoActions(false);
        }
    }

    const search = async () => {
        setActiveId(null);
        
        if (controler.searchingText.trim() !== "") {
            await searchIntoProducts(optimizeTextForSearch(controler.searchingText));
        }
    }

    useEffect(() => {
        if (controler) {
            setFilter(controler.filter);
            if(controler.filter !== filter) {
                search();
            }
        }
    // eslint-disable-next-line
    }, [controler])

    const tryAgain = () => {
        search();
    }

    const triggerSearch = async () => {
        try {
            const searchingText = optimizeTextForSearch(controler.searchingText);

            let products = null;
            let customers = null;
            let suppliers = null;
            let sales = null;

            // Products ***********************************************************************
            if (accessTo.products) {
                const productsRef = collection(firebase.db, `accounts/${accountData.id}/products`);

                const productsQ = query(productsRef, where("searchKeys", "array-contains", searchingText), orderBy("name"), limit(3));
                const productsQCounter = query(productsRef, where("searchKeys", "array-contains", searchingText), orderBy("name"));

                const productsSnapshot = await getDocsFromServer(productsQ);
                const productsSnapshotCounter = await getCountFromServer(productsQCounter);

                const productsRes = [];

                productsSnapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        productsRes.push(thisDoc)
                    }
                });

                if (productsSnapshotCounter.data().count > 0) {
                    products  = {
                        counter: productsSnapshotCounter.data().count,
                        list: Array.isArray(productsRes) ? productsRes : [],
                    }
                }
            }

            // Customers ***************************************************************************
            if (accessTo.customers) {
                const customersRef = collection(firebase.db, `accounts/${accountData.id}/customers`);

                const customersQ = query(customersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"), limit(3));
                const customersQCounter = query(customersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"));

                const customersSnapshot = await getDocsFromServer(customersQ);
                const customersSnapshotCounter = await getCountFromServer(customersQCounter);

                const customersRes = [];

                customersSnapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        customersRes.push(thisDoc)
                    }
                });

                if (customersSnapshotCounter.data().count > 0) {
                    customers  = {
                        counter: customersSnapshotCounter.data().count,
                        list: Array.isArray(customersRes) ? customersRes : [],
                    }
                }
            }

            // Suppliers ***************************************************************************
            if (accessTo.suppliers) {
                const suppliersRef = collection(firebase.db, `accounts/${accountData.id}/suppliers`);

                const suppliersQ = query(suppliersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"), limit(3));
                const suppliersQCounter = query(suppliersRef, where("searchKeys", "array-contains", searchingText), orderBy("lastTransactionTime", "desc"));

                const suppliersSnapshot = await getDocsFromServer(suppliersQ);
                const suppliersSnapshotCounter = await getCountFromServer(suppliersQCounter);

                const suppliersRes = [];

                suppliersSnapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        suppliersRes.push(thisDoc)
                    }
                });

                if (suppliersSnapshotCounter.data().count > 0) {
                    suppliers  = {
                        counter: suppliersSnapshotCounter.data().count,
                        list: Array.isArray(suppliersRes) ? suppliersRes : [],
                    }
                }
            }
            
            // Sales ***********************************************************************************
            if (accessTo.sales) {
                const salesRef = collection(firebase.db, `accounts/${accountData.id}/sales`);

                const salesQ = query(salesRef, where("searchKeys", "array-contains", searchingText), orderBy("date", "desc"), limit(3));
                const salesQCounter = query(salesRef, where("searchKeys", "array-contains", searchingText), orderBy("date", "desc"));

                const salesSnapshot = await getDocsFromServer(salesQ);
                const salesSnapshotCounter = await getCountFromServer(salesQCounter);

                const salesRes = [];

                salesSnapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }

                        delete thisDoc.searchKeys;
                        
                        salesRes.push(thisDoc)
                    }
                });

                if (salesSnapshotCounter.data().count > 0) {
                    sales = {
                        counter: salesSnapshotCounter.data().count,
                        list: Array.isArray(salesRes) ? salesRes : [],
                    }
                }
            }
            
            // accessTo.purchases ***********

            setResults({
                "products": products,
                "customers": customers,
                "suppliers": suppliers,
                "sales": sales
            });
            setNoActions(false);
        } catch (error) {
            console.log(error);
        }
    }

    let observedRef = useRef(null);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return window.innerWidth;
            } else {
                try {
                    const width = observedRef.current.offsetWidth
                    return width;
                } catch {
                    return window.innerWidth;
                }
            }
        } else {
            return window.innerWidth;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        const newState = {
            "showConnectionError": showConnectionError ,
            "noActions": noActions,
            "filter": filter,
            "results": results,
            "empty": empty,
            "controler": controler,
            "activeId": activeId
        }
        navigate(null, { replace: true, state: newState});
    // eslint-disable-next-line
    }, [controler, noActions, filter, results, empty, controler, activeId]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "General search" : "Búsqueda general"} </title>
            </Helmet>
            <div className={`page-app-searcher-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {desktopView ?
                        <ToolTitle
                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                            text={appLanguage.en ? "General search" : "Búsqueda general"}
                        />
                        : null}
                    {accountData.active ? 
                        <div className={`page-choose-account-item `}>
                            {accessTo.customers || accessTo.products || accessTo.suppliers || accessTo.sales || accessTo.purchases ? <Fragment>
                                <CustomerSearchBox autoFocus={autoFocus} controler={controler} setControler={setControler} search={search}/>
                                {loading ? <Spinner/> : <Fragment>
                                    {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                                        <div 
                                            ref={observedRef}
                                            className="new-searcher-page-content"
                                            style={{ 
                                                colorScheme: appTheme.dark ?  "dark" : "light",
                                                height: `calc(100vh - ${desktopView ? 175 : 134}px)`
                                            }}
                                        >
                                            {empty ? 
                                                <NoCustumesYet noActions={noActions} /> 
                                            :
                                                <GeneralResults 
                                                    results={results} 
                                                    activeId={activeId}
                                                    setActiveReference={setActiveReference}
                                                    onClickLink={onClickLink}
                                                    width={width}
                                                    searchingText={controler ? controler.searchingText : null}
                                                />
                                            }
                                        </div>
                                    </Fragment>} 
                                </Fragment>}
                            </Fragment> : <SectionForbidden sectionName={appLanguage.en ? "General search" : "Búsqueda general"} />}
                        </div>
                    : 
                        <div className="page-app-searcher-item">
                            <div className="searcher-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div> 
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const NoCustumesYet = ({noActions}) => {
    const { appLanguage } = useContext(AppContext);

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noActions ? searchImg : emptySearch} alt="No customers yet" />  
            {noActions ? 
                <p><b>{appLanguage.en ? "Search the entire account." : "Hacer búsqueda en toda la cuenta"}</b></p>
            : 
                <p><b>{appLanguage.en ? "We couldn't find any results for your search" : "No pudimos encontrar ningún resultado para tu búsqueda"}</b></p>
            }
            <p>{appLanguage.en ? "You can search for products, customers, or sales records." : "Puede buscar productos, clientes o registros de ventas."}</p>

        </div>
    )
}

export default SearcherPage;