import { useContext, useRef, useEffect } from 'react';
import { AlertContext } from './alertContext';
import { AppContext } from '../../context/AppContext';

import './alert.scss';

const Alert = () => {

    const { alertActive, setAlertActive, iconAlert, alertData, onAgree } = useContext(AlertContext);
    const { appLanguage } = useContext(AppContext);

    const { appTheme } = useContext(AppContext);
    
    let menuRef = useRef();

    const onClickOk = () => {
        setAlertActive(false);
    }

    useEffect(() => {
        if(alertActive) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    onClickOk();
                };
            };
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    return (
        <div className='unselectable'>
            {alertActive ? <div className='lock-screen-alert' /> : null }
            <div ref={menuRef} className={`alert-genaral-card ${alertActive ? 'active' : 'inactive' }`} style={{background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black"}} >
                
                <div className='alert-general-card-img'>
                    {iconAlert ? <img src={iconAlert} alt='Success' /> : null}
                </div>
                
                <div className='alert-general-despcrition'>
                    <h4>{appLanguage.en ? alertData.title.en : alertData.title.es}</h4>
                    <p>{appLanguage.en ? alertData.description.en : alertData.description.es}</p>
                </div>
                {alertData.type === 'question' ? 
                    <div className='alert-button-container'> 
                        <button
                            className={`alert-button-iten-cancel`}
                            style={{color: appTheme.dark ? "white" : "black"}}
                            onClick={onClickOk}
                        >
                            {appLanguage.en ? 'Cancel' : 'Cancelar'}
                        </button>
                        <button
                            className={`alert-button-iten-yes`}
                            onClick={onAgree}
                        >
                            {appLanguage.en ? 'Yes' : 'Si'}
                        </button>
                    </div> 
                : 
                    <div className='alert-button-container'>
                        <button
                            className={`alert-button-iten`}
                            onClick={onClickOk}
                        >
                            <b>Ok</b> 
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Alert;