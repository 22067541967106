import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { useLocation, useNavigate } from "react-router-dom";
import { uid } from "uid";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import SupplierDetails from "../../../appTools/appToolsComponents/supplierDetails/supplierDetails";
import SupplierEditingInfo from "../../../appTools/appToolsComponents/supplierEditingInfo/supplierEditingInfo";

const SupplierDetailsPage = () => {
    const { appLanguage, appTheme } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    const observedDiv = useRef(null);

    const [ showingDetails, setShowingDetails ] = useState(true);
    const [ showingEditingInfo, setShowingEditingInfo ]= useState(false);
    const [ showingEditingStatus, setShowingEditingStatus ] = useState(false);

    useEffect(() => {
        if (showingDetails) {
            setShowingEditingInfo(false);
            setShowingEditingStatus(false);
        }
    }, [showingDetails]);

    useEffect(() => {
        if (showingEditingInfo) {
            setShowingDetails(false);
            setShowingEditingStatus(false);
        }
    }, [showingEditingInfo]);

    useEffect(() => {
        if (showingEditingStatus) {
            setShowingDetails(false);
            setShowingEditingInfo(false);
        }
    }, [showingEditingStatus]);

    const [ record, setRecord ] = useState(null);

    const [ request, setRequest] = useState("0000");

    useEffect(() => {
        if(location.state) {
            const customerAccountsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));
            if (customerAccountsDeleted) {
                const customerId = location.state.id;
                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }
                if (containsString(customerAccountsDeleted, customerId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    setRequest(uid());
                    setRecord(location.state);
                }
            } else {
                setRequest(uid());
                setRecord(location.state);
            }
        } else {
            navigate("/proveedores", { replace: true })
        }
    // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.suppliers)) {
                navigate("/proveedores", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    // Now I have to listeng to the customer doc
    useEffect(() => {
        if (record && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/suppliers`, record.id), (doc) => {
                const accountUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete accountUpdated.searchKeys;

                if (doc.data()) {
                    setRecord(accountUpdated);
                    navigate(null, { replace: true, state: accountUpdated});
                }
            }, err => {
                console.error(err);
            });
            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [request]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'More details about supplier' : 'Más detalles sobre el proveedor'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && record ? <Fragment>
                    {accountData.active ? 
                        <div className="page-app-settings-item">
                            {accessTo.suppliers ? <Fragment>
                                <div  
                                    ref={observedDiv} 
                                    className="customer-details-page-main-container"
                                    style={{
                                        colorScheme: appTheme.dark ? "dark" :"light",
                                    }}
                                >
                                    {showingDetails ? 
                                        <SupplierDetails record={record} setShowingEditingInfo={setShowingEditingInfo} setShowingEditingStatus={setShowingEditingStatus}/> 
                                    :
                                        <SupplierEditingInfo record={record} setShowingDetails={setShowingDetails} setRecord={setRecord}/>
                                    }
                                </div>
                            </Fragment> : null}
                        </div>
                    : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default SupplierDetailsPage;