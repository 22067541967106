import { useContext, useState, Fragment, useEffect } from 'react';

import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../../components/alert/alertContext';
import { AuthContext } from '../../firebase/context';

import { Helmet } from 'react-helmet-async';

import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import OutsideNav from "../../components/outside-nav/outsideNav";

import cancelIcon from '../../img/cancel.svg';

import firebase from '../../firebase';

import Spinner from "../../components/spinner/spinner";

import sendIconDark from '../../icons/send-dark.png';
import crossIconDark from '../../icons/cancel-dark.png';

import GoBackButton from '../../components/navigation/go-back-button/goBackButton';


const CheckingPage = () => {

    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();

    const { appTheme, appLanguage } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setEmailVerified } = useContext(AuthContext);

    const [ nuevoUsuario, setNuevoUsuario ] = useState({
        password : "",
        repeatPassword : ""
    });

    const [ passwordAlert , setPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ repeatPasswordAlert , setRepeatPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if(e.target.name === "password") {
            setPasswordAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if(e.target.name === "repeatPassword") {
                setRepeatPasswordAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }

        setNuevoUsuario({
            ...nuevoUsuario,
            [e.target.name] : e.target.value
        })
    }

    const  validar = e => {
        e.preventDefault();

        if (nuevoUsuario.password.trim()=== "") {
            setPasswordAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;

        } else {
            if(nuevoUsuario.password.length < 8) {
                setPasswordAlert({
                    alert: true,
                    enText: '* Password must be at least 8 characters',
                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                });
                return;
            } else {
                if (nuevoUsuario.repeatPassword.trim()=== "") { //
                    setRepeatPasswordAlert({
                        alert: true,
                        enText: '* Required field',
                        esText: '* Campo requerido'
                    });
                    return;
                } else {
                    if(nuevoUsuario.repeatPassword.length < 8) {
                        setRepeatPasswordAlert({
                            alert: true,
                            enText: '* Password must be at least 8 characters',
                            esText: '* La contraseña debe ser de al menos 8 caracteres'
                        });
                        return;
                    } else {
                        if (nuevoUsuario.repeatPassword.trim() !== nuevoUsuario.password) {
                            setRepeatPasswordAlert({
                                alert: true,
                                enText: '* Both passwords must be the same',
                                esText: '* Ambas contraseñas deben ser iguales'
                            });
                            return;
                        }
                    }
                }
            }
        }

        resetPasswordWithLink(nuevoUsuario.password);
    }

    const [ mode ] = useSearchParams();
    const linkMode = mode.get('mode');
    const linkCode = mode.get('oobCode');
    const linkApiKey = mode.get('apiKey');

    const typeOfLink = () => {
        if(linkMode === 'verifyEmail') {
            return 'verifyEmail';
        } else {
            if(linkMode === 'resetPassword') {
                return 'resetPassword';
            } else {
                return false;
            }
        }
    }

    useEffect(() => {
        const linkCodeLocal = JSON.parse(localStorage.getItem('linkCodeLocal'));
        if(linkMode === 'verifyEmail') {
            setLoading(true);
            if(linkCodeLocal) {
                if(linkCodeLocal !== linkCode) {
                    localStorage.setItem("linkCodeLocal", JSON.stringify(linkCode));
                    verifyEmailAddress();
                }
            } else {
                localStorage.setItem("linkCodeLocal", JSON.stringify(linkCode));
                verifyEmailAddress();
            }
        }
    // eslint-disable-next-line
    }, []);

    async function verifyEmailAddress(e) {
        if(e) {
            e.preventDefault();
        }
        setLoading(true);
        try {
            await firebase.useVerifyEmailLink(linkCode, linkApiKey);
            setAlertData({
                type : 'success',
                title: {
                    en : 'Verified email',
                    es : 'Correo verificado'
                },
                code : '',
                description : {
                    en : 'Your email has been successfully verified.',
                    es : 'Su correo electrónico ha sido verificado con éxito.'
                }
            });
            setLoading(true);
            localStorage.setItem("isEmailVerified", JSON.stringify(true));
            setEmailVerified(true);
            setAlertActive(true);
            localStorage.removeItem('linkCodeLocal');
            navigate('/', { replace: true });

        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            localStorage.removeItem('linkCodeLocal');
            console.log(code);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to verify email',
                    es : 'Error al intentar verificar correo'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);

        }
    }

    async function resetPasswordWithLink(password) {

        setLoading(true);

        try {
            await firebase.useResetPassworkLink(linkCode, linkApiKey, password);
            setAlertData({
                type : 'success',
                title: {
                    en : 'Password created',
                    es : 'Contraseña creada'
                },
                code : '',
                description : {
                    en : 'Your new password has been successfully created.',
                    es : 'Su nueva contraseña ha sido creada con éxito.'
                }
            });
            setLoading(false);
            setAlertActive(true);
            navigate('/', { replace: true });
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to create new passwork',
                    es : 'Error al intentar crear nueva contraseña'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
            setNuevoUsuario({
                password : "",
                repeatPassword : ""
            });
        }
    }

    return (
        <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} >    
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                    <title>HazCuentas - {appLanguage.en ? 'Verifying link' : 'Verificando enlace' } </title>
            </Helmet>
            <div className="outside-nav-space" />
            <OutsideNav />
            {loading ? 
                <div style={{marginTop: "80px"}}><Spinner/></div>
            : <Fragment>
                <div 
                    className="login-page-card-container"
                    style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                >
                    <div className={`creating-account-container login-page-card-mobile-view ${appTheme.dark ? 'dar-sec-bg dar-box-sha-keep-hover' : 'lig-sec-bg lig-box-sha-keep-hover'}`}>
                        <div className="login-page-card-title">
                            <GoBackButton/>
                            <h3 className="recover-password-h3">{appLanguage.en ? 'Verifying link' : 'Verificando enlace'}</h3>
                        </div>
                            <form className={`login-page-form-mobile`} >
                                {typeOfLink() === 'verifyEmail' ? 
                                    <div className='verify-data-email'>
                                        <p className='verify-data-card-text'>{appLanguage.en ? 'It was not possible to verify this link, do you want to try again?' : 'No fue posible verificar este enlace, ¿quieres intentarlo de nuevo?'}</p>
                                        <div className='verify-data-card-buttons'>
                                            <Link 
                                                className={`outside-button-container dar-box-sha-hover verify-email-cancel-dark width-100`}
                                                to={"/"}
                                                replace
                                            >
                                                <img 
                                                    src={crossIconDark}
                                                    alt="Cancel"
                                                    className='outside-button-container-img'
                                                />   
                                                <p className='outside-button-container-text'>{appLanguage.en ? 'Cancel' : 'Cancelar'}</p> 
                                            </Link>
                                            <button 
                                                className={`outside-button-container dar-pri dar-box-sha-hover`}
                                                onClick={verifyEmailAddress}
                                                type="submit"
                                            >
                                                <img 
                                                    src={sendIconDark}
                                                    alt="Verify"
                                                    className='outside-button-container-img'
                                                />   
                                                <p className='outside-button-container-text'>{appLanguage.en ? 'Verify' : 'Verificar'}</p> 
                                            </button>
                                        </div>
                                    </div>
                                : null}
                                {typeOfLink() === 'resetPassword' ? 
                                    <div className='verify-data-password'>
                                        <h4>{appLanguage.en ? 'Create a new passwork' : 'Crea una contraseña nueva'}</h4>
                                        <br/>
                                        <label 
                                            className="input-description" 
                                            htmlFor="password"
                                        >{appLanguage.en ? 'Password : ' : 'Contraseña : '} <b>{passwordAlert.alert ? (
                                            appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                                        ) : "*"}</b></label>
                                        <input 
                                            className={`input-content ${passwordAlert.alert ? 'input-content-alert' : null }`} 
                                            type="password" 
                                            name="password" 
                                            id='password'
                                            value={nuevoUsuario.password}
                                            placeholder={appLanguage.en ? 'Type password...' : 'Escribir contraseña...'}
                                            onChange={ e => onChange(e)}
                                        />
                                        <label 
                                            className="input-description" 
                                            htmlFor="repeatPassword"
                                        >{appLanguage.en ? 'Repeat password : ' : 'Repite la contraseña : '} <b>{repeatPasswordAlert.alert ? (
                                            appLanguage.en ? repeatPasswordAlert.enText : repeatPasswordAlert.esText
                                        ) : "*"}</b></label>
                                        <input 
                                            className={`input-content ${repeatPasswordAlert.alert ? 'input-content-alert' : null }`} 
                                            type="password" 
                                            name="repeatPassword" 
                                            id="repeatPassword"
                                            value={nuevoUsuario.repeatPassword}
                                            placeholder={appLanguage.en ? 'Type password...' : 'Escribir contraseña...'}
                                            onChange={ e => onChange(e)}
                                        />
                                        <button 
                                            className={`outside-button-container dar-pri dar-box-sha-hover`}
                                            onClick={validar}
                                            type="submit"
                                        >
                                            <img 
                                                src={sendIconDark}
                                                alt="Reset password"
                                                className='outside-button-container-img'
                                            />   
                                            <p className='outside-button-container-text'>{appLanguage.en ? 'Save' : 'Guardar'}</p> 
                                        </button>
                                    </div>
                                : null}
                                {!typeOfLink() ? 
                                    <div className='verify-data-invalid'>

                                        <img src={cancelIcon} alt='Error' />
                                        <h4>{appLanguage.en ? 'This link is invalid' : 'Este enlace no es válido'}</h4>
                                    </div>
                                : null }
                            </form>
                    </div>
                </div>
            </Fragment>}
        </div>
    );
}

export default CheckingPage;