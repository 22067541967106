import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AlertContext } from "../../../components/alert/alertContext";

import firebase from "../../../firebase";
import { collection, query, limit, getCountFromServer, getDocsFromServer, where, orderBy, startAt, startAfter, doc, getDoc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import deleteIconDark from '../../../icons/closed-dark.png';
import edictIconDark from '../../../icons/signature-dark.png';

import addImgDark from "../../../icons/plus-mini-dark.png";
import addImgLight from "../../../icons/plus-mini-light.png";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";
import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";

import "./inventory.page.scss";

import InventorySelectTable from "../../../appTools/appToolsComponents/inventorySelectTable/inventorySelectTable";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const InventoryPage = () => {

    let observedRef = useRef(null);

    const location = useLocation();

    const { accountData, accessTo, setProductListener, products, productsStatistics } = useContext(AuthContext);
    const { appTheme, appLanguage, formatRationalNumber, isNumber, isTouchEnabled, setDropDownCartOpen, setDropDownProfileMenuOpen, getCategory, getRate } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { desktopView, windowDimension, isSideMenuMinimized, smallView } = useContext(SideMenuContext);

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    // This will be save only in Ram Memory
    const [selecting, setSelecting] = useState(false);

    useEffect(() => {
        setActiveId(null);
    }, [selecting]);

    const [selectingAll, setSelectingAll] = useState(false);

    const [productsSelected, setProductsSelected] = useState([]);

    const [showConnectionError, setShowConnectionError] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setProductListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const recentlyOpenedAccounts = Array.isArray(products) ? products : [];

    const [docsCount, setDocsCount] = useState(null);

    const [loading, setLoading] = useState(true);

    const [longProcess, setLongProcess] = useState(false);

    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    useEffect(() => {
        if (results) {
            if (results.every(item => iAmSelected(item.id))) {
                setSelectingAll(true);
            } else {
                setSelectingAll(false);
            }
        } else {
            setSelectingAll(false);
        }
    // eslint-disable-next-line
    }, [results, productsSelected]);

    const getRecordById = (idToFind) => {
        const result = results.find(element => element.id === idToFind);
        return result;
    }
    
    const onClickSelectAll = () => {
        if (selectingAll) {
            const stringsToRemove = results.map(obj => obj.id);
            const removalSet = new Set(stringsToRemove);
            const filteredArray = productsSelected.filter(item => !removalSet.has(item.id));
            setProductsSelected(filteredArray);
        } else {
            if (results) {
                const temNotSelected = [...productsSelected];
                for (let i = 0; i < results.length; i++) {
                    // 999 = Limit of 1,000
                    if (temNotSelected.length <= 999) {
                        if (!temNotSelected.some(selected => selected.id === results[i].id)) {
                            temNotSelected.push(results[i]);
                        }
                    } else {
                        setSoftAlertActive(true);
                        setSoftAlertData({
                            type: 'error',
                            text: {
                                en: 'You cannot select more than 1,000 records.',
                                es: 'No puedes seleccionar más de 1,000 registros.',
                            }
                        });
                        break;
                    }
                }
                setProductsSelected(temNotSelected);
            }
        }
    }

    const onClickLink = (product) => {
        if (selecting) {
            // Check if the notification is selected or not
            if (iAmSelected(product.id)) {
                const temNotSelected = productsSelected.filter(element => element.id !== product.id);
                setProductsSelected(temNotSelected);
            } else {
                const temNotSelected = [...productsSelected];

                // 999 = Limit of 1,000
                if (temNotSelected.length <= 999) {
                    temNotSelected.push(getRecordById(product.id));
                    setProductsSelected(temNotSelected);
                } else {
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'error',
                        text: {
                            en: 'You cannot select more than 1,000 records.',
                            es: 'No puedes seleccionar más de 1,000 registros.',
                        }
                    });
                }
            }
        } else {
            let newControler = null;

            if (controler) {
                newControler = {
                    ...controler,
                    "triggerSearch": false,
                }
            }

            const newState = {
                "results": results,
                "filter": filter,
                "docsCount": docsCount,
                "controler": newControler,
                "pagesHistory": pagesHistory,
                "navControlers": navControlers,
                "showConnectionError": showConnectionError,
                "empty": empty,
                "showPageNav": showPageNav,
                "activeId": product.id,
                "searchingByTextActive": searchingByTextActive,
                "loading": false,
            }

            navigate(null, { replace: true, state: newState });
            navigate("/detalles-de-producto", { state: product });
        }
    }

    useEffect(() => {
        if (location.state) {

            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount)
            setFilter(location.state.filter);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            function removeObjectsWithIds(array, idsToRemove) {
                if (Array.isArray((array))) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                } else {
                    return null;
                }
            }

            if (location.controler) {
                if (
                    products &&
                    page === 1 &&
                    location.state.controler.filter === "name" &&
                    location.state.controler.category === "all" &&
                    !location.controler.priceFrom &&
                    !location.controler.priceTo &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
                    // filtar
                    const res = recentlyOpenedAccounts;
                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                } else {
                    // filtar
                    const res = location.state.results;

                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                }
            } else {
                // filtar
                const res = location.state.results;

                if (itemsDeleted) {
                    const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                    setResults(filteredArray);
                } else {
                    setResults(res);
                }
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setResults(recentlyOpenedAccounts);

            setControler({
                "filter": "name",
                "category": "all",
                "priceFrom": null,
                "priceTo": null,
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (navControlers && productsStatistics) {
            if (
                products &&
                navControlers.page === 1 &&
                controler.filter === "name" &&
                controler.category === "all" &&
                !controler.priceFrom &&
                !controler.priceTo &&
                controler.searchingText.trim() === ""
            ) {
                const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
                setResults(recentlyOpenedAccounts);

                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
            }
        }

        if (productsStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                if (productsStatistics.all > 0) {
                    setEmpty(false);
                    if (productsStatistics.all > 30) {
                        setShowPageNav(true);
                    } else {
                        setShowPageNav(false);
                    }
                } else {
                    setEmpty(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [products, productsStatistics, docsCount]);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, results]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, width, isSideMenuMinimized]);

    const [disabled, setDisabled] = useState(true);

    const [category, setCategory] = useState("all");
    const [priceFrom, setPriceFrom] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [sortBy, setSortBy] = useState("name");

    useEffect(() => {
        if (category === "all" && priceFrom === "" && priceTo === "" && sortBy === "name") {
            if (filter) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line
    }, [category, priceFrom, priceTo, sortBy])

    const [showAlertFrom, setShowAlertFrom] = useState(false);
    const [showAlertTo, setShowAlertTo] = useState(false);

    useEffect(() => {
        if (productsStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                setDocsCount(productsStatistics.all);
            }
        }
    }, [filter, productsStatistics, controler]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);
    const [addIcon, setAddIcon] = useState(appTheme.dark ? addImgDark : addImgLight);
    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const [dropDownAddOpen, setDropDownAddOpen] = useState(false);
    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownAddOpen(false);
        setDropDownOptionsOpen(false);
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    let menuRef = useRef();
    let addRef = useRef();
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownOptionsOpen, appTheme]);

    useEffect(() => {
        if (dropDownAddOpen) {
            setAddIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setAddIcon(appTheme.dark ? addImgDark : addImgLight);
        }
    }, [dropDownAddOpen, appTheme]);

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    useEffect(() => {
        if (controler) {
            if (
                pageNumber === 1 &&
                products &&
                controler.filter === "name" &&
                controler.category === "all" &&
                !controler.priceFrom &&
                !controler.priceTo &&
                controler.searchingText.trim() === ""
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchSales(controler);
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    const searchSales = async (controler) => {
        setActiveId(null);

        if (
            products &&
            navControlers.page === 1 &&
            controler.filter === "name" &&
            controler.category === "all" &&
            !controler.priceFrom &&
            !controler.priceTo &&
            controler.searchingText.trim() === ""
        ) { return; }

        // eslint-disable-next-line
        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (filter) {
            if (filter.category === "all") {
                if (isNumber(filter.from)) {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    }
                } else {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                            );
                        }

                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                            );
                        }
                    }
                }
            } else {
                if (isNumber(filter.from)) {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    }
                } else {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("category", "==", filter.category),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                where("category", "==", filter.category),
                                orderBy("nameInsensitive"),
                            );
                        }

                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("category", "==", filter.category),
                            );
                        }
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    orderBy("nameInsensitive"),
                    limit(30)
                );
            } else {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                qNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                );
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc);
                }
            });

            // eslint-disable-next-line
            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setShowPageNav(productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);
                if (filter) {
                    if (filter.sortBy === "name") {
                        updateOrPushHistory({
                            "page": 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    } else {
                        updateOrPushHistory({
                            "page": 1,
                            "startAt": res[0].finalPrice,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].finalPrice,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                } else {
                    updateOrPushHistory({
                        "page": 1,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
            // eslint-disable-next-line
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        setActiveId(null);
        blink();

        setControler({
            "filter": "name",
            "category": "all",
            "priceFrom": null,
            "priceTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setResults(products);

        setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (filter) {
                if (filter.sortBy === "name") {
                    updateOrPushHistory({
                        "page": pageN,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                } else {
                    updateOrPushHistory({
                        "page": pageN,
                        "startAt": res[0].finalPrice,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].finalPrice,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": pageN,
                    "startAt": res[0].nameInsensitive,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].nameInsensitive,
                    "endAtDocId": res[res.length - 1].id,
                });
            }

            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickPrevious = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
            onResetAll();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = null;
            let lastQueryNoOrder = null;

            if (filter) {
                if (filter.category === "all") {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                } else {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    where("category", "==", filter.category),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                );
                            }
                        }
                    }
                }
            } else {
                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );

                    // To get the docsCount to control the page nav
                    lastQueryNoOrder = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                    );
                }
            }


            await goToPagePrevious(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = null;
                    if (filter) {
                        if (filter.sortBy === "name") {
                            newHistory = [
                                {
                                    "page": 1,
                                    "startAt": results[0].nameInsensitive,
                                    "startAtDocId": results[0].id,
                                    "endAt": results[results.length - 1].nameInsensitive,
                                    "endAtDocId": results[results.length - 1].id,
                                },
                                {
                                    "page": navControlers.page + 1,
                                    "startAt": res[0].nameInsensitive,
                                    "startAtDocId": res[0].id,
                                    "endAt": res[res.length - 1].nameInsensitive,
                                    "endAtDocId": res[res.length - 1].id,
                                }
                            ];
                        } else {
                            newHistory = [
                                {
                                    "page": 1,
                                    "startAt": results[0].finalPrice,
                                    "startAtDocId": results[0].id,
                                    "endAt": results[results.length - 1].finalPrice,
                                    "endAtDocId": results[results.length - 1].id,
                                },
                                {
                                    "page": navControlers.page + 1,
                                    "startAt": res[0].finalPrice,
                                    "startAtDocId": res[0].id,
                                    "endAt": res[res.length - 1].finalPrice,
                                    "endAtDocId": res[res.length - 1].id,
                                }
                            ];
                        }
                    } else {
                        newHistory = [
                            {
                                "page": 1,
                                "startAt": results[0].nameInsensitive,
                                "startAtDocId": results[0].id,
                                "endAt": results[results.length - 1].nameInsensitive,
                                "endAtDocId": results[results.length - 1].id,
                            },
                            {
                                "page": navControlers.page + 1,
                                "startAt": res[0].nameInsensitive,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].nameInsensitive,
                                "endAtDocId": res[res.length - 1].id,
                            }
                        ];
                    }

                    setPageHistory(newHistory);
                } else {
                    if (filter) {
                        if (filter.sortBy === "name") {
                            updateOrPushHistory({
                                "page": navControlers.page + 1,
                                "startAt": res[0].nameInsensitive,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].nameInsensitive,
                                "endAtDocId": res[res.length - 1].id,
                            });
                        } else {
                            updateOrPushHistory({
                                "page": navControlers.page + 1,
                                "startAt": res[0].finalPrice,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].finalPrice,
                                "endAtDocId": res[res.length - 1].id,
                            });
                        }
                    } else {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                }
            } else {
                if (filter) {
                    if (filter.sortBy === "name") {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    } else {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].finalPrice,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].finalPrice,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            }
            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickNext = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            endAtDocId = lastProduct.id;

            if (filter) {
                if (filter.sortBy === "name") {
                    startAfterOrAfterText = lastProduct.nameInsensitive;
                } else {
                    startAfterOrAfterText = lastProduct.finalPrice;
                }
            } else {
                startAfterOrAfterText = lastProduct.nameInsensitive;
            }
        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, endAtDocId));
            }

            if (filter) {
                if (filter.category === "all") {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                } else {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("category", "==", filter.category),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    where("category", "==", filter.category),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("category", "==", filter.category),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    where("category", "==", filter.category),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                }
            } else {
                if (searchingBy === "") {
                    // To get the sales docs
                    nextQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        orderBy("nameInsensitive"),
                        endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    nextQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                        endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                        limit(30)
                    );

                    // To get the docsCount to control the page nav
                    nextQueryNoOrder = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                    );
                }
            }

            await goToNextPage(nextQuery);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    useEffect(() => {
        if (dropDownMenuOpen) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setDropDownMenuOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownAddOpen) {
            let handler = (e) => {
                if (addRef.current) {
                    if (!addRef.current.contains(e.target)) {
                        setDropDownAddOpen(false);
                    };
                } else {
                    setDropDownAddOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    const onClickAddButton = () => {
        dropDownAddOpen ? setDropDownAddOpen(false) : setDropDownAddOpen(true);
    }

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (loading) {
            return;
        }
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
            setSelecting(false);
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const refreshSearch = async () => {
        if (
            products &&
            navControlers.page === 1 &&
            controler.filter === "name" &&
            controler.category === "all" &&
            !controler.priceFrom &&
            !controler.priceTo &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
            setResults(recentlyOpenedAccounts);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
                onResetAll();
                return
            }

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                    docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                if (filter) {
                    if (filter.category === "all") {
                        if (isNumber(filter.from)) {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        } else {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        }
                    } else {
                        /******************************* */
                        if (isNumber(filter.from)) {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where("category", "==", filter.category),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where("category", "==", filter.category),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("category", "==", filter.category),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("category", "==", filter.category),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        } else {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where("category", "==", filter.category),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where("category", "==", filter.category),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("category", "==", filter.category),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("category", "==", filter.category),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        }
                    }
                } else {
                    if (searchingBy === "") {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/products`),
                            orderBy("nameInsensitive"),
                            startAt(docSnap ? docSnap : startAtText),
                            limit(30)
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/products`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("nameInsensitive"),
                            startAt(docSnap ? docSnap : startAtText),
                            limit(30)
                        );
                    }
                }

                await goToPagePrevious(lastQuery, navControlers.page);

                setLoading(false);

                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const onClickApplyFilter = () => {
        if ((priceFrom === "") !== (priceTo === "")) {
            if (priceFrom === "") {
                setShowAlertFrom(true);
                return;
            }

            if (priceTo === "") {
                setShowAlertTo(true);
                return;
            }
        }

        setFilter({
            "from": priceFrom === "" ? "" : Number(priceFrom),
            "to": priceTo === "" ? "" : Number(priceTo),
            "sortBy": sortBy,
            "category": category
        });

        setControler({
            ...controler,
            "filter": sortBy,
            "category": category,
            "priceFrom": priceFrom === "" ? "" : Number(priceFrom),
            "priceTo": priceTo === "" ? "" : Number(priceTo),
            "searchingText": searchingText,
            "triggerSearch": true,
        });

        setPageHistory([]);

        setDropDownMenuOpen(false);
    }

    const onClickCleanFilter = () => {
        setResults(recentlyOpenedAccounts);

        setControler({
            "filter": "name",
            "category": "all",
            "priceFrom": null,
            "priceTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setNavControlers({
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
        });

        setShowConnectionError(false);

        setEmpty(productsStatistics ? (productsStatistics.all > 0 ? false : true) : true);
        setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);

        setPageNumber(1);

        setSearchingText("");
        setFilter(null);
        setPriceFrom("");
        setPriceTo("");
        setSortBy("name");
        setCategory("all");

        setSearchingTextActive(false);

        setSelecting(false);

        blink();
    }

    const search = async () => {
        if (loading) {
            return;
        }
        if (searchingText.trim() !== "") {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
            setSelecting(false);
        }
    }

    const onChangePriceFrom = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }

        setPriceFrom(amount);
    }

    const onChangePriceTo = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setPriceTo(amount);
    }

    const onChangeSort = (e) => {
        setSortBy(e.target.value);
    }

    const onChangeCategory = (e) => {
        setCategory(e.target.value);
    }

    const getcategoryTest = (sortBy) => {
        if (sortBy === "all") {
            const text = appLanguage.en ? "Showing All" : "Mostrando Todo";
            return text;
        } else {
            const category = getCategory(sortBy);
            const text = `${appLanguage.en ? "Showing " : "Mostrando "} ${category}`;
            return text;
        }
    }

    const getSortByTest = (sortBy) => {
        if (sortBy === "name") {
            const text = appLanguage.en ? "Sorted by name" : "Ordenado por nombre";
            return text;
        } else {
            if (sortBy === "higher") {
                const text = appLanguage.en ? "From highest to lowest price" : "De mayor a menor precio";
                return text;
            } else {
                if (sortBy === "lower") {
                    const text = appLanguage.en ? "From lowest to highest price" : "De menor a mayor precio";
                    return text;
                } else {
                    return sortBy
                }
            }
        }
    }

    const tryAgain = () => {
        setActiveId(null);
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    useEffect(() => {
        if (filter) {
            setPriceFrom(filter.from);
            setPriceTo(filter.to);
            setSortBy(filter.sortBy);
        } else {
            setPriceFrom("");
            setPriceTo("");
            setSortBy("name");
            setCategory("all");
        }
        setShowAlertFrom(false);
        setShowAlertTo(false);
        // eslint-disable-next-line
    }, [dropDownMenuOpen]);

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    useEffect(() => {
        if (!selecting) {
            setProductsSelected([]);
        }
    }, [selecting]);

    const onClickSelect = () => {
        if (!selecting && loading) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        } else {
            setSelecting(!selecting);
        }
    }

    const iAmSelected = (idToCheck) => {
        const exists = productsSelected.some(element => element.id === idToCheck);
        return exists;
    }

    async function deleteProducts() {
        setAlertActive(false);

        setLoading(true);
        if (productsSelected.length > 1) {
            setLongProcess(true);
        }

        const idsToRemove = [];

        productsSelected.forEach((product) => {
            idsToRemove.push(product.id);
        })

        try {
            const res = await firebase.useDeleteProducts({
                "productsSelected": idsToRemove,
                "accountId": accountData.id
            });

            setResults(res.products);

            setControler({
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            if (res.statistics) {
                setNavControlers({
                    "page": 1,
                    "totalPage": res.statistics ? Math.ceil(res.statistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": res.statistics ? (Math.ceil(res.statistics.all / 30) > 1 ? true : false) : false
                });
                setEmpty(res.statistics ? (res.statistics.all > 0 ? false : true) : true);
                setShowPageNav(res.statistics ? (Math.ceil(res.statistics.all / 30) > 1 ? true : false) : false);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
                setEmpty(productsStatistics ? (productsStatistics.all > 0 ? false : true) : true);
                setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);
            }

            setShowConnectionError(false);
            setPageNumber(1);
            setSearchingText("");
            setSearchingTextActive(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'success',
                text: {
                    en: `${productsSelected.length > 1 ? "Records" : "Record"} deleted successfully.`,
                    es: `${productsSelected.length > 1 ? "Registros eliminados" : "Registro eliminado"} exitosamente.`
                }
            });

            setSelecting(false);
            setProductsSelected([]);
            setLoading(false);
            setLongProcess(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setLongProcess(false);

            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to delete ${productsSelected.length > 1 ? "records" : "record"}`,
                    es: `Error al tratar de eliminar ${productsSelected.length > 1 ? "los registros" : "el registro"}`
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
        }
    }

    const onDelete = () => {
        if (productsSelected) {
            if (productsSelected.length > 0) {
                setAlertData({
                    type: 'question',
                    title: {
                        en: productsSelected.length > 1 ? 'Delete selected products?' : 'Delete selected product?',
                        es: productsSelected.length > 1 ? '¿Eliminar productos seleccionados?' : '¿Eliminar producto seleccionado?'
                    },
                    code: '',
                    description: {
                        en: productsSelected.length > 1 ? `Are you sure you want to delete ${productsSelected.length} products?` : 'Are you sure you want to remove this product?',
                        es: productsSelected.length > 1 ? `¿Estás seguro de que deseas eliminar ${productsSelected.length} productos?` : '¿Estás seguro de que deseas eliminar este producto?'
                    }
                });
                setOnAgree(() => deleteProducts);
                setAlertActive(true);
            }
        }
    }

    const edictProducts = () => {
        if (productsSelected) {
            if (productsSelected.length > 0) {
                navigate("/editar-productos", { state: {editingRecords: productsSelected, currentRecords: productsSelected} });
            }
        }
    }

    const downloadInExcell = () => {
        if (productsSelected) {
            if (productsSelected.length > 0) {
                blink();

                const data = [];
                
                productsSelected.forEach((product) => {
                    if (appLanguage.en) {
                        data.push({
                            "Name": product.name,
                            "Description": product.description,
                            "Barcode": product.barcode ? String(product.barcode) : null,
                            "Location": product.location,
                            "Quantity": product.quantity,
                            "Category": getCategory(product.category),
                            "Rate": getRate(product.rate),
                            "Price without ITBIS": `RD$ ${formatRationalNumber(product.priceWithoutTax)}`,
                            "% ITBIS": product.itbisPercentage,
                            "RD$ ITBIS": `RD$ ${formatRationalNumber(product.itbisAmount)}`,
                            "Final Price": `RD$ ${formatRationalNumber(product.finalPrice)}`
                        });
                    } else {
                        data.push({
                            "Nombre": product.name,
                            "Descripción": product.description,
                            "Código de Barra": product.barcode ? String(product.barcode) : null,
                            "Ubicación": product.location,
                            "Cantidad": product.quantity,
                            "Categoría": getCategory(product.category),
                            "Tarifa": getRate(product.rate),
                            "Precio sin ITBIS": `RD$ ${formatRationalNumber(product.priceWithoutTax)}`,
                            "% ITBIS": product.itbisPercentage,
                            "RD$ ITBIS": `RD$ ${formatRationalNumber(product.itbisAmount)}`,
                            "Precio Final": `RD$ ${formatRationalNumber(product.finalPrice)}`
                        });
                    }
                });

                // Preprocess data to replace empty or null fields with '--'
                const processedData = data.map(item => {
                    const newItem = {};
                    for (const key in item) {
                        newItem[key] = item[key] !== null && item[key] !== undefined ? item[key] : '--';
                    }
                    return newItem;
                });

                // Create a new workbook and a worksheet
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(processedData);

                // Set column widths
                const colWidths = [
                    { wch: 30 },  // Name
                    { wch: 50 },  // Description
                    { wch: 15 },  // Barcode
                    { wch: 15 },  // Location
                    { wch: 10 },  // Quantity
                    { wch: 10 },  // Category
                    { wch: 10 },  // Rate
                    { wch: 15 },  // Price without ITBS
                    { wch: 10 },  // % ITBS
                    { wch: 15 },  // RD$ ITBS
                    { wch: 15 },  // Final Price
                ];
                ws['!cols'] = colWidths;

                // Define cell formats
                const numberFormat = '#,##0.00'; // Example number format with two decimal places
                const percentageFormat = '0.00%'; // Example percentage format
                const currencyFormat = '"RD$ "#,##0.00'; // Example currency format for RD$

                ws['E1'].s = { numFmt: numberFormat }; // Quantity
                ws['G1'].s = { numFmt: numberFormat }; // Rate
                ws['H1'].s = { numFmt: currencyFormat }; // Price without ITBS
                ws['I1'].s = { numFmt: percentageFormat }; // % ITBS
                ws['J1'].s = { numFmt: currencyFormat }; // RD$ ITBS
                ws['K1'].s = { numFmt: currencyFormat }; // Final Price

                // Apply formats to the entire column
                for (let i = 2; i <= data.length + 1; i++) {
                    ws[`E${i}`].s = { numFmt: numberFormat }; // Quantity
                    ws[`G${i}`].s = { numFmt: numberFormat }; // Rate
                    ws[`H${i}`].s = { numFmt: currencyFormat }; // Price without ITBS
                    ws[`I${i}`].s = { numFmt: percentageFormat }; // % ITBS
                    ws[`J${i}`].s = { numFmt: currencyFormat }; // RD$ ITBS
                    ws[`K${i}`].s = { numFmt: currencyFormat }; // Final Price
                }

                // Add worksheet to workbook
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                // Create Excel file as a Blob
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([wbout], { type: 'application/octet-stream' });

                // Trigger the download using file-saver
                saveAs(blob, `${appLanguage.en ? "Products" : "Productos"}.xlsx`);

                setSelecting(false);
                setDropDownOptionsOpen(false);
            }
        }
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, filter, docsCount, searchingByTextActive]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Inventory' : 'Inventario'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && controler ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.inventory ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Inventory' : 'Inventario'}
                                        />
                                        : null}

                                    <div className="products-services-first-section">
                                        <div className="products-services-first-item">
                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                    onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                            <div style={{ maxWidth: "420px", width: "420px", justifyContent: "flex-end", minHeight: "58.4px" }} className="sales-filter-botton-main unselectable">
                                                {!selecting ?
                                                    <div ref={menuRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickUserButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                width: smallView ? "60px" : "100px"
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: smallView ? 0 : "10px"
                                                                }}
                                                                src={filterIcon}
                                                                alt="Filter"
                                                            />
                                                            {smallView ? null : <p style={dropDownMenuOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Filter" : "Filtrar"}</p>}
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: smallView ? "32px" : "50px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: smallView ? "0px" : "0px"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                        >

                                                            {smallView ? <p style={{ textAlign: "center", marginBottom: "10px" }}><b>{appLanguage.en ? "Filter products" : "Filtrar productos"}</b></p> : null}

                                                            <label className="filter-labels-inputs" htmlFor="category">{appLanguage.en ? "Category" : "Categoría"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                name="category"
                                                                id="category"
                                                                value={category}
                                                                onChange={e => onChangeCategory(e)}
                                                            >
                                                                <option value="all">{appLanguage.en ? "All" : "Todo"}</option>
                                                                <option value="general" >{appLanguage.en ? "General" : "General"}</option>
                                                                <option value="service">{appLanguage.en ? "Services" : "Servicios"}</option>
                                                                <option value="fool">{appLanguage.en ? "Food" : "Alimentos"}</option>
                                                                <option value="technology">{appLanguage.en ? "Technology and Accessories" : "Tecnología y Accesorios"}</option>
                                                                <option value="home">{appLanguage.en ? "Home and Garden" : "Hogar y Jardín"}</option>
                                                                <option value="tool">{appLanguage.en ? "Tools" : "Herramientas"}</option>
                                                                <option value="clothing">{appLanguage.en ? "Clothing" : "Vestimenta"}</option>
                                                                <option value="drink">{appLanguage.en ? "Drinks" : "Bebidas"}</option>
                                                                <option value="care">{appLanguage.en ? "Personal Care and Health" : "Cuidado Personal y Salud"}</option>
                                                                <option value="office">{appLanguage.en ? "Stationery and Office" : "Papelería y Oficina"}</option>
                                                                <option value="pet">{appLanguage.en ? "Pets" : "Mascotas"}</option>
                                                            </select>

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "From " : "Desde "} {showAlertFrom ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <input
                                                                value={priceFrom}
                                                                type="text"
                                                                inputMode="numeric"
                                                                onChange={e => onChangePriceFrom(e)}
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`input-type-filter-price-num-text ${showAlertFrom ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} `}
                                                                placeholder={"RD$ 0.00"}
                                                            />

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "Up to" : "Hasta"} {showAlertTo ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <input
                                                                value={priceTo}
                                                                type="text"
                                                                inputMode="numeric"
                                                                onChange={e => onChangePriceTo(e)}
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`input-type-filter-price-num-text ${showAlertTo ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                                placeholder={"RD$ 0.00"}
                                                            />

                                                            <label className="filter-labels-inputs" htmlFor="seller">{appLanguage.en ? "Sort by" : "Ordenar por"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                name="seller"
                                                                id="seller"
                                                                value={sortBy}
                                                                onChange={e => onChangeSort(e)}
                                                            >
                                                                <option value="name">{appLanguage.en ? "Name" : "Nombre"}</option>
                                                                <option value="higher">{appLanguage.en ? "Higher price" : "Mayor precio"}</option>
                                                                <option value="lower">{appLanguage.en ? "Lower price" : "Menor precio"}</option>
                                                            </select>

                                                            <div className="filter-button-action-div">
                                                                <button
                                                                    disabled={disabled}
                                                                    onClick={onClickApplyFilter}
                                                                    className="filter-button-btn-div"
                                                                    style={disabled ? {
                                                                        backgroundColor: "#3f526698",
                                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "rgba(255, 255, 255, 0.712)",
                                                                        cursor: "default",
                                                                        boxShadow: "none",
                                                                    } : null}
                                                                >
                                                                    {appLanguage.en ? "Apply" : "Aplicar"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                                {!selecting ?
                                                    <div ref={addRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickAddButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                width: smallView ? "60px" : "120px"
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: smallView ? 0 : "10px"
                                                                }}
                                                                src={addIcon}
                                                                alt="Add"
                                                            />
                                                            {smallView ? null : <p style={dropDownAddOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Add" : "Agregar"}</p>}
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownAddOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: smallView ? "32px" : "60px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: smallView ? "-80px" : "-50px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownAddOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            {smallView ? <p><b>{appLanguage.en ? "Add product" : "Agregar producto"}</b></p> : null}

                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/agregar-producto"}
                                                                style={{
                                                                    width: "170px",
                                                                    marginTop: "20px"
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "A record" : "Un registro"} </p>
                                                            </Link>

                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/agregar-productos"}
                                                                style={{
                                                                    width: "170px",
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Multiple records" : "Múltiples registros"} </p>
                                                            </Link>

                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/importar-productos-desde-excel"}
                                                                style={{
                                                                    width: "170px",
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Import from Excel" : "Importar desde Excel"} </p>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                    : null}

                                                {selecting ?
                                                    <div ref={optionsRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickOptionsButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                paddingLeft: "8px",
                                                                paddingRight: "8px",
                                                                width: "90px",
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "10px"
                                                                }}
                                                                src={optionsIcon}
                                                                alt="Options"
                                                            />
                                                            <p style={dropDownOptionsOpen ? { fontWeight: "bold" } : {}}>{productsSelected ? productsSelected.length : ""}</p>
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "45px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "-65px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        >

                                                            <button
                                                                style={{ marginTop: "20px" }}
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onDelete}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                    src={deleteIconDark}
                                                                    alt="Delete"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Delete" : "Eliminar"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={downloadInExcell}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download in Excel" : "Descargar en Excel"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={edictProducts}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                    src={edictIconDark}
                                                                    alt="Edict"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Edit" : "Editar"} </p>
                                                            </button>

                                                        </div>
                                                    </div>
                                                    : null}

                                                <button
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    style={{
                                                        margin: "20px 10px 0 10px",
                                                        height: "38.4px"
                                                    }}
                                                    disabled={loading ? true : false}
                                                    onClick={onClickSelect}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selecting}
                                                        onChange={onClickSelect}
                                                        name='customerAccounts'
                                                        id='customerAccounts'
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light"
                                                        }}
                                                    />
                                                    <p>{appLanguage.en ? "Select" : "Seleccionar"}</p>
                                                </button>
                                            </div>

                                            {filter ?
                                                <div
                                                    className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <div className="filter-sales-details-data">
                                                        <p style={{ fontSize: "15px" }}>{getcategoryTest(filter.category)}</p>
                                                        {isNumber(filter.from) ? <Fragment>
                                                            {filter.from > filter.to ?
                                                                <p style={{ fontSize: "12px", color: "rgba(255, 0, 0, 0.76)" }}><b>*** {appLanguage.en ? "Invalid price range" : "Rango de precios no válido"} ***</b></p>
                                                                :
                                                                <p style={{ fontSize: "12px" }}><b>RD$ {formatRationalNumber(filter.from)} - RD$ {formatRationalNumber(filter.to)}</b></p>
                                                            }
                                                        </Fragment> : <Fragment>
                                                            <p style={{ fontSize: "12px" }}><b>{getSortByTest(filter.sortBy)}</b></p>
                                                        </Fragment>}
                                                    </div>
                                                    <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                                :
                                                searchingByTextActive ?
                                                    <div
                                                        className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <div className="filter-sales-details-data">
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                            <p>{appLanguage.en ? "Sorted by name" : "Ordenado por nombre"}</p>
                                                        </div>
                                                        <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                            <img
                                                                style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                                src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                                alt="Clean filter"
                                                            />
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <InventorySelectTable
                                    onScroll={onScroll}
                                    longProcess={longProcess}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    showConnectionError={showConnectionError}
                                    tryAgain={tryAgain}
                                    results={results}
                                    onClickLink={onClickLink}
                                    activeId={activeId}
                                    setActiveReference={setActiveReference}
                                    pageNumber={pageNumber}
                                    navControlers={navControlers}
                                    showPageNav={showPageNav}
                                    onClickNext={onClickNext}
                                    onClickPrevious={onClickPrevious}
                                    dropDownMenuOpen={dropDownMenuOpen}
                                    dropDownAddOpen={dropDownAddOpen}
                                    dropDownOptionsOpen={dropDownOptionsOpen}
                                    selecting={selecting}
                                    iAmSelected={iAmSelected}
                                    onClickSelectAll={onClickSelectAll}
                                    selectingAll={selectingAll}
                                />
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default InventoryPage;