import "./collectPayment.scss";

import { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import checkIconDark from "../../../icons/check-dark.png";

import checkSvgIcon from "../../../img/check.svg";

import arrowLeftDarkIcon from "../../../icons/arrow-left-dark.png";
import arrowLeftLightIcon from "../../../icons/arrow-left-light.png";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";
import { CollectPaymentContext } from "./collectPaymentContext";
import { useNavigate } from "react-router-dom";

const CollectPayment = ({ pickerWidth }) => {
    const { appTheme, appLanguage, formatRationalNumber, getNumberFromCustomString } = useContext(AppContext);
    const { alertActive, setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { shoppingCart } = useContext(AuthContext);
    const { 
        collectPaymentActive, 
        setCollectPaymentActive, 
        cashAmount, 
        cashToCharge,
        setCashAmount, 
        setCashToCharge,
        loading,
        setLoading,
        isCash,
        setIsCash, 
        paymentMethod, 
        setPaymentMethod,
        paymentNotes, 
        setPaymentNotes, 
        cashAmountAlert, 
        setCashAmountAlert,
        isSecondSectionSelected,
        setIsSecondSectionSelected,
        amountSelected,
        setAmountSelected,
        resetCollectPaymentState
    } = useContext(CollectPaymentContext);

    let menuRef = useRef();

    const navigate = useNavigate();

    let inputRef = null;

    const setStartReference = (inputElement) => {
        inputRef = inputElement;
    }

    useEffect(() => {
        if (collectPaymentActive) {
            try {
                if (inputRef) {
                    // console.log(inputRef)
                    inputRef.focus();
                }
            } catch (error) {
                console.warn(error);
            }
        }
        // eslint-disable-next-line
    }, [collectPaymentActive]);

    useEffect(() => {
        if (collectPaymentActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setCollectPaymentActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setCollectPaymentActive(false);
    }

    const onChangeCashAmount = (e) => {
        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        if (isPoint) {
                            amount += "0";
                        } else {
                            amount += text[i];
                        }
                    }
                }
            }
        }
        setCashAmount(amount);
    }

    const formatAmount = () => {
        if (cashAmount && cashAmount.trim() !== "") {
            setCashAmount(formatRationalNumber(cashAmount));
        }
    }

    const getCustomerChange = () => {
        const change = getNumberFromCustomString(cashAmount) - getNumberFromCustomString(cashToCharge);
        if (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge)) {
            return "0.00"
        } else {
            if (Number.isNaN(change)) {
                return "0.00"
            } else {
                return formatRationalNumber(change);
            }
        }
    }

    const getPaymentMethod = () => {
        if (isCash) {
            return appLanguage.en ? "in cash" : "en efectivo";
        } else {
            switch (paymentMethod) {
                case "card":
                    return appLanguage.en ? "with a credit or debit card" : "con tarjeta de crédito o débito";
                case "bankTransfer":
                    return appLanguage.en ? "via bank transfer" : "vía transferencia bancaria";
                case "link":
                    return appLanguage.en ? "through a payment link" : "a través de un enlace de pago";
                case "check":
                    return appLanguage.en ? "through a check" : "a través de un cheque";
                case "paypal":
                    return appLanguage.en ? "through PayPal" : "a través de PayPal";
                case "other":
                    return appLanguage.en ? "" : "";
                default:
                    return appLanguage.en ? "in cash" : "en efectivo";
            }
        }
    }

    const testPaymentResult = (num) => {
        if (num % 2 === 0) {
            setAlertData({
                type: 'success',
                title: {
                    en: 'The payment was processed successfully',
                    es: 'El pago se proceso de manera exitosa'
                },
                code: '',
                description: {
                    en: `This is a test. Now you can see the sale confirmation page where you can print or send the receipt.`,
                    es: `Esta es una prueba. Ahora puedes ver la página de confirmación de venta donde puedes imprimir o enviar el recibo.`
                }
            });
            setAlertActive(true);
            setLoading(false);
            setCollectPaymentActive(false);
            navigate("/venta-exitosa", {replace: true})
        } else {
            const code = "error";
            
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to process payment`,
                    es: `Error al intentar procesar el pago`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    // For test only
    const processPayment = () => {
        setLoading(true);
        setAlertActive(false);

        setTimeout(() => {
            testPaymentResult(Math.floor(Math.random() * 20) + 1)
        }, 3000)
    }

    const onSaveProcess = () => {
        if (isCash) {
            if (cashAmount.trim() === "" || (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge))) {
                setCashAmountAlert({
                    alert: true,
                    enText: 'Required field',
                    esText: 'Campo requerido'
                });
                return;
            }  
        }
        
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        setAlertData({
            type: 'question',
            title: {
                en: `Process payment?`,
                es: `¿Procesar pago?`
            },
            code: '',
            description: {
                en: `Are you sure you want to save a payment ${getPaymentMethod()} for RD$ ${formatRationalNumber(cashToCharge)}?`,
                es: `¿Estás seguro de que deseas guardar un pago ${getPaymentMethod()} por RD$ ${formatRationalNumber(cashToCharge)}?`
            }
        });
        setOnAgree(() => processPayment);
        setAlertActive(true);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSaveProcess();
        }
    }

    const onClickCash = () => {
        if (!isCash) {
            setIsCash(true);
            // Round 
            if (shoppingCart && (!shoppingCart.splitPayment)) {
                setCashAmount(formatRationalNumber(Math.round(shoppingCart.total)));
                setCashToCharge(formatRationalNumber(Math.round(shoppingCart.total)));
            } else {
                setCashAmount(formatRationalNumber(Math.round(shoppingCart.splitPayment[amountSelected].value)));
                setCashToCharge(formatRationalNumber(Math.round(shoppingCart.splitPayment[amountSelected].value)));
            }
        }
    }

    const onClickAnother = () => {
        if (isCash) {
            setIsCash(false);
            // Remove rounding 
            if (shoppingCart && (!shoppingCart.splitPayment)) {
                setCashAmount(formatRationalNumber(shoppingCart.total));
                setCashToCharge(formatRationalNumber(shoppingCart.total));
            } else {
                setCashAmount(formatRationalNumber(shoppingCart.splitPayment[amountSelected].value));
                setCashToCharge(formatRationalNumber(shoppingCart.splitPayment[amountSelected].value));
            }
        }
    }

    const onChangePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    }

    const onChangePaymentNotes = (e) => {
        setPaymentNotes(e.target.value);
    }

    const selectFirstAmount = () => {
        if (shoppingCart && shoppingCart.splitPayment && shoppingCart.splitPayment.firstAmount) {
            if (!shoppingCart.splitPayment.firstAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("firstAmount");
                setCashAmount(formatRationalNumber(Math.round(shoppingCart.splitPayment.firstAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(shoppingCart.splitPayment.firstAmount.value)));
            }
        }
    }

    const selectSecondAmount = () => {
        if (shoppingCart && shoppingCart.splitPayment && shoppingCart.splitPayment.secondAmount) {
            if (!shoppingCart.splitPayment.secondAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("secondAmount");
                setCashAmount(formatRationalNumber(Math.round(shoppingCart.splitPayment.secondAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(shoppingCart.splitPayment.secondAmount.value)));
            } 
        }
    }

    const selectThirdAmount = () => {
        if (shoppingCart && shoppingCart.splitPayment && shoppingCart.splitPayment.thirdAmount) {
            if (!shoppingCart.splitPayment.thirdAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("thirdAmount");
                setCashAmount(formatRationalNumber(Math.round(shoppingCart.splitPayment.thirdAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(shoppingCart.splitPayment.thirdAmount.value)));
            } 
        }
    }

    const goToFirstSection = () => {
        setIsSecondSectionSelected(false);
        resetCollectPaymentState();
    }

    return (
        <div 
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {collectPaymentActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${collectPaymentActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {shoppingCart ? (shoppingCart.items.length > 0 ? 
                    <div className='picker-date-general-despcrition' style={{display: "flex", justifyContent: "space-between", minHeight: "280px"}}>
                        {loading ?
                            <div style={{marginTop: "40px"}}><Spinner/></div>
                        :
                            <Fragment>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                    {shoppingCart.splitPayment && isSecondSectionSelected ? 
                                        <button
                                            onClick={goToFirstSection}
                                            className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                        >
                                            <img style={{width: "20px", height: "20px" }} className="see-details-transations-img" src={appTheme.dark ? arrowLeftDarkIcon : arrowLeftLightIcon} alt="close" />
                                        </button>
                                    : null}

                                    <h4 style={{ marginLeft: "15px" }}>
                                        {shoppingCart.splitPayment && (!isSecondSectionSelected) ? 
                                            <span style={{fontWeight: "500"}}>{appLanguage.en ? "Select an amount to collect" : "Seleccione un monto a cobrar"}:</span>
                                        : 
                                            <Fragment>
                                                <span style={{fontWeight: "400"}}>{appLanguage.en ? "You must charge" : "Debes cobrar"}:</span> RD$ {formatRationalNumber(cashToCharge)}
                                            </Fragment>
                                        }
                                    </h4>
                                    
                                    <button
                                        onClick={onClickClose}
                                        className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                    >
                                        <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                                    </button>
                                </div>
                                    
                                {shoppingCart.splitPayment && (!isSecondSectionSelected) ? 
                                    <Fragment>
                                        <div className="unselectable" style={{ display: "flex", flexDirection: "column", minHeight: "200px" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "0px",
                                                    marginBottom: "15px", 
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <button
                                                    onClick={selectFirstAmount}
                                                    className={`${shoppingCart.splitPayment.firstAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                    style={shoppingCart.splitPayment.firstAmount.completed ? { 
                                                        width: "230px", 
                                                        maxWidth: "230px", 
                                                        margin: "15px",
                                                        cursor: "auto",
                                                        backgroundColor: "transparent",
                                                        color: appTheme.dark ? "white" : "black"
                                                    } : {
                                                        width: "230px", 
                                                        maxWidth: "230px", 
                                                        margin: "15px",
                                                    }}
                                                >
                                                    {shoppingCart.splitPayment.firstAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "25px" }} alt="First amount to pay" /> : null}
                                                    <p style={{fontWeight: "bold", fontSize: "16px"}}>RD$ {formatRationalNumber(shoppingCart.splitPayment.firstAmount.value)}</p> 
                                                </button>

                                                <button
                                                    onClick={selectSecondAmount}
                                                    className={`${shoppingCart.splitPayment.secondAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                    style={shoppingCart.splitPayment.secondAmount.completed ? { 
                                                        width: "230px", 
                                                        maxWidth: "230px", 
                                                        margin: "15px",
                                                        cursor: "auto",
                                                        backgroundColor: "transparent",
                                                        color: appTheme.dark ? "white" : "black"
                                                    } : {
                                                        width: "230px", 
                                                        maxWidth: "230px", 
                                                        margin: "15px",
                                                    }}
                                                >
                                                    {shoppingCart.splitPayment.secondAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "15px" }} alt="Second amount to pay" /> : null}
                                                    <p style={{fontWeight: "bold", fontSize: "16px"}}>RD$ {formatRationalNumber(shoppingCart.splitPayment.secondAmount.value)}</p> 
                                                </button>

                                                {shoppingCart.splitPayment.thirdAmount ? 
                                                    <button
                                                        onClick={selectThirdAmount}
                                                        className={`${shoppingCart.splitPayment.thirdAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                        style={shoppingCart.splitPayment.thirdAmount.completed ? { 
                                                            width: "230px", 
                                                            maxWidth: "230px", 
                                                            margin: "15px",
                                                            cursor: "auto",
                                                            backgroundColor: "transparent",
                                                            color: appTheme.dark ? "white" : "black"
                                                        } : {
                                                            width: "230px", 
                                                            maxWidth: "230px", 
                                                            margin: "15px",
                                                        }}
                                                    >
                                                        {shoppingCart.splitPayment.thirdAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "25px" }} alt="Third amount to pay" /> : null}
                                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>RD$ {formatRationalNumber(shoppingCart.splitPayment.thirdAmount.value)}</p> 
                                                    </button> 
                                                : null}
                                                
                                            </div>
                                        </div>
                                    </Fragment>
                                :
                                    <Fragment>
                                    
                                        <div className="unselectable">
                                            <div className="coll-payme-me-cont" style={{marginBottom: "20px"}}>
                                                <button onClick={onClickCash} className={`coll-payme-me-btn-left ${isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Cash" : "Efectivo"}</button>
                                                <button onClick={onClickAnother} className={`coll-payme-me-btn-right ${!isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Other" : "Otro"}</button>
                                            </div>
                                        </div>

                                        {isCash ? 
                                            <div style={{ margin: "8px 15px" }}>
                                                <p>{appLanguage.en ? "Cash received" : "Efectivo recibido"}</p>
                                                <input
                                                    ref={setStartReference}
                                                    value={cashAmount}
                                                    onChange={(e) => onChangeCashAmount(e)}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    onBlur={formatAmount}
                                                    inputMode="numeric"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder="RD$ 0.00"
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black"
                                                    }}
                                                    className={`cash-in-cash-reg-input ${cashAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                                />

                                                <p style={{marginTop: "25px"}}>{ appLanguage.en ? "Customer Change" : "Devuleta del cliente"}:</p>
                                                <p style={{marginTop: "5px"}}><b>RD$ {formatRationalNumber(getCustomerChange())}</b></p>
                                            </div>
                                        : 
                                            <div style={{ margin: "8px 15px" }}>
                                                <p>{appLanguage.en ? "Payment method" : "Método de pago"}</p>
                                                <select 
                                                    className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black",
                                                        width: "100%"
                                                    }}
                                                    name="paymentMethod" 
                                                    id="paymentMethod"
                                                    value={paymentMethod}
                                                    onChange={(e) => onChangePaymentMethod(e)}
                                                >
                                                    <option value="card">{appLanguage.en ? "Credit/debit card" : "Tarjeta de crédito/débito"}</option>
                                                    <option value="bankTransfer">{appLanguage.en ? "Bank transfer" : "Transferencia bancaria"}</option>
                                                    <option value="link">{appLanguage.en ? "Payment link" : "Enlace de pago"}</option>
                                                    <option value="check">{appLanguage.en ? "Check" : "Cheque"}</option>
                                                    <option value="paypal">PayPal</option>
                                                    <option value="other">{appLanguage.en ? "None of the above" : "Ninguno de los anteriores"}</option>
                                                </select>
                                                
                                                <p style={{marginTop: "25px"}}>{ appLanguage.en ? "Note (optional)" : "Nota (opcional)"}:</p>
                                                <input
                                                    ref={setStartReference}
                                                    value={paymentNotes}
                                                    onChange={(e) => onChangePaymentNotes(e)}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    inputMode="text"
                                                    maxLength={35}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder={appLanguage.en ? "Reference number or description" : "Número de referencia o descripción"}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black"
                                                    }}
                                                    className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                                />
                                            </div>
                                        }

                                        <div className="unselectable" style={{ display: "flex", flexDirection: "column" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "30px",
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                <button
                                                    onClick={onSaveProcess}
                                                    className="cash-in-cash-reg-bottons-button-save"
                                                    style={{ width: "190px", maxWidth: "190px" }}
                                                >
                                                    <img src={checkIconDark} style={{ marginRight: "10px" }} alt="End shift" />
                                                    {appLanguage.en ? <p>Confirm</p> : <p>Confirmar</p>}
                                                </button>
                                            </div>
                                        </div>

                                    </Fragment>
                                }

                            </Fragment>
                        }
                    </div>
                : null) : null}
            </div>
        </div>
    )
}

export default CollectPayment;