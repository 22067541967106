import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../firebase/context";

const IsAccountSelected = () => {
    const { accountSelected } = useContext(AuthContext);
    return (
        accountSelected ? <Outlet/> : <Navigate to="/elegir-cuenta" replace />
    );
}

export default IsAccountSelected;