import { Fragment, useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../img/analysis.svg';

import SettingsLeavingCard from '../../../components/settings-leaving-card/settingsLeavingCard';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import '../businessAccount.page.scss';

const LeaveAccountPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(adminAccess || managerAccess) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    })

    const getText= () => {
        if(appLanguage.en) {
            return `By pressing confirm you will be removed from this business account.`
        } else {
            return `Al presionar confirmar, será eliminado de esta cuenta de negocios.`
        }
    }
    
    const [text, setText ] = useState(getText());

    useEffect(() => {
        setText(getText());
    // eslint-disable-next-line
    }, [appLanguage]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Leave account' : 'Abandonar cuenta' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "Leave account" : "Abandonar cuenta"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ? null : <Fragment>
                                <SettingsLeavingCard 
                                    title={appLanguage.en ? "Your are leaving this business account" : "Estás a punto de abandonar esta cuenta de negocios"}
                                    text={text}
                                    businessName={accountData ? accountData.businessName : null}
                                />
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LeaveAccountPage;