import { Fragment, useContext } from "react";
import { AppContext } from '../../context/AppContext';
import { SideMenuContext } from "../../context/SideMenuContext";
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';

import SettingsHeader from "../../components/settings-header/settingsHeader";
import SettingsTitleBus from "../../components/settings-title-bus/settingsTitleBus";

import SettingsOptionAction from "../../components/settings-options/settingsOptionAction";

import settingIconDark from '../../icons/settings-dark.png';
import settingIconLight from '../../icons/settings-light.png';

import customerServiceIcon from '../../img/customer-service.png';

import whatsappIcon from '../../img/whatsapp.svg';
import callIcon from '../../img/call.svg';
import emailIcon from '../../img/email.svg';

const HelpPage = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData } = useContext(AuthContext);

    const whatsappEnText = `https://wa.me/18099958646?text=Hello%20HazCuentas%20team.%20I%20am%20writing%20to%20you%20because%20I%20need%20assistance%20with%20my%20account%20${accountData ? accountData.id : ""}`;
    const whatsappEsText = `https://wa.me/18099958646?text=Hola%20equipo%20de%20HazCuentas.%20Le%20escribo%20porque%20necesito%20ayuda%20con%20mi%20cuenta%20${accountData ? accountData.id : ""}`;

    const emailEnText = `mailto:info@hazcuentas.com?subject=Assistance&body=Hello%20HazCuentas%20team.%20I%20am%20writing%20to%20you%20because%20I%20need%20assistance%20with%20my%20account%20${accountData ? accountData.id : ""}`;
    const emailEsText = `mailto:info@hazcuentas.com?subject=Asistencia&body=Hola%20equipo%20de%20HazCuentas.%20Le%20escribo%20porque%20necesito%20ayuda%20con%20mi%20cuenta%20${accountData ? accountData.id : ""}`;

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Help' : 'Ayuda' } </title>
            </Helmet>
            <div 
                className={`page-business-account-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ colorScheme: appTheme.dark ?  "dark" : "light" }}
            >
                <div className="page-business-account-item">
                    {desktopView ? 
                        <SettingsHeader 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Help Center" : "Centro de ayuda"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={customerServiceIcon}
                                firstText={appLanguage.en ? "Help" : "Ayuda"}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : "--"}`}
                            />
                            <br/>
                            <SettingsOptionAction
                                description={appLanguage.en ? "Chat +1 809 995 8646" : "Chatear +1 809 995 8646"}
                                icon={whatsappIcon}
                                goTo={appLanguage.en ? whatsappEnText : whatsappEsText}
                            />
                            <SettingsOptionAction
                                description={appLanguage.en ? "Call +1 809 995 8646" : "Llamar +1 809 995 8646"}
                                icon={callIcon}
                                goTo={"tel:+1809-995-8646"}
                            />
                            <SettingsOptionAction
                                description={appLanguage.en ? "info@hazcuentas.com" : "info@hazcuentas.com"}
                                icon={emailIcon}
                                goTo={appLanguage.en ? emailEnText : emailEsText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HelpPage;