import {  useContext, useEffect, useState,  } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { AlertContext } from '../alert/alertContext';
import { SoftAlertContext } from '../soft-alert/softAlertContext';
import { Link } from 'react-router-dom';
import firebase from '../../firebase';
import { useNavigate } from 'react-router-dom';

import Alert from '../alert/alert';

import { uid } from 'uid';
import OutsideNav from '../outside-nav/outsideNav';
import Spinner from '../spinner/spinner';

import GoBackButtonLock from '../navigation/go-back-button/goBackButtonLock';

import nextIcon from '../../icons/arrow-small-right-white.png';

import "./unlockApp.scss";

const UnlockApp = ({setAppLock}) => {

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appTheme, appLanguage, credentials, stopDecrypt, setupCretentials, setStopDescrypt, setAlertLockActive } = useContext(AppContext);
    const { userData, userPictures } = useContext(AuthContext);

    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);

    const [passwordState, setPasswordState] = useState("");

    const [ usePassword, setUsePassword ] = useState(credentials ? false : true);

    useEffect(() => {
        if(credentials) {
            setUsePassword(false);
        } else {
            setUsePassword(true)
        }
    }, [credentials]);

    const [passwordAlert, setPasswordAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if (e.target.name === "password") {
            setPasswordAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        setPasswordState(e.target.value)
    }

    async function decryptWithPassword() {
        setLoading(true);
        setStopDescrypt(true);
        setAlertLockActive(false);

        try {
            await firebase.useConfirmPassword(passwordState);

            if (!credentials) {
                // if there is no key in the app
                setupCretentials();
            }

            setAppLock(false);

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to login',
                    es : 'Error al intentar iniciar sesión'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoading(false);
    }

    const validar = e => {
        e.preventDefault();

        if (passwordState.trim() === "") {
            setPasswordAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;

        } else {
            if (passwordState.length < 8) {
                setPasswordAlert({
                    alert: true,
                    enText: '* Password must be at least 8 characters',
                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                });
                return;
            }

        }

        decryptWithPassword();
    }

    async function logOut() {
        setAlertActive(false);
        setAlertLockActive(false);

        try {
            await firebase.useSignOut();
            navigate('/auth/iniciar-sesion', { replace: true });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
        }
        setAlertActive(false);
    }

    const activateUsePassword = e => {
        e.preventDefault();

        setUsePassword(true);
    }

    const changeProfile = e => {
        e.preventDefault();

        setAlertData({
            type: 'question',
            title: {
                en: 'Log in to a different profile?',
                es: '¿Iniciar sesión en un perfil diferente?'
            },
            code: 'confirm-log-out',
            description: {
                en: "The current session will be closed so you can open the other profile.",
                es: "La sesión actual se cerrará para que puedas abrir el otro perfil."
            }
        });
        setOnAgree(() => logOut);
        setAlertActive(true);
    }

    async function decrypt() {
        const randomString = uid(20);
        setStopDescrypt(true);
        setAlertLockActive(false);
        setLoading(true);

        if (credentials) {
            const publicKeyCredentialRequestOptions = {
                challenge: Uint8Array.from(
                    randomString.toString(), c => c.charCodeAt(0)),
                allowCredentials: [{
                    id: credentials,
                    type: 'public-key',
                    transports: ["internal"],
                }],
                timeout: 60000,
            }

            try {
                await navigator.credentials.get({
                    publicKey: publicKeyCredentialRequestOptions
                });
                setAppLock(false);
            } catch (error) {
                console.error(error);
                setSoftAlertActive(true);
                setSoftAlertData({
                    type : 'error',
                    text: {
                        en : 'Error when trying to unlock',
                        es : 'Error al intentar desbloquear',
                    }
                });
            }
        } else {
            console.log("No credential saved");
        }

        setLoading(false);
    }

    useEffect(() => {
        if (credentials) {
            if (credentials.length > 0) {
                if (!stopDecrypt) {
                    decrypt();
                }
            } 
        }
    // eslint-disable-next-line
    }, [credentials]);
    
    return (
        <div className={`app-unlock-container ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            <div className="page-business-account-item">
                <Alert />
                <OutsideNav />
                <div className='page-body'>
                    <div className={`app-unlock-card`}>
                        <div className='app-unlock-card-title-card'>
                            <div className='app-unlock-card-title-card-div'>
                                {usePassword ? (credentials ? <GoBackButtonLock setUsePassword={setUsePassword} /> : null) : null}
                                {appLanguage.en ? <p>Welcome back</p> : <p>Bienvenido de nuevo</p>}
                            </div>
                            
                            <div style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "100%"
                            }}> 
                                <img 
                                    src={userPictures ? userPictures.d140x140 : '/profile.svg'}
                                    alt='User' 
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "100%"
                                    }} 
                                />
                            </div>
                            <h4><b>{userData ? userData.displayName : "--"}</b></h4>
                            
                        </div>
                        
                        {loading ? 
                            <div className='app-unlock-spinner-div'>
                                <Spinner />
                            </div>
                        :
                            <div>
                                {!usePassword ? 
                                    <div className='app-unlock-login-form'>
                                        <button className='app-unlock-card-button' onClick={decrypt}><p><b>{appLanguage.en ? "Continue" : "Continuar"}</b></p> <img src={nextIcon} alt="Continue" /></button>
                                        <div className='app-unlock-dif-prof-butt-div'>
                                            <button onClick={activateUsePassword} className='app-unlock-dif-prof-butt'>{appLanguage.en ? "Use profile password" : "Utilizar contraseña del perfil"}</button>
                                            <button onClick={changeProfile} className='app-unlock-dif-prof-butt'>{appLanguage.en ? "Go to different profile" : "Ir a otro perfil"}</button>
                                        </div>
                                    </div>
                                :
                                    <form className='app-unlock-login-form'>
                                        <label
                                            className="app-unlock-input-description"
                                            htmlFor="password"
                                        >
                                            {appLanguage.en ? 'Password: ' : 'Contraseña: '} 
                                            <b>{passwordAlert.alert ? (appLanguage.en ? passwordAlert.enText : passwordAlert.esText) : "*"}</b>
                                        </label>
                                        <input 
                                            style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                                            className={`input-content ${passwordAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder={appLanguage.en ? 'Type password...' : 'Digite contraseña...'}
                                            onChange={e => onChange(e)}
                                        />
                                        <button className='app-unlock-login-form-button' onClick={validar}><p><b>{appLanguage.en ? "Log in" : "Entrar"}</b></p> <img src={nextIcon} alt="Continue" /></button>
                                        
                                        <div className='app-unlock-dif-prof-butt-div'>
                                            <button onClick={changeProfile} className='app-unlock-dif-prof-butt'>{appLanguage.en ? "Go to different profile" : "Ir a otro perfil"}</button>
                                        </div>

                                        <Link to="/auth/has-olvidado-tu-password" className={`forgot-password-span ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                            <span>{appLanguage.en ? "Forgot password?" : "¿Has olvidado tú contraseña?"}</span>
                                        </Link>
                                    </form>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnlockApp;