import { Fragment, useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../firebase/context'
import { AppContext } from '../../context/AppContext';

import accessIcon from '../../img/access.svg';

import "./connectWithExistingAccount.scss";

const ConnectWithExistingAccount = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { userData } = useContext(AuthContext);

    const [ picture, setPicture ] = useState(null);

    useEffect(() => {
        if(appLanguage.en) {
            if(appTheme.dark) {
                setPicture("https://firebasestorage.googleapis.com/v0/b/aztonia-4b67f.appspot.com/o/public%2Fsteps-to-connect-to-account%2Fsteps-english-dark.png?alt=media&token=6988ee6d-1a64-4a9c-b5be-1b9832a02243&_gl=1*8iejco*_ga*MTAxMDM5OTgwMy4xNjgzNjQ5ODYy*_ga_CW55HF8NVT*MTY5NzkyMjI3NC4zNC4xLjE2OTc5MjM5MjkuNDguMC4w");
            } else {
                setPicture("https://firebasestorage.googleapis.com/v0/b/aztonia-4b67f.appspot.com/o/public%2Fsteps-to-connect-to-account%2Fsteps-english-light.png?alt=media&token=cc114051-6cfd-461b-a3a5-9353f6f51947&_gl=1*15g0sgi*_ga*MTAxMDM5OTgwMy4xNjgzNjQ5ODYy*_ga_CW55HF8NVT*MTY5NzkyMjI3NC4zNC4xLjE2OTc5MjM5MTcuNjAuMC4w");
            }
        } else {
            if(appTheme.dark) {
                setPicture("https://firebasestorage.googleapis.com/v0/b/aztonia-4b67f.appspot.com/o/public%2Fsteps-to-connect-to-account%2Fsteps-spanish-dark.png?alt=media&token=547316ed-c96d-4ba1-8abf-f3891bd4bd7b&_gl=1*v0lmc6*_ga*MTAxMDM5OTgwMy4xNjgzNjQ5ODYy*_ga_CW55HF8NVT*MTY5NzkyMjI3NC4zNC4xLjE2OTc5MjM0OTEuNjAuMC4w");
            } else {
                setPicture("https://firebasestorage.googleapis.com/v0/b/aztonia-4b67f.appspot.com/o/public%2Fsteps-to-connect-to-account%2Fsteps-spanish-light.png?alt=media&token=6563374a-27ba-471d-bffe-da2d78b98017&_gl=1*1q945qq*_ga*MTAxMDM5OTgwMy4xNjgzNjQ5ODYy*_ga_CW55HF8NVT*MTY5NzkyMjI3NC4zNC4xLjE2OTc5MjIzNTguMzcuMC4w");
            }
        }

    }, [appLanguage, appTheme])

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Connect with existing account' : 'Conectarse con cuenta existente'} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    <div className="page-steps-to-connect-header">
                        <img src={accessIcon} alt="Create account icon" />
                        <h3 className="page-steps-to-connect-header-tittle">{appLanguage.en ? 'Steps to connect with account' : 'Pasos para conectarse con la cuenta'} </h3>
                    </div>
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <div className={`page-steps-to-connect-header-tittle-body ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
                                {appLanguage.en ? <Fragment>
                                    <p>To access an existing business account you must contact the administrator of the account and provide them with your email address <i>"{userData ? userData.email : ""}"</i></p> 
                                    <br/>
                                    <p>The administrator must access the business account and go to the option:</p>
                                    <br/>
                                    <p><b> 1. Business account <br/>2. Authorized users <br/>3. Authorize access. </b></p>
                                    <br/>
                                    <p>Once in that section the administrator must complete the following form:</p>
                                </Fragment> : <Fragment>
                                    <p>Para acceder a una cuenta de negocios existente debes ponerte en contacto con la persona administradora de la misma y proporcionarle tu correo electrónico <i>"{userData ? userData.email : ""}"</i></p>
                                    <br/>
                                    <p>El administrador deberá acceder a la cuenta de negocios e ir a la opción: </p>
                                    <br/>
                                    <p> <b> 1. Cuenta de negocios <br/>2. Usuarios autorizados <br/>3. Autorizar acceso.</b> </p>
                                    <br/>
                                    <p>Una vez en esa sección el administrador deberá completar el siguiente formulario: </p>
                                </Fragment>}
                                <img src={picture} alt="Steps to connect" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ConnectWithExistingAccount;