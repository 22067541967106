import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from '../../../firebase/context';
import { Link } from 'react-router-dom';

import firebase from '../../../firebase/firebase';
import { ref, getDownloadURL } from "firebase/storage";

import authUserAlertIcon from '../../../icons/authUserAlert.png';

import './authorizedUserCard.scss';

const AuthorizedUserCard = ({user}) => {
    const { appTheme } = useContext(AppContext);
    const { setPictureInMemory } = useContext(AuthContext)

    const [loadingPicture, setLoadingPicture] = useState(true);
    const [pictureData, setPictureData ] = useState(null);

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = path;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        setLoadingPicture(true);

        let requestCounter = 8;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            setPictureData({
                                pictureFile: urlData,
                                location: path
                            });
                            setLoadingPicture(false);
                        }
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, path);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setLoadingPicture(false);
                    console.error(error);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    useEffect(() => {
        if (user) {
            if (user.pictureLocation) {
                checkIfImageExistInGe(user.pictureLocation);
            } else {
                setPictureData(false);
                setLoadingPicture(false);
            }
        } else {
            setLoadingPicture(false);
        }
    // eslint-disable-next-line    
    }, [user]);

    return (
        <Link 
            to={"/ver-perfl-usuario"}
            state={user}
            className={`auth-view-card-container ${appTheme.dark ? "auth-view-card-container-dar" : "auth-view-card-container-lig"}`}
        >
            {loadingPicture ?
                <div className="auth-user-view-mini-profile-yes-img-big-cont unselectable">
                    <div
                        style={{
                            backgroundColor: "rgba(128, 128, 128, 0.13)",
                            borderRadius: "100%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        className="aut-use-view-mini-pro-yes-img-big-con-div"
                    />
                </div>
            :  
                <div className='settings-auth-user-mini-name-img'>
                    <img src={pictureData ? pictureData.pictureFile : "/profile.svg"} alt="User" />
                </div>
            }

            <div className='auth-view-card-div'>
                <p>
                    {user.status === "block" ? <img src={authUserAlertIcon} alt="Alert" /> : null} 
                    <b>{user.name}</b></p>
                <p>{user.email}</p>
            </div>
        </Link>
    );
}

export default AuthorizedUserCard;