import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./productView.page.scss";

import { AuthContext } from "../../../firebase/context";
import { Helmet } from "react-helmet-async";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { AppContext } from "../../../context/AppContext";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import ProductDetails from "../../../appTools/appToolsComponents/productDetails/productDetails";
import { uid } from "uid";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

import { SideMenuContext } from "../../../context/SideMenuContext";
import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import PictureProductViewer from "../../../appTools/appToolsComponents/pictureProductViewer/pictureProductViewer";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const ProductViewPage = () => {

    const { accountData, accessTo, addToShoppingCart, removeFromShoppingCart } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [product, setProduct] = useState(null);

    const [request, setRequest] = useState("0000");

    const [showingDetails, setShowingDetails] = useState(true);

    const [ loadingPicture, setLoadingPicture ] = useState(true);
    const [ uploadingPicture, setUploadingPicture ] = useState(false);

    const [pictureProductViewerActive, setPictureProductViewerActive] = useState(false);

    const [width, setWidth] = useState();

    const onClickPicture = () => {
        setPictureProductViewerActive(true);
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.products)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (location.state) {
            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (itemsDeleted) {
                const productId = location.state.id;

                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }

                if (containsString(itemsDeleted, productId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    setProduct(location.state);
                    setRequest(uid());
                }

            } else {
                setProduct(location.state);
                setRequest(uid());
            }
        } else {
            navigate("/productos", { replace: true })
        }
        // eslint-disable-next-line
    }, []);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (product && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                const productUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete productUpdated.searchKeys;

                if (doc.data()) {
                    setProduct(productUpdated);
                    navigate(null, { replace: true, state: productUpdated });
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const { setPictureInMemory } = useContext(AuthContext);

    const [pictureData, setPictureData ] = useState(null);
    const [loadingError, setLoadingError] = useState(false);

    const [noImgFile, setNotImgFile] = useState(() => {
        if (product) {
            if (product.picturesLocation) {
                if (product.picturesLocation[0]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    });

    useEffect(() => {
        if (product) {
            if (product.picturesLocation && product.id) {
                if (product.picturesLocation[0]) {
                    const path = product.picturesLocation[0];
                    if(!uploadingPicture) {
                        if (!pictureData) {
                            setLoadingPicture(true);
                        } else {
                            if (path !== pictureData.location) {
                                setLoadingPicture(true);
                            }
                        }
                        checkIfImageExistInPr(path);
                    }
                }
            }
            setNotImgFile(() => {
                if (product.picturesLocation) {
                    if (product.picturesLocation[0]) {
                        return true
                    } else {
                        setLoadingPicture(false);
                        return false
                    }
                } else {
                    setLoadingPicture(false);
                    return false
                }
            })
        }
        // eslint-disable-next-line
    }, [product]);

    const checkIfImageExistInPr = (path) => {

        const dimensions = "d600x600";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d600x600";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions
        // of a picture store in firebase store

        let requestCounter = 6;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        setPictureData({
                            pictureFile: urlData,
                            location: path
                        });
                        setLoadingPicture(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                    setLoadingError(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    console.error(error);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                    setLoadingPicture(false);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const getPictureFileAfterUploading = async (path) => {
        // This is a recursive function that download all the dimensions
        // of a picture store in firebase store
        let requestCounter = 6;
        setUploadingPicture(true);

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        setPictureData({
                            pictureFile: urlData,
                            location: path
                        });
                        setUploadingPicture(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setLoadingError(false);
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if (requestCounter > 0) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setUploadingPicture(false);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                    console.error(error);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const addToCart = () => {
        const res = addToShoppingCart(product);
        if (res) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Added to cart`,
                    es: `Agregado al carrito`,
                }
            });
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Item is already in the shopping cart.',
                    es : 'El artículo ya está en el carrito de compras.',
                }
            });
        }
    }

    const removeFromCart = () => {
        const res = removeFromShoppingCart(product.id);

        if (res !== null) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Removed from cart`,
                    es: `Eliminado del carrito`,
                }
            });
        } else {
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Something went wrong',
                    es : 'Algo salió mal',
                }
            });
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'View product' : 'Ver producto'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && product && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className="page-app-settings-item">
                            {accessTo.products ? <Fragment>
                                <PictureProductViewer
                                    productName={product.name}
                                    viewerWidth={ width ? width + 5 : ""}
                                    pictureProductViewerActive={pictureProductViewerActive}
                                    setPictureProductViewerActive={setPictureProductViewerActive}
                                    pictureData={pictureData}
                                    setProduct={setProduct}
                                    setLoadingPicture={setLoadingPicture}
                                    businessId={accountData.id}
                                    productId={product.id}
                                    path={product.picturesLocation ? product.picturesLocation[0] : null}
                                    setPictureData={setPictureData}
                                />
                                {(desktopView && showingDetails) ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? 'View product' : 'Ver producto'}
                                    />
                                    : null}
                                <div
                                    style={{
                                        height: (desktopView && showingDetails) ?  "calc(100vh - 107px)" : "calc(100vh - 65px)",
                                        colorScheme: appTheme.dark ?  "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    <ProductDetails
                                        product={product}
                                        setProduct={setProduct}
                                        pictureData={pictureData}
                                        setPictureData={setPictureData}
                                        loadingError={loadingError}
                                        noImgFile={noImgFile}
                                        setShowingDetails={setShowingDetails}
                                        pictureProductViewerActive={pictureProductViewerActive}
                                        onClickPicture={onClickPicture}
                                        setWidth={setWidth}
                                        addToCart={addToCart}
                                        removeFromCart={removeFromCart}
                                        loadingPicture={loadingPicture}
                                        setUploadingPicture={setUploadingPicture}
                                        uploadingPicture={uploadingPicture}
                                        getPictureFileAfterUploading={getPictureFileAfterUploading}
                                    />
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductViewPage;