import { useState, useContext, useEffect, Fragment } from 'react';
import { AuthContext } from '../../firebase/context';
import firebase from '../../firebase/firebase';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../alert/alertContext';
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner/spinner';
import './takePayment.scss';

const TakePayment = () => {

    const { appLanguage } = useContext(AppContext);
    const { accountData, setAccountData } = useContext(AuthContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const navigate = useNavigate();

    const [ cardNameAlert, setCardNameAlert ] =  useState(false);
    const [ cardNumberAlert, setCardNumberAlert ] = useState(false);
    const [ expirationAlert, setExpirationAlert ] =  useState(false);
    const [ cscAlert, setCscAlert ] = useState(false);

    const [ loading, setLoading ] = useState(false)

    const [ paymentData, setPaymentData ] = useState({
        type: "",
        amount: 0
    });

    const [tittle, setTitle ] = useState("");

    useEffect(() => {
        if (paymentData) {
            if (appLanguage.en) {
                if (paymentData.type === "payDueBalance") {
                    setTitle("Pay overdue balance");
                } else {
                    if (paymentData.type === "payCurrentBalance") {
                        setTitle("Pay full balance");
                    }
                }
            } else {
                if (paymentData.type === "payDueBalance") {
                    setTitle("Pagar balance vencido");
                } else {
                    if (paymentData.type === "payCurrentBalance") {
                        setTitle("Pagar balance total");
                    }
                }
            }
        }
        
    }, [appLanguage, paymentData]);

    const location = useLocation();

    useEffect(() => {
        try {
            setPaymentData(location.state);
        } catch {}
    // eslint-disable-next-line
    }, []);

    const [ paymentMethod, setPaymentMethod ] = useState({
        "CARD_NAME": "",
        "CARD_NUMBER": "",
        "EXPIRATION": "",
        "CSC": ""
    });

    const validateCardNumber = text => {
        let numberWithoutSpace = '';
        
        for (let i = 0; i < text.length; i++) {
            numberWithoutSpace += Number.isInteger(parseInt(text[i])) ? text[i] : "";;
        }

        const number = parseInt(numberWithoutSpace);

        //Check if the number contains only numeric value  
        //and is of between 13 to 19 digits
        const regex = new RegExp("^[0-9]{13,19}$");
        if (!regex.test(number)){
            return false;
        }
      
        return luhnCheck(number);
    }
    
    const luhnCheck = val => {
        let checksum = 0; // running checksum total
        let j = 1; // takes value of 1 or 2
    
        // Process each digit one by one starting from the last
        for (let i = val.length - 1; i >= 0; i--) {
          let calc = 0;
          // Extract the next digit and multiply by 1 or 2 on alternative digits.
          calc = Number(val.charAt(i)) * j;
    
          // If the result is in two digits add 1 to the checksum total
          if (calc > 9) {
            checksum = checksum + 1;
            calc = calc - 10;
          }
    
          // Add the units element to the checksum total
          checksum = checksum + calc;
    
          // Switch the value of j
          if (j === 1) {
            j = 2;
          } else {
            j = 1;
          }
        }
      
        //Check if it is divisible by 10 or not.
        return (checksum % 10) === 0;
    }

    const validateEXPIRATION = text => {

        const now = new Date();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();

        const length = text.length;

        let monthToCompre = '';
        monthToCompre += text[0];
        monthToCompre += text[1];

        if(parseInt(monthToCompre) > 12 || parseInt(monthToCompre) < 1) {
            return false;
        }

        if (length === 5 || length === 7) {
            if (length === 5) {
                let year2 = '';
                year2 += text[3];
                year2 += text[4];

                let nowYear2 = '';
                const yearString = String(year);

                nowYear2 +=  yearString[2];
                nowYear2 +=  yearString[3];

                if (parseInt(year2) < parseInt(nowYear2) ) {
                    return false;
                } else {
                    if(parseInt(year2) === parseInt(nowYear2) ) {
                        if (parseInt(monthToCompre) <= month) {
                            return false;
                        }
                    }
                }
            } else {
                let year4 = '';
                year4 += text[3];
                year4 += text[4];
                year4 += text[5];
                year4 += text[6];

                if (parseInt(year4) < year) {
                    return false;
                } else {
                    if(parseInt(year4) === year) {
                        if (parseInt(monthToCompre) < month) {
                            return false;
                        }
                    }
                }
            }
        } else {
            return false;
        }
        return true;
    }

    const handleChanged = e => {

        if (e.target.name === "CARD_NAME") {
            setCardNameAlert(false);
        } else {
            if (e.target.name === "CARD_NUMBER") {
                setCardNumberAlert(false);
            } else {
                if (e.target.name === "EXPIRATION") {
                    setExpirationAlert(false);
                } else {
                    if (e.target.name === "CSC") {
                        setCscAlert(false);
                    }
                }
            }
        }

        if (e.target.name === "CARD_NUMBER") {
            let number = '';
            let numberWithSpace = '';
            const CARD_NUMBER = e.target.value;
            
            for (let i = 0; i < CARD_NUMBER.length; i++) {
                number += Number.isInteger(parseInt(CARD_NUMBER[i])) ? CARD_NUMBER[i] : "";
            }

            for (let j = 0; j < number.length; j++) {
                if (j === 4 || j === 8 || j === 12 || j === 16 ) {
                    numberWithSpace += " ";
                    numberWithSpace += number[j];
                } else {
                    numberWithSpace += number[j];
                }
            }

            setPaymentMethod({
                ...paymentMethod,
                [e.target.name]: numberWithSpace
            })

        } else {
            if (e.target.name === "EXPIRATION") {
                let number = '';
                const EXPIRATION = e.target.value;
                
                for (let i = 0; i < EXPIRATION.length; i++) {
                    if(i === 2) {
                        if (EXPIRATION[i] === "/") {
                            number += EXPIRATION[i];
                        } else {
                            if (Number.isInteger(parseInt(EXPIRATION[i]))) {
                                number += "/";
                                number += EXPIRATION[i];
                            }
                        }
                    } else {
                        if (Number.isInteger(parseInt(EXPIRATION[i]))) {
                            number += EXPIRATION[i];
                        }
                    }
                }

                setPaymentMethod({
                    ...paymentMethod,
                    [e.target.name]: number
                })
            } else {
                if (e.target.name === "CSC") {
                    let number = '';
                    const CSC = e.target.value;
                    
                    for (let i = 0; i < CSC.length; i++) {
                        if (Number.isInteger(parseInt(CSC[i]))) {
                            number += CSC[i];
                        }
                    }

                    setPaymentMethod({
                        ...paymentMethod,
                        [e.target.name]: number
                    })

                } else {
                    setPaymentMethod({
                        ...paymentMethod,
                        [e.target.name]: e.target.value
                    });
                }
            }
        }
    }

    const validar = e => {
        e.preventDefault();

        if (paymentMethod.CARD_NAME.trim() === "") {
            setCardNameAlert(true);
            return;
        } else {
            if (paymentMethod.CARD_NUMBER.trim() === "") {
                setCardNumberAlert(true);
                return;
            } else {
                if (!validateCardNumber(paymentMethod.CARD_NUMBER)) {
                    setCardNumberAlert(true);
                    return;
                } else {
                    if (paymentMethod.EXPIRATION.trim() === "") {
                        setExpirationAlert(true);
                        return;
                    } else {
                        if (!validateEXPIRATION(paymentMethod.EXPIRATION.trim())) {
                            setExpirationAlert(true);
                            return;
                        } else {
                            if (paymentMethod.CSC.trim() === "") {
                                setCscAlert(true);
                                return;
                            } else {
                                if (!((paymentMethod.CSC.trim().length === 3) || (paymentMethod.CSC.trim().length === 4))) {
                                    setCscAlert(true);
                                    return;
                                } 
                            }
                        }
                    }
                }
            }
        }

        const data = {
            "paymentMethod": paymentMethod,
            "paymentData": paymentData,
        }

        processTransaction(data);

    }

    async function processTransaction(data) {
        setLoading(true);

        try {
            if (paymentData.type === "payCurrentBalance") { 
                const res = await firebase.usePayCurrentBalance({
                    ...data,
                    "accountId": accountData.id
                });

                const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));

                if (isAccountSelected) {
                    // Get Account data
                    try {
                        const response = await firebase.useGetAccountSelected(isAccountSelected);
                        localStorage.setItem("isAccountData", JSON.stringify(response));
                        setAccountData(response);
                    } catch (error) {
                        console.error(error);
                    }
                }

                setAlertData({
                    type: 'success',
                    title: {
                        en: 'Successful payment',
                        es: 'Pago exitoso'
                    },
                    code: '',
                    description: {
                        en: 'The payment has been completed successfully. Ref number: ' + res.ref,
                        es: 'El pago se ha completado con éxito. Número de referencia: ' + res.ref
                    }
                });
                setAlertActive(true);
                navigate("/", { replace: true });

            } else {
                if (paymentData.type === "payDueBalance") {
                    const res = await firebase.usePayDueBalance({
                        ...data,
                        "accountId": accountData.id
                    });

                    const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));
                    if (isAccountSelected) {
                        // Get Account data
                        try {
                            const response = await firebase.useGetAccountSelected(isAccountSelected);
                            localStorage.setItem("isAccountData", JSON.stringify(response));
                            setAccountData(response);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    setAlertData({
                        type: 'success',
                        title: {
                            en: 'Successful payment',
                            es: 'Pago exitoso'
                        },
                        code: '',
                        description: {
                            en: 'The payment has been completed successfully. Ref number: ' + res.ref,
                            es: 'El pago se ha completado con éxito. Número de referencia: ' + res.ref
                        }
                    });
                    setAlertActive(true);
                    navigate("/", { replace: true });
                }
            }
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to process payment',
                    es: 'Error al intentar procesar el pago'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoading(false);
    }

    return (
        <div className={`settings-take-payment`}>
            {paymentData ? <Fragment>
                <p>{tittle}.: <b>RD${paymentData.amount}</b></p>
                {loading ? <Spinner /> : <Fragment>
                    <div className='settings-take-payment-name-numbre-cont'>
                        <label>{appLanguage.en ? "CARD OWNER" : "DUEÑO DE LA TARJETA"}</label>
                        <input 
                            className={`dark-text-input ${cardNameAlert ? "input-content-alert" : "input-border-card"}`}
                            maxLength={30}
                            name='CARD_NAME'
                            style={{outline: "none"}} 
                            placeholder={appLanguage.en ? "Ex: Ana Maria" : "Ej.: Ana Maria"}
                            type='text'
                            value={paymentMethod.CARD_NAME}
                            onChange={e => handleChanged(e)}
                        />

                        <label>{appLanguage.en ? "CARD NUMBER" : "NÚMERO DE LA TARJETA"}</label>
                        <input 
                            className={`dark-text-input  ${cardNumberAlert ? "input-content-alert" : "input-border-card"}`}
                            maxLength={23}
                            name='CARD_NUMBER'
                            style={{outline: "none"}} 
                            placeholder='0000 0000 0000 0000' 
                            type='text'
                            inputMode="numeric"
                            value={paymentMethod.CARD_NUMBER}
                            onChange={e => handleChanged(e)}
                        />
                    </div>
                    
                    <div className='settings-take-payment-date-code-cont' >
                        <div>
                            <label>{appLanguage.en ? "EXPIRATION DATE" : "FECHA DE EXPIRACIÓN"}</label>
                            <input 
                                className={`dark-text-input  ${expirationAlert ? "input-content-alert" : "input-border-card"}`}
                                maxLength={7}
                                name="EXPIRATION"
                                style={{outline: "none"}} 
                                placeholder={appLanguage.en ? "Ex: 11/27" : "Ej.: 11/27"}
                                type='text'
                                inputMode="numeric"
                                value={paymentMethod.EXPIRATION}
                                onChange={e => handleChanged(e)}
                            />
                        </div>

                        <div>
                            <label>{appLanguage.en ? "CVV CODE" : "CÓDIGO CVV"}</label>
                            <input 
                                className={`dark-text-input  ${cscAlert ? "input-content-alert" : "input-border-card"}`}
                                maxLength={4}
                                name="CSC"
                                style={{outline: "none"}}  
                                placeholder={appLanguage.en ? "Ex.: 123" : "Ej.: 123"}
                                type='text'
                                inputMode="numeric"
                                value={paymentMethod.CSC}
                                onChange={e => handleChanged(e)}
                            />
                        </div>
                    </div>
                
                    <button onClick={validar} className='settings-take-payment-pay dar-pri dar-box-sha-hover'>{appLanguage.en ? "Pay" : "Pagar"}</button>
                </Fragment> }
            </Fragment> : null}
        </div>
    );
}

export default TakePayment;