import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const StartAndEndShiftContext = createContext();

const StartAndEndShiftProvider = (props) => {

    const location = useLocation();

    const [ startAndEndShiftActive, setStartAndEndShiftActive ] = useState(false);
    const [ cashAmount, setCashAmount ] = useState("");

    const [ loading, setLoading ] = useState(false);

    const [ cashAmountAlert, setCashAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    useEffect(() => {
        setCashAmount("");
        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }, [startAndEndShiftActive]);

    useEffect(() => {
        if (startAndEndShiftActive) {
            if (location.pathname !== "/gestionar-turnos" && (!loading)) {
                setStartAndEndShiftActive(false);
            }
        }
    // eslint-disable-next-line
    }, [location, loading])

    return (
        <StartAndEndShiftContext.Provider
            value={{ 
                startAndEndShiftActive,
                cashAmount,
                loading, 
                cashAmountAlert,
                setStartAndEndShiftActive,
                setCashAmount,
                setLoading,
                setCashAmountAlert
            }}
        >
            {props.children}
        </StartAndEndShiftContext.Provider>
    )
}

export default StartAndEndShiftProvider;