import { Fragment, useContext} from 'react';
import copyIcon from "../../../icons/copy.png";
import copyIconLight from "../../../icons/copyLight.png";
import { AppContext } from '../../../context/AppContext';
import "./accountDetails.scss";
import { SideMenuContext } from '../../../context/SideMenuContext';

const AccountDetails = ({account, copyAccountNumber}) => {
    const { desktopView } = useContext(SideMenuContext);
    const { appTheme, appLanguage } = useContext(AppContext);

    return (
        <Fragment>
            {desktopView ? 
                <div className="account-details-container">
                    <p className="account-details-name" >{account.name} <span className={account.active ? "account-details-status-active" : "account-details-status-inactive"}>{account.active ? (appLanguage.en ? "Active" : "Activa") : (appLanguage.en ? "Inactive" : "Inactiva")}</span></p>
                    <span onClick={copyAccountNumber} className="account-details-acc-num unselectable">{account.accountID} <img src={appTheme.dark ? copyIcon : copyIconLight} alt="Copy" /></span>
                </div>
            : 
                <div className="account-details-container-minimize">
                    <div className='account-details-container-minimize-div unselectable'>
                        <span onClick={copyAccountNumber} className="account-details-acc-num-minimize">{account.accountID} <img src={appTheme.dark ? copyIcon : copyIconLight} alt="Copy" /></span>
                    </div>
                    <p className="account-details-name" >{account.name} <span className={account.active ? "account-details-status-active" : "account-details-status-inactive"}>{account.active ? (appLanguage.en ? "Active" : "Activa") : (appLanguage.en ? "Inactive" : "Inactiva")}</span></p>
                </div>
            }
        </Fragment>
    )
}

export default AccountDetails;