import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./inputPickerDate.scss";
import { InputPickerDateContext } from "./inputPickerDateContext";
import { AppContext } from "../../../context/AppContext";

import downArrowDark from "../../../icons/down-arrow-dark.png";
import downArrowLight from "../../../icons/down-arrow-light.png";

import crossIcon from "../../../icons/cross-white.png";
import checkIcon from "../../../icons/check-dark.png";

import arrowLeftDark from "../../../icons/arrow-left-dark.png";
import arrowLeftLight from "../../../icons/arrow-left-light.png";

import arrowRightDark from "../../../icons/arrow-small-right-white.png";
import arrowRightLight from "../../../icons/arrow-small-right.png";

const InputPickerDate = ({pickerWidth, onlyInThisMonth}) => {
    const { 
        currentDate,
        setCurrentDate,
        pickerDateActive, 
        setPickerDateActive, 
        pickerDateTitle,  
        pickeDate,
        pickeMonth,
        pickeYear,
        setPickeDate,
        setPickeMonth,
        setPickeYear
    } = useContext(InputPickerDateContext);

    const { appTheme, appLanguage, getMonthNameAndFullYear } = useContext(AppContext);

    const [ tempDate, setTempDate ] = useState(new Date(currentDate.getTime()));

    useEffect(() => {
        setTempDate(currentDate)
    }, [currentDate])

    let menuRef = useRef();

    useEffect(() => {
        if(pickerDateActive) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    setPickerDateActive(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickDaySelect = () => {
        setPickeDate(true);
    }

    const onClickMonthSelect = () => {
        setPickeMonth(true);
    }

    const onClickYearSelect = () => {
        setPickeYear(true);
    }

    const onClickSetToday = () => {
        setPickeDate(true);
        setTempDate(new Date());
    }

    const getMonthInString = (date) => {
      
        const month = date.getMonth();

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
    
        if(appLanguage.en) {
            return `${months[month]}`;
        } else {
            return `${meses[month]}`;
        }
    }

    const onClickSetDay = (day) => {
        const newTempDate = new Date(tempDate.getTime());
        newTempDate.setDate(day);
        setTempDate(newTempDate);
    }

    const onClickSetMonth = (month) => {
        const newTempDate = new Date(tempDate.getTime());
        newTempDate.setMonth(month);
        setTempDate(newTempDate);
    }

    const onClickSetYear = (year) => {
        const newTempDate = new Date(tempDate.getTime());
        newTempDate.setFullYear(year);
        setTempDate(newTempDate);
    }

    const onClickCancel = () => {
        setPickerDateActive(false);
    }

    const onClickSelectDate = () => {
        setCurrentDate(new Date(tempDate.getTime()));
        setPickerDateActive(false);
    }

    return (
        <div className='unselectable'>
            {pickerDateActive ? <div className='picker-date-lock-screen' style={{width: pickerWidth, minHeight: "calc(100vh - 65px)"}} /> : null }
            <div ref={menuRef} className={`picker-date-genaral-card ${pickerDateActive ? 'active' : 'inactive' }`} style={{marginLeft: `calc(${pickerWidth / 2}px - 175px)`, background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black"}}>
                <div className='picker-date-general-despcrition'>
                    <h4>{pickerDateTitle}</h4>
                    <div className="picker-date-select-inputs">
                        {onlyInThisMonth ? 
                            <div>
                                <p style={{margin: "5px", fontSize: "18px"}}> {getMonthNameAndFullYear(onlyInThisMonth)}</p>
                            </div>
                        :
                            <Fragment>
                                <div onClick={onClickDaySelect} className={`picker-date-select-container-current unselectable ${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'date-picker-botton-light border-box-light'}`}> 
                                    <span><b>{tempDate.getDate().toString().padStart(2, '0')}</b></span>
                                    <img className="picker-date-select-container-current-arrow-img" src={appTheme.dark ? downArrowDark : downArrowLight} alt="Down arrow" />
                                </div>
                                <div onClick={onClickMonthSelect} className={`picker-date-select-container-current unselectable ${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'date-picker-botton-light border-box-light'}`}>
                                    <span><b>{getMonthInString(tempDate)}</b></span>
                                    <img className="picker-date-select-container-current-arrow-img" src={appTheme.dark ? downArrowDark : downArrowLight} alt="Down arrow" />
                                </div>
                                <div onClick={onClickYearSelect} className={`picker-date-select-container-current unselectable ${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'date-picker-botton-light border-box-light'}`}>
                                    <span><b>{tempDate.getFullYear()}</b></span>
                                    <img className="picker-date-select-container-current-arrow-img" src={appTheme.dark ? downArrowDark : downArrowLight} alt="Down arrow" />
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="picker-date-options-cont">
                        {pickeDate ? <Fragment>
                            <Dates tempDate={tempDate}  onClickSetToday={onClickSetToday} onClickSetDay={onClickSetDay} onlyInThisMonth={onlyInThisMonth}/>
                        </Fragment> : <Fragment>
                            {pickeMonth ? <Fragment>
                                <Months tempDate={tempDate} onClickSetMonth={onClickSetMonth} onClickSetToday={onClickSetToday} onlyInThisMonth={onlyInThisMonth}/>
                            </Fragment> : <Fragment>
                                {pickeYear ? <Fragment>
                                    <Years tempDate={tempDate} onClickSetYear={onClickSetYear} onClickSetToday={onClickSetToday} onlyInThisMonth={onlyInThisMonth}/>
                                </Fragment> : null}
                            </Fragment> }
                        </Fragment>}
                    </div>
                    <div className="picker-date-bottons-cont">
                        <button 
                            onClick={onClickCancel} 
                            className="new-customer-bottons-button-save"
                        >
                            <img src={crossIcon} alt="Cancel" />
                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                        </button>
                        <button 
                            onClick={onClickSelectDate}
                            className="new-customer-bottons-button-save"
                        >
                            <img src={checkIcon} alt="Save" />
                            {appLanguage.en ? <p>Select</p> : <p>Seleccionar</p>}
                        </button>
                    </div>
                </div> 
            </div>
        </div>
    )
}

const Dates = ({tempDate, onClickSetToday, onClickSetDay, onlyInThisMonth}) => {
    
    const { appLanguage, appTheme } = useContext(AppContext);

    const currentDay = tempDate.getDate();

    const firstDayOfMonth = new Date(tempDate.getFullYear(), tempDate.getMonth());
    const dayOrder = firstDayOfMonth.getDay();

    const lastDayOfMonth = new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0);
    const limit = lastDayOfMonth.getDate();

    const [ daysControler, setDaysControler ] = useState(() => {

        let startDayCount = false;
        let orderCount = 0;
        let dayCount = 1;
        
        const arrayDays = [
            "a1", "a2", "a3", "a4", "a5", "a6", "a7",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "c1", "c2", "c3", "c4", "c5", "c6", "c7",
            "d1", "d2", "d3", "d4", "d5", "d6", "d7",
            "e1", "e2", "e3", "e4", "e5", "e6", "e7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7"
        ];

        let newDaysObjects = {};

        for (let i = 0; (i < arrayDays.length); i++) {
            if (!startDayCount) {
                if (orderCount === dayOrder) {
                    startDayCount = true;
                    newDaysObjects = {
                        ...newDaysObjects,
                        [arrayDays[i]]: {
                            active: currentDay === dayCount ? true : false,
                            value: dayCount
                        }, 
                    }
                    dayCount++;
                } else {
                    newDaysObjects = {
                        ...newDaysObjects,
                        [arrayDays[i]]: null, 
                    }
                }
            } else {
                if (dayCount > limit) {
                    newDaysObjects = {
                        ...newDaysObjects,
                        [arrayDays[i]]: null, 
                    }
                    dayCount++;
                } else {
                    newDaysObjects = {
                        ...newDaysObjects,
                        [arrayDays[i]]: {
                            active: currentDay === dayCount ? true : false,
                            value: dayCount
                        }, 
                    }
                    dayCount++;
                }
            }
            orderCount++;
        }

        return newDaysObjects;
    });

    useEffect(() => {
        setDaysControler(() => {
            let startDayCount = false;
            let orderCount = 0;
            let dayCount = 1;
            const arrayDays = [
                "a1", "a2", "a3", "a4", "a5", "a6", "a7",
                "b1", "b2", "b3", "b4", "b5", "b6", "b7",
                "c1", "c2", "c3", "c4", "c5", "c6", "c7",
                "d1", "d2", "d3", "d4", "d5", "d6", "d7",
                "e1", "e2", "e3", "e4", "e5", "e6", "e7",
                "f1", "f2", "f3", "f4", "f5", "f6", "f7"
            ];
            let newDaysObjects = {};
            for (let i = 0; (i < arrayDays.length); i++) {
                if (!startDayCount) {
                    if (orderCount === dayOrder) {
                        startDayCount = true;
                        newDaysObjects = {
                            ...newDaysObjects,
                            [arrayDays[i]]: {
                                active: currentDay === dayCount ? true : false,
                                value: dayCount
                            }, 
                        }
                        dayCount++;
                    } else {
                        newDaysObjects = {
                            ...newDaysObjects,
                            [arrayDays[i]]: null, 
                        }
                    }
                } else {
                    if (dayCount > limit) {
                        newDaysObjects = {
                            ...newDaysObjects,
                            [arrayDays[i]]: null, 
                        }
                        dayCount++;
                    } else {
                        newDaysObjects = {
                            ...newDaysObjects,
                            [arrayDays[i]]: {
                                active: currentDay === dayCount ? true : false,
                                value: dayCount
                            }, 
                        }
                        dayCount++;
                    }
                }
                orderCount++;
            }
            return newDaysObjects;
        });
    // eslint-disable-next-line
    }, [tempDate]);

    return (
        <Fragment>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "20px",
                marginBottom: "15px"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Su" : "Do"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Mo" : "Lu"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Tu" : "Ma"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "We" : "Mi"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Th" : "Ju"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Fr" : "Vi"}</p></b>
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <b><p>{appLanguage.en ? "Sa" : "Sa"}</p></b>
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a1 ? <button onClick={() => onClickSetDay(daysControler.a1.value)} className={`picker-date-day-option ${daysControler.a1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a2 ? <button onClick={() => onClickSetDay(daysControler.a2.value)} className={`picker-date-day-option ${daysControler.a2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a3 ? <button  onClick={() => onClickSetDay(daysControler.a3.value)} className={`picker-date-day-option ${daysControler.a3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a4 ? <button  onClick={() => onClickSetDay(daysControler.a4.value)} className={`picker-date-day-option ${daysControler.a4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a5 ? <button  onClick={() => onClickSetDay(daysControler.a5.value)} className={`picker-date-day-option ${daysControler.a5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a6 ? <button  onClick={() => onClickSetDay(daysControler.a6.value)} className={`picker-date-day-option ${daysControler.a6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.a7 ? <button  onClick={() => onClickSetDay(daysControler.a7.value)} className={`picker-date-day-option ${daysControler.a7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.a7.value}</button> : null}
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b1 ? <button onClick={() => onClickSetDay(daysControler.b1.value)} className={`picker-date-day-option ${daysControler.b1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b2 ? <button onClick={() => onClickSetDay(daysControler.b2.value)} className={`picker-date-day-option ${daysControler.b2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b3 ? <button  onClick={() => onClickSetDay(daysControler.b3.value)} className={`picker-date-day-option ${daysControler.b3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b4 ? <button  onClick={() => onClickSetDay(daysControler.b4.value)} className={`picker-date-day-option ${daysControler.b4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b5 ? <button  onClick={() => onClickSetDay(daysControler.b5.value)} className={`picker-date-day-option ${daysControler.b5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b6 ? <button  onClick={() => onClickSetDay(daysControler.b6.value)} className={`picker-date-day-option ${daysControler.b6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.b7 ? <button  onClick={() => onClickSetDay(daysControler.b7.value)} className={`picker-date-day-option ${daysControler.b7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.b7.value}</button> : null}
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c1 ? <button onClick={() => onClickSetDay(daysControler.c1.value)} className={`picker-date-day-option ${daysControler.c1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c2 ? <button onClick={() => onClickSetDay(daysControler.c2.value)} className={`picker-date-day-option ${daysControler.c2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c3 ? <button  onClick={() => onClickSetDay(daysControler.c3.value)} className={`picker-date-day-option ${daysControler.c3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c4 ? <button  onClick={() => onClickSetDay(daysControler.c4.value)} className={`picker-date-day-option ${daysControler.c4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c5 ? <button  onClick={() => onClickSetDay(daysControler.c5.value)} className={`picker-date-day-option ${daysControler.c5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c6 ? <button  onClick={() => onClickSetDay(daysControler.c6.value)} className={`picker-date-day-option ${daysControler.c6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.c7 ? <button  onClick={() => onClickSetDay(daysControler.c7.value)} className={`picker-date-day-option ${daysControler.c7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.c7.value}</button> : null}
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d1 ? <button onClick={() => onClickSetDay(daysControler.d1.value)} className={`picker-date-day-option ${daysControler.d1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d2 ? <button onClick={() => onClickSetDay(daysControler.d2.value)} className={`picker-date-day-option ${daysControler.d2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d3 ? <button  onClick={() => onClickSetDay(daysControler.d3.value)} className={`picker-date-day-option ${daysControler.d3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d4 ? <button  onClick={() => onClickSetDay(daysControler.d4.value)} className={`picker-date-day-option ${daysControler.d4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d5 ? <button  onClick={() => onClickSetDay(daysControler.d5.value)} className={`picker-date-day-option ${daysControler.d5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d6 ? <button  onClick={() => onClickSetDay(daysControler.d6.value)} className={`picker-date-day-option ${daysControler.d6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.d7 ? <button  onClick={() => onClickSetDay(daysControler.d7.value)} className={`picker-date-day-option ${daysControler.d7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.d7.value}</button> : null}
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e1 ? <button onClick={() => onClickSetDay(daysControler.e1.value)} className={`picker-date-day-option ${daysControler.e1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e2 ? <button onClick={() => onClickSetDay(daysControler.e2.value)} className={`picker-date-day-option ${daysControler.e2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e3 ? <button  onClick={() => onClickSetDay(daysControler.e3.value)} className={`picker-date-day-option ${daysControler.e3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e4 ? <button  onClick={() => onClickSetDay(daysControler.e4.value)} className={`picker-date-day-option ${daysControler.e4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e5 ? <button  onClick={() => onClickSetDay(daysControler.e5.value)} className={`picker-date-day-option ${daysControler.e5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e6 ? <button  onClick={() => onClickSetDay(daysControler.e6.value)} className={`picker-date-day-option ${daysControler.e6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.e7 ? <button  onClick={() => onClickSetDay(daysControler.e7.value)} className={`picker-date-day-option ${daysControler.e7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.e7.value}</button> : null}
                </span>
            </div>

            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f1 ? <button onClick={() => onClickSetDay(daysControler.f1.value)} className={`picker-date-day-option ${daysControler.f1.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f1.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f2 ? <button onClick={() => onClickSetDay(daysControler.f2.value)} className={`picker-date-day-option ${daysControler.f2.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f2.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f3 ? <button  onClick={() => onClickSetDay(daysControler.f3.value)} className={`picker-date-day-option ${daysControler.f3.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f3.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f4 ? <button  onClick={() => onClickSetDay(daysControler.f4.value)} className={`picker-date-day-option ${daysControler.f4.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f4.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f5 ? <button  onClick={() => onClickSetDay(daysControler.f5.value)} className={`picker-date-day-option ${daysControler.f5.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f5.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f6 ? <button  onClick={() => onClickSetDay(daysControler.f6.value)} className={`picker-date-day-option ${daysControler.f6.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f6.value}</button> : null}
                </span>
                <span 
                    style={{
                        width: "14.2%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {daysControler.f7 ? <button  onClick={() => onClickSetDay(daysControler.f7.value)} className={`picker-date-day-option ${daysControler.f7.active ? "picker-date-day-option-active" : (appTheme.dark ? 'picker-date-day-option-inactive picker-date-day-option-dark' : 'picker-date-day-option-inactive picker-date-day-option-light')}`}>{daysControler.f7.value}</button> : null}
                </span>
            </div>
            {!onlyInThisMonth ? 
                <div className="picker-date-today-cont" style={{width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "-10px"}}>
                    <button onClick={onClickSetToday} className={`${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'border-box-light date-picker-botton-light'}`}><p>{appLanguage.en ? "Today" : "Hoy"}</p></button> 
                </div>
            : null}
        </Fragment>
    )
}

const Months = ({tempDate, onClickSetMonth, onClickSetToday, onlyInThisMonth}) => {
    const { appTheme, appLanguage } = useContext(AppContext);
    
    return (
        <Fragment>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "30px"
            }}>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(0)} className={`picker-date-month-option ${tempDate.getMonth() === 0 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Jan" : "Ene"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(1)} className={`picker-date-month-option ${tempDate.getMonth() === 1 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Feb" : "Feb"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(2)} className={`picker-date-month-option ${tempDate.getMonth() === 2 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Mar" : "Mar"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(3)} className={`picker-date-month-option ${tempDate.getMonth() === 3 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Apr" : "Abr"}</button>
                </span>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
            <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(4)} className={`picker-date-month-option ${tempDate.getMonth() === 4 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "May" : "Mayo"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(5)} className={`picker-date-month-option ${tempDate.getMonth() === 5 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "June" : "Jun"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(6)} className={`picker-date-month-option ${tempDate.getMonth() === 6 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "July" : "Jul"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(7)} className={`picker-date-month-option ${tempDate.getMonth() === 7 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Aug" : "Ago"}</button>
                </span>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
            <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(8)} className={`picker-date-month-option ${tempDate.getMonth() === 8 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Sept" : "Sept"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(9)} className={`picker-date-month-option ${tempDate.getMonth() === 9 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Oct" : "Oct"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(10)} className={`picker-date-month-option ${tempDate.getMonth() === 10 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Nov" : "Nov"}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetMonth(11)} className={`picker-date-month-option ${tempDate.getMonth() === 11 ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{appLanguage.en ? "Dec" : "Dic"}</button>
                </span>
            </div>
            {!onlyInThisMonth ? 
                <div className="picker-date-today-cont" style={{width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
                    <button onClick={onClickSetToday} className={`${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'border-box-light date-picker-botton-light'}`}><p>{appLanguage.en ? "Today" : "Hoy"}</p></button> 
                </div>
            : null}
        </Fragment>
    )
}

const Years = ({tempDate, onClickSetYear, onClickSetToday, onlyInThisMonth}) => {
    const { appTheme, appLanguage } = useContext(AppContext);

    const [ yearsControler, setYearsControler ] = useState({
        "a1": {
            active: false,
            value: tempDate.getFullYear() - 4
        },
        "a2": {
            active: false,
            value: tempDate.getFullYear() - 3
        },
        "a3": {
            active: false,
            value: tempDate.getFullYear() - 2
        },
        "a4": {
            active: false,
            value: tempDate.getFullYear() - 1
        },
        "b1": {
            active: true,
            value: tempDate.getFullYear()
        },
        "b2": {
            active: false,
            value: tempDate.getFullYear() + 1
        },
        "b3": {
            active: false,
            value: tempDate.getFullYear() + 2
        },
        "b4": {
            active: false,
            value: tempDate.getFullYear() + 3
        },
        "c1": {
            active: false,
            value: tempDate.getFullYear() + 4
        },
        "c2": {
            active: false,
            value: tempDate.getFullYear() + 5
        },
        "c3": {
            active: false,
            value: tempDate.getFullYear() + 6
        },
        "c4": {
            active: false,
            value: tempDate.getFullYear() + 7
        },
    });

    useEffect(() => {
        if (tempDate) {
            const newYearsControler = {...yearsControler};

            Object.entries(yearsControler).forEach(([key, value]) => {
                newYearsControler[key].active = (tempDate.getFullYear() === value.value ? true : false);
            });

            setYearsControler(newYearsControler);
        }
    // eslint-disable-next-line
    }, [tempDate]);

    const onClickDown = () => {
        const a1Positon = yearsControler.a1.value;

        setYearsControler({
            "a1": {
                active: (tempDate.getFullYear() === (a1Positon - 12)) ? true : false,
                value: a1Positon - 12
            },
            "a2": {
                active: (tempDate.getFullYear() === (a1Positon - 11)) ? true : false,
                value: a1Positon - 11
            },
            "a3": {
                active: (tempDate.getFullYear() === (a1Positon - 10)) ? true : false,
                value: a1Positon - 10
            },
            "a4": {
                active: (tempDate.getFullYear() === (a1Positon - 9)) ? true : false,
                value: a1Positon - 9
            },
            "b1": {
                active: (tempDate.getFullYear() === (a1Positon - 8)) ? true : false,
                value: a1Positon - 8
            },
            "b2": {
                active: (tempDate.getFullYear() === (a1Positon - 7)) ? true : false,
                value: a1Positon - 7
            },
            "b3": {
                active: (tempDate.getFullYear() === (a1Positon - 6)) ? true : false,
                value: a1Positon - 6
            },
            "b4": {
                active: (tempDate.getFullYear() === (a1Positon - 5)) ? true : false,
                value: a1Positon - 5
            },
            "c1": {
                active: (tempDate.getFullYear() === (a1Positon - 4)) ? true : false,
                value: a1Positon - 4
            },
            "c2": {
                active: (tempDate.getFullYear() === (a1Positon - 3)) ? true : false,
                value: a1Positon - 3
            },
            "c3": {
                active: (tempDate.getFullYear() === (a1Positon - 2)) ? true : false,
                value: a1Positon - 2
            },
            "c4": {
                active: (tempDate.getFullYear() === (a1Positon - 1)) ? true : false,
                value: a1Positon - 1
            },
        });
    }

    const onClickUp = () => {
        const c4Positon = yearsControler.c4.value;

        setYearsControler({
            "a1": {
                active: (tempDate.getFullYear() === (c4Positon + 1)) ? true : false,
                value: c4Positon + 1
            },
            "a2": {
                active: (tempDate.getFullYear() === (c4Positon + 2)) ? true : false,
                value: c4Positon + 2
            },
            "a3": {
                active: (tempDate.getFullYear() === (c4Positon + 3)) ? true : false,
                value: c4Positon + 3
            },
            "a4": {
                active: (tempDate.getFullYear() === (c4Positon + 4)) ? true : false,
                value: c4Positon + 4
            },
            "b1": {
                active: (tempDate.getFullYear() === (c4Positon + 5)) ? true : false,
                value: c4Positon + 5
            },
            "b2": {
                active: (tempDate.getFullYear() === (c4Positon + 6)) ? true : false,
                value: c4Positon + 6
            },
            "b3": {
                active: (tempDate.getFullYear() === (c4Positon + 7)) ? true : false,
                value: c4Positon + 7
            },
            "b4": {
                active: (tempDate.getFullYear() === (c4Positon + 8)) ? true : false,
                value: c4Positon + 8
            },
            "c1": {
                active: (tempDate.getFullYear() === (c4Positon + 9)) ? true : false,
                value: c4Positon + 9
            },
            "c2": {
                active: (tempDate.getFullYear() === (c4Positon + 10)) ? true : false,
                value: c4Positon + 10
            },
            "c3": {
                active: (tempDate.getFullYear() === (c4Positon + 11)) ? true : false,
                value: c4Positon + 11
            },
            "c4": {
                active: (tempDate.getFullYear() === (c4Positon + 12)) ? true : false,
                value: c4Positon + 12
            },
        });
    }

    return (
        <Fragment>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "30px"
            }}>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.a1.value)} className={`picker-date-month-option ${yearsControler.a1.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.a1.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.a2.value)} className={`picker-date-month-option ${yearsControler.a2.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.a2.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.a3.value)} className={`picker-date-month-option ${yearsControler.a3.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.a3.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.a4.value)} className={`picker-date-month-option ${yearsControler.a4.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.a4.value}</button>
                </span>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
            <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.b1.value)} className={`picker-date-month-option ${yearsControler.b1.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.b1.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.b2.value)} className={`picker-date-month-option ${yearsControler.b2.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.b2.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.b3.value)} className={`picker-date-month-option ${yearsControler.b3.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.b3.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.b4.value)} className={`picker-date-month-option ${yearsControler.b4.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.b4.value}</button>
                </span>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around"
            }}>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.c1.value)} className={`picker-date-month-option ${yearsControler.c1.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.c1.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.c2.value)} className={`picker-date-month-option ${yearsControler.c2.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.c2.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.c3.value)} className={`picker-date-month-option ${yearsControler.c3.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.c3.value}</button>
                </span>
                <span 
                    style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <button onClick={() => onClickSetYear(yearsControler.c4.value)} className={`picker-date-month-option ${yearsControler.c4.active ? "picker-date-month-option-active" : (appTheme.dark ? 'picker-date-month-option-inactive picker-date-month-option-dark' : 'picker-date-month-option-inactive picker-date-month-option-light')}`}>{yearsControler.c4.value}</button>
                </span>
            </div>
            {!onlyInThisMonth ? 
                <div className="picker-date-today-cont" style={{marginTop: "15px"}}>
                    <div className="picker-date-today-second-cont" >
                        <button onClick={onClickDown} style={{width: "70px"}} className={`${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'date-picker-botton-light border-box-light'}`}><img src={appTheme.dark ? arrowLeftDark : arrowLeftLight } alt="Down" /></button>
                        <button onClick={onClickUp} style={{width: "70px"}} className={`${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'date-picker-botton-light border-box-light'}`}><img src={appTheme.dark ? arrowRightDark : arrowRightLight} alt="Up" /></button>
                    </div>
                    <button onClick={onClickSetToday} className={`${appTheme.dark ? 'date-picker-botton-dark border-box-dark' : 'border-box-light date-picker-botton-light'}`}><p>{appLanguage.en ? "Today" : "Hoy"}</p></button> 
                </div>
            : null}
        </Fragment>
    )
}

export default InputPickerDate;