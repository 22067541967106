import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import './settingsParagraphs.scss';

const SettingsParagraphs = ({paragraphs}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`settings-container-paragraph  ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            {paragraphs?.map( paragraph => { 
                return (
                    <p key={Math.random()}>{paragraph}</p>
                )
            })}
        </div>
    );
}

export default SettingsParagraphs;