import './spinnerOutSideLight3.css';

const SpinnerOutSideLight3 = () => {
    return (
        <div className="sk-fading-circle-light3">
            <div className="sk-circle1-light3 sk-circle-light3"></div>
            <div className="sk-circle2-light3 sk-circle-light3"></div>
            <div className="sk-circle3-light3 sk-circle-light3"></div>
            <div className="sk-circle4-light3 sk-circle-light3"></div>
            <div className="sk-circle5-light3 sk-circle-light3"></div>
            <div className="sk-circle6-light3 sk-circle-light3"></div>
            <div className="sk-circle7-light3 sk-circle-light3"></div>
            <div className="sk-circle8-light3 sk-circle-light3"></div>
            <div className="sk-circle9-light3 sk-circle-light3"></div>
            <div className="sk-circle10-light3 sk-circle-light3"></div>
            <div className="sk-circle11-light3 sk-circle-light3"></div>
            <div className="sk-circle12-light3 sk-circle-light3"></div>
        </div>
    );
}

export default SpinnerOutSideLight3;