import './spinnerOutSideDark.css';

const SpinnerOutSideDark = () => {
    return (
        <div className="sk-fading-circle-dark">
            <div className="sk-circle1-dark sk-circle-dark"></div>
            <div className="sk-circle2-dark sk-circle-dark"></div>
            <div className="sk-circle3-dark sk-circle-dark"></div>
            <div className="sk-circle4-dark sk-circle-dark"></div>
            <div className="sk-circle5-dark sk-circle-dark"></div>
            <div className="sk-circle6-dark sk-circle-dark"></div>
            <div className="sk-circle7-dark sk-circle-dark"></div>
            <div className="sk-circle8-dark sk-circle-dark"></div>
            <div className="sk-circle9-dark sk-circle-dark"></div>
            <div className="sk-circle10-dark sk-circle-dark"></div>
            <div className="sk-circle11-dark sk-circle-dark"></div>
            <div className="sk-circle12-dark sk-circle-dark"></div>
        </div>
    );
}

export default SpinnerOutSideDark;