import { useContext } from 'react';
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from '../../../firebase/context';
import { useNavigate, useLocation } from 'react-router-dom';

import './goBackButton.scss';

import arrowLeftDark from '../../../icons/arrow-left-dark.png';
import arrowLeftLight from '../../../icons/arrow-left-light.png';

const GoBackButton = () => {

    const { appTheme, isTouchEnabled } = useContext(AppContext);
    const { accountSelected } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    }
    
    return (
        <button 
            className={`navigation-container-go-back ${(location.pathname === '/') || ((location.pathname === '/elegir-cuenta') && (!accountSelected)) ? 'navigation-container-go-back-disable ' : "" }  ${appTheme.dark ? `${isTouchEnabled() ? "navigation-container-go-back-dark-no-hover" : "navigation-container-go-back-dark"} border-box-dark` : `${isTouchEnabled() ? "navigation-container-go-back-light-no-hover" : "navigation-container-go-back-light"} border-box-light`}`}
            onClick={goBack}
            disabled={(location.pathname === '/') || ((location.pathname === '/elegir-cuenta') && (!accountSelected)) ? true : false}
        >
            <div className={`navigation-container-go-back-item`}>
                <img 
                    className='icon-navigation' 
                    src={appTheme.dark ? arrowLeftDark : arrowLeftLight} 
                    alt="menuBurger-img" 
                />
            </div>
        </button>
    )
}

export default GoBackButton;
