import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../firebase/context";

const IsEmailVerify = () => {
    const { emailVerified } = useContext(AuthContext);
    return (
        emailVerified ? <Outlet/> : <Navigate to="/auth/verificar-correo" replace />
    );
}

export default IsEmailVerify;