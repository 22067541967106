import { useContext, useRef, useEffect, useState } from 'react';
import { AlertNewAuthUserContext } from './alertNewAuthUserContext';
import { ref, getDownloadURL } from "firebase/storage";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';

import checkIcon from '../../img/check.svg';
import plusIcon from '../../img/plus.svg';
import firebase from '../../firebase/firebase';
import Spinner3 from '../spinner3/spinner3';

import './alertNewAuthUser.scss';

const AlertNewAuthUser = () => {

    const { alertActiveAuth, setAlertActiveAuth, alertDataAuth, onAgreeAuth, onCancelAuth } = useContext(AlertNewAuthUserContext);
    const { appLanguage, appTheme, formatRationalNumber } = useContext(AppContext);
    const { setPictureInMemory } = useContext(AuthContext);

    const [loadingPicture, setLoadingPicture] = useState(false);
    const [pictureURL, setPictureURL] = useState(null);

    let menuRef = useRef();

    const onClickOk = () => {
        onCancelAuth()
        setAlertActiveAuth(false);
    }

    useEffect(() => {
        if (alertActiveAuth) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    onClickOk();
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d600x600";

        const dbId = path;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureURL(request.result.key[dimensions]);
                            setLoadingPicture(false);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        setLoadingPicture(true);

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                setPictureURL(urlData);
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, path);
                                    setLoadingPicture(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
                setLoadingPicture(false);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    useEffect(() => {
        if (alertDataAuth.pictureLocation) {
            checkIfImageExistInGe(alertDataAuth.pictureLocation);
        }
        // eslint-disable-next-line
    }, [alertDataAuth]);

    useEffect(() => {
        if (!alertActiveAuth) {
            setPictureURL(null);
        }
    }, [alertActiveAuth])

    return (
        <div className='unselectable'>
            {alertActiveAuth ? <div className='lock-screen-alert' /> : null}
            <div ref={menuRef} className={`alert-genaral-card-auth ${alertActiveAuth ? 'active' : 'inactive'}`} style={{ background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='alert-general-despcrition-auth'>
                    <h4 className='alert-general-despcrition-p-h4'>{appLanguage.en ? alertDataAuth.title.en : alertDataAuth.title.es}</h4>
                    {loadingPicture ?
                        <div className="auth-user-profile-yes-img-big-cont unselectable">
                            <div
                                style={{
                                    backgroundColor: "rgba(128, 128, 128, 0.13)",
                                    borderRadius: "100%",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                className="aut-use-pro-yes-img-big-con-div"
                            >
                                <Spinner3 />
                            </div>
                        </div>
                        :
                        <div className='alert-general-despcrition-pic-div'>
                            <img className='alert-general-despcrition-pic-img' src={pictureURL ? pictureURL : "/profile.svg"} alt='User' />
                        </div>
                    }

                    <p className='alert-general-despcrition-email'><b>{alertDataAuth.email}</b></p>

                    {alertDataAuth.type === 'freeUser' ?
                        <p className='alert-general-despcrition-p'>
                            <img className="pricing-cre-acc-icons" src={checkIcon} alt="Included" /> 
                            {appLanguage.en ? " Adding this user will not have any extra charge as it is included in your plan." : " Agregar este usuario no tendrá ningún cargo extra pues esta incluido en su plan."}
                        </p>
                    :
                        appLanguage.en ?
                            <p className='alert-general-despcrition-p'>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" /> <b> RD$ {alertDataAuth.price ? formatRationalNumber(alertDataAuth.price) : "--"}</b> will be added to your bill as a charge for adding an extra user.
                                
                            </p>
                        :
                            <p className='alert-general-despcrition-p'>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" /> A su factura se le sumará <b>RD${alertDataAuth.price ? formatRationalNumber(alertDataAuth.price) : "--"}</b> mensual como cargo por agregar un usuario extra.
                                
                            </p>
                    }
                </div>
                <div className='alert-button-container'>
                    <button
                        className={`alert-button-iten-cancel`}
                        style={{ color: appTheme.dark ? "white" : "black" }}
                        onClick={onClickOk}
                    >
                        {appLanguage.en ? 'Cancel' : 'Cancelar'}
                    </button>
                    <button
                        className={`alert-button-iten-yes`}
                        onClick={onAgreeAuth}
                    >
                        {appLanguage.en ? 'Confirm' : 'Confirmar'}
                    </button>

                </div>
            </div>
        </div>
    )
}

export default AlertNewAuthUser;