import { useState, useEffect, Fragment, useContext } from "react";
import { AppContext } from "../../../context/AppContext";

import "./pageNavigator.scss";

import arrowBack from "../../../icons/arrow-left-dark.png";
import arrowHead from "../../../icons/arrow-small-right-white.png";

const PageNavigator = ({pageNumber, showPageNav, onClickNext, onClickPrevious, empty, navControlers, elemmarginBottom, elemMarginTo}) => {

    const { appTheme, appLanguage } = useContext(AppContext);
    
    const [ minimize, setMinimize ] = useState(() => {
        if(window.innerWidth > 400) {
            return false;
        } else {
            return true;
        }
    });

    const [ windowDimension, setWindowDimension ] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight
        });

        if(window.innerWidth > 400) {
            setMinimize(false);
        } else {
            setMinimize(true);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize);

        return() => {
            window.removeEventListener('resize', detectSize);
        }
    }, [windowDimension]);

    return (
        <Fragment> {empty ? null : 
            <div style={elemMarginTo ? {marginTop: elemMarginTo, marginBottom: elemmarginBottom} : {}} className="page-navigator-container unselectable" >
                {showPageNav ? 
                    <div className="page-navigator-element">
                        <button
                            className={navControlers.isPrevious ? "navigator-element-button" : (appTheme.dark ? "navigator-element-button-disable-dark" : "navigator-element-button-disable-light")}
                            disabled={!navControlers.isPrevious}
                            onClick={(e) => onClickPrevious(e)}
                        >
                            <img className={minimize ? "navigator-element-minimize-button" : ""} src={arrowBack} alt="Back" />
                        </button>

                        <p style={{
                            marginLeft: "18px",
                            whiteSpace: "nowrap"

                        }}>{appLanguage.en ? "Page "  :"Página "} <b className="navigator-element-page-number">{pageNumber}</b></p>

                        <p style={{
                            marginRight: "18px",
                            whiteSpace: "nowrap"
                        }}>{appLanguage.en ? " of"  :" de"} <b className="navigator-element-page-number">{navControlers.totalPage ? navControlers.totalPage : "-" }</b></p>

                        <button
                            className={navControlers.isNext ? "navigator-element-button" : (appTheme.dark ? "navigator-element-button-disable-dark" : "navigator-element-button-disable-light")}
                            disabled={!navControlers.isNext}
                            onClick={(e) => onClickNext(e)}
                        >
                            <img className={minimize ? "navigator-element-minimize-button" : ""} src={arrowHead} alt="Next" />
                        </button>
                    </div>
                :
                    <div className="page-navigator-element">
                        <p>{appLanguage.en ? "Page 1 of 1"  :"Página 1 de 1"}</p>
                        <br />
                    </div>
                }
            </div>
        } </Fragment>
    )
}

export default PageNavigator;