import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { SideMenuContext } from "../../context/SideMenuContext";

import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";
import "./home.page.scss";

import homeDarkIcon from '../../icons/home-dark.png';
import homeLightIcon from '../../icons/home-light.png';

import productsIcon from "../../img/home/delivery-box.png";
import salesIcon from "../../img/home/cashier-machine.png";

import inventoryIcon from "../../img/home/inventory.png";
import supplyIcon from "../../img/home/logistics.png";

import invoiceIcon from "../../img/home/file.png";
// import purchasingIcon from "../../img/home/purchasing.png";

import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";

const HomePage = () => {

    const { useMode, appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);

    const [ isAnyTool, setIsAnyTool ] = useState(null);
    const [ areAllTools, setAreAllTools ] = useState(null);

    useEffect(() =>{
        if(accessTo) {
            let i = 0;
            let j = 0;
            Object.entries(accessTo).forEach(([key, value]) => {
                if (key === "customer" || key === "metrics") {
                    // Ignore
                } else {
                    j++;
                    if (value) {
                        i++;
                    } 
                }
            });

            if (i > 0) { 
                setIsAnyTool(true);
            } else {
                setIsAnyTool(false);
            }

            if (j >= 5) {
                setAreAllTools(true);
            } else {
                setAreAllTools(false);
            }
        }
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Home' : 'Inicio' } </title>
            </Helmet>
            <div
                className={`page-app-home-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light",
                    height: desktopView ? "calc(100vh - 65px)" : "calc(100vh - 135px)",
                }}
            >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item `}>

                            <div className="welcome-to-business-account-cont">
                                <div className="welcome-to-business-account-div">
                                    <img src={appTheme.dark ? homeDarkIcon : homeLightIcon} alt="Business" />  
                                    <p className="welcome-to-business-account-title"><b>{accountData.businessName}</b></p>
                                </div>
                            </div>

                            {isAnyTool !== null ? <Fragment>
                                {isAnyTool ? <Fragment>
                                    <div className="page-choose-tools-options-container">
                                        <ul 
                                            className="page-choose-tools-options"
                                            style={areAllTools ?
                                                {maxWidth: useMode === "development" ? "600px" : "700px"} 
                                            : 
                                                {maxWidth: useMode === "development" ? "600px" : "700px"}
                                            }
                                        >
                                            {(accessTo.sales || accessTo.salesSup || accessTo.salesConsult) ? 
                                                <ToolsItems dark={appTheme.dark} img={salesIcon} text={appLanguage.en ? "Sales" : "Ventas"} path={"/ventas"} /> 
                                            : null}

                                            {accessTo.products ? 
                                                <ToolsItems dark={appTheme.dark} img={productsIcon} text={appLanguage.en ? "Products" : "Productos"} path={"/productos"} /> 
                                            : null}

                                            {accessTo.inventory ? 
                                                <ToolsItems dark={appTheme.dark} img={inventoryIcon} text={appLanguage.en ? "Inventory" : "Inventario"} path={"/inventario"} /> 
                                            : null}

                                            {accessTo.suppliers ? 
                                                <ToolsItems dark={appTheme.dark} img={supplyIcon} text={appLanguage.en ? "Suppliers" : "Proveedores"} path={'/proveedores'} /> 
                                            : null}

                                            {useMode === "development" &&  accessTo.taxes ? 
                                                <ToolsItems dark={appTheme.dark} img={invoiceIcon} text={appLanguage.en ? "Invoices" : "Facturas"} path={null} /> 
                                            : null}

                                            {/*(accessTo.purchases || accessTo.purchasesConsult) ? 
                                                <ToolsItems dark={appTheme.dark} img={purchasingIcon} text={appLanguage.en ? "Purchases" : "Compras"} path={null} /> 
                                            : null*/}
                                        </ul>
                                    </div>
                                    <div className={desktopView ? null : "mobile-control-button-space"} />
                                </Fragment> : <SectionForbidden sectionName={appLanguage.en ? "No tools enabled" : "Ninguna herramienta habilitada"} />}
                            </Fragment> : null}
                        </div>
                    :
                        <div className="page-app-home-item">
                            <div className="home-options-container">
                                <div className="home-options-container-item">
                                    <SettingsActionRequired />
                                </div> 
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

function ToolsItems({dark, img, text, path}) {
    return(
        <li  className={`page-choose-tools-option ${dark ? 'page-choose-tools-option-dar' : 'page-choose-tools-option-lig'}`}>
            <Link className={`page-choose-tools-link ${dark ? 'dark-text' : 'light-text'}`} to={path}>
                <img src={img} alt={text}/>
                <p>{text}</p>
            </Link>
        </li>
    );
}

export default HomePage;