import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const SaleMoreOptionsContext = createContext();

const SaleMoreOptionsProvider = (props) => {

    const location = useLocation();

    const [ saleMoreOptionsActive, setSaleMoreOptionsActive ] = useState(false);
    const [ option, setOption ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (saleMoreOptionsActive) {
            if (location.pathname !== "/resumen-de-venta" && (!loading)) {
                setSaleMoreOptionsActive(false);
                resetOptionsState();
            }
        }
    // eslint-disable-next-line
    }, [location, loading]);

    // For split payments option
    const [ splitPayment, setSplitPayment ] = useState(2);
    const [ firstAmount, setFirstAmount ] = useState("");
    const [ secondAmount, setSecondAmount ] = useState("");
    const [ thirdAmount, setThirdAmount ] = useState("");
    
    const [ firstAmountAlert, setFirstAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ secondAmountAlert, setSecondAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const resetOptionsState = () => {
        setSplitPayment(2);
        setFirstAmount("");
        setSecondAmount("");
        setThirdAmount("");
        setFirstAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        setSecondAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }

    useEffect(() => {
        if (!saleMoreOptionsActive) {
            resetOptionsState();
        }
    }, [saleMoreOptionsActive])

    return (
        <SaleMoreOptionsContext.Provider
            value={{ 
                saleMoreOptionsActive,
                option,
                loading, 
                splitPayment,
                firstAmount,
                secondAmount,
                thirdAmount,
                firstAmountAlert,
                secondAmountAlert,
                setSaleMoreOptionsActive,
                setOption,
                setLoading,
                setSplitPayment,
                setFirstAmount,
                setSecondAmount,
                setThirdAmount,
                setFirstAmountAlert,
                setSecondAmountAlert
            }}
        >
            {props.children}
        </SaleMoreOptionsContext.Provider>
    )
}

export default SaleMoreOptionsProvider;