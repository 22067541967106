import { useContext, Fragment, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import './sideMenuButton.scss';

import downArrowLight from '../../../icons/down-arrow-light.png';
import downArrowDark from '../../../icons/down-arrow-dark.png'

const SideMenuButton = ({imgIcon, description, downArrow, onClickButton, isOpen, goTo}) => {

    const { appTheme, isInsideFatherPath } = useContext(AppContext);
    const { setSideMenuOpen, setLockScreen } = useContext(SideMenuContext);

    const location = useLocation();

    const handlerClick = () => {
        onClickButton();
        setSideMenuOpen(false);
        setLockScreen(false);
    }

    const theCurrentPath = () => {
        if(isInsideFatherPath(goTo)) {
            return true;
        } else {
            return false;
        }
    }

    const [ isCurrentPath, setIsCurrentPath ] = useState(theCurrentPath());

    useEffect(() => {
        setIsCurrentPath(theCurrentPath());
    // eslint-disable-next-line
    }, [location]);

    return (
        <Fragment>
            {goTo ? (
                <Link 
                    onClick={handlerClick}
                    className={`side-menu-button-container  ${isCurrentPath ? `${appTheme.dark ? 'side-menu-button-dark-focus' : 'side-menu-button-light-focus'}` : `${appTheme.dark ? 'side-menu-button-dark' : 'side-menu-button-light'}`}`}
                    to={goTo}
                >
                    <img className='side-menu-icon' src={imgIcon} alt='little logo' />
                    <p className='side-menu-description'>{description}</p>
                    <div className='navigation-container-space' />

                    {downArrow ? (
                        <img 
                            className={`side-menu-icon-down-arrow ${isOpen ? "is-open" : "is-closed"}`}
                            src={`${appTheme.dark ? downArrowDark : downArrowLight}`} alt='Down Arrow'
                        />
                    ) : null}
                </Link>
            ) : (
                <button 
                    onClick={onClickButton}
                    className={`side-menu-button-container ${appTheme.dark ? 'side-menu-button-dark' : 'side-menu-button-light'}`}
                >
                    <img className='side-menu-icon' src={imgIcon} alt='little logo' />
                    <p className='side-menu-description'>{description}</p>
                    <div className='navigation-container-space' />

                    {downArrow ? (
                        <img 
                            className={`side-menu-icon-down-arrow ${isOpen ? "is-open" : "is-closed"}`}
                            src={`${appTheme.dark ? downArrowDark : downArrowLight}`} alt='Down Arrow'
                        />
                    ) : null}
                </button>
            )}
        </Fragment>
    )
}

export default SideMenuButton;