import { Fragment, useContext, useEffect, useState } from "react";
import { uid } from "uid";
import { SideMenuContext } from "../../context/SideMenuContext";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../firebase/context";

import firebase from '../../firebase';
import { collection, query, onSnapshot, orderBy, limit, getCountFromServer } from "firebase/firestore";

import Spinner from "../spinner/spinner";
import ConnectionError from "../connection-error/connectionError";

import emptyBox from '../../img/empty-box.svg';

import shareDarkIcon from '../../icons/share-dark.png';

import "./settingsBillHistory.scss";


const SettingsBillHistory = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);

    const [billingHistory, setBillingHistory] = useState(null);

    const [showConnectionError, setConnectionError] = useState(false);

    const [effectControler, setEffectControler] = useState(100000);

    async function verifiedIfBillHistoryCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setBillingHistory([]);
                setConnectionError(false);
                setLoading(false);
            }
        } catch (error) {
            console.warn(error);
            setConnectionError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (accountData) {
            setLoading(true);

            const q = query(collection(firebase.db, `accounts/${accountData.id}/billHistory`), orderBy("newBillingDay", "desc"), limit(10));

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfBillHistoryCollectionIsReallyEmpty(q)
                } else {
                    if (billingHistory) {
                        setBillingHistory(res);
                        setConnectionError(false);
                        setLoading(false);
                    } else {
                        setBillingHistory(res);
                        setConnectionError(false);
                        setLoading(false);
                    }
                }
            }, err => {
                console.error(err);
                setConnectionError(true);
                setLoading(false);
            });

            return () => unsub();
        }
        // eslint-disable-next-line
    }, [effectControler]);

    const onTryAgain = () => {
        setEffectControler(uid(6))
    }

    return (
        <div className={`settings-bill-history ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <p><b>{appLanguage.en ? "Invoice history" : "Historial de facturas"}</b></p>

            {loading ? <Spinner /> : <Fragment>
                {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> : <Fragment>
                    {billingHistory ? <Fragment>
                        {billingHistory.length > 0 ?
                            <Fragment>
                                {billingHistory.map((record) => {
                                    return (
                                        <BillRecord key={uid()} record={record} />
                                    );
                                })}
                                <div className='settings-bill-history-item-hr' />
                            </Fragment>
                            :
                            <div className="settings-bill-history-empty-con">
                                <img src={emptyBox} alt="Empty box" />
                                <p>{appLanguage.en ? "Nothing to show" : "Nada que mostrar"}</p>
                            </div>
                        }
                    </Fragment> : null}
                </Fragment>}
            </Fragment>}
        </div>
    );
}

const BillRecord = ({ record }) => {

    const { desktopView } = useContext(SideMenuContext);
    const { appLanguage, getDateInString, formatRationalNumber } = useContext(AppContext);

    const date = new Date(record.newBillingDay);

    return (
        <Fragment>
            <div className='settings-bill-history-item-hr' />
            {desktopView ?
                <div className="settings-bill-history-item-con">
                    <div>
                        <p><b>RD$ {formatRationalNumber(record.newCurrentBalance)}</b></p>
                    </div>
                    <div
                        style={{
                            minWidth: "120px"
                        }}
                    >
                        <p>{getDateInString(date)}</p>
                    </div>
                    <div
                        style={{
                            minWidth: "170px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {record.pdfLink ?
                            <a
                                href={record.pdfLink}
                                target="_blank"
                                rel="noreferrer"
                                className="settings-bill-history-open-butt"
                            >
                                <p style={{whiteSpace: "nowrap"}}>{appLanguage.en ? "Open" : "Abrir"} PDF</p> <img src={shareDarkIcon} alt="Open" />
                            </a>
                        :
                            <p style={{whiteSpace: "nowrap"}}>{appLanguage.en ? "PDF not available" : "PDF no disponible"}</p>
                        }
                    </div>
                </div>
                :
                <div className="settings-bill-history-item-con">
                    <div>
                        <p style={{whiteSpace: "nowrap"}}><b>RD$ {formatRationalNumber(record.newCurrentBalance)}</b></p>
                        <p style={{whiteSpace: "nowrap"}}>{getDateInString(date)}</p>
                    </div>
                    <div
                        style={{
                            minWidth: "170px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {record.pdfLink ?
                            <a
                                href={record.pdfLink}
                                target="_blank"
                                rel="noreferrer"
                                className="settings-bill-history-open-butt"
                            >
                                <p style={{whiteSpace: "nowrap"}}>{appLanguage.en ? "Open" : "Abrir"} PDF</p> <img src={shareDarkIcon} alt="Open" />
                            </a>
                        :
                            <p style={{whiteSpace: "nowrap"}}>{appLanguage.en ? "PDF not available" : "PDF no disponible"}</p>
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default SettingsBillHistory;