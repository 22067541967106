import './spinnerOutSideDark3.css';

const SpinnerOutSideDark3 = () => {
    return (
        <div className="sk-fading-circle-dark3">
            <div className="sk-circle1-dark3 sk-circle-dark3"></div>
            <div className="sk-circle2-dark3 sk-circle-dark3"></div>
            <div className="sk-circle3-dark3 sk-circle-dark3"></div>
            <div className="sk-circle4-dark3 sk-circle-dark3"></div>
            <div className="sk-circle5-dark3 sk-circle-dark3"></div>
            <div className="sk-circle6-dark3 sk-circle-dark3"></div>
            <div className="sk-circle7-dark3 sk-circle-dark3"></div>
            <div className="sk-circle8-dark3 sk-circle-dark3"></div>
            <div className="sk-circle9-dark3 sk-circle-dark3"></div>
            <div className="sk-circle10-dark3 sk-circle-dark3"></div>
            <div className="sk-circle11-dark3 sk-circle-dark3"></div>
            <div className="sk-circle12-dark3 sk-circle-dark3"></div>
        </div>
    );
}

export default SpinnerOutSideDark3;