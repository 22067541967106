import { Fragment, useContext } from "react";
import { AppContext } from '../../context/AppContext';
import { SideMenuContext } from "../../context/SideMenuContext";
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';

import keyIconDark from '../../icons/key-dark.png';
import keyIconLight from '../../icons/key-light.png';

import settingIconDark from '../../icons/settings-dark.png';
import settingIconLight from '../../icons/settings-light.png';

import signatureIconDark from '../../icons/signature-dark.png';
import signatureIconLight from '../../icons/signature-light.png';

import securityIconDark from '../../icons/security-dark.png';
import securityIconLight from '../../icons/security-light.png';
    
import SettingsHeader from "../../components/settings-header/settingsHeader";
import SettingsTitle from "../../components/settings-title/settingsTitle";
import SettingsOption from "../../components/settings-options/settingsOption";

import './myProfile.page.scss';

const MyProfilePage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { userData, userPictures } = useContext(AuthContext);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'My Profile' : 'Mi perfil' } </title>
            </Helmet>
            <div 
                className={`page-my-profile-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-my-profile-item">
                    {desktopView ? 
                        <SettingsHeader 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "My Profile" : "Mi perfil"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={userPictures ? userPictures.d140x140 : '/profile.svg'}
                                firstText={userData ? userData.displayName : "--"}
                                secondText={userData ? userData.email : "--"}
                            />
                            <br/>
                            <SettingsOption
                                description={appLanguage.en ? "Edit profile" : "Editar perfil"}
                                icon={appTheme.dark ? signatureIconDark : signatureIconLight}
                                goTo={'/editar-perfil'}
                            />
                            <SettingsOption
                                description={appLanguage.en ? "Change Password" : "Cambiar contraseña"}
                                icon={appTheme.dark ? keyIconDark : keyIconLight}
                                goTo={'/cambiar-password'}
                            />
                            <SettingsOption
                                description={appLanguage.en ? "Security" : "Seguridad"}
                                icon={appTheme.dark ? securityIconDark : securityIconLight}
                                goTo={'/seguridad'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MyProfilePage;