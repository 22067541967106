import { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AuthContext } from '../../../../firebase/context';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, onSnapshot } from "firebase/firestore";

import firebase from '../../../../firebase/firebase';
import { ref, getDownloadURL } from "firebase/storage";

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';
import SettingsAuthUserDesc from '../../../../components/settings-auth-user-desc/settingsAuthUserDesc';
import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import businessIcon from '../../../../img/analysis.svg';

const ViewAuthorizedProfilePage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess, setPictureInMemory } = useContext(AuthContext);

    const [ authorizedUser, setAuthorizedUser ] = useState(null);
    const [ authUser, setAuthUser ] = useState(null);

    const [loadingPicture, setLoadingPicture] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [pictureData, setPictureData ] = useState(null);
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    }); 

    useEffect(() => {
        if(location.state) {
            setAuthorizedUser(location.state);
            setAuthUser(location.state);
        } else {
            navigate("/usuarios-autorizados", { replace: true })
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(accountData) {
            if(!accountData.active) {
                navigate("/usuarios-autorizados", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (authorizedUser && accountData) {
            const authUserRef = `accounts/${accountData.id}/authorizedUsers`;
            const unsub = onSnapshot(doc(firebase.db, authUserRef, authorizedUser.id), (doc) => {
                const authUserUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };
                if (doc.data()) {
                    setAuthUser(authUserUpdated);
                }
            });
            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [authorizedUser, accountData]);

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d600x600";

        const dbId = path;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        setLoadingPicture(true);

        let requestCounter = 8;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions !== "_600x600") {
                            setPictureData({
                                pictureFile: urlData,
                                location: path
                            });
                        }
                        setLoadingPicture(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, path);
                                    setLoadingError(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if (requestCounter < 3) {
                    console.error(error);
                }
                if (requestCounter > 0) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setLoadingPicture(false);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    useEffect(() => {
        if (authUser) {
            if (authUser.pictureLocation) {
                checkIfImageExistInGe(authUser.pictureLocation);
            } else {
                setPictureData(false);
                setLoadingPicture(false);
            }
        } else {
            setLoadingPicture(false);
        }
    // eslint-disable-next-line    
    }, [authUser]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'View profile' : 'Ver perfil' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader3 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/usuarios-autorizados"}
                            text2={appLanguage.en ? 'Authorized users' : 'Usuarios autorizados'}
                            text={appLanguage.en ? "View profile" : "Ver perfil"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : ""}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ?  <Fragment>
                                <SettingsAuthUserDesc 
                                    authUser={authUser}
                                    setAuthUser={setAuthUser}
                                    loadingPicture={loadingPicture}
                                    loadingError={loadingError}
                                    pictureData={pictureData}
                                />
                            </Fragment> :null} 
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ViewAuthorizedProfilePage;