import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import firebaseConfig from './config';
import axios from 'axios';
import { uid } from 'uid';

import { 
    getFirestore,
    getDoc,
    doc,
} from 'firebase/firestore';

import {
    getAuth, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signInWithPopup,
    GoogleAuthProvider,
    sendEmailVerification,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
} from "firebase/auth";

import { 
    getStorage, 
    ref, 
    uploadBytes,
    deleteObject 
} from "firebase/storage";

import {
    getFunctions,
    httpsCallable,
    // connectFunctionsEmulator,
} from 'firebase/functions';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account"
});

const appCheckConfig = {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_API),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
};

class Firebase {
    constructor() {
        this.app = initializeApp(firebaseConfig);
        this.appCheck = initializeAppCheck(this.app, appCheckConfig);
        this.auth = getAuth();
        this.db = getFirestore();
        this.storage = getStorage();
        this.functions = getFunctions();
        // connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
    }

    // Only for trial period
    async useIsAuthorized(email) {
        const isAuthorized = httpsCallable(this.functions, 'isAuthorized');
        const response = await isAuthorized(email);
        return response.data;
    }

    async useSignInWithPopup() {
        const response = await signInWithPopup(this.auth, provider);
        const { user } = response;

        // Only for trial period
        try {
            await this.useIsAuthorized(user.email);
        } catch (error) {
            await signOut(this.auth);
        }

        const userDocRef = doc(this.db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);

        const newResponse = {
            completed: null,
            user: {
                uid: user.uid,
                displayName: user.displayName,
                displayNameInsensitive: user.displayName.toLocaleLowerCase(),
                email: user.email,
                picture: "/profile.svg",
                picturePath: null,
            }
        }

        if(!userSnapshot.exists()) {
            newResponse.completed = false;
            return newResponse;
        } else {
            newResponse.completed = true;
            return newResponse;
        }
    }

    // To check is the user has data
    async useCheckUserDataExicts() {
        const user = this.auth.currentUser;
        if (user !== null) {
            const uid = user.uid;
            const userDocRef = doc(this.db, 'users', uid);
            const userSnapshot = await getDoc(userDocRef);
            if(userSnapshot.exists()) {
                return true;
            } else {
                return false;
            }
        }
        return null;
    }

    async useCompleteData(user) {
        const { displayName, displayNameInsensitive, phoneNumber, agreeWithTermsAndConditions } = user;
        const userData = {
            displayName, 
            displayNameInsensitive,
            phoneNumber,
            agreeWithTermsAndConditions,
            picture: "/profile.svg",
            picturePath: null,
        }
        const completeDataFunction = httpsCallable(this.functions, 'completeUserData');
        const response = await completeDataFunction(userData);
        return response.data;
    }

    async useUploadUserPicture(file) {
        const fileName = uid(16);
        const userId = this.auth.currentUser.uid
        const newPath = `users/${userId}/${fileName}`;

        const imageRef = ref(firebase.storage, newPath);
        await uploadBytes(imageRef, file);

        const deleteAllPicturesButThisAndGetUserData = httpsCallable(this.functions, 'deleteAllPicturesButThisAndGetUserData');

        const response = await deleteAllPicturesButThisAndGetUserData({
            "newPath": newPath
        });

        return {
            "user": response.data,
            "path": newPath
        };
    }

    async useUpdateNotificationSettings(tempNotificationSettings) {
        const user = this.auth.currentUser;
    
        if (user) {
            const updateNotificationSettings = httpsCallable(this.functions, 'updateNotificationSettings');
            const response =  await updateNotificationSettings({
                "notificationSettings": tempNotificationSettings
            });
            return response.data;
        } else {
            return null;
        }
    }

    async useUpdateNotificationStatus() {
        const updateNotificationStatus = httpsCallable(this.functions, 'updateNotificationStatus');
        const response =  await updateNotificationStatus();
        return response.data;
    }

    async useDeleteNotifications(data) {
        const deleteNotifications = httpsCallable(this.functions, 'deleteNotifications');
        const response =  await deleteNotifications(data);
        return response.data;
    }

    async useDeleteCustomers(data) {
        const deleteCustomers = httpsCallable(this.functions, 'deleteCustomers');
        const response =  await deleteCustomers(data);
        return response.data;
    }

    async useDeleteSuppliers(data) {
        const deleteSuppliers = httpsCallable(this.functions, 'deleteSuppliers');
        const response =  await deleteSuppliers(data);
        return response.data;
    }

    async useDeleteUserPicture() {
        const deleteUserPicture = httpsCallable(this.functions, 'deleteUserPicture');
        const response =  await deleteUserPicture();
        return response.data;
    }

    async useGetPicture(url) {
        const response = await url;
        return response;
    }

    async useCreateUserWithEmailAndPassword(user) {
        await createUserWithEmailAndPassword(this.auth, user.email.trim(), user.password);
        const response = this.useCompleteData(user);
        return response;
    }

    async useSignInWithEmailAndPassword(newUser) {
        const response = await signInWithEmailAndPassword(this.auth, newUser.email.trim(), newUser.password);
        const { user } = response;

        const userDocRef = doc(this.db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);
        
        const newResponse = {
            completed: null,
            response
        }

        if(!userSnapshot.exists()) {
            newResponse.completed = false;
            return newResponse;
        } else {
            newResponse.completed = true;
            return newResponse;
        }
    }

    async useSignOut() {
        await signOut(this.auth);
    }

    async useSendPasswordResetEmail(email) {
        await sendPasswordResetEmail(this.auth, email.trim());
    }

    async useSendEmailVerification() {
        await sendEmailVerification(this.auth.currentUser);
    }

    async useVerifyEmailLink(linkCode, linkApiKey) {
        await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${linkApiKey}`, {
            oobCode : linkCode
        }, { 'Content-type' : 'application/json' });
    }

    async useResetPassworkLink(linkCode, linkApiKey, password) {
        await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${linkApiKey}`, {
            oobCode : linkCode,
            newPassword : password
        }, { 'Content-type' : 'application/json' });
    }

    async useUpdatePassword(oldPassword, newPassword) {
        const user = this.auth.currentUser;
        await signInWithEmailAndPassword(this.auth, user.email, oldPassword);
        await updatePassword(user, newPassword);
    }

    async getUserData() {
        const uid = this.auth.currentUser.uid;
        const docRef = doc(this.db, "users", uid);
        const docSnap = await getDoc(docRef);
        return {...docSnap.data(), id: docSnap.id}
    }

    async useCreateBusinessAccount(account) {
        const createBusinessAccount = httpsCallable(this.functions, 'createBusinessAccount');
        const response = await createBusinessAccount(account);
        return response.data;
    }
    
    async getUserAccounts() {
        const getUserAccounts = httpsCallable(this.functions, 'getUserAccounts');
        const response = await getUserAccounts();
        return response.data;
    }

    async useConfirmPassword(password) {
        const response = await signInWithEmailAndPassword(this.auth, this.auth.currentUser.email, password);
        return response;
    }

    async useEdictAccount(data) {
        const edictAccount = httpsCallable(this.functions, 'edictAccount');
        const response = await edictAccount(data);
        return response;
    }

    async useDeleteBusinessAccount(info) {
        const deleteBusinessAccount = httpsCallable(this.functions, 'deleteBusinessAccount');
        const response = await deleteBusinessAccount(info);
        return response;
    }

    async useEdictAuthUser(data) {
        const edictAuthUser = httpsCallable(this.functions, 'edictAuthUser');
        const response = await edictAuthUser(data);
        return response.data;
    }

    async useGetAccountAdminById(data) {
        const getAccountAdminById = httpsCallable(this.functions, 'getAccountAdminById');
        const response = await getAccountAdminById(data);
        return response.data;
    }

    async useGetAccountBill(data) {
        const getAccountBill = httpsCallable(this.functions, 'getAccountBill');
        const response = await getAccountBill(data);
        return response.data;
    }

    async useGetAccountBillAndRestriction(data) {
        const getAccountBillAndRestriction = httpsCallable(this.functions, 'getAccountBillAndRestriction');
        const response = await getAccountBillAndRestriction(data);
        return response.data;
    }

    async useGetBillingHistory(data) {
        const getAccountBillingHistory = httpsCallable(this.functions, 'getAccountBillingHistory');
        const response = await getAccountBillingHistory(data);
        return response.data;
    }

    async useGetAccountSelected(data) {
        const getAccountSelected = httpsCallable(this.functions, 'getAccountSelected');
        const response = await getAccountSelected(data);
        return response.data;
    }

    async useGetAccountUserById(data) {
        const getAccountUserById = httpsCallable(this.functions, 'getAccountUserById');
        const response = await getAccountUserById(data);
        return response.data;
    }

    async useGetTermsAndConditions() {
        const getTermsAndConditions = httpsCallable(this.functions, 'getTermsAndConditions');
        const response =  await getTermsAndConditions();
        return response.data;
    }

    async useGetPrivacyPolicies() {
        const getTermsAndConditions = httpsCallable(this.functions, 'getPrivacyPolicies');
        const response =  await getTermsAndConditions();
        return response.data;
    }

    async useAddAuthorizedUser(data) {
        const addAuthorizedUser = httpsCallable(this.functions, 'addAuthorizedUser');
        const response =  await addAuthorizedUser(data);
        return response.data;
    }

    async useAddAuthUserCheck(data) {
        const addAuthUserCheck = httpsCallable(this.functions, 'addAuthUserCheck');
        const response =  await addAuthUserCheck(data);
        return response.data;
    }

    async useLeaveAccount(data) {
        const leaveAccount = httpsCallable(this.functions, 'leaveAccount');
        const response =  await leaveAccount(data);
        return response.data;
    }

    async useGetAccountRestriction(data) {
        const getAccountRestriction = httpsCallable(this.functions, 'getAccountRestriction');
        const response =  await getAccountRestriction(data);
        return response.data;
    }

    async useGetCurrentPlans() {
        const getCurrentPlans = httpsCallable(this.functions, 'getCurrentPlans');
        const response =  await getCurrentPlans();
        return response.data;
    }

    async useUpdateUser(data) {
        const updateUserData = httpsCallable(this.functions, 'updateUserData');
        const response =  await updateUserData(data);
        return response.data;
    }

    async useGetNumberOfAuthUsers(data) {
        const getNumberOfAuthUsers = httpsCallable(this.functions, 'getNumberOfAuthUsers');
        const response =  await getNumberOfAuthUsers(data);
        return response.data;
    }

    async useRemoveAuthUser(data) {
        const removeAuthUser = httpsCallable(this.functions, 'removeAuthUser');
        const response =  await removeAuthUser(data);
        return response.data;
    }

    async usePayByBankTransfer(file, accountId, currentPath) {
        const fileName = uid(10);
        const newPath = `accounts/${accountId}/paymentHistory/${fileName}`;

        const imageRef = ref(this.storage, newPath);
        await uploadBytes(imageRef, file);

        const data = {
            "pictureLocation": newPath,
            "accountId": accountId
        }

        const payByBankTransfer = httpsCallable(this.functions, 'payByBankTransfer');
        const response =  await payByBankTransfer(data);

        if (currentPath !== null) {
            try {
                const oldImageRef = ref(this.storage, currentPath);
                await deleteObject(oldImageRef);
            } catch (error) {
                console.error(error);
            }
        }

        return response.data;
    }

    async usePayCurrentBalance(data) {
        const payCurrentBalance = httpsCallable(this.functions, 'payCurrentBalance');
        const response =  await payCurrentBalance(data);
        return response.data;
    }

    async usePayDueBalance(data) {
        const payDueBalance = httpsCallable(this.functions, 'payDueBalance');
        const response =  await payDueBalance(data);
        return response.data;
    }

    async useCancelPlan(data) {
        const cancelPlan = httpsCallable(this.functions, 'cancelPlan');
        const response =  await cancelPlan(data);
        return response.data;
    }

    async useEstimatePlanChange(data) {
        const estimatePlanChange = httpsCallable(this.functions, 'estimatePlanChange');
        const response =  await estimatePlanChange(data);
        return response.data;
    }

    async useEstimateReativatePlan(data) {
        const estimateReativatePlan = httpsCallable(this.functions, 'estimateReativatePlan');
        const response =  await estimateReativatePlan(data);
        return response.data;
    }

    async usePlanChange(data) {
        const planChange = httpsCallable(this.functions, 'planChange');
        const response =  await planChange(data);
        return response.data;
    }

    async useReactivatePlan(data) {
        const reactivatePlan = httpsCallable(this.functions, 'reactivatePlan');
        const response =  await reactivatePlan(data);
        return response.data;
    }

    async useRequestForPayment(data) {
        const requestForPayment = httpsCallable(this.functions, 'requestForPayment');
        const response =  await requestForPayment(data);
        return response.data;
    }

    async useGetAccountDataPayment(id) {
        const getAccountDataPayment = httpsCallable(this.functions, 'getAccountDataPayment');
        const response = await getAccountDataPayment(id);
        return response.data;
    }

    async useGetAccountDataSimple(id) {
        const getAccountDataSimple = httpsCallable(this.functions, 'getAccountDataSimple');
        const response = await getAccountDataSimple(id);
        return response.data;
    }

    async useCreateNewCustomer(data) {
        const createNewCustomer = httpsCallable(this.functions, 'createNewCustomer');
        const response =  await createNewCustomer(data);
        return response.data;
    }

    async useCreateNewSupplier(data) {
        const createNewSupplier = httpsCallable(this.functions, 'createNewSupplier');
        const response =  await createNewSupplier(data);
        return response.data;
    }

    async useCreateCustomers(data) {
        const createCustomers = httpsCallable(this.functions, 'createCustomers');
        const response =  await createCustomers(data);
        return response.data;
    }

    async useCreateSuppliers(data) {
        const createSuppliers = httpsCallable(this.functions, 'createSuppliers');
        const response =  await createSuppliers(data);
        return response.data;
    }

    async useEditCustomers(data) {
        const editCustomers = httpsCallable(this.functions, 'editCustomers');
        const response =  await editCustomers(data);
        return response.data;
    }

    async useEditSuppliers(data) {
        const editSuppliers = httpsCallable(this.functions, 'editSuppliers');
        const response =  await editSuppliers(data);
        return response.data;
    }

    async useSetCustomerNote(data) {
        const setCustomerNote = httpsCallable(this.functions, 'setCustomerNote');
        const response =  await setCustomerNote(data);
        return response.data;
    }

    async useDeleteCustomerNote(data) {
        const deleteCustomerNote = httpsCallable(this.functions, 'deleteCustomerNote');
        const response =  await deleteCustomerNote(data);
        return response.data;
    }

    async useEditCustomer(data) {
        const editCustomer = httpsCallable(this.functions, 'editCustomer');
        const response =  await editCustomer(data);
        return response.data;
    }

    async useDeleteCustomer(data) {
        const deleteCustomer = httpsCallable(this.functions, 'deleteCustomer');
        const response =  await deleteCustomer(data);
        return response.data;
    }

    async useEditCustomerStatus(data) {
        const editCustomerStatus = httpsCallable(this.functions, 'editCustomerStatus');
        const response =  await editCustomerStatus(data);
        return response.data;
    }

    async useCreateNewProduct(data) {
        const createNewProduct = httpsCallable(this.functions, 'createNewProduct');
        const response =  await createNewProduct(data);
        return response.data;
    }

    async useCreateProducts(data) {
        const createProducts = httpsCallable(this.functions, 'createProducts');
        const response =  await createProducts(data);
        return response.data;
    }

    async useEditProducts(data) {
        const editProducts = httpsCallable(this.functions, 'editProducts');
        const response =  await editProducts(data);
        return response.data;
    }

    async useEdictProduct(data) {
        const edictProduct = httpsCallable(this.functions, 'edictProduct');
        const response =  await edictProduct(data);
        return response.data;
    }

    async useDeleteProduct(data) {
        const deleteProduct = httpsCallable(this.functions, 'deleteProduct');
        const response =  await deleteProduct(data);
        return response.data;
    }

    async useDeleteProducts(data) {
        const deleteProducts = httpsCallable(this.functions, 'deleteProducts');
        const response =  await deleteProducts(data);
        return response.data;
    }

    async useChangeToolsStatus(data) {
        const changeToolsStatus = httpsCallable(this.functions, 'changeToolsStatus');
        const response =  await changeToolsStatus(data);
        return response.data;
    }

    async useUploadProductPicture(data) {
        const uploadProductPicture = httpsCallable(this.functions, 'uploadProductPicture');
        const response =  await uploadProductPicture(data);
        return response.data;
    }

    async useDeleteProductPicture(data) {
        const deleteProductPicture = httpsCallable(this.functions, 'deleteProductPicture');
        const response =  await deleteProductPicture(data);
        return response.data;
    }

    async useSetSupplierNote(data) {
        const setSupplierNote = httpsCallable(this.functions, 'setSupplierNote');
        const response =  await setSupplierNote(data);
        return response.data;
    }

    async useDeleteSupplierNote(data) {
        const deleteSupplierNote = httpsCallable(this.functions, 'deleteSupplierNote');
        const response =  await deleteSupplierNote(data);
        return response.data;
    }

    async useEditSupplier(data) {
        const editSupplier = httpsCallable(this.functions, 'editSupplier');
        const response =  await editSupplier(data);
        return response.data;
    }

    async useDeleteSupplier(data) {
        const deleteSupplier = httpsCallable(this.functions, 'deleteSupplier');
        const response =  await deleteSupplier(data);
        return response.data;
    }

    async useCreateMovement(data) {
        const createMovement = httpsCallable(this.functions, 'createMovement');
        const response =  await createMovement(data);
        return response.data;
    }

    async useGetProductIndicators(data) {
        const getProductIndicators = httpsCallable(this.functions, 'getProductIndicators');
        const response =  await getProductIndicators(data);
        return response.data;
    }

    async useDeleteProductLoss(data) {
        const deleteProductLoss = httpsCallable(this.functions, 'deleteProductLoss');
        const response =  await deleteProductLoss(data);
        return response.data;
    }

    async useGetGeneralIndicators(data) {
        const getAllProductsIndicators = httpsCallable(this.functions, 'getAllProductsIndicators');
        const response =  await getAllProductsIndicators(data);
        return response.data;
    }

    async useUpdateSalesSettings(data) {
        const updateSalesSettings = httpsCallable(this.functions, 'updateSalesSettings');
        const response =  await updateSalesSettings(data);
        return response.data;
    }

    async useChangeShift(data) {
        const changeShift = httpsCallable(this.functions, 'changeShift');
        const response =  await changeShift(data);
        return response.data;
    }
}

const firebase = typeof window !== "undefined" ? new Firebase() : null;
export default firebase;