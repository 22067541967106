import React, { createContext, useState } from 'react';

export const SoftLoadingContext = createContext();

const SoftLoadingProvider = (props) => {

    const [ softLoadingActive, setSoftLoadingActive ] = useState(false);

    return (
        <SoftLoadingContext.Provider
            value={{ 
                softLoadingActive,
                setSoftLoadingActive,
            }}
        >
            {props.children}
        </SoftLoadingContext.Provider>
    )
}

export default SoftLoadingProvider;