import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import './settingsHeader2.scss';

const SettingsHeader2 = ({text, textLink, text2, icon}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`settings-container-header ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            <img className='settings-container-header-img' src={icon} alt='Page name' />
            <h4>
                <Link to={textLink} replace className={`settings-text-link-header ${appTheme.dark ? "settings-text-link-header-dark-text" : "settings-text-link-header-light-text"}`}>
                    {text}
                </Link>  
                    {" > "} {text2}
            </h4>
        </div>
    );
}

export default SettingsHeader2;