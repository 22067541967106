import "./productsViewList.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import PageNavigator from "../pageNavigator/pageNavigator";

import emptyBox from "../../../img/empty-box.svg";
import emptySearch from "../../../img/empty-search.svg";
import ProductMiniCard from "../productMiniCard/productMiniCard";

const ProductsViewList = ({
    observedRef, 
    onScroll, 
    headerHeight,
    loading, 
    showConnectionError,
    tryAgain,
    empty,
    results,
    activeId,
    setActiveReference,
    onClickLink,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    containerWidth
}) => {

    const { appTheme } = useContext(AppContext);
    const { productsStatistics } = useContext(AuthContext);

    return (
        <div
            className="products-cards-main-container"
            ref={observedRef}
            onScroll={onScroll}
            style={{
                maxHeight: `calc(100vh - ${headerHeight + 65}px)`,
                height: `calc(100vh - ${headerHeight + 65}px)`,
                colorScheme: appTheme.dark ? "dark" : "light",
            }}
        >
            <div className="products-cards-items" style={{ width: "100%", justifyContent: "center"}}>
                {loading ?
                    <div style={{ marginTop: "50px", width: "100%" }}>
                        <Spinner />
                    </div>
                    :
                    <Fragment>
                        {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                            {(!empty) ? <Fragment>
                                {results ? <Fragment>
                                    {results.map((product) => {
                                        return (
                                            <ProductMiniCard
                                                key={product.id}
                                                product={product}
                                                activeId={activeId}
                                                setActiveReference={setActiveReference}
                                                onClickLink={onClickLink}
                                                containerWidth={containerWidth}
                                                cardMaxWidth={"750px"}
                                            />
                                        );
                                    })}
                                </Fragment> : null}
                            </Fragment> :
                                <Fragment>
                                    {productsStatistics ? <Fragment>
                                        <NoProductsYet productsStatistics={productsStatistics} />
                                    </Fragment> : null}
                                </Fragment>
                            }
                        </Fragment>}
                    </Fragment>}
            </div>

            <div className="products-space-between-page-nav" />

            {/** Aquí va la navegacion */}
            {loading || empty || showConnectionError ? null :
                <PageNavigator
                    pageNumber={pageNumber}
                    navControlers={navControlers}
                    onChangePageNumber={null}
                    showPageNav={showPageNav}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                    empty={false}
                    elemMarginTo={"13px"}
                    elemmarginBottom={"8px"}
                />
            }

            <div style={{ minHeight: "25px" }} />

        </div>
    );
}

const NoProductsYet = ({ productsStatistics }) => {
    const { appLanguage } = useContext(AppContext);
    const [noCustomers, setNoCustomer] = useState(productsStatistics.all > 0 ? false : true);

    useEffect(() => {
        setNoCustomer(productsStatistics.all > 0 ? false : true);
    }, [productsStatistics]);

    const [enTitle, setEnTitle] = useState("There are no products or services yet");
    const [esTitle, setEsTitle] = useState("Aún no hay productos o servicios");

    const [enDesc, setEnDesc] = useState("Once you add products or services, they will be displayed here.");
    const [esDesc, setEsDesc] = useState("Una vez que agregues productos o servicios, estos se mostrarán aquí.");

    useEffect(() => {
        if (noCustomers) {
            setEnTitle("There are no products or services yet");
            setEsTitle("Aún no hay productos o servicios");
            setEnDesc("Once you add products or services, they will be displayed here.");
            setEsDesc("Una vez que agregues productos o servicios, estos se mostrarán aquí.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by name or description, and also filter by category.');
            setEsDesc('Puedes buscar por nombre o descripción, y también filtrar por categoría.');
        }
    }, [noCustomers])

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noCustomers ? emptyBox: emptySearch} alt="No customers yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}

export default ProductsViewList;