import { useContext, useRef, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

import './alertLock.scss';

import timerIcon from '../../img/stopwatch.svg';

const AlertLock = () => {

    const { appLanguage, setAppLock, alertLockActive, setAlertLockActive, appTheme} = useContext(AppContext);

    const [counting, setCounting ] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const getTime = () => {

        setSeconds(seconds - 1);

        if(seconds < 2) {
            setCounting(false);
            setAlertLockActive(false);
            setAppLock(true);
        }
    };

    useEffect(() => {
        if(counting) {
            const interval = setInterval(() => getTime(), 1000);
            return () => clearInterval(interval);
        }
    });

    useEffect(() => {
        setSeconds(30);
        if(alertLockActive) {
            setCounting(true);
        } else {
            setCounting(false);
        }
    }, [alertLockActive]);
    
    let menuRef = useRef();

    const onClickOk = () => {
        setAlertLockActive(false);
        setCounting(false);
    }

    useEffect(() => {
        if(alertLockActive) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    onClickOk();
                };
            };
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    return (
        <div className='unselectable'>
            {alertLockActive ? <div className='lock-screen-alert' /> : null }
            <div ref={menuRef} className={`alert-genaral-card ${alertLockActive ? 'active' : 'inactive' }`} style={{background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black"}}>
                <img className='alert-general-card-img' src={timerIcon} alt='Timer' />
                <h2 className='alert-general-card-sec' >{seconds} {appLanguage.en ? "seconds" : "segundos"}</h2>
                <div className='alert-general-despcrition'>
                <h4>{appLanguage.en ? "Are you still there?" : "¿Aún estas ahi?"}</h4>
                <p>{appLanguage.en ? "The App will automatically lock when the counter reaches zero." : "La aplicación se bloqueará automáticamente cuando el contador llegue a cero."}</p>
                </div>
           
                <div className='alert-button-container'>         
                    <button
                        className={`alert-button-iten-yes`}
                        onClick={onClickOk}
                    >
                        <b>{appLanguage.en ? 'Do not block' : 'No bloquear'}</b>
                    </button>
                </div> 
            </div>
        </div>
    )
}

export default AlertLock;