import { Fragment, useContext, useEffect, useState } from "react";
import "./notifications.scss";
import { AppContext } from "../../../context/AppContext";

import arrowIconDark from '../../../icons/arrow-small-right-white.png';
import arrowIconLight from '../../../icons/arrow-small-right.png';

import shareIconDark from "../../../icons/share-dark.png";
import shareIconLight from "../../../icons/share-light.png";

import upDarkIcon from "../../../icons/up-dark.png";

import Spinner3 from "../../../components/spinner3/spinner3";
import Spinner2 from "../../../components/spinner2/spinner2";
import { SideMenuContext } from "../../../context/SideMenuContext";

const Notifications = ({ 
    notifications,
    selecting,
    onClickNotification,
    iAmSelected,
    onScroll,
    loadingState,
    loadingMoreNotifications,
    loadMoreNotifications,
    lessThan10,
    noMoreNotifications,
    unreadNotifications
}) => {

    const { appTheme, appLanguage, getTimeInString, isTouchEnabled } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date);
        return getTimeInString(dateObj);
    }

    const [ onTop, setOnTop ] = useState(true);
    const [ topRef, setTopRef ] = useState(null);

    const [ onBottom, setOnBottom ] = useState(false);
    const [ bottomRef, setBottomRef ] = useState(null);

    useEffect(() => {
        if((!lessThan10) && onBottom && (!loadingMoreNotifications) && (!noMoreNotifications)) {
            loadMoreNotifications();
        }
    // eslint-disable-next-line    
    }, [onBottom]);

    const setTopReference = (inputElement)=>{
        setTopRef(inputElement);
    }

    const setBottomReference = (inputElement)=>{
        setBottomRef(inputElement);
    }

    const handleClickTop = () => {
        if (topRef) {
            topRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const toObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnTop(true);
            } else {
                setOnTop(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (topRef) {
            toObserver.observe(topRef);
        }
    
        return () => {  
          if (topRef) { 
            // eslint-disable-next-line
            toObserver.unobserve(topRef);
          }
        };
    }, [topRef]);

    useEffect(() => {
        const bottomObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnBottom(true);
            } else {
                setOnBottom(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (bottomRef) {
            bottomObserver.observe(bottomRef);
        }
    
        return () => {  
          if (bottomRef) { 
            // eslint-disable-next-line
            bottomObserver.unobserve(bottomRef);
          }
        };
    }, [bottomRef]);

    return (
        <div
            onScroll={onScroll}
            className="notifications-list-container-main"
        >
            <div style={{height: "10px"}} ref={setTopReference} />
            {notifications ?
                notifications.map((notification) => {
                    return (
                        <button
                            key={notification.id}
                            onClick={() => onClickNotification(notification.id)}
                            className={isTouchEnabled() ? "notification-card-in-list-no-hover" : "notification-card-in-list"}
                        >
                            {selecting ?
                                <div className="nofification-card-in-list-input-div">
                                    <input
                                        type="checkbox"
                                        checked={iAmSelected(notification.id)}
                                        onChange={() => onClickNotification(notification.id)}
                                        name='customerAccounts'
                                        id='customerAccounts'
                                    />
                                </div>
                            : null}
                            <div className="nofification-card-in-list-text-div">
                                <p
                                    style={{color: appTheme.dark ? "#74b7ff" : "#1083ff"}}
                                    className="nofification-card-in-list-element-date"
                                >
                                     {getTime(notification.time)} - {getDate(notification.time)}
                                </p>
                                {loadingState === notification.id ?
                                        <Spinner3 />
                                    :
                                        <p className="nofification-card-in-list-element-text">
                                            {unreadNotifications === null ?
                                                notification.read ? "" : <b>{appLanguage.en ? "New" : "Nueva"}: </b>
                                            :
                                                unreadNotifications.includes(notification.id) ? <b>{appLanguage.en ? "New" : "Nueva"}: </b> : "" 
                                            }

                                            {appLanguage.en ? notification.text.en : notification.text.es}
                                        </p>
                                }
                            </div>
                            {(!selecting) && (loadingState !== notification.id) ?
                                <div className="nofification-card-in-list-img-div">
                                    <img src={notification.internal ? (appTheme.dark ? arrowIconDark : arrowIconLight) : (appTheme.dark ? shareIconDark : shareIconLight)} alt="Open link" />
                                </div>
                            : null}
                        </button>
                    )
                })
            : null}

            <div ref={setBottomReference} style={loadingMoreNotifications ? {height: "80px"} : {height: "20px"}} >
                {loadingMoreNotifications ? 
                    <div className="nofification-card-in-list-more-notes-con">
                        <Spinner2 />
                    </div>
                : null}
            </div>

            {onTop ? null : 
                <Fragment>
                    <div className="nofification-card-in-list-con-botton-space" />
                    <button 
                        onClick={handleClickTop} 
                        className={`nofification-card-in-list-con-buttons-elem ${isTouchEnabled() ? "nofification-card-in-list-con-buttons-elem-no-hover" : "nofification-card-in-list-con-buttons-elem-hover"}`}
                        style={{
                            right: desktopView ? "20%" : "50px" 
                        }}
                    >
                        <img src={upDarkIcon} alt="Go Up" />
                    </button>
                </Fragment>
            }
        </div>
    )
}

export default Notifications;