import { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitle from '../../../components/settings-title/settingsTitle';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import SettingsProtectAccess from '../../../components/settings-protect-access/settingsProtectAccess';

import pageIcon from '../../../img/logo192.png';

import './protectAccess.page.scss';

const ProtectAccessPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    
    const { desktopView } = useContext(SideMenuContext);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Biometrics or PIN" : "Biométricas o PIN" } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "More options" : "Más opciones"}
                            textLink={"/ajustes"}
                            text2={appLanguage.en ? "Biometrics or PIN" : "Biométricas o PIN"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={pageIcon}
                                firstText={"HazCuentas"}
                                secondText={"República Dominicana 2024"}
                            />

                           <SettingsProtectAccess />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProtectAccessPage;