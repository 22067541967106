import { Fragment, useContext, useState, useEffect } from 'react';
import { uid } from 'uid';

import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AuthContext } from '../../../../firebase/context';
import { AlertContext } from '../../../../components/alert/alertContext';
import { Link, useNavigate } from 'react-router-dom';

import firebase from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import SettingsParagraphs from '../../../../components/settings-paragraphs/settingsParagraphs';

import Spinner from '../../../../components/spinner/spinner';

import warningIcon from "../../../../img/warning.svg";

import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../../img/analysis.svg';

import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import deleteIcon from '../../../../icons/trash-white.png';
import helpIcon from '../../../../icons/interrogation-white.png';

import ConnectionError from '../../../../components/connection-error/connectionError';

import '../../businessAccount.page';
import './deleteAccount.page.scss';

const DeleteAccountPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { 
        accountData, 
        setAccountSelected, 
        setAccountData, 
        adminAccess, 
        managerAccess, 
        setAccessTo, 
        deleteKey, 
        setCustomers, 
        setAdminData, 
        setCustomersStatistics,
        partiallyEliminateCustomers
    } = useContext(AuthContext);

    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);

    const [ loading, setLoading ] = useState(true);
    const [ loadingToDelete, setLoadingToDelete] = useState(false);

    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const [ enableToBeDeleted, setEnableToBeDeleted ] = useState(false);

    const inputsNames = {
        "businessName": uid(8),
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.businessName:
                return "businessName";
            default:
                return uid;
        }
    } 

    const navigate = useNavigate();

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    })

    const [information, setInformation] = useState({
        id: accountData ? accountData.id : null,
        businessName: "",
        password: "",
    })

    const esParagraphs = [
        `Estas a punto de eliminar por completo la cuenta “${accountData.businessName}”, esto significa que todos datos, imágenes, información de clientes, métodos de pagos, etc, serán eliminado de manera permanente de nuestros servidores, y su recuperación será imposible.`,
        `Si deseas utilizar esta cuenta en el futuro, puedes cancelar tu suscripción y nosotros conservaremos tus datos para cuando desees regresar.`,
    ];
    const enParagraphs = [
        `You are about to completely delete the account "${accountData.businessName}", this means that all data, images, customer information, payment methods, etc, will be permanently deleted from our servers, and its recovery will be impossible.`,
        `If you wish to use this account in the future, you can unsubscribe and we will retain your data for when you wish to return.`,
    ];

    const [businessNameAlert, setBusinessNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [passwordAlert, setPasswordAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if (getInputName(e.target.name) === "businessName") {
            setBusinessNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if (e.target.name === "password") {
                setPasswordAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }

        setInformation({
            ...information,
            [getInputName(e.target.name)]: e.target.value
        })
    }

    async function confirmPassword() {
        setAlertActive(false);
        setLoadingToDelete(true);

        try {
            await firebase.useConfirmPassword(information.password);
            await firebase.useDeleteBusinessAccount(information);

            // Force the user to selecte a new account
            localStorage.removeItem("isAccountSelected");

            localStorage.removeItem("itemsDeleted");

            setAccessTo(null);
            deleteKey("accessTo");
            deleteKey("accountData");

            deleteKey("customers");
            setCustomers(null);

            deleteKey("customersStatistics");
            setCustomersStatistics(null);

            deleteKey("adminData");
            setAdminData(null);

            setAccountSelected(null);
            setAccountData(null);

            partiallyEliminateCustomers();

            setAlertData({
                type : 'success',
                title: {
                    en : 'Account deleted',
                   es : 'Cuenta eliminada'
                },
                code : '',
                 description : {
                     en : `You have just successfully removed "${information.businessName}".`,
                     es : `Acabas de eliminar "${information.businessName}" de manera exitosa.`
                 }
            });
            setAlertActive(true);

            setLoadingToDelete(false);
            navigate('/elegir-cuenta', { replace: true });

        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to delete account',
                    es: 'Error al intentar eliminar cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoadingToDelete(false);
        }
    }

    const deletingAccountConfirmation = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Delete account',
                es: 'Eliminar cuenta'
            },
            code: 'confirm-log-out',
            description: {
                en: `Are you sure you want to delete "${information.businessName}"?`,
                es: `¿Estás seguro de que quieres eliminar "${information.businessName}"?`
            }
        });
        setOnAgree(() => confirmPassword);
        setAlertActive(true);
    }

    const validar = e => {
        e.preventDefault();

        if (information.businessName.trim() === "") {
            setBusinessNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if(information.businessName.trim() !== accountData.businessName.trim()) {
                setBusinessNameAlert({
                    alert: true,
                    enText: '* Business name does not match',
                    esText: '* Nombre de la empresa no coincide'
                });
                return;
            } else {
                if (information.password.trim()=== "") {
                    setPasswordAlert({
                        alert: true,
                        enText: '* Required field',
                        esText: '* Campo requerido'
                    });
                    return;
                } else {
                    if(information.password.length < 8) {
                        setPasswordAlert({
                            alert: true,
                            enText: '* Password must be at least 8 characters',
                            esText: '* La contraseña debe ser de al menos 8 caracteres'
                        });
                        return;
                    }
                }
            }
        }

        deletingAccountConfirmation(information);
    }

    const [ effectControler, setEffectControler ] = useState(100000);

    useEffect(() => {
        if (accountData) {
            const billRef = `accounts/${accountData.id}/billingDetails`;

            const unsub = onSnapshot(doc(firebase.db, billRef, "restriction"), (doc) => {
                const restrictionUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    setEnableToBeDeleted(restrictionUpdated.enableToBeDeleted);
                    setShowConnectionError(false);
                    setLoading(false);
                        
                    
                } else {
                    setShowConnectionError(true);
                    setLoading(false);
                }
            }, err =>{
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    const onTryAgain = () => {
        setEffectControler(uid(6))
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Delete business account' : 'Eliminar cuenta de negocios'} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader3
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/editar-cuenta"}
                            text2={appLanguage.en ? "Edit account" : "Editar cuenta"}
                            text={appLanguage.en ? 'Delete business account' : 'Eliminar cuenta de negocios'}
                        />
                        : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">

                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            
                            {(adminAccess || managerAccess) ? <Fragment>
                                {(loading || loadingToDelete )? <Spinner /> : <Fragment>  
                                    {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> : <Fragment> 
                                        <div className='warning-del-container'>
                                            <img className='warning-del-img' src={warningIcon} alt='Warning' />
                                        </div>

                                        <SettingsParagraphs
                                            paragraphs={appLanguage.en ? enParagraphs : esParagraphs}
                                        />

                                        <Link 
                                            to={"/cancelar-suscripcion"}
                                            className='warning-del-container-link'
                                        >
                                            {appLanguage.en ? "I'd rather just unsubscribe." : "Prefiero solo cancelar la suscripción."}
                                        </Link>
                                        
                                        {enableToBeDeleted ? 
                                            <form className={`close-account-form-page-container unselectable`} >
                                                <div className={`close-account-form-page-item`}>
                                                    <label
                                                        className="close-account-form-page-description"
                                                        htmlFor="businessName"
                                                    >{appLanguage.en ? `Type "${accountData.businessName}" to confirm` : `Escribe "${accountData.businessName}" para confirmar`}<b> { businessNameAlert.alert ? (
                                                            appLanguage.en ? businessNameAlert.enText : businessNameAlert.esText
                                                        ) : "*"}
                                                        </b></label>
                                                        <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.businessName} >{inputsNames.businessName}</label>
                                                    <input
                                                        className={`close-account-form-page-input ${businessNameAlert.alert ? 'close-account-form-page-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                                        type="text"
                                                        name={inputsNames.businessName}
                                                        id={inputsNames.businessName}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        placeholder={appLanguage.en ? 'Enter business name...' : 'Ingrese el nombre del negocio...'}
                                                        onChange={e => onChange(e)}
                                                    />
                                                    <label
                                                        className="close-account-form-page-description"
                                                        htmlFor="password"
                                                    >{appLanguage.en ? 'Your profile password : ' : 'La contraseña de tu perfil : '} <b>{passwordAlert.alert ? (
                                                            appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                                                        ) : "*"}
                                                        </b></label>
                                                    <input
                                                        className={`close-account-form-page-input ${passwordAlert.alert ? 'close-account-form-page-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        placeholder={appLanguage.en ? 'Enter your profile password...' : 'Ingrese la contraseña de su perfil...'}
                                                        onChange={e => onChange(e)}
                                                    />

                                                    <br />

                                                    <button
                                                        className={`close-account-form-page-button`}
                                                        onClick={validar}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="Create account button"
                                                            className='close-account-form-page-img'
                                                        />
                                                        <p className='close-account-form-page-text'><b>{appLanguage.en ? 'Delete' : 'Eliminar'}</b></p>
                                                    </button>
                                                </div>
                                            </form>
                                        : 
                                            <div className='close-account-form-page-container'>
                                                <div className={`close-account-enable-page-item`}>
                                                    <p className='close-account-enable-page-p'>
                                                        {appLanguage.en ? 
                                                            "This account has the full deletion option disabled. You can request its activation by contacting the help center. We do this to ensure that your business data is not at risk." 
                                                        : 
                                                            'Esta cuenta tiene la opción de eliminación total desactivada. Puedes solicitar su activación contactando con el centro de ayuda. Hacemos esto para garantizar que los datos de su negocio no estén en riesgo.'
                                                        }
                                                    </p>
                                                    <Link
                                                        className="close-account-enable-page-button"
                                                        to={"/ayuda"}
                                                    >
                                                        <img
                                                            src={helpIcon}
                                                            alt="Create account button"
                                                            className='close-account-form-page-img'
                                                        />
                                                        <p className='close-account-form-page-text'><b> {appLanguage.en ? 'Help' : 'Ayuda'}</b></p>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>}
                                </Fragment>}
                            </Fragment> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default DeleteAccountPage;