import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import './settingsHeader.scss';

const SettingsHeader = ({text, icon}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`settings-container-header ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            <img className='settings-container-header-img' src={icon} alt='Page name' />
            <h4>{text}</h4>
        </div>
    );
}

export default SettingsHeader;