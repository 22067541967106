import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const CollectPaymentContext = createContext();

const CollectPaymentProvider = (props) => {

    const location = useLocation();

    const [ collectPaymentActive, setCollectPaymentActive ] = useState(false);
    const [ cashAmount, setCashAmount ] = useState("");
    const [ cashToCharge, setCashToCharge ] = useState("");

    const [ loading, setLoading ] = useState(false);

    const [ isCash, setIsCash ] = useState(true);
    const [ paymentMethod, setPaymentMethod ] = useState("cash");
    const [ paymentNotes, setPaymentNotes ] = useState("");

    const [ cashAmountAlert, setCashAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    // First section is to select the amount to payment
    // Second section is where the payment is process
    const [ isSecondSectionSelected, setIsSecondSectionSelected ] = useState(false);
    const [ amountSelected, setAmountSelected] = useState("fullAmount");

    useEffect(() => {
        setPaymentNotes("");
        if (isCash) {
            setPaymentMethod("cash");
        } else {
            setPaymentMethod("card");
        }
    }, [isCash]);

    const resetCollectPaymentState = () => {
        setCashAmount("");
        setCashToCharge("");
        setIsCash(true);
        setPaymentMethod("cash");
        setPaymentNotes("");
        setIsSecondSectionSelected(false);
    }

    useEffect(() => {
        if (!collectPaymentActive) {
            resetCollectPaymentState();
        }

        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        
    }, [collectPaymentActive]);

    useEffect(() => {
        if (collectPaymentActive) {
            if (location.pathname !== "/resumen-de-venta" && (!loading)) {
                setCollectPaymentActive(false);
                resetCollectPaymentState();
            }
        }
    // eslint-disable-next-line
    }, [location, loading])

    return (
        <CollectPaymentContext.Provider
            value={{ 
                collectPaymentActive,
                cashAmount,
                cashToCharge,
                loading, 
                isCash,
                paymentMethod,
                paymentNotes,
                cashAmountAlert,
                isSecondSectionSelected,
                amountSelected,
                setCollectPaymentActive,
                setCashAmount,
                setCashToCharge,
                setLoading,
                setIsCash,
                setPaymentMethod,
                setPaymentNotes,
                setCashAmountAlert,
                setIsSecondSectionSelected,
                setAmountSelected,
                resetCollectPaymentState
            }}
        >
            {props.children}
        </CollectPaymentContext.Provider>
    )
}

export default CollectPaymentProvider;