import React, { createContext, useState, useEffect } from 'react';

export const InputPickerDateContext = createContext();

const InputPickerDateProvider = (props) => {

    const [ currentDate, setCurrentDate ] = useState(new Date());
    const [ inputName, setInputName ] = useState(null);

    const [ pickerDateActive, setPickerDateActive ] = useState(false);
    const [ pickerDateTitle, setPickerDateTitle ] = useState("");

    const [ pickeDate, setPickeDate ] = useState(true);
    const [ pickeMonth, setPickeMonth ] = useState(false);
    const [ pickeYear, setPickeYear ] = useState(false);

    useEffect(() => {
        if (pickeDate) {
            setPickeMonth(false);
            setPickeYear(false);
        }
    }, [pickeDate]);

    useEffect(() => {
        if (pickeMonth) {
            setPickeDate(false);
            setPickeYear(false);
        }
    }, [pickeMonth]);

    useEffect(() => {
        if (pickeYear) {
            setPickeDate(false);
            setPickeMonth(false);
        }
    }, [pickeYear]);

    useEffect(() => {
        if (!pickerDateActive) {
            setPickeDate(true);
            setPickeMonth(false);
            setPickeYear(false);
            setCurrentDate(new Date());
            setInputName(null);
        }
    }, [pickerDateActive])

    return (
        <InputPickerDateContext.Provider
            value={{ 
                currentDate,
                inputName,
                pickerDateActive,
                pickerDateTitle,
                pickeDate,
                pickeMonth,
                pickeYear,
                setCurrentDate,
                setInputName,
                setPickerDateActive,
                setPickerDateTitle,
                setPickeDate,
                setPickeMonth,
                setPickeYear
            }}
        >
            {props.children}
        </InputPickerDateContext.Provider>
    )
}

export default InputPickerDateProvider;