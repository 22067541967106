import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import './settingsHeader3.scss';

const SettingsHeader3 = ({text, text1, textLink1, text2, textLink2, icon}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`settings-container-header3 ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            <img className='settings-container-header3-img' src={icon} alt='Page name' />
            <h4>   
                <Link to={textLink1} replace className={`settings-text-link-header3 ${appTheme.dark ? "settings-text-link-header-dark-text" : "settings-text-link-header-light-text"}`}>{text1}</Link>
                {" > "}
                <Link to={textLink2} replace className={`settings-text-link-header3 ${appTheme.dark ? "settings-text-link-header-dark-text" : "settings-text-link-header-light-text"}`}>{text2}</Link>  
                {" > "} 
                {text}
            </h4>
        </div>
    );
}

export default SettingsHeader3;