import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { SoftLoadingContext } from "./softLoadingContext";

import crossIcon from '../../icons/cross-white.png';
import crossIconLight from '../../icons/cross.png';

import './softLoading.scss';

const SoftLoading = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { softLoadingActive, setSoftLoadingActive } = useContext(SoftLoadingContext);

    const closeAlert = () => {
        setSoftLoadingActive(false);
    }

    return (
        <div className='unselectable'>
            <div 
                style={appTheme.dark ? {boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"} : {boxShadow: "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)"}}  
                className={`soft-loading-cont ${appTheme.dark ? "soft-loading-cont-dark" : "soft-loading-cont-light"}  ${softLoadingActive ? 'active' : 'inactive' }`}
            >
                <p className='soft-loading-p'>{appLanguage.en ? "Loading..." : "Cargando..."}</p>
                <button onClick={closeAlert} className='soft-loading-button'>
                    <img className='soft-loading-img' src={appTheme.dark ? crossIcon : crossIconLight} alt='Close notification'/>
                </button>
            </div>
        </div>
    )
}

export default SoftLoading;