import "./customerListView.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import PageNavigator from "../pageNavigator/pageNavigator";

import emptyBox from "../../../img/empty-box.svg";
import emptySearch from "../../../img/empty-search.svg";
import CustomerCard from "../customerCard/customerCard";

const CustomerListView = ({
    observedRef, 
    longProcess,
    onScroll, 
    headerHeight, 
    loading, 
    showConnectionError,
    tryAgain,
    empty,
    results,
    activeId,
    setActiveReference,
    onClickLink,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    selecting,
    iAmSelected
}) => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { customersStatistics } = useContext(AuthContext);

    return (
        <div
            className="products-cards-main-container"
            ref={observedRef}
            onScroll={onScroll}
            style={{
                maxHeight: `calc(100vh - ${headerHeight + 65}px)`,
                height: `calc(100vh - ${headerHeight + 65}px)`,
                colorScheme: appTheme.dark ? "dark" : "light"
            }}
        >
            <div 
                className="products-cards-items"
                style={{
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                {loading ?
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"  }}>
                        <Spinner />
                        {longProcess ? <p style={{ fontSize: "17px", marginTop: "25px"}}>{appLanguage.en ? "Working with multiple records, this process may take a few minutes, please wait." : "Trabajando con múltiples registros, este proceso puede tomar unos minutos. Por favor, espere."}</p> : null}
                    </div>
                    :
                    <Fragment>
                        {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                            {(!empty) ? <Fragment>
                                {results ? <Fragment>
                                    {results.map((product) => {
                                        return (
                                            <CustomerCard
                                                key={product.id}
                                                account={product}
                                                activeId={activeId}
                                                setActiveReference={setActiveReference}
                                                onClickLink={onClickLink}
                                                selecting={selecting}
                                                iAmSelected={iAmSelected}
                                            />
                                        );
                                    })}
                                </Fragment> : null}
                            </Fragment> :
                                <Fragment>
                                    {customersStatistics ? <Fragment>
                                        <NoCustomersYet customersStatistics={customersStatistics} />
                                    </Fragment> : null}
                                </Fragment>
                            }
                        </Fragment>}
                    </Fragment>}
            </div>

            <div className="products-space-between-page-nav" />

            {/** Aquí va la navegacion */}
            {loading || empty || showConnectionError ? null :
                <PageNavigator
                    pageNumber={pageNumber}
                    navControlers={navControlers}
                    onChangePageNumber={null}
                    showPageNav={showPageNav}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                    empty={false}
                    elemMarginTo={"13px"}
                    elemmarginBottom={"8px"}
                />
            }

            <div style={{ minHeight: "25px" }} />

        </div>
    );
}

const NoCustomersYet = ({ customersStatistics }) => {
    const { appLanguage } = useContext(AppContext);
    const [noCustomers, setNoCustomer] = useState(customersStatistics.all > 0 ? false : true);

    useEffect(() => {
        setNoCustomer(customersStatistics.all > 0 ? false : true);
    }, [customersStatistics]);

    const [enTitle, setEnTitle] = useState("There are no customers yet");
    const [esTitle, setEsTitle] = useState("Aún no hay clientes");

    const [enDesc, setEnDesc] = useState("Once you add customer records, they will be displayed here.");
    const [esDesc, setEsDesc] = useState("Una vez que agregues registros de clientes estos se mostrarán aquí.");

    useEffect(() => {
        if (noCustomers) {
            setEnTitle("There are no customers yet");
            setEsTitle("Aún no hay clientes");
            setEnDesc("Once you add customer records, they will be displayed here.");
            setEsDesc("Una vez que agregues registros de clientes estos se mostrarán aquí.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by record number, name, phone number, email or (cédula/RNC).');
            setEsDesc('Puedes buscar por número del registro, nombre, teléfono, correo electrónico o (cédula/RNC).');
        }
    }, [noCustomers]);

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noCustomers ? emptyBox: emptySearch} alt="No customers yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}

export default CustomerListView;