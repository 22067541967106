import "./saleMiniCard.scss";
import { Link } from "react-router-dom";

import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";


const SaleMiniCard = ({ sale, activeId, setActiveReference, onClickLink, width}) => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, getTimeInString, getShortDateEn, getShortDateEs} = useContext(AppContext);

    const [ time, setTime ] = useState(appLanguage.en ? getShortDateEn(sale.date) : getShortDateEs(sale.date));

    useEffect(() => {
        setTime(appLanguage.en ? getShortDateEn(sale.date) : getShortDateEs(sale.date));
    // eslint-disable-next-line
    }, [appLanguage]);

    const [ normalClassName, setNormalClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
    const [ focusClassName, setFocusClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);

    useEffect(() => {
        setNormalClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
        setFocusClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);
    // eslint-disable-next-line
    }, [appTheme]);

    return (
        <Link 
            key={sale.id}
            to={"/ver-detalles-de-venta"} 
            ref={sale.id === activeId ? setActiveReference : null}
            onClick={() => onClickLink(sale.id)}
            state={sale}
            className={sale.id === activeId ? focusClassName : normalClassName}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <p className="products-mini-view-product-ref"># {sale.id}</p>
                <p className="products-mini-view-product-price" style={{ color: appTheme.dark ? "#a7d0ff" : "#2089ff" }}>RD$ {formatRationalNumber(sale.amount)}</p>
            </div>

            {width > 530 ?
                <div 
                    style={{
                        maxWidth: "260px",
                        minWidth: "260px",
                        paddingLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <p className="sales-mini-view-texto-overflow">
                        <b>{appLanguage.en ? "Items" : "Artículos"}: </b> 
                        {sale.items.map((item) => {
                            return (
                                <Fragment key={item.id}>
                                    ({item.quantity}: {item.name}),&nbsp;&nbsp;
                                </Fragment>
                            )
                        })}
                    </p>
                </div> 
            : null}

            <div 
                className="recently-opened-section2" 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <p className="recently-opened-hours">{getTimeInString(sale.date)}</p>
                <p style={{marginTop: "4px"}}>{time}</p>
            </div>
        </Link>
    );
}

export default SaleMiniCard;