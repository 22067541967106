import { Fragment, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import firebase from '../../firebase';
import { collection, query, onSnapshot, limit, getCountFromServer, where } from "firebase/firestore";

import { uid } from 'uid';

import emptyBoxIcon from '../../img/empty-box.svg';

import Spinner from '../../components/spinner/spinner';
import ConnectionError from '../connection-error/connectionError';

import AuthorizedUserCard from './authorized-User-Card/authorizedUserCard';

import './authorizedUsersList.scss'

const AuthorizedUsersList = () => {

    const { appTheme, appLanguage  } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [ usersEmpty, setUsersEmpty ] = useState(false);
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const [users, setUsers] = useState([]);

    const [ effectControler, setEffectControler ] = useState(100000);

    async function verifiedIfAuthUsersCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) { 
                setUsers([]);
                setUsersEmpty(true);
                setLoading(false);
                setShowConnectionError(false);
            }
        } catch (error) {
            console.warn(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    useEffect(() => {
        if (accountData) {
            setLoading(true);

            const q = query(
                collection(firebase.db, `accounts/${accountData.id}/authorizedUsers`), 
                where("status", "not-in", ["admin", "removed"]),
                limit(50),
            );

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        });
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfAuthUsersCollectionIsReallyEmpty(q);
                } else {
                    if (users) {
                        setUsers(res);
                        setLoading(false);
                        setUsersEmpty(false);
                        setShowConnectionError(false);
                    } else {
                        setUsers(res);
                        setLoading(false);
                        setUsersEmpty(false);
                        setShowConnectionError(false);
                    }
                }
            }, err =>{
                console.error(err);
                setLoading(false);
                setShowConnectionError(true);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    const tryAgain = () => {
        setEffectControler(uid(6))
    }

    return (
        <div className={`autho-users-list-container ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}> 
            <div className='autho-users-list-header'>
                <p className='autho-users-list-tittle'><b>{appLanguage.en ? "List of authorized users:" : "Lista de usuarios autorizados:"}</b></p>
            </div>
            { loading ? <div className='autho-users-list-empty-spinner'>
                <div className='autho-users-list-empty-spinner'><Spinner/></div>
            </div> : <Fragment>
                {showConnectionError ? <div className='autho-users-list-empty-spinner'>
                    <ConnectionError onTryAgain={tryAgain} />
                    <br/>
                    <br/>
                </div> : <Fragment>
                    {usersEmpty ? 
                        <div className='autho-users-list-empty'>
                            <div>
                                <img className="autho-users-list-empty-img" src={emptyBoxIcon} alt='Empty box' /> 
                                <p 
                                    style={{
                                        fontSize: "14px",
                                        marginTop: "10px",
                                        color: "gray"
                                        
                                    }}
                                >{appLanguage.en ? 'No users found' : 'Ningún usuario encontrado'}</p>
                            </div>
                        </div>
                    :   
                        <div className='autho-users-list-users'>
                            {users ? <div>
                                {users.map((user) => {
                                    return (
                                        <AuthorizedUserCard key={uid()} user={user} /> 
                                    );
                                })}
                            </div> : null}
                        </div>
                    }
                </Fragment>}
            </Fragment>}
        </div>
    );
}

export default AuthorizedUsersList;