import { useState, useEffect, useRef, useContext, Fragment } from "react";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import './menuBurger.scss';

import LockScreenTrans from "../../lock-screen-trans/lockScreenTrans";

import menuBurgerIcon from '../../../icons/menu-burger.png';
import menuBurgerWhiteIcon from '../../../icons/menu-burger-white.png';

import SideMenuButton from '../../buttons/side-menu-button/sideMenuButton';
import SideMenuNotificationButton from "../../buttons/side-menu-notification-button/sideMenuNotificationButton";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import chooseIcon from '../../../img/choose.svg';
import businessIcon from '../../../img/analysis.svg';
import bellIcon from '../../../img/bell.svg';

import themeIcon from '../../../img/moon.svg';
import moreOptIcon from '../../../img/more-options.svg';

const MenuBurger = () => {

    const { appTheme, setAppTheme, appLanguage } = useContext(AppContext);
    const { sideMenuOpen, setSideMenuOpen, lockScreen, setLockScreen } = useContext(SideMenuContext);

    const [isThemeOpen, setIsThemeOpen] = useState(false);

    // Data to control theme
    let formStateTemp = {};
    const getFormThemeState = () => {
        formStateTemp = {
            asInTheSystme: appTheme.asInTheSystem,
            dark: appTheme.asInTheSystem ? false : appTheme.dark,
            light: appTheme.asInTheSystem ? false : (appTheme.dark ? false : true)
        }
        return formStateTemp;
    }
    const [themeformState, setThemeFormState] = useState(getFormThemeState());

    useEffect(() => {
        setThemeFormState(getFormThemeState());
        // eslint-disable-next-line
    }, [appTheme]);

    const onClickMenuBurgerButton = () => {
        setLockScreen(!sideMenuOpen);
        setSideMenuOpen(!sideMenuOpen);
    }

    // To use with useEffect()
    let menuRef = useRef();

    const handleChangeTheme = event => {
        if (event.target.value === "light") {
            formStateTemp.asInTheSystme = false;
            formStateTemp.light = true;
            formStateTemp.dark = false;
        } else {
            if (event.target.value === "dark") {
                formStateTemp.asInTheSystme = false;
                formStateTemp.light = false;
                formStateTemp.dark = true;
            } else {
                formStateTemp.asInTheSystme = true;
                formStateTemp.light = false;
                formStateTemp.dark = false;
            }
        }

        setThemeFormState(formStateTemp);

        if (formStateTemp.asInTheSystme === true) {
            let firstTheme = {
                dark: false,
                asInTheSystem: true
            }
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                firstTheme.dark = true;
            } else {
                firstTheme.dark = false;
            }
            localStorage.setItem("theme", JSON.stringify(firstTheme));
            setAppTheme(firstTheme);
        } else {
            localStorage.setItem("theme", JSON.stringify({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            }));
            setAppTheme({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            })
        }
    }

    useEffect(() => {
        if (sideMenuOpen) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setSideMenuOpen(false);
                    setLockScreen(false);
                };
            };
            document.addEventListener("mousedown", handler);

            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        closeOptions();
    }, [sideMenuOpen])

    const onClickTheme = () => {
        setIsThemeOpen(!isThemeOpen);
    }


    const closeOptions = () => {
        setIsThemeOpen(false);
    }

    return (
        <Fragment>
            <LockScreenTrans lockScreen={lockScreen} />
            <div ref={menuRef}>
                <button
                    className={`navigation-container-menuBurger ${appTheme.dark ? 'border-box-dark navigation-container-menuBurger-dark' : 'border-box-light navigation-container-menuBurger-light'}`}
                    onClick={onClickMenuBurgerButton}
                >
                    <div className={`navigation-container-menuBurger-item`}>
                        <img
                            className='icon-navigation'
                            src={appTheme.dark ? menuBurgerWhiteIcon : menuBurgerIcon}
                            alt="menuBurger-img"
                        />
                    </div>
                </button>

                <div className={`side-menu-container ${sideMenuOpen ? 'active' : 'inactive'} ${appTheme.dark ? 'dar-sec-bg' : 'lig-sec-bg'}`}>
                    <button
                        onClick={onClickMenuBurgerButton}
                        className={`side-menu-close-button ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                    >
                        <img className="side-menu-close-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                    </button>
                    <ul className="side-menu-options">
                        
                        <li className="side-menu-item">
                            <SideMenuButton
                                description={appLanguage.en ? 'Business account' : 'Cuenta de negocios'}
                                imgIcon={businessIcon}
                                onClickButton={closeOptions}
                                goTo={"/cuenta-de-negocios"}
                            />
                        </li>
                        <li className="side-menu-item">
                            <SideMenuButton
                                description={appLanguage.en ? 'Choose account' : 'Elegir cuenta'}
                                imgIcon={chooseIcon}
                                onClickButton={closeOptions}
                                goTo={"/elegir-cuenta"}
                            />
                        </li>
                        <li className="side-menu-item">
                            <SideMenuNotificationButton
                                description={appLanguage.en ? 'Notifications' : 'Notificaciones'}
                                imgIcon={bellIcon}
                                onClickButton={closeOptions}
                                goTo={"/notificaciones"}
                            />
                        </li>
                        <li className={`side-menu-item ${isThemeOpen ? 'open' : 'closed'} ${appTheme.dark ? "dark" : "light"}`}>
                            <SideMenuButton
                                description={appLanguage.en ? 'Theme' : 'Tema'}
                                imgIcon={themeIcon}
                                downArrow={true}
                                isOpen={isThemeOpen}
                                onClickButton={onClickTheme}
                            />
                            <form className={`side-menu-form ${isThemeOpen ? 'open' : 'closed'}`}>
                                <div className="side-menu-form-input-container">
                                    <input
                                        style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                        type="radio"
                                        id="systemTheme"
                                        name="AppTheme"
                                        checked={themeformState.asInTheSystme}
                                        value="asInTheSystme"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="systemTheme">{appLanguage.en ? 'Default' : 'Predeterminado'}</label>
                                </div>
                                <div className="side-menu-form-input-container">
                                    <input
                                        style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                        type="radio"
                                        id="lightTheme"
                                        name="AppTheme"
                                        checked={themeformState.light}
                                        value="light"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="lightTheme">{appLanguage.en ? 'Light' : 'Claro'}</label>
                                </div>
                                <div className="side-menu-form-input-container">
                                    <input
                                        style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                        type="radio"
                                        id="darkTheme"
                                        name="AppTheme"
                                        checked={themeformState.dark}
                                        value="dark"
                                        onChange={handleChangeTheme}
                                    />
                                    <label htmlFor="darkTheme">{appLanguage.en ? 'Dark' : 'Oscuro'}</label>
                                </div>
                            </form>
                        </li>
                        <li className="side-menu-item">
                            <SideMenuButton
                                description={appLanguage.en ? 'More options' : 'Más opciones'}
                                imgIcon={moreOptIcon}
                                onClickButton={closeOptions}
                                goTo={"/ajustes"}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default MenuBurger;