import React, { createContext, useEffect, useState } from 'react';

export const SoftAlertContext = createContext();

const SoftAlertProvider = (props) => {

    const [ softAlertActive, setSoftAlertActive ] = useState(false);
    const [ softAlertData, setSoftAlertData ] = useState({
        type : '',
        text: {
            en : '',
            es : ''
        }
    });

    useEffect(() => {
        if (softAlertActive) {
            if (softAlertData.type === "success" || softAlertData.type === "sucess") {
                setTimeout(() => setSoftAlertActive(false), 1200)
            } else {
                if (softAlertData.type === "error") {
                    setTimeout(() => setSoftAlertActive(false), 1500)
                }
            }
        } else {
            setSoftAlertData({
                type : '',
                text: {
                    en : '',
                    es : ''
                }
            })
        }
    // eslint-disable-next-line
    }, [softAlertActive])

    return (
        <SoftAlertContext.Provider
            value={{ 
                softAlertActive,
                softAlertData,
                setSoftAlertActive,
                setSoftAlertData
            }}
        >
            {props.children}
        </SoftAlertContext.Provider>
    )
}

export default SoftAlertProvider;