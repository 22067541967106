import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { Helmet } from 'react-helmet-async';
import firebase from "../../firebase";
import { uid } from "uid";

import Spinner from "../../components/spinner/spinner";

import PricingCard from "../../components/pricingCard/pricingCard";
import pricingIcon from '../../img/price-tag.svg';

import ConnectionError from "../../components/connection-error/connectionError";

import './pricingOptions.page.scss';


const PricingOptionsPage = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const [ plans, setPlans ] = useState([]);
    const [ freeTrial, setFreeTrial ] = useState(true);

    const [ loadingAccounts, setLoadingAccounts ] = useState(true);
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    async function getCurrentPlans() {
        setShowConnectionError(false);

        try {
            const response = await firebase.useGetCurrentPlans();
            setLoadingAccounts(false);
            setFreeTrial(response.freeTrial);
            setPlans(response.plans);
        } catch (error) {
            console.log(error);
            setLoadingAccounts(false);
            setShowConnectionError(true);
        }
    }

    useEffect(() => {
        getCurrentPlans();
        setLoadingAccounts(true);
        setShowConnectionError(false);
    }, []);

    const tryAgain = () => {
        getCurrentPlans();
        setLoadingAccounts(true);
        setShowConnectionError(false);
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Pricing' : 'Precios' } </title>
            </Helmet>
            <div 
                className={`page-pricing-options-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-pricing-options-item">
                    <div className="page-pricing-options-header">
                        <img src={pricingIcon} alt="Create account icon" />
                        <h3 className="page-pricing-options-tittle">{appLanguage.en ? 'Subscription price' : 'Precio de suscripción'} </h3>
                    </div>

                    <div className="page-pricing-cards-container">
                        {loadingAccounts ? <Spinner/> : <Fragment>
                            {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : 
                                <Fragment>
                                    {plans ? <Fragment>
                                        {plans.map((plan) => {
                                            return <PricingCard key={uid()} plan={plan} freeTrial={freeTrial} />
                                        })}
                                    </Fragment> : null}
                                </Fragment>
                            } 
                        </Fragment>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PricingOptionsPage;