import { useContext, Fragment } from "react";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../firebase/context";
import { uid } from "uid";

import checkIcon from '../../img/check.svg';
import uncheckIcon from '../../img/uncheck.svg';
import plusIcon from '../../img/plus.svg';
import nextIcon from '../../icons/arrow-small-right-white.png';

import './changePlanCard.scss';

const ChangePlanCard = ({plan, onReactivate}) => {

    const { appLanguage, appTheme, formatRationalNumber } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);

    return (
        <div className={`change-plan-cre-acc-card ${appTheme.dark ? 'dar-box-sha-keep-hover dar-grad-bg-solid input-border-box-dark' : 'lig-box-sha-keep-hover lig-grad-bg-solid input-border-box-light'}`}>
            {appLanguage.en ? <Fragment>
                <div className="change-plan-cre-acc-title">
                    <h3>{plan ? plan.name.en : "..."}</h3>
                    <p>RD$ <b>{plan ? formatRationalNumber(plan.price) : "..."}</b> monthly </p>
                </div>
                {accountData.status.type === "customer" ? 
                    <ReactivateButton appLanguage={appLanguage}  plan={plan} onReactivate={onReactivate}/>
                : 
                    <CurrentPlan appLanguage={appLanguage} /> 
                }
                <ul>
                    {plan ? <Fragment>
                        {plan.items.included.en.map((item) => {
                            return <li key={uid()}>
                                <img className="change-plan-cre-acc-icons" src={checkIcon} alt="Included" /> {item}
                            </li>
                        })}
                        {plan.items.excluded.en.map((item) => {
                            return <li key={uid()}>
                                <img className="change-plan-cre-acc-icons" src={uncheckIcon} alt="excluded" />  <s>{item}</s>
                            </li>
                        })}
                        <br />
                        {plan.items.extra.en.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" />  {item}
                            </li>
                        })}
                    </Fragment>: "..."}
                </ul>

            </Fragment> : <Fragment>
                <div className="change-plan-cre-acc-title">
                    <h3>{plan ? plan.name.es : "..."}</h3>
                    <p>RD$ <b>{plan ? formatRationalNumber(plan.price) : "..."}</b> mensual </p>
                </div>
                {accountData.status.type === "customer" ? 
                    <ReactivateButton appLanguage={appLanguage}  plan={plan} onReactivate={onReactivate}/>
                : 
                    <CurrentPlan appLanguage={appLanguage} /> 
                }
                <ul>
                    {plan ? <Fragment>
                        {plan.items.included.es.map((item) => {
                            return <li key={uid()}>
                                <img className="change-plan-cre-acc-icons" src={checkIcon} alt="Included" /> {item}
                            </li>
                        })}
                        {plan.items.excluded.es.map((item) => {
                            return <li key={uid()}>
                                <img className="change-plan-cre-acc-icons" src={uncheckIcon} alt="excluded" />  <s>{item}</s>
                            </li>
                        })}
                        <br />
                        {plan.items.extra.es.map((item) => {
                            return <li key={uid()}>
                                <img className="pricing-cre-acc-icons" src={plusIcon} alt="excluded" /> {item}
                            </li>
                        })}
                    </Fragment>: "..."}
                </ul>
            </Fragment>}
        </div>
    )
}

const ReactivateButton = ({appLanguage, onReactivate, plan}) => {
    return (
        <div className="change-plan-button-cont">
            <button
                className={`change-plan-acc-button-container`}
                onClick={() => onReactivate(plan)}
            >
                <img
                    src={nextIcon}
                    alt="Next button"
                    className='change-plan-acc-button-container-img'
                />
                <p className='change-plan-acc-button-container-text'>{appLanguage.en ? "Reactivate" : "Reactivar"} </p>
            </button>
        </div>
    );
}

const CurrentPlan = ({appLanguage}) => {
    return (
        <div className="change-plan-button-cont">
            <div className="change-plan-button-current">
                <b>*** {appLanguage.en ? "Current" : "Actual"} ***</b>
            </div>
        </div>
    );
}

export default ChangePlanCard;
