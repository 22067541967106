import "./customerCard.scss";

import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";

const CustomerCard = ({ account, activeId, setActiveReference, onClickLink, selecting, iAmSelected }) => {
    const { appTheme, appLanguage, getTimeInString, getShortDateEn, getShortDateEs, isTouchEnabled} = useContext(AppContext);

    const [ time, setTime ] = useState(appLanguage.en ? getShortDateEn(account.lastTransactionTime) : getShortDateEs(account.lastTransactionTime));

    useEffect(() => {
        setTime(appLanguage.en ? getShortDateEn(account.lastTransactionTime) : getShortDateEs(account.lastTransactionTime));
    // eslint-disable-next-line
    }, [appLanguage]);

    const refreshTime = () => {
        setTime(appLanguage.en ? getShortDateEn(account.lastTransactionTime) : getShortDateEs(account.lastTransactionTime))
    }

    useEffect(() => {
        if (time === "Ahora" || time === "Now") {
            setTimeout(refreshTime, 70000);
        }
    // eslint-disable-next-line
    }, [time]);

    const [ normalClassName, setNormalClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
    const [ focusClassName, setFocusClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);

    useEffect(() => {
        setNormalClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
        setFocusClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);
    // eslint-disable-next-line
    }, [appTheme]);

    return (
        <Fragment key={account.id} >
            {selecting ? 
                <button
                    onClick={() => onClickLink(account.id)}
                    style={{minHeight: "58px"}} 
                    className={iAmSelected(account.id) ? focusClassName : normalClassName}
                >
                    <div className="nofification-card-in-list-input-div">
                        <input
                            type="checkbox"
                            checked={iAmSelected(account.id)}
                            onChange={() => onClickLink(account.id)}
                            name='customerAccounts'
                            id='customerAccounts'
                        />
                    </div>
                    <div className="recently-opened-section1">
                        <div>
                            <p className="recently-opened-id-name customer-card-texto-overflow">
                                <b>{account.id} • {account.name}</b> 
                            </p>
                        </div>
                        <div className="recently-opened-desc-div">
                            <p className="customer-card-texto-overflow">
                                {appLanguage.en ? account.lastTransactionDesc.enDescription : account.lastTransactionDesc.esDescription}
                            </p>
                        </div>
                    </div>
                    <div className="recently-opened-section2">
                        <p className="recently-opened-hours">{getTimeInString(account.lastTransactionTime)}</p>
                        <p style={{marginTop: "4px"}} className={time === "Ahora" || time === "Now" ? "recently-opened-right-now" : ""}>{time}</p>
                    </div>
                </button>
            :
                <Link 
                    to={"/ver-cliente"} 
                    ref={account.id === activeId ? setActiveReference : null}
                    onClick={() => onClickLink(account.id)}
                    state={account}
                    className={account.id === activeId ? focusClassName : normalClassName}
                >
                    <div className="recently-opened-section1">
                        <div>
                            <p className="recently-opened-id-name customer-card-texto-overflow">
                                <b>{account.id} • {account.name}</b> 
                            </p>
                        </div>
                        <div className="recently-opened-desc-div">
                            <p className="customer-card-texto-overflow">{appLanguage.en ? account.lastTransactionDesc.enDescription : account.lastTransactionDesc.esDescription}</p>
                        </div>
                    </div>
                    <div className="recently-opened-section2">
                        <p className="recently-opened-hours">{getTimeInString(account.lastTransactionTime)}</p>
                        <p style={{marginTop: "4px"}} className={time === "Ahora" || time === "Now" ? "recently-opened-right-now" : ""}>{time}</p>
                    </div>
                </Link>
            }
        </Fragment>
    );
}

export default CustomerCard;