import "./getQuoteComponent.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import { AuthContext } from "../../../firebase/context";
import printIconDark from "../../../icons/printer-dark.png";

const GetQuoteComponent = () => {
    const { accountData, shoppingCart } = useContext(AuthContext);

    const [receiptData, setReceiptData] = useState(shoppingCart ? {
        ...shoppingCart,
        date: new Date().toLocaleDateString(),
        storeName: accountData.businessName,
    } : null);

    useEffect(() => {
        if (shoppingCart) {
            setReceiptData({
                ...shoppingCart,
                date: new Date().toLocaleDateString(),
                storeName: accountData.businessName,
            })
        } else {
            setReceiptData(null);
        }
    // eslint-disable-next-line
    }, [shoppingCart])

    const receiptRef = useRef(); // Referencia para el componente de recibo

    return (
        <div style={{display: "flex", width: "100%", height: "150px", justifyContent: "center", alignItems: "center"}}>
            {receiptData && (
                <ReactToPrint
                    trigger={() => 
                        <button className="add-to-card-btn-product" style={{height: "45px"}} >
                            <p>Imprimir cotización</p>
                            <img style={{marginLeft: "10px", width: "20px", height: "20px"}} src={printIconDark} alt="Print" />
                        </button>
                    }
                    content={() => receiptRef.current}
                />
            )}

            <div style={{ display: 'none' }}>
                <Receipt ref={receiptRef} receiptData={receiptData} />
            </div>
        </div>
    );
}

const Receipt = React.forwardRef(({ receiptData }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    function formatRationalNumber(number) {
        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    
        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    return (
        <div ref={ref} className="receipt">
            <p style={{fontSize: "16px",  fontWeight: "500", textAlign: "center"}}>{receiptData.storeName}</p>
            <p style={{fontSize: "12px", textAlign: "center", marginBottom: "10px"}}>Dirrecion y telefono</p>
            <p style={{whiteSpace: "nowrap", fontSize: "10px"}}>---------------------------------------------------------------------------</p>
            <p style={{fontSize: "14px", textAlign: "center", fontWeight: "500"}}>COTIZACIÓN DE ARTÍCULOS</p>
            <p style={{whiteSpace: "nowrap", fontSize: "10px", marginBottom: "10px"}}>---------------------------------------------------------------------------</p>

            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
   
            <table>
                <thead>
                    <tr>
                        <th><p style={{fontSize: "12px"}}>DESCRIPCION</p></th>
                        <th><p style={{fontSize: "12px"}}>ITBIS</p></th>
                        <th><p style={{fontSize: "12px"}}>VALOR</p></th>
                    </tr>
                </thead>
                <tbody>
                    {receiptData.items.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <p style={{fontSize: "12px"}}> <b>{item.product.barcode ? item.product.barcode : ""}</b> {item.product.name}</p>
                            </td>
                            <td><p style={{fontSize: "12px", margin: "0px 10px 0px 10px"}}>{formatRationalNumber(item.itbis)}</p></td>
                            <td><p style={{fontSize: "12px"}}>{formatRationalNumber(item.value)}</p></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <hr />
            <p>Subtotal: {formatRationalNumber(receiptData.subtotal)}</p>
            <p>Desc: {formatRationalNumber(receiptData.desc)}</p>
            <p>ITBIS: {formatRationalNumber(receiptData.itbis)}</p>
            <p>Total: {formatRationalNumber(receiptData.total)}</p>
        </div>
    );
});

export default GetQuoteComponent;