import "./movementDetails.scss";

import { useContext, useEffect, useRef } from "react";
import { MovementDetailsContext } from "./movementDetailsContext";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import arrowIconDark from '../../../icons/arrow-small-right-white.png';
import closedDarkIcon from '../../../icons/closed-dark.png';
import { AlertContext } from "../../../components/alert/alertContext";

const MovementDetails = ({pickerWidth, getAuthUserName, cancelLoass}) => {
    const { movementDetailsActive, setMovementDetailsActive, transaction } = useContext(MovementDetailsContext);
    const { appTheme, appLanguage, getTimeInString, getMovementDescription, getMovementIcon, formatRationalNumber } = useContext(AppContext);

    const { setAlertData, setAlertActive, setOnAgree, alertActive } = useContext(AlertContext);

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date);
        return getTimeInString(dateObj);
    }

    let menuRef = useRef();

    useEffect(() => {
        if(movementDetailsActive) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    if (!alertActive) {
                        setMovementDetailsActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setMovementDetailsActive(false);
    }

    const getQuantityTitle = (text) => {
        switch (text) {
            case "purchases":
                return appLanguage.en ? "Purchased quantity" : "Cantidad comprada";
            case "sales":
                return appLanguage.en ? "Cantidad vendida" : "Quantity sold";
            case "customerReturns" || "returnsToSuppliers":
                return appLanguage.en ? "Quantity returned" : "Cantidad devuelta";
            case "production":
                return appLanguage.en ? "Quantity produced" : "Cantidad producida";
            case "consumption":
                return appLanguage.en ? "Quantity consumed" : "Cantidad cansumida";
            case "adjustments":
                if (transaction.type === "transaccionEntryYellow") {
                    return appLanguage.en ? "Added quantity" : "Cantidad agregada";
                } else {
                    return appLanguage.en ? "Removed quantity" : "Cantidad removida";
                }
            case "loss":
                return appLanguage.en ? "Lost quantity" : "Cantida perdida";
            default:
                return appLanguage.en ? "Quantity" : "Cantidad";
        }
    }

    const onCancelLoss = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Cancel loss?`,
                es: `¿Cancelar pérdida?`
            },
            code: '',
            description: {
                en: `Cancel loss of ${transaction.quantity} ${transaction.quantity > 1 ? "items" : "item"}?`,
                es: `¿Cancelar pérdida de ${transaction.quantity} ${transaction.quantity > 1 ? "articulos" : "articulo"}?`
            }
        });
        setOnAgree(() => cancelLoass);
        setAlertActive(true);
    }

    return (
        <div className='unselectable'>
            {movementDetailsActive ? <div className='picker-date-lock-screen' style={{width: pickerWidth, minHeight: "calc(100vh - 65px)"}} /> : null }
            <div ref={menuRef} className={`picker-date-genaral-card ${movementDetailsActive ? 'active' : 'inactive' }`} style={{marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black"}}>
                <div className='picker-date-general-despcrition'>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px"}}>
                        <h4 style={{marginLeft: "20px"}}>{appLanguage.en ? "Movement details" : "Detalles del movimiento"}</h4>

                        <button
                            onClick={onClickClose}
                            className={`see-details-transations-view ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                        >
                            <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                        </button>
                    </div>
                    
                    {transaction ?
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div
                                style={{
                                    display: "flex", 
                                    width: "100%",
                                    alignItems: "center",
                                    marginBottom: "10px"
                                }}
                            >
                                <img style={{width: "30px", height: "30px", marginLeft: "10px"}} src={getMovementIcon(transaction.type)} alt="Movements" />
                                <p style={{ marginLeft: "3px"}}>{getMovementDescription(transaction.description)}:</p>
                            </div>

                            <p className="see-details-transations-description">{appLanguage.en ? transaction.note.en : transaction.note.es}</p>

                            <p className="see-details-transations-time">{getTime(transaction.time)} - {getDate(transaction.time)}</p>
                            
                            {transaction.userId ? <p className="see-details-transations-description"><b>{appLanguage.en ? "User" : "Usuario"}:</b> {getAuthUserName(transaction.userId)}</p> : null}
                            {transaction.quantity ? <p className="see-details-transations-description"><b>{getQuantityTitle(transaction.description)}:</b> {transaction.quantity}</p> : null}
                            <p className="see-details-transations-description"><b>{appLanguage.en ? "Quantity to date" : "Cantidad a la fecha"}:</b> {transaction.quantityToDate}</p>
                            <p className="see-details-transations-description"><b>{appLanguage.en ? "Value to date" : "Valor a la fecha"}:</b> RD$ {formatRationalNumber(transaction.valueToDate)}</p>

                            {transaction.ref ? 
                                <div 
                                    style={{
                                        display: "flex", 
                                        width: "100%", 
                                        justifyContent: "center", 
                                        alignItems: "center",
                                        marginTop: "30px",
                                        marginBottom: "15px"
                                    }}
                                >
                                    {transaction.ref === "itCanBeCancel" ? 
                                        <button 
                                            onClick={onCancelLoss}
                                            className="new-customer-bottons-button-save"
                                            style={{maxWidth: "190px"}}
                                        >   
                                            <img src={closedDarkIcon} style={{marginRight: "10px"}} alt="Cancel loss" />
                                            {appLanguage.en ? <p>Cancel loss</p> : <p>Cancelar pérdida</p>}
                                        </button>
                                    :
                                        <button 
                                            onClick={null}
                                            className="new-customer-bottons-button-save"
                                            style={{maxWidth: "190px"}}
                                        >
                                            {appLanguage.en ? <p>Open {getMovementDescription(transaction.description)}</p> : <p>Abrir {getMovementDescription(transaction.description)}</p>}
                                            <img src={arrowIconDark} style={{marginLeft: "10px"}} alt="Open record" />
                                        </button>
                                    }
                                </div>
                            :   
                                <div style={{minHeight: "30px"}} />
                            }
                        </div>
                    : null}
                </div> 
            </div>
        </div>
    )
}

export default MovementDetails;