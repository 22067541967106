import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

import shareIconDark from "../../icons/share-dark.png";
import shareIconLight from "../../icons/share-light.png";

import './settingsOption.scss';

const SettingsOptionAction = ({description, icon, goTo}) => {

    const { appTheme, isTouchEnabled } = useContext(AppContext);

    return (
        <a
            href={goTo}
            target='_blank' 
            rel="noreferrer" 
            className={`${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appTheme.dark ? (isTouchEnabled() ? "settiongs-individual-option-action-dark-no-hover" : "settiongs-individual-option-action-dark") : (isTouchEnabled() ? "settiongs-individual-option-action-light-no-hover" : "settiongs-individual-option-action-light")}`}
        >
            <div className='settings-individual-option-icons'>
                <img src={icon} alt='Option' />
                <p>{description}</p>
            </div>
            <img className='settings-individual-option-arrow' src={appTheme.dark ? shareIconDark : shareIconLight } alt='Go to page' />
        </a>
    )
}

export default SettingsOptionAction;