import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import forbiddenLight from "../../icons/forbidden-light.png";
import forbiddenDark from "../../icons/forbidden-dark.png";

import "./sectionForbidden.scss";

const SectionForbidden = ({sectionName}) => {

    const { appTheme, appLanguage } = useContext(AppContext);

    return (
        <div className="section-forbidden-container">
            <div className="section-forbidden-page-name">
                <img className="section-forbidden-img" src={appTheme.dark ? forbiddenDark : forbiddenLight} alt="Forbidden" />
                <p className="section-forbidden-title">{sectionName}</p>
            </div>
            <p className="section-forbidden-text">{appLanguage.en ? "You do not have access to this section" : "No tienes acceso a esta sección"}</p>
        </div>
    )
}

export default SectionForbidden;