import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import "./connectionError.scss";

import networkIconDark from '../../icons/network-signal-dark.png';
import networkIconLight from '../../icons/network-signal-light.png';

import refreshIconDark from '../../icons/refresh-dark.png';

const ConnectionError = ({onTryAgain}) => {
    
    const { appLanguage, appTheme } = useContext(AppContext);

    return (
        <div className="internet-error-container">
            <div className="internet-error-header">
                <img src={appTheme.dark ? networkIconDark : networkIconLight} alt="Connection" />
                <p><b> {appLanguage.en ? "Something went wrong" : "Algo salió mal"} </b></p>
            </div>
            <button className="internet-error-button" onClick={e => onTryAgain(e)}>
                <img src={refreshIconDark} alt="Try again" />
                {appLanguage.en ? "Try again" : "Intentar otra vez"}
            </button>
        </div>
    );
}

export default ConnectionError;