import { useContext, useState, Fragment } from 'react';
import firebase from '../../firebase';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../../components/alert/alertContext';
import { useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import GoBackButton from "../../components/navigation/go-back-button/goBackButton";

import sendIconDark from '../../icons/send-dark.png';

import OutsideNav from "../../components/outside-nav/outsideNav";

import Spinner from "../../components/spinner/spinner";

const ForgotPassword = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);

    const [ nuevoUsuario, setNuevoUsuario ] = useState({
        userID : ""
    });

    const [ userIdAlert, setUserIdAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    async function passwordResetEmail(email) {
        setLoading(true);

        try {
            await firebase.useSendPasswordResetEmail(email);
            setAlertData({
                type : 'success',
                title: {
                    en : 'Email sent successfully',
                    es : 'El correo eléctronico enviado con éxito'
                },
                code : '',
                description : {
                    en : 'In a moment you will be receiving an email. Click the link to reset your password.',
                    es : 'En un momento estarás recibiendo un correo electrónico. Haga clic en el enlace para restablecer su contraseña.'
                }
            });
            setAlertActive(true);
            navigate('/auth/iniciar-sesion', { replace: true });
            
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            setNuevoUsuario({
                ...nuevoUsuario,
                password : ""
            })
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to reset password',
                    es : 'Error al intentar restablecer la contraseña'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onChange = e => {
        if(e.target.name === "userID") {
            setUserIdAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }
        
        setNuevoUsuario({
            ...nuevoUsuario,
            [e.target.name] : e.target.value
        })
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const  validar = e => {
        e.preventDefault();

        setUserIdAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        if(!validateEmail(nuevoUsuario.userID.trim())) {
            setUserIdAlert({
                alert: true,
                enText: '* Email is not valid',
                esText: '* El correo no es válido'
            });
            return;
        } else {
            if (nuevoUsuario.userID.trim()=== "") {
                setUserIdAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }
        }

        passwordResetEmail(nuevoUsuario.userID);
    }

    return (
        <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} >
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Recover password' : 'Recupera la contraseña' } </title>
            </Helmet>
            <div className="outside-nav-space" />
            <OutsideNav />
            {loading ? 
                <div style={{marginTop: "80px"}}><Spinner/></div>
            : <Fragment>
                <div 
                    className="login-page-card-container"
                    style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                >
                    <div className={`creating-account-container login-page-card-mobile-view`}>
                        <div className="login-page-card-title">
                            <GoBackButton/>
                            <h3 className="recover-password-h3">{appLanguage.en ? 'Recover password' : 'Recupera la contraseña'}</h3>
                        </div>
                        <form className="login-page-form-mobile">
                            <div style={{paddingBottom: "40px"}} className='form-mobile-first-section'>

                                <p className="recover-password-h4">{appLanguage.en ? 'Enter your email in the input below and press send, you will immediately receive an email with a link to create a new password.' : 'Ingrese su correo electrónico a continuación y presione enviar, inmediatamente recibirás un correo electrónico con un enlace para crear una nueva contraseña.'}</p>

                                <label 
                                    className="input-description" 
                                    htmlFor="userID"
                                >{appLanguage.en ? 'Email address : ' : 'Correo electrónico : '} <b>{userIdAlert.alert ? (
                                    appLanguage.en ? userIdAlert.enText : userIdAlert.esText
                                ) : "*"}</b></label>
                                <input 
                                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                    className={`input-content ${userIdAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                    type="text" 
                                    name="userID" 
                                    id='userID'
                                    value={nuevoUsuario.userID}
                                    placeholder={appLanguage.en ? 'Type email...' : 'Escribir correo...'}
                                    onChange={ e => onChange(e)}
                                />
                                <br/>
                                <button
                                    className={`outside-button-container`}
                                    onClick={validar}
                                    type="submit"
                                >
                                    <img 
                                        src={sendIconDark}
                                        alt="Login button"
                                        className='outside-button-container-img'
                                    />   
                                    <p className='outside-button-container-text'>{appLanguage.en ? 'Send' : 'Enviar'}</p> 
                                </button>
                            </div>
                        </form>
                    </div>
                </div> 
            </Fragment>}
        </div>
    );
}

export default ForgotPassword;