import React, { createContext, useEffect, useState } from 'react';

import checkedIcon from '../../img/checked.svg';
import cancelIcon from '../../img/cancel.svg';
import warningIcon from '../../img/warning.svg';
import questionIcon from '../../img/question.svg';

export const AlertContext = createContext();

const AlertProvider = (props) => {

    const [ alertActive, setAlertActive ] = useState(false);
    const [ alertData, setAlertData ] = useState({
        type : '',
        title: {
            en : '',
            es : ''
        },
        code : '',
        description : {
            en : '',
            es : ''
        }
    });

    const onAgreeFuction = () => {
        return null;
    }

    const [ onAgree, setOnAgree ] = useState(() => onAgreeFuction);
 
    const [ iconAlert, setIconAlert ] = useState(null);

    const getErrorDescription = code => {
        switch (code) {
            case 'auth/wrong-password':
                return {
                    en : 'Wrong password',
                    es : 'Contraseña incorrecta'
                };
            case 'auth/user-not-found':
                return {
                    en : 'User not found. Check that the email is correct.',
                    es : 'Usuario no encontrado. Revisa que el email sea correcto.'
                };
            case 'auth/network-request-failed':
                return {
                    en : 'No internet connection. Try again later.',
                    es : 'Sin conexión a Internet. Vuelva a intentarlo más tarde.'
                };
            case 'auth/internal-error':
                return {
                    en : 'No internet connection. Try again later.',
                    es : 'Sin conexión a Internet. Vuelva a intentarlo más tarde.'
                };
            case 'auth/email-already-in-use':
                return {
                    en : 'Email in use. If it is your email, try to log in to your existing account, if not, try a different email.',
                    es : 'Correo en uso. Si es suyo, intente iniciar sesión en su cuenta, si no, intente con un correo diferente.'
                };
            case 'security/not-eligible-for-free-trial':
                return {
                    en : 'Not eligible for a free trial. Promotion expired or used.',
                    es : 'No es elegible para una prueba gratuita. Promoción vencida o utilizada.'
                };
            case 'already-in':
                return {
                    en : 'The user currently has access to this account.',
                    es : 'El usuario actualmente tiene acceso a esta cuenta.'
                };
            default:
                return {
                    en : 'There was an unexpected problem. Try again later.',
                    es : 'Hubo un problema inesperado. Trata de nuevo más tarde.'
                };
        }
    }

    useEffect(() =>{
        switch (alertData.type) {
            case 'success':
                setIconAlert(checkedIcon);
                break;
            case 'error':
                setIconAlert(cancelIcon);
                break;
            case 'warning':
                setIconAlert(warningIcon);
                break;
            case 'question':
                setIconAlert(questionIcon);
                break;
            default:
                setIconAlert(null);
        }
    }, [alertData]);

    useEffect(() => {
        if(alertActive === false) {
            setAlertData({
                type : '',
                title: {
                    en : '',
                    es : ''
                },
                code : '',
                description : {
                    en : '',
                    es : ''
                }
            });
            setOnAgree(() => onAgreeFuction)
        }
    }, [alertActive]);

    return (
        <AlertContext.Provider
            value={{ 
                alertActive,
                alertData,
                iconAlert,
                onAgree,
                setAlertActive,
                setAlertData,
                setIconAlert,
                getErrorDescription,
                setOnAgree
            }}
        >
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertProvider;