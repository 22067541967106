import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { SoftAlertContext } from "./softAlertContext";

import crossIcon from '../../icons/cross-white.png';

import './softAlert.scss';

const Alert = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { softAlertActive, softAlertData, setSoftAlertActive } = useContext(SoftAlertContext);

    const closeAlert = () => {
        setSoftAlertActive(false);
    }

    return (
        <div className='unselectable'>
            <div
                style={appTheme.dark ? {boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"} : {boxShadow: "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)"}}  
                className={`soft-alert-cont ${((softAlertData.type === "sucess") || (softAlertData.type === "success")) ? "soft-alert-background-green" : null} ${softAlertData.type === "error" ? "soft-alert-background-orange" : null} ${appTheme.dark ? "soft-alert-cont-dark" : "soft-alert-cont-light"}  ${softAlertActive ? 'active' : 'inactive' }`}
            >
                <p className='soft-alert-p'>{appLanguage.en ? softAlertData.text.en : softAlertData.text.es}</p>
                <button onClick={closeAlert} className='soft-alert-button'>
                    <img className='soft-alert-img' src={crossIcon} alt='Close notification'/>
                </button>
            </div>
        </div>
    )
}

export default Alert;