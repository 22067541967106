import { Fragment, useContext, useEffect  } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';
import SettingsOption from '../../../components/settings-options/settingsOption';
import SettingsEdictAcc from '../../../components/settings-edict-acc/settingsEdictAcc';

import businessIcon from '../../../img/analysis.svg';

import deleteIconDark from '../../../icons/trash-white.png';
import deleteIconLight from '../../../icons/trash.png';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import '../businessAccount.page.scss';

const EdictAccountPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(!(adminAccess || managerAccess))  {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    })

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Edit account" : "Editar cuenta" } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "Edit account" : "Editar cuenta"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : "--"}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ? <Fragment>
                                <SettingsEdictAcc />
                                <SettingsOption
                                    description={appLanguage.en ? "Delete account" : "Eliminar cuenta"}
                                    icon={appTheme.dark ? deleteIconDark : deleteIconLight}
                                    goTo={"/eliminar-cuenta"}
                                />
                            </Fragment>  : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EdictAccountPage;