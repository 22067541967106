import { Fragment, useContext, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AlertContext } from '../../../../components/alert/alertContext';
import { AuthContext } from '../../../../firebase/context';
import { useNavigate } from 'react-router-dom';
import questionIcon from '../../../../img/question.svg';
import SettingsActionRequired from '../../../../components/settings-action-required/settingsActionRequired';

import { uid } from 'uid';

import firebase from '../../../../firebase/firebase';

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import Spinner from '../../../../components/spinner/spinner';
import ConnectionError from '../../../../components/connection-error/connectionError';
import ChangePlanCard from '../../../../components/changePlanCard/changePlanCard';

import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../../img/analysis.svg';

import './planDetails.page.scss';

const PlanDetailsPage = () => {

    const { appLanguage, appTheme, getPlanNameAbbre, getDateInStringDayMonth, formatRationalNumber } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, setAccountData, adminAccess, managerAccess } = useContext(AuthContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const navigate = useNavigate();

    const [ plans, setPlans ] = useState([]);
    const [ confirmChange, setComfirmChange ] = useState(false);
    const [ toReactivate, setToReactivate ] = useState(false);
    
    const [ oldPlan, setOldPlan ] = useState(null)

    const [ loadingAccounts, setLoadingAccounts ] = useState(true);
    const [ estimate, setEstimate ] = useState(null);
    const [ loadingEstimate, setLoadingEstimate ] = useState(false);
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const [ paymentData, setPaymentData ] = useState({
        type: "",
        amount: 0
    });

    const [buttonDisable, setBottonDisable ] = useState(true);

    async function getCurrentPlans() {
        setShowConnectionError(false);

        try {
            const response = await firebase.useGetCurrentPlans();
            setLoadingAccounts(false);
            setPlans(response.plans);
        } catch (error) {
            console.log(error);
            setLoadingAccounts(false);
            setShowConnectionError(true);
        }
    }

    const onSelectPlan = (plan) => {
        setOldPlan(plan);
        estimatePlanChange(plan);
    }

    const onReactivate = (plan) => {
        setOldPlan(plan);
        estimateReativatePlan(plan);
    }

    // eslint-disable-next-line
    async function planChange() {
        setLoadingEstimate(true);
    
        try {
            await firebase.usePlanChange({
                ...estimate,
                "accountId": accountData.id
            });

            const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));
            if (isAccountSelected) {
                // Get Account data
                try {
                    const response = await firebase.useGetAccountSelected(isAccountSelected);
                    localStorage.setItem("isAccountData", JSON.stringify(response));
                    setAccountData(response);
                } catch (error) {
                    console.error(error);
                }
            }

            setAlertData({
                type: 'success',
                title: {
                    en: 'Plan changed',
                    es: 'Plan cambiado'
                },
                code: '',
                description: {
                    en: 'The plan for this account has been successfully changed.',
                    es: 'El plan para esta cuenta se ha cambiado correctamente.'
                }
            });
            setAlertActive(true);
            setToReactivate(false);
            navigate("/suscripcion", { replace: true })
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to change the plan',
                    es: 'Error al tratar de cambiar el plan'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoadingEstimate(false);
    }

    // eslint-disable-next-line
    async function reactivatePlan() {
        setLoadingEstimate(true);
    
        try {
            await firebase.useReactivatePlan({
                ...estimate,
                "accountId": accountData.id
            });

            const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));
            if (isAccountSelected) {
                // Get Account data
                try {
                    const response = await firebase.useGetAccountSelected(isAccountSelected);
                    localStorage.setItem("isAccountData", JSON.stringify(response));
                    setAccountData(response);
                } catch (error) {
                    console.error(error);
                }
            }

            setAlertData({
                type: 'success',
                title: {
                    en: 'Account has been activated',
                    es: 'Cuenta ha sido activada.'
                },
                code: '',
                description: {
                    en: 'Your account has been successfully activated.',
                    es: 'Tu cuenta ha sido activada con éxito.'
                }
            });
            setAlertActive(true);
            setToReactivate(false);
            navigate("/", { replace: true })
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to activate account',
                    es: 'Error al tratar de activa cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoadingEstimate(false);
    }

    const onConfirm = () => {
        if (toReactivate) {
            reactivatePlan();
        }
        
    }

    useEffect(() => {
        getCurrentPlans();
        setLoadingAccounts(true);
        setShowConnectionError(false);
    }, []);

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    const tryAgain = () => {
        getCurrentPlans();
        setLoadingAccounts(true);
        setShowConnectionError(false);
    }

    async function estimatePlanChange(plan) {
        setLoadingEstimate(true);
        setComfirmChange(true);
    
        try {
            const respose = await firebase.useEstimatePlanChange({
                "accountId": accountData.id,
                "planId": plan.id

            });
            setEstimate(respose);
            setToReactivate(false);
            
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to obtain the estimate',
                    es: 'Error al tratar de obtener la estimación'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setComfirmChange(false);
        }

        setLoadingEstimate(false);
    }

    async function estimateReativatePlan(plan) {
        setLoadingEstimate(true);
        setComfirmChange(true);
    
        try {
            const respose = await firebase.useEstimateReativatePlan({
                "accountId": accountData.id,
                "planId": plan.id

            });
            setEstimate(respose);

            setToReactivate(true);

            setPaymentData({
                "type": "payToReactivate",
                "amount": respose.payNow
            });
            setBottonDisable(false);
            
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to obtain the estimate',
                    es: 'Error al tratar de obtener la estimación'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setComfirmChange(false);
        }

        setLoadingEstimate(false);
    }

    async function requestForPayment(paymentData) {
        setLoadingEstimate(true); 

        try {
            await firebase.useRequestForPayment({
                ...paymentData,
                "accountId": accountData.id,
                "planId": oldPlan.id
            });

            const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));

            if (isAccountSelected) {
                try {
                    const response = await firebase.useGetAccountSelected(isAccountSelected);
                    localStorage.setItem("isAccountData", JSON.stringify(response));
                    setAccountData(response);
                } catch {}
            }

            navigate('/facturas', { replace: true });
            
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to reactivate account',
                    es: 'Error al tratar de reactivar la cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoadingEstimate(false);
            setBottonDisable(false);
            setToReactivate(true);
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Plan details" : "Detalles del plan"} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader3
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/suscripcion"}
                            text2={appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción"}
                            text={appLanguage.en ? "Plan details" : "Detalles del plan"}
                        />
                        : null}
                    <div className="settings-options-container-page-change">
                        <div className="settings-options-container-item-page-change">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {((accountData.status.type === "customer") || (accountData.active)) ? <Fragment> 
                                {(adminAccess || managerAccess) ? <Fragment>
                                    {confirmChange ? 
                                        <div className="page-change-confirm-change-container">
                                            {loadingEstimate ? <Spinner/> : 
                                                <div className={`page-change-confirm-change-card ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover" : "lig-sec-bg lig-box-sha-keep-hover"}`}>
                                                    <div className='page-change-confirm-change-card-title'>
                                                        <img src={questionIcon} alt="Confirm" />
                                                        <p>{appLanguage.en ? <b>{toReactivate ? "Confirm reactivation": "Confirm plan change"}</b> : <b>{toReactivate ? "Confirmar reactivación" : "Confirmar cambio de plan"}</b>}</p>
                                                        
                                                    </div>
                                                    {estimate ?  
                                                        <div>
                                                            <br/>
                                                            {toReactivate ? null : <Fragment>
                                                                <div className='page-change-confirm-change-card-amount'>
                                                                    <p>{appLanguage.en ? <i><b>Previous plan: </b></i> : <i><b>Plan anterior: </b></i>}<br/>{getPlanNameAbbre(estimate.planFrom)} RD$ {formatRationalNumber(accountData.bill.planPrice)}</p>
                                                                    <p>{appLanguage.en ? <i><b>New plan: </b></i> : <i><b>Nuevo plan: </b></i>}<br/>{getPlanNameAbbre(estimate.planTo)} RD$ {oldPlan ? formatRationalNumber(oldPlan.price) : "---"}</p>
                                                                </div>
                                                                <div className='page-change-confirm-change-card-amount-hr' />
                                                                <div className='page-change-confirm-change-card-amount'>
                                                                    <b>
                                                                        {appLanguage.en ? 
                                                                            <i><p>Adjust from {getDateInStringDayMonth(new Date(estimate.estimateDate))} to {getDateInStringDayMonth(new Date(estimate.estimateDateTo))}</p></i> 
                                                                        : 
                                                                            <i><p>Ajustes del {getDateInStringDayMonth(new Date(estimate.estimateDate))} al {getDateInStringDayMonth(new Date(estimate.estimateDateTo))}</p></i> 
                                                                        }
                                                                    </b>
                                                                </div>
                                                                
                                                                <div className='page-change-confirm-change-card-amount'>
                                                                    {appLanguage.en ? <p>Previous plan credit:</p> : <p>Crédicto plan anterior: </p>}
                                                                    <p className='page-change-confirm-change-card-amount-rd'> <i>RD$-{estimate.chargesForOldPlan}</i></p>
                                                                </div>
                                                                <div className='page-change-confirm-change-card-amount'>
                                                                    {appLanguage.en ? <p>New plan charge:</p> : <p>Cargo nuevo plan: </p>}
                                                                    <p className='page-change-confirm-change-card-amount-rd'> <i>RD${estimate.chargesForNewPlan}</i></p>
                                                                </div>
                                                            </Fragment>}
                                                            {toReactivate ? <br/> : <div className='page-change-confirm-change-card-amount-hr' /> }
                                                                
                                                            <div className='page-change-confirm-change-card-amount'>
                                                                {appLanguage.en ? <p>Current balance:</p> : <p>Balance Actual: </p>}
                                                                <p className='page-change-confirm-change-card-amount-rd'> RD$<b>{estimate.currentBalance}</b></p>
                                                            </div>
                                                            <div className='page-change-confirm-change-card-amount'>
                                                                <p>Plan ({getPlanNameAbbre(estimate.planTo)}): </p>
                                                                <p className='page-change-confirm-change-card-amount-rd'> RD$<b>{oldPlan ? formatRationalNumber(oldPlan.price) : "---"}</b></p>
                                                            </div>

                                                            {toReactivate ? <br/> : <div className='page-change-confirm-change-card-amount-hr' /> }
                                                            
                                                            <div className='page-change-confirm-change-card-amount'>
                                                                <b>
                                                                    {appLanguage.en ?
                                                                        <p className='page-change-confirm-change-card-amount-rd'> 
                                                                            <i>Billing period from {getDateInStringDayMonth(new Date(estimate.nextCycleFrom))} to {getDateInStringDayMonth(new Date(estimate.nextCycleTo))}</i> 
                                                                        </p>
                                                                    :
                                                                        <p className='page-change-confirm-change-card-amount-rd'> 
                                                                            <i>Periodo de facturación del {getDateInStringDayMonth(new Date(estimate.nextCycleFrom))} al {getDateInStringDayMonth(new Date(estimate.nextCycleTo))}</i> 
                                                                        </p>
                                                                    }
                                                                </b>
                                                            </div>
                                                            {toReactivate ? null 
                                                            :
                                                                <div className='page-change-confirm-change-card-amount'>
                                                                    {appLanguage.en ? <p>Total Adjustments:</p> : <p>Total ajustes: </p>}
                                                                    <p className='page-change-confirm-change-card-amount-rd'> RD$<b>{estimate.adjustment}</b></p>
                                                                </div> 
                                                            }
                                                            <br/>
                                                            <div className='page-change-confirm-change-card-amount'>
                                                                {appLanguage.en ? <p>Pay now: </p> : <p>Pagar ahora: </p> }
                                                                <p className='page-change-confirm-change-card-amount-rd'> RD$<b>{estimate.payNow}</b></p>
                                                            </div>
                                                            <br/>
                                                            {estimate.payNow > 0 ? 
                                                                <div className='page-change-reactivate-link-div'>
                                                                    <div 
                                                                        className='page-change-reactivate-link'
                                                                        onClick={() => requestForPayment(paymentData)}
                                                                    >
                                                                        <button
                                                                            disabled={buttonDisable}
                                                                            className={`${buttonDisable ? "settings-bills-butt-card-disable" : "settings-bills-butt-card"}`}
                                                                        >
                                                                            <p>{appLanguage.en ? "Confirm" : "Confirmar"}</p>  
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            : 
                                                                <div className='page-change-confirm-change-button-cont'>
                                                                    <button
                                                                        onClick={onConfirm}
                                                                        className='page-change-confirm-change-button'
                                                                    >
                                                                        {appLanguage.en ? "Confirm" : "Confirmar"}
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div> 
                                                    : null}
                                                </div>
                                            }
                                        </div>
                                    : 
                                        <div className="page-change-plan-cards-container">
                                            {loadingAccounts ? <Spinner/> : <Fragment>
                                                {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : 
                                                    <Fragment>
                                                        {plans ? <Fragment>
                                                            {plans.map((plan) => {
                                                                let current = null;
                                                                if (plan.id === accountData.plan) {current = true}
                                                                return <ChangePlanCard key={uid()} plan={plan} current={current} onSelectPlan={onSelectPlan} onReactivate={onReactivate}/>
                                                            })}
                                                        </Fragment> : null}
                                                    </Fragment>
                                                }
                                            </Fragment>}
                                        </div> 
                                    }
                                </Fragment> : null }
                            </Fragment> : <SettingsActionRequired /> }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PlanDetailsPage;