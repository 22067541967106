import "./productMovements.page.scss";
import { Fragment, useContext, useEffect, useState, useRef } from "react";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import firebase from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { collection, query, limit, onSnapshot, doc, where, orderBy, getCountFromServer, getDoc, startAt, getDocsFromServer, startAfter } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import Spinner from "../../../components/spinner/spinner";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";

import addImgDark from "../../../icons/plus-mini-dark.png";
import addImgLight from "../../../icons/plus-mini-light.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import noPicture from "../../../img/no-picture.png";
import { uid } from "uid";
import InventoryMovementsTable from "../../../appTools/appToolsComponents/inventoryMovementsTable/inventoryMovementsTable";
import { MovementDetailsContext } from "../../../appTools/appToolsComponents/movementDetails/movementDetailsContext";
import MovementDetails from "../../../appTools/appToolsComponents/movementDetails/movementDetails";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AlertContext } from "../../../components/alert/alertContext";

const ProductMovementsPage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const { accountData, accessTo, setPictureInMemory, authorizedUsers, setAuthorizedUsersListener } = useContext(AuthContext);

    const {
        appLanguage,
        appTheme,
        isTouchEnabled,
        setDropDownCartOpen,
        setDropDownProfileMenuOpen,
        formatRationalNumber,
        getCategory,
        getMonthNameAndFullYear
    } = useContext(AppContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setMovementDetailsActive, transaction, setTransaction } = useContext(MovementDetailsContext);

    const [period, setPeriod] = useState(null);

    const [pictureFile, setPictureFile] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const [request, setRequest] = useState("0000");
    const [product, setProduct] = useState(null);

    const [filter, setFilter] = useState(null);

    const [showConnectionError, setShowConnectionError] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const [transactions, setTransactions] = useState([]);

    const [docsCount, setDocsCount] = useState(null);
    const [docsFilterCount, setDocsFilterCount] = useState(null); 

    const [loading, setLoading] = useState(true);
    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);

    // Keep state in the current path
    const [results, setResults] = useState([]);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler && docsCount) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const getAuthUserName = (userId) => {
        if (authorizedUsers) {
            const user = authorizedUsers.find(user => user.id === userId);
            return user ? user.name : "--";
        } else {
            return "--"
        }
    }

    useEffect(() => {
        if (product && product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }
        // eslint-disable-next-line
    }, [product]);

    useEffect(() => {
        if (location.state) {

            if (refresh === 0) {
                setRefresh(1)
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (itemsDeleted) {
                const productId = location.state.product.id;

                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }

                if (containsString(itemsDeleted, productId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    setPeriod(location.state.product.periods.current);
                    setProduct(location.state.product);
                    setRequest(uid());

                    // Repeat all *******************************
                    if (location.state.docsCount) {
                        setTransactions(location.state.transactions);
                        setFilter(location.state.filter);
                        setDocsCount(location.state.docsCount);
                        setDocsFilterCount(location.state.docsFilterCount)
                        setControler(location.state.controler);
                        if (location.state.controler) {
                            setPeriod(location.state.controler.period)
                        }
                        setPageHistory(location.state.pagesHistory);
                        setNavControlers(location.state.navControlers);
                        setEmpty(location.state.empty);
                        setShowPageNav(location.state.showPageNav);

                        setPageNumber(location.state.navControlers.page);

                        let page = null;

                        if (location.state.navControlers) {
                            setPageNumber(location.state.navControlers.page);
                            page = location.state.navControlers.page;
                        }

                        if (location.state.controler) {
                            if (
                                transactions &&
                                page === 1 &&
                                location.state.controler.period === location.state.product.periods.current
                            ) {
                                setResults(transactions);
                            } else {
                                const res = location.state.results;
                                setResults(res);
                            }
                        } else {
                            setResults(transactions);
                            setShowConnectionError(false);
                            setEmpty(false);
                            setShowPageNav(false);
                            setPageNumber(1);
                        }
                    }
                }
            } else {
                setPeriod(location.state.product.periods.current);
                setProduct(location.state.product);
                setRequest(uid());

                // Repeat all *******************************
                if (location.state.docsCount) {
                    setTransactions(location.state.transactions);
                    setFilter(location.state.filter);
                    setDocsCount(location.state.docsCount);
                    setDocsFilterCount(location.state.docsFilterCount)
                    setControler(location.state.controler);
                    if (location.state.controler) {
                        setPeriod(location.state.controler.period)
                    }
                    setPageHistory(location.state.pagesHistory);
                    setNavControlers(location.state.navControlers);
                    setEmpty(location.state.empty);
                    setShowPageNav(location.state.showPageNav);

                    setPageNumber(location.state.navControlers.page);

                    let page = null;

                    if (location.state.navControlers) {
                        setPageNumber(location.state.navControlers.page);
                        page = location.state.navControlers.page;
                    }

                    if (location.state.controler) {
                        if (
                            transactions &&
                            page === 1 &&
                            location.state.controler.period === location.state.product.periods.current
                        ) {
                            setResults(transactions);
                        } else {
                            const res = location.state.results;
                            setResults(res);
                        }
                    } else {
                        setResults(transactions);
                        setShowConnectionError(false);
                        setEmpty(false);
                        setShowPageNav(false);
                        setPageNumber(1);
                    }
                }
            }
        } else {
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/inventario', { replace: true });
            }
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (transactions && product && docsCount && controler) {
            if (
                transactions &&
                pageNumber === 1 &&
                controler.period === product.periods.current
            ) {
                setResults(transactions);

                setNavControlers({
                    "page": 1,
                    "totalPage": Math.ceil(docsCount / 30),
                    "isPrevious": false,
                    "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
                });
            }
        }

        if (docsCount && product) {
            if (docsCount > 0) {
                setEmpty(false);
                if (docsCount > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }
            } else {
                setEmpty(true);
            }
        }
        // eslint-disable-next-line
    }, [transactions, docsCount, product]);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (product && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                const productUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete productUpdated.searchKeys;

                if (doc.data()) {
                    setProduct(productUpdated);
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            setPictureFile(urlData);
                        }
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const getHeaderHeight = () => {
        setTimeout(() => {
            if (headerObservedRef) {
                if (!headerObservedRef.current) {
                    return;
                }

                if (headerObservedRef.current.offsetHeight !== headerHeight) {
                    setHeaderHeight(headerObservedRef.current.offsetHeight);
                }
            }
        }, 300);
    }

    useEffect(() => {
        getHeaderHeight();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== width) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6 : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(68);
    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);
    const [newMovementIcon, setNewMovementIcon] = useState(appTheme.dark ? addImgDark : addImgLight);

    const [dropDownFilterOpen, setDropDownFilterOpen] = useState(false);
    const [dropDownMovementOpen, setDropDownMovementOpen] = useState(false);

    // eslint-disable-next-line
    const onScroll = () => {
        setDropDownFilterOpen(false);
        setDropDownMovementOpen(false);

        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    let filterRef = useRef();
    let newMovementRef = useRef();

    useEffect(() => {
        if (dropDownFilterOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownFilterOpen, appTheme]);

    useEffect(() => {
        if (dropDownMovementOpen) {
            setNewMovementIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setNewMovementIcon(appTheme.dark ? addImgDark : addImgLight);
        }
    }, [dropDownMovementOpen, appTheme]);

    useEffect(() => {
        if (dropDownFilterOpen) {
            let handler = (e) => {
                if (filterRef.current) {
                    if (!filterRef.current.contains(e.target)) {
                        setDropDownFilterOpen(false);
                    };
                } else {
                    setDropDownFilterOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownMovementOpen) {
            let handler = (e) => {
                if (newMovementRef.current) {
                    if (!newMovementRef.current.contains(e.target)) {
                        setDropDownMovementOpen(false);
                    };
                } else {
                    setDropDownMovementOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickFilterButton = () => {
        dropDownFilterOpen ? setDropDownFilterOpen(false) : setDropDownFilterOpen(true);
    }

    const onClickMovementButton = () => {
        dropDownMovementOpen ? setDropDownMovementOpen(false) : setDropDownMovementOpen(true);
    }

    const onChangePeriod = (e) => {
        setPeriod(e.target.value);
    }

    useEffect(() => {
        if (period) {
            if (!controler) {
                setControler({
                    "period": period,
                    "triggerSearch": false,
                });
            }
        }
        // eslint-disable-next-line
    }, [period]);

    const onClickTransaction = (transaction) => {
        setMovementDetailsActive(true);
        setTransaction(transaction);
    }

    async function getTransactionsCounter(q) {
        try {
            const snapshot = await getCountFromServer(q);
            setDocsCount(snapshot.data().count);
            setLoading(false);
        } catch (error) {
            console.warn(error);
            setLoading(false);
        }
    }

    async function verifiedIfTransationsIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setTransactions([]);
                setDocsCount(0);
                setLoading(false);
            }
        } catch (error) {
            console.warn(error);
            setShowConnectionError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (accountData && product) {
            const q = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", product.periods.current),
                orderBy("time", "desc"),
                limit(30)
            );

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (res.length > 0) {
                    setTransactions(res);

                    const q = query(
                        collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                        where("productId", "==", product.id),
                        where("period", "==", product.periods.current),
                        orderBy("time", "desc")
                    );
                    getTransactionsCounter(q);

                } else {
                    verifiedIfTransationsIsReallyEmpty(q);
                }
            }, err => {
                console.error(err);
                setLoading(false);
                setShowConnectionError(true);
            });

            return () => unsub();
        }
        // eslint-disable-next-line
    }, [product]);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    useEffect(() => {
        if (controler && product) {
            if (
                pageNumber === 1 &&
                controler.period === product.periods.current
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchSales(controler);
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    const searchSales = async () => {
        if (
            transactions &&
            pageNumber === 1 &&
            controler.period === product.periods.current
        ) { return; }

        setLoading(true);
        setShowConnectionError(false);

        let q = null;
        let qForDocsCounter = null;

        let newFilter = null;

        if (filter.period === product.periods.current) {
            newFilter = null;
            setFilter(null);
        } else {
            newFilter = {...filter};
        }

        if (newFilter) {
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", newFilter.period),
                orderBy("time", "desc"),
                limit(30)
            );

            qForDocsCounter = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", newFilter.period),
                orderBy("time", "desc"),
            );
        } else {
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", product.periods.current),
                orderBy("time", "desc"),
                limit(30)
            );
        }
        
        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];
            let newDocsFilterCount = 0;

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }
                    res.push(thisDoc);
                }
            });

            if (newFilter) {
                const snapshotCounter = await getCountFromServer(qForDocsCounter);
                newDocsFilterCount = snapshotCounter.data().count
                setDocsFilterCount(newDocsFilterCount);
            }

            setPageHistory([]);
            setPageNumber(1);

            if (newFilter) {
                setShowPageNav(Math.ceil(newDocsFilterCount / 30) > 1 ? true : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": Math.ceil(newDocsFilterCount / 30),
                    "isPrevious": false,
                    "isNext": Math.ceil(newDocsFilterCount / 30) > 1 ? true : false
                });
            } else {
                setShowPageNav(Math.ceil(docsCount / 30) > 1 ? true : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": Math.ceil(docsCount / 30),
                    "isPrevious": false,
                    "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);

                updateOrPushHistory({
                    "page": 1,
                    "startAt": res[0].time,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].time,
                    "endAtDocId": res[res.length - 1].id,
                });

            } else {
                setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        blink();

        setControler({
            "period": period,
            "triggerSearch": false,
        });

        setPeriod(product.periods.current);

        setPageHistory([]);

        if (transactions) {
            if (transactions.length > 0) {
                setEmpty(false);
            } else {
                setEmpty(true);
            }
        }
        
        setResults(transactions);

        setShowPageNav(Math.ceil(docsCount / 30) > 1 ? true : false);

        setNavControlers({
            "page": 1,
            "totalPage": Math.ceil(docsCount / 30),
            "isPrevious": false,
            "isNext": Math.ceil(docsCount / 30) > 1 ? true : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            updateOrPushHistory({
                "page": pageN,
                "startAt": res[0].time,
                "startAtDocId": res[0].id,
                "endAt": res[res.length - 1].time,
                "endAtDocId": res[res.length - 1].id,
            });
            setResults(res);
        }
    }

    const refreshSearch = async () => {
        if (
            transactions &&
            pageNumber === 1 &&
            controler.period === product.periods.current 
        ) {
            setResults(transactions);
            setNavControlers({
                "page": 1,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": false,
                "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            if (lastPageNumber === 1 && (!filter)) {
                onResetAll();
                return
            }

            setLoading(true);

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const productRef = collection(firebase.db, `accounts/${accountData.id}/productsTransactions`);
                    docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                    where("productId", "==", product.id),
                    where("period", "==", filter ? filter.period : product.periods.current),
                    orderBy("time", "desc"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
                
                await goToPagePrevious(lastQuery, navControlers.page);

                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
    }

    const onClickPrevious = async e => {
        e.preventDefault();

        if (navControlers.isPrevious === false || filter) {
            return;
        }

        let startAtText = null;

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && (!filter)) {
            onResetAll();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const productRef = collection(firebase.db, `accounts/${accountData.id}/productsTransactions`);
                docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", period ? period : product.periods.current),
                orderBy("time", "desc"),
                startAt(docSnap ? docSnap : startAtText),
                limit(30)
            );

            await goToPagePrevious(lastQuery, navControlers.page - 1);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (filter) {
                setNavControlers({
                    "page": 1,
                    "totalPage": 1,
                    "isPrevious": false,
                    "isNext": false
                });
            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": Math.ceil(docsCount / 30),
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": Math.ceil(docsCount / 30) > newPageNumber ? true : false
                });
            }
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = [
                        {
                            "page": 1,
                            "startAt": results[0].time,
                            "startAtDocId": results[0].id,
                            "endAt": results[results.length - 1].time,
                            "endAtDocId": results[results.length - 1].id,
                        },
                        {
                            "page": navControlers.page + 1,
                            "startAt": res[0].time,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].time,
                            "endAtDocId": res[res.length - 1].id,
                        }
                    ];
                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].time,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].time,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": navControlers.page + 1,
                    "startAt": res[0].time,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].time,
                    "endAtDocId": res[res.length - 1].id,
                });
            }
            setResults(res);
        }
    }

    const onClickNext = async e => {
        e.preventDefault();

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            endAtDocId = lastProduct.id;
            startAfterOrAfterText = lastProduct.time;
        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        let nextQuery = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const productRef = collection(firebase.db, `accounts/${accountData.id}/productsTransactions`);
                docSnap = await getDoc(doc(productRef, endAtDocId));
            }

            nextQuery = query(
                collection(firebase.db, `accounts/${accountData.id}/productsTransactions`),
                where("productId", "==", product.id),
                where("period", "==", filter ? filter.period : product.periods.current),
                orderBy("time", "desc"),
                endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                limit(30)
            );

            await goToNextPage(nextQuery);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (filter) {
                setNavControlers({
                    "page": 1,
                    "totalPage": Math.ceil(docsFilterCount / 30),
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": Math.ceil(docsFilterCount / 30) > 1 ? true : false
                });
            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": Math.ceil(docsCount / 30),
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": Math.ceil(docsCount / 30) > newPageNumber ? true : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const onClickApplyFilter = () => {
        console.log(period);
        console.log(product.periods.current)
        if (period === product.periods.current ) {
            console.log("reset all")
            setFilter(null);
            onResetAll();
        } else {
            setFilter({
                "period": period,
            });
            setControler({
                "period": period,
                "triggerSearch": true,
            });
        }
        setDropDownFilterOpen(false);
    }

    const tryAgain = () => {
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    const goToCreate = (type) => {
        navigate('/registrar-movimiento', { state: { type, product } });
    }

    async function cancelLoass() {
        setAlertActive(false);
        setMovementDetailsActive(false);
        setLoading(true);

        let tempTransaction = {...transaction}

        try {
            await firebase.useDeleteProductLoss({
                "transactionId": tempTransaction.id,
                "businessId": accountData.id,
            });
            setAlertData({
                type : 'success',
                title: {
                    en : 'The movement has been register in the inventory.',
                    es : 'El movimiento ha sido registrado en el inventario.'
                },
                code : '',
                description : {
                    en: `The loss was canceled and the number of this article was successfully updated.`,
                    es: `La pérdida fue cancelada y el número de este artículo actualizado de manera exitosa.`
                },
            });
            setAlertActive(true);
            setPeriod(product.periods.current);
            setShowPageNav(Math.ceil(docsCount / 30) > 1 ? true : false);
            setDocsFilterCount(0);
            setNavControlers({
                "page": 1,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": false,
                "isNext": Math.ceil(docsCount / 30) > 1 ? true : false,
            });
            setPageNumber(1);
            setRefresh(1);
        } catch (error) {
            console.error(error);
            onClickTransaction(tempTransaction);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to cancel the loss in inventory`,
                    es: `Error al intentar cancelar la pérdida en inventario`
                },
                code: "error",
                description: getErrorDescription("error"),
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "product": product,
            "transactions": transactions,
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "docsFilterCount": docsFilterCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showPageNav": showPageNav,
            "empty": empty,
        }

        navigate(null, { replace: true, state: newState });

    // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, empty, showPageNav, filter, docsCount, docsFilterCount, product, transactions]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Product details" : 'Detalles del producto'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className="page-app-settings-item">
                            {accessTo.inventory ? <Fragment>
                                <MovementDetails
                                    pickerWidth={pageWidth}
                                    getAuthUserName={getAuthUserName}
                                    cancelLoass={cancelLoass}
                                />
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Product movements" : 'Movimientos del producto'}
                                    />
                                    : null}
                                {loading ? null :
                                    <div ref={headerObservedRef} className="product-details-page-botttons-section-main-cont">
                                        <div className="product-details-page-botttons-section" style={{ justifyContent: "flex-start", maxWidth: "1085px", marginLeft: "10px", flexWrap: "nowrap" }}>

                                            <div ref={newMovementRef} className="sales-filter-botton-container" style={{ marginTop: "10px", marginBottom: "10px", height: "37px" }}>

                                                <button
                                                    onClick={onClickMovementButton}
                                                    style={{
                                                        margin: "0px 10px 0px 10px",
                                                        paddingLeft: "8px",
                                                        paddingRight: "8px",
                                                        width: "150px",
                                                    }}
                                                    className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                >
                                                    <img
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            marginRight: "10px"
                                                        }}
                                                        src={newMovementIcon}
                                                        alt="Options"
                                                    />
                                                    <p style={dropDownMovementOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Movement" : "Movimiento"}</p>
                                                </button>

                                                <span
                                                    className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMovementOpen ? 'active' : 'inactive'}`}
                                                    style={{
                                                        marginLeft: "75px",
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                        marginLeft: "20px",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "210px",
                                                    }}
                                                    className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownMovementOpen ? 'active' : 'inactive'}`}
                                                >

                                                    <label style={{ marginLeft: "8px", marginBottom: "10px", fontWeight: "bold" }} className="filter-labels-inputs" htmlFor="seller">{appLanguage.en ? "Register movement" : "Registrar movimiento"}</label>

                                                    <button
                                                        style={{ marginTop: "15px" }}
                                                        className="invent-add-menu-btn"
                                                        disabled={loading ? true : false}
                                                        onClick={() => goToCreate("Losses")}
                                                    >
                                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Losses" : "Pérdidas"} </p>
                                                    </button>

                                                    <button
                                                        className="invent-add-menu-btn"
                                                        disabled={loading ? true : false}
                                                        onClick={() => goToCreate("Production")}
                                                    >
                                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Production" : "Producción"} </p>
                                                    </button>

                                                    <button
                                                        className="invent-add-menu-btn"
                                                        disabled={loading ? true : false}
                                                        onClick={() => goToCreate("Internal consumption")}
                                                    >
                                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Internal consumption" : "Consumo interno"} </p>
                                                    </button>

                                                </div>
                                            </div>

                                            <div ref={filterRef} className="sales-filter-botton-container" style={{ marginTop: "10px", marginBottom: "10px", }}>{/*height: "37px"*/}
                                                <button
                                                    onClick={onClickFilterButton}
                                                    style={{
                                                        margin: "0px 10px 0px 10px",
                                                        width: "180px"
                                                    }}
                                                    className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                >
                                                    <img
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            marginRight: "10px"
                                                        }}
                                                        src={filterIcon}
                                                        alt="Filter"
                                                    />
                                                    <p style={dropDownFilterOpen ? { fontWeight: "bold", whiteSpace: "nowrap" } : {whiteSpace: "nowrap"}}>{product ?  getMonthNameAndFullYear(filter ? filter.period : product.periods.current) : "--"}</p>
                                                </button>
                                                <span
                                                    className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownFilterOpen ? 'active' : 'inactive'}`}
                                                    style={{
                                                        marginLeft: "50px",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                        marginLeft: "-70px"
                                                    }}
                                                    className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownFilterOpen ? 'active' : 'inactive'}`}
                                                >
                                                    <label className="filter-labels-inputs" htmlFor="seller">{appLanguage.en ? "Period" : "Periodo"}</label>
                                                    <select
                                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                        className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                        name="period"
                                                        id="period"
                                                        value={period}
                                                        onChange={e => onChangePeriod(e)}
                                                    >
                                                        <option value={product.periods.current}>{getMonthNameAndFullYear(product.periods.current)}</option>

                                                        {product.periods.previous.length > 0 ?
                                                            <Fragment>
                                                                {product.periods.previous.map((period) => {
                                                                    return (
                                                                        <option key={period} value={period}>{getMonthNameAndFullYear(period)}</option>
                                                                    );
                                                                })}
                                                            </Fragment>
                                                            : null}
                                                    </select>

                                                    <div className="filter-button-action-div">
                                                        <button
                                                            onClick={onClickApplyFilter}
                                                            className="filter-button-btn-div"
                                                        >
                                                            {appLanguage.en ? "Apply" : "Aplicar"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px - ${headerHeight}px)` : `calc(100vh - 65px - ${headerHeight}px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        <div
                                            ref={observedRef}
                                            className="product-details-page-container"
                                            style={{
                                                marginTop: 0,
                                                width: "100%",
                                                maxWidth: "1121px",
                                                display: "flex",
                                                flexDirection: "column",
                                                margin: "0px",
                                            }}
                                        >
                                            {product ? <Fragment>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        borderRadius: "10px",
                                                        alignItems: "center",
                                                        marginTop: "10px",
                                                        marginLeft: "20px",
                                                        marginRight: "20px",
                                                        marginBottom: "5px",
                                                        width: "calc(100% - 25px)",
                                                        boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                    className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                >
                                                    {pictureFile ?
                                                        <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                            <img className="products-details-micro-product-yes-img" src={pictureFile} alt="Product" />
                                                        </div>
                                                        :
                                                        <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                            <img className="products-details-micro-product-no-img" src={noPicture} alt="Product" />
                                                        </div>
                                                    }

                                                    <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px" }}><b>{product.name}</b></p>
                                                    <span style={{ flexGrow: 4 }} />

                                                    {width < 800 ? null :
                                                        <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px", width: "200px" }}>Categoria: <br /><b>{getCategory(product.category)}</b></p>
                                                    }

                                                    {width < 450 ? null :
                                                        <p style={{ margin: "5px 10px", width: "150px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Final price" : "Precio final"}: <br /> <b>RD$ {formatRationalNumber(product.finalPrice)}</b></p>
                                                    }
                                                </div>

                                                <InventoryMovementsTable
                                                    onScroll={onScroll}
                                                    headerHeight={headerHeight + (desktopView ? 115 : 75)}
                                                    loading={loading}
                                                    showConnectionError={showConnectionError}
                                                    tryAgain={tryAgain}
                                                    results={results}
                                                    onClickLink={onClickTransaction}
                                                    pageNumber={pageNumber}
                                                    navControlers={navControlers}
                                                    showPageNav={showPageNav}
                                                    onClickNext={onClickNext}
                                                    onClickPrevious={onClickPrevious}
                                                    dropDownFilterOpen={dropDownFilterOpen}
                                                    dropDownMovementOpen={dropDownMovementOpen}
                                                    getAuthUserName={getAuthUserName}
                                                    empty={empty}
                                                />

                                            </Fragment> : null}
                                        </div>
                                    </Fragment>}
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductMovementsPage;