import { useContext } from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AppContext } from '../../context/AppContext';

import Alert from '../../components/alert/alert';
import OutsideNav from '../../components/outside-nav/outsideNav';
import erro404Icon from '../../img/error-404.svg';

import './noFound.page.scss';
import '../outside/outside.page.scss';

const NoFoundPage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);

    return (
        <HelmetProvider>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <meta name="theme-color" content={`${appTheme.dark ? "#202022" : "#ffffff"}`} />
                <title>HazCuentas - Error 404 </title>
            </Helmet>
            <div className={`${appTheme.dark ? "dark-theme" : "light-theme"} App`} >
                <div className='app-container'>
                    <Alert />
                    <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} >
                        <OutsideNav />
                        <div className='no-found-container'>
                            <h3>{appLanguage.en ? "We couldn't find the page you requested" : "No pudimos encontrar la página que solicitaste"}</h3>
                            <img src={erro404Icon} alt='Error 404' />
                            <div>
                                <Link to={'/'} className='no-found-page-botton'><p>{appLanguage.en ? "Go home" : "Ir a inicio"}</p> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
}

export default NoFoundPage;