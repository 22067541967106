import { useContext, useState, Fragment, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../../components/alert/alertContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../firebase/context';

import firebase from '../../firebase'; 

import DoNotVerifyEmail from '../../components/navigation/go-back-button/do-not-verify-email';
import OutsideNav from "../../components/outside-nav/outsideNav";
import Spinner from "../../components/spinner/spinner";

import sendIconDark from '../../icons/send-dark.png';
import emailIconDark from '../../icons/circle-envelope-dark.png';
import emailIconLight from '../../icons/circle-envelope-light.png';
import timerIcon from '../../img/stopwatch.svg';

const VerifyEmailPage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { user, setEmailVerified, emailVerified } = useContext(AuthContext);

    const navigate = useNavigate();

    const userEmail = user ? user.email : ' ';

    const [ loading, setLoading ] = useState(false);
    const [ counting, setCounting ] = useState(false);
    const [ showTimer, setShowTimer ] = useState(false);
    const [ showSend, setShowSend ] = useState(false);
    const [ timerFor, setTimerFor ] = useState(300);

    const startTimer = () => {
        setCounting(true);
    }

    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);

    const getTime = () => {
        let min;
        let sec;
        setTimerFor(timerFor - 1);
        if(timerFor >= 0) {
            min = Math.floor(timerFor / 60);
            sec = Math.floor((timerFor) % 60);
            setMinutes(min);
            setSeconds(sec.toString().padStart(2, '0'));
        } else {
            setCounting(false);
            setMinutes(0);
            setSeconds("00");
            setTimerFor(300);
            setShowSend(true);
        }
    };

    useEffect(() => {
        if(counting) {
            const interval = setInterval(() => getTime(), 1000);
            return () => clearInterval(interval);
        }
    });
   
    
    async function verifyEmailFirebase() {
        setLoading(true);

        try {
            await firebase.useSendEmailVerification();
            setAlertData({
                type : 'success',
                title: {
                    en : 'Email sent successfully',
                    es : 'Correo eléctronico enviado con éxito'
                },
                code : '',
                description : {
                    en : 'In a moment you will be receiving an email. Click the link to verify email.',
                    es : 'En un momento estarás recibiendo un correo electrónico. Haga clic en el enlace para verificar su correo.'
                }
            });
            setLoading(false);
            setAlertActive(true);
            setShowTimer(true);
            setShowSend(false);
            setTimerFor(300);
            startTimer();
            
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to verify email',
                    es : 'Error al intentar verificar correo'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const  validar = e => {
        e.preventDefault();
        verifyEmailFirebase();
    }

    let interval = setInterval(async () => {
        if(user) {
            if (user.emailVerified) {
                clearInterval(interval);
                localStorage.setItem("isEmailVerified", JSON.stringify(true));
                setEmailVerified(true);
            }
            try {
                await user.reload();
            } catch {}
        }
    }, 3500);

    useEffect(() => {
        if(emailVerified) {
            if (window.PublicKeyCredential) {
                // setAlertRecommendLockActive(true);
            };
            navigate('/elegir-cuenta', { replace: true });
        }
    // eslint-disable-next-line
    }, [emailVerified]);

    return (
        <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} > 
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Verify your email' : 'Verifique su correo electrónico' } </title>
            </Helmet>
            <div className="outside-nav-space" />
            <OutsideNav />
            {loading ? 
                <div style={{marginTop: "80px"}}><Spinner/></div>
            : <Fragment>
                <div 
                    className="login-page-card-container"
                    style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                >
                    <div className={`creating-account-container login-page-card-mobile-view `}>
                        <div className="login-page-card-title">
                            <DoNotVerifyEmail />
                            <h3 className="recover-password-h3">{appLanguage.en ? 'Verify your email' : 'Verifique su correo electrónico'}</h3>
                        </div> 
                        <br/>
                        {showTimer ?               
                            <div  style={{paddingBottom: "40px"}}  className='email-verification-counter'>
                                <img className='email-verification-counter-img' src={timerIcon} alt='Timer' />
                                <h1 className='email-verification-counter-h1' >{minutes}:{seconds} min</h1>
                                {showSend ? 
                                    <div className='re-send-verify-email'>
                                        <p className="recover-password-p">{appLanguage.en ? 'If you did not receive the email, you can try again by pressing send.' : 'Si no recibio el correo ahora puede reintentar presionando enviar.'}</p>
                                        <div className={`email-verification-address ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                            <img className='recover-passwor-email' src={appTheme.dark ? emailIconDark : emailIconLight} alt="Email" />
                                            <h4 className="email-verification-h4">{userEmail}</h4>
                                        </div>
                                        <br/>
                                        <button 
                                            className={`outside-button-container`}
                                            onClick={validar}
                                            type="submit"
                                        >
                                            <img 
                                                src={sendIconDark}
                                                alt="Login button"
                                                className='outside-button-container-img'
                                            />   
                                            <p className='outside-button-container-text'>{appLanguage.en ? 'Send' : 'Enviar'}</p> 
                                        </button>
                                    </div> 
                                : <div className='re-send-verify-email'>
                                    <p className='email-verification-counter-p' >
                                        {appLanguage.en ? "Go to your email and click the link, if you don't receive the link you can send the email again when the counter is at zero." : "Vaya a su correo electrónico y haga clic en el enlace, si no recibe el enlace, puede enviar el correo electrónico nuevamente cuando el contador esté en cero."}
                                    </p>
                                    <div className={`email-verification-address ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                        <img className='recover-passwor-email' src={appTheme.dark ? emailIconDark : emailIconLight} alt="Email" />
                                        <h4 className="email-verification-h4">{userEmail}</h4>
                                    </div>
                                </div>}
                            </div>
                        : <form style={{paddingBottom: "40px"}} className="login-page-form-mobile">
                            <div className='form-mobile-first-section'>
                                <div className={`email-verification-address ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                    <img className='recover-passwor-email' src={appTheme.dark ? emailIconDark : emailIconLight} alt="Email" />
                                    <h4 className="email-verification-h4">{userEmail}</h4>
                                </div>
                                <br/> 
                                <p className="recover-password-p">{appLanguage.en ? 'In order to continue you must verify your email. To do this, simply press send, then you will receive an email with a link.' : 'Para continuar debe verificar su correo. Para hacer esto, simplemente presione enviar, luego recibirá un correo con un enlace.'}</p>
                                <br/>
                                <button 
                                    className={`outside-button-container`}
                                    onClick={validar}
                                    type="submit"
                                >
                                    <img 
                                        src={sendIconDark}
                                        alt="Login button"
                                        className='outside-button-container-img'
                                    />   
                                    <p className='outside-button-container-text'>{appLanguage.en ? 'Send' : 'Enviar'}</p> 
                                </button>
                            </div>
                        </form>}
                    </div>
                </div> 
            </Fragment>}
        </div>
    );
}

export default VerifyEmailPage;