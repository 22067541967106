import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';

import arrowIconDark from '../../icons/arrow-small-right-white.png';
import arrowIconLight from '../../icons/arrow-small-right.png';

import './settingsOption.scss';

const SettingsOption = ({description, icon, goTo}) => {

    const { appTheme, isTouchEnabled } = useContext(AppContext);

    return (
        <Link 
            to={goTo} 
            className={`${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appTheme.dark ? (isTouchEnabled() ? "settiongs-individual-option-action-dark-no-hover" : "settiongs-individual-option-action-dark") : (isTouchEnabled() ? "settiongs-individual-option-action-light-no-hover" : "settiongs-individual-option-action-light")}`}
            // className={`settiongs-individual-option ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appTheme.dark ? "dar-sec-bg settiongs-individual-option-dark" : "lig-sec-bg settiongs-individual-option-light"}`}>
        >  
            <div className='settings-individual-option-icons'>
                <img src={icon} alt='Option' />
                <p>{description}</p>
            </div>
            <img className='settings-individual-option-arrow' src={appTheme.dark ? arrowIconDark : arrowIconLight } alt='Go to page' />
        </Link>
    )
}

export default SettingsOption;