import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const SideMenuContext = createContext();

const SideMenuProvider = (props) => {

    const location = useLocation();

    const [ sideMenuOpen, setSideMenuOpen ] = useState(false);
    const [ lockScreen, setLockScreen ] = useState(false);
    const [ isSideMenuMinimized, setIsSideMenuMinimized ] = useState(JSON.parse(localStorage.getItem('isSideMenuMinimized')));

    const [ optimizeMobileView, setOptimizeMobileView ] = useState(() => {
        if(location.pathname === '/' || location.pathname === '/metricas' || location.pathname === '/clientes' || location.pathname === '/elegir-cuenta' ) {
            return false;
        } else {
            return true;
        }
    });
    
    const [ desktopView, setDesktopView ] = useState(() => {
        if(window.innerWidth > 820) {
            return true;
        } else {
            return false;
        }
    });

    const [ smallView, setSmallView ] = useState(() => {
        if(window.innerWidth < 390) {
            return true;
        } else {
            return false;
        }
    });

    const [ windowDimension, setWindowDimension ] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight
        });

        if(window.innerWidth > 820) {
            setDesktopView(true);
        } else {
            setDesktopView(false);
        }

        if(window.innerWidth < 390) {
            setSmallView(true);
        } else {
            setSmallView(false);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize);

        return() => {
            window.removeEventListener('resize', detectSize);
        }
    }, [windowDimension]);

    useEffect(() => {
        setOptimizeMobileView(() => {
            if(location.pathname === '/' || location.pathname === '/metricas' || location.pathname === '/elegir-cuenta' ) {
                return false;
            } else {
                return true;
            }
        });
    }, [location]);

    return (
        <SideMenuContext.Provider
            value={{ 
                sideMenuOpen,
                lockScreen,
                isSideMenuMinimized, 
                desktopView,
                smallView,
                windowDimension,
                optimizeMobileView,
                setSideMenuOpen,
                setLockScreen,
                setIsSideMenuMinimized,
                setDesktopView,
                setWindowDimension,
                setOptimizeMobileView
            }}
        >
            {props.children}
        </SideMenuContext.Provider>
    )
}

export default SideMenuProvider;