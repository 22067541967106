import { Fragment, useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";

import Spinner from "../../../components/spinner/spinner";
import PageNavigator from "../pageNavigator/pageNavigator";
import ConnectionError from "../../../components/connection-error/connectionError";

import emptySearch from "../../../img/empty-search.svg";

const InventoryMovementsTable = ({
    onScroll,
    headerHeight,
    loading,
    showConnectionError,
    tryAgain,
    results,
    onClickLink,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    dropDownFilterOpen,
    dropDownMovementOpen,
    getAuthUserName,
    empty
}) => {

    const { 
        appTheme, 
        appLanguage, 
        formatRationalNumber, 
        dropDownCartOpen, 
        dropDownProfileMenuOpen, 
        isTouchEnabled, 
        getTimeInString, 
        getDateWithMonthShort, 
        getMovementIcon, 
        getMovementDescription 
    } = useContext(AppContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${ headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownFilterOpen || dropDownMovementOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownFilterOpen, dropDownMovementOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "20px";
    const widthHour = "100px";
    const widthDate = "130px";
    const widthDescription = "250px";
    const widthUser = "170px";
    const widthQuantityToDate = "160px";
    const widthValueToDate = "160px";

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light", 
                borderRadius: "10px"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: loading ? "hidden" : "auto"}}>
                {loading ? 
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                    </div> 
                : <Fragment>
                    {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                        {(!empty) ?
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        <th className={`inventory-table-select-th inventory-table-select-td-sticky`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce"}}>
                                            <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}></span> 
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthHour, maxWidth: widthHour }}>
                                            <p>{appLanguage.en ? "Hour" : "Hora"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDate, maxWidth: widthDate }}>
                                            <p>{appLanguage.en ? "Date" : "Fecha"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDescription, maxWidth: widthDescription }}>
                                            <p>{appLanguage.en ? "Description" : "Descripción"}</p>
                                        </th>
                                        
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthUser, maxWidth: widthUser }}>
                                            <p>{appLanguage.en ? "User" : "Usuario"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthQuantityToDate, maxWidth: widthQuantityToDate }}>
                                            <p>{appLanguage.en ? "Quantity to date" : "Cantidad a la fecha"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthValueToDate, maxWidth: widthValueToDate, borderTopRightRadius: "10px" }}>
                                            <p>{appLanguage.en ? "Value to date" : "Valor a la fecha"}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results ?
                                        results.map((transaccion) => {
                                            return (
                                                <tr 
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")} 
                                                    style={{cursor: "pointer"}} 
                                                    key={transaccion.id} 
                                                    onClick={() => onClickLink(transaccion)}
                                                >
                                                    <td className={`inventory-table-select-td inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck }}>
                                                        <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                            <img style={{width: "30px", height: "30px"}} src={getMovementIcon(transaccion.type)} alt="Movements" />
                                                        </span>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthHour, maxWidth: widthHour }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{getTimeInString(transaccion.time)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDate, maxWidth: widthDate }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{getDateWithMonthShort(new Date(transaccion.time))}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDescription, maxWidth: widthDescription }}>
                                                        <p
                                                            style={{
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >{getMovementDescription(transaccion.description)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthUser, maxWidth: widthUser }}>
                                                        <p 
                                                            style={{
                                                                "display": "-webkit-box",
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >
                                                            {transaccion.userId ? getAuthUserName(transaccion.userId) : <b>{appLanguage.en ? "Automatic" : "Automático"}</b>}
                                                        </p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthQuantityToDate, maxWidth: widthQuantityToDate }}>
                                                        <p style={{textAlign: "center", whiteSpace: "nowrap"}}>{transaccion.quantityToDate}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthValueToDate, maxWidth: widthValueToDate }}>
                                                        <p style={{whiteSpace: "nowrap"}} >RD$ {formatRationalNumber(transaccion.valueToDate)}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    : null}
                                </tbody>
                            </table>
                        :
                            <NoTransaccionsYet />   
                        }
                    </Fragment>}
                </Fragment>}
            </div>
            <div>
                {/** Aquí va la navegacion */}
                {loading || empty || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={null}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"5px"}
                        elemmarginBottom={"5px"}
                    />
                }
            </div>
        </div>
    )
}

const NoTransaccionsYet = () => {
    const { appLanguage } = useContext(AppContext);

    const [enTitle] = useState("There are no results");
    const [esTitle] = useState("No hay resultados");

    const [enDesc] = useState("We could not find any movement for the selected period.");
    const [esDesc] = useState("No pudimos encontrar ningun movimiento para el periodo seleccionado.");

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={ emptySearch } alt="No transaccions yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}
export default InventoryMovementsTable;