import { Fragment, useContext, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AlertContext } from "../../components/alert/alertContext";
import { SideMenuContext } from "../../context/SideMenuContext";
import { useNavigate } from "react-router-dom";

import AgreeTermsAndCond from "../../components/agreeTermsAndCond/agreeTermsAndCond";

import firebase from "../../firebase";
import { Helmet } from 'react-helmet-async';

import GoBackButton from "../../components/navigation/go-back-button/goBackButton";
import OutsideNav from "../../components/outside-nav/outsideNav";
import addDark from '../../icons/check-dark.png';
import Spinner from "../../components/spinner/spinner";

import './outside.page.scss';

const NewAccountPage = () => {

    const { appTheme, appLanguage, setupCretentials } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [ customerAgree, setCustomerAgree ] = useState(false);

    const navigate = useNavigate();

    async function createAccount(user) {
        setLoading(true);

        try {
            await firebase.useIsAuthorized(user.email.trim());
            await firebase.useCreateUserWithEmailAndPassword(user);

            // security security
            setupCretentials();

            setLoading(false);
            navigate('/auth/verificar-correo', { replace: true });

        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);

            if(code === 'auth/email-already-in-use') {
                setUserIdAlert({
                    alert: true,
                    enText: '* Email already in use',
                    esText: '* Correo ya en uso'
                });
            }
            setNuevoUsuario({
                ...nuevoUsuario,
                password : "",
                repeatPassword : ""
            })
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to create profile',
                    es : 'Error al intentar crear perfil',
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const [ loading, setLoading ] = useState(false);

    const [ nuevoUsuario, setNuevoUsuario ] = useState({
        userName : "",
        userPhone : "",
        userID : "",
        password : "",
        repeatPassword : ""
    });

    const [ phone, setPhone ] = useState("");

    const [ userNameAlert, setUserNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ userPhoneAlert, setUserPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ userIdAlert, setUserIdAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ passwordAlert , setPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ repeatPasswordAlert , setRepeatPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });
    
    const onChange = e => {
        if(e.target.name === "userName") {
            setUserNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if(e.target.name === "userPhone") {
                setUserPhoneAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            } else {
                if(e.target.name === "userID") {
                    setUserIdAlert({
                        alert: false,
                        enText: '',
                        esText: ''
                    });
                } else {
                    if(e.target.name === "password") {
                        setPasswordAlert({
                            alert: false,
                            enText: '',
                            esText: ''
                        });
                    } else {
                        if(e.target.name === "repeatPassword") {
                            setRepeatPasswordAlert({
                                alert: false,
                                enText: '',
                                esText: ''
                            });
                        }
                    }
                }
            }
        }

        if (e.target.name === "userPhone") {
        
            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i]; 
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            setPhone(phoneFormatted);
        } else {
            setNuevoUsuario({
                ...nuevoUsuario,
                [e.target.name] : e.target.value
            })
        }
    }

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNuevoUsuario({
                ...nuevoUsuario,
                [e.target.name]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNuevoUsuario({
                    ...nuevoUsuario,
                    [e.target.name]: "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
            
        }
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const  validar = e => {
        e.preventDefault();

        if(nuevoUsuario.userName.trim() === "") {
            setUserNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if(phone.trim() === "") {
                setUserPhoneAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            } else {
                if(!validateEmail(nuevoUsuario.userID.trim())) {
                    setUserIdAlert({
                        alert: true,
                        enText: '* Email is not valid',
                        esText: '* El correo no es válido'
                    });
                    return;
                    
                } else {
                    if (nuevoUsuario.userID.trim()=== "") {
                        setUserIdAlert({
                            alert: true,
                            enText: '* Required field',
                            esText: '* Campo requerido'
                        });
                        return;
                    } else {
                        if (nuevoUsuario.password.trim()=== "") {
                            setPasswordAlert({
                                alert: true,
                                enText: '* Required field',
                                esText: '* Campo requerido'
                            });
                            return;
        
                        } else {
                            if(nuevoUsuario.password.length < 8) {
                                setPasswordAlert({
                                    alert: true,
                                    enText: '* Password must be at least 8 characters',
                                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                                });
                                return;
                            } else {
                                if (nuevoUsuario.repeatPassword.trim()=== "") { //
                                    setRepeatPasswordAlert({
                                        alert: true,
                                        enText: '* Required field',
                                        esText: '* Campo requerido'
                                    });
                                    return;
                                } else {
                                    if(nuevoUsuario.repeatPassword.length < 8) {
                                        setRepeatPasswordAlert({
                                            alert: true,
                                            enText: '* Password must be at least 8 characters',
                                            esText: '* La contraseña debe ser de al menos 8 caracteres'
                                        });
                                        return;
                                    } else {
                                        if (nuevoUsuario.repeatPassword.trim() !== nuevoUsuario.password) {
                                            setRepeatPasswordAlert({
                                                alert: true,
                                                enText: '* Both passwords must be the same',
                                                esText: '* Ambas contraseñas deben ser iguales'
                                            });
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const user = {
            displayName: nuevoUsuario.userName,
            displayNameInsensitive: nuevoUsuario.userName.toLocaleLowerCase(),
            phoneNumber: nuevoUsuario.userPhone,
            email: nuevoUsuario.userID.toLocaleLowerCase(),
            password: nuevoUsuario.password,
            agreeWithTermsAndConditions: customerAgree
        }

        createAccount(user);
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Create profile' : 'Crear perfil'} </title>
            </Helmet>
            <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} >
                <div className="outside-nav-space" />
                <OutsideNav />
                {loading ? 
                    <div style={{marginTop: "80px"}}><Spinner/></div>
                : <Fragment>
                    <div 
                        className="login-page-card-container"
                        style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                    >
                        <div className={`${desktopView ? 'login-page-card-mobile-desktop' : 'login-page-card-mobile-view'}`}>
                            <div className="login-page-card-title">
                                <GoBackButton/>
                                <h3>{appLanguage.en ? 'Create profile' : 'Crear perfil'}</h3>
                            </div>
                            <form className={`${desktopView ? 'login-page-form-desktop' : 'login-page-form-mobile' }`} >
                                <div className={`${desktopView ? 'form-desktop-first-section ' : 'form-mobile-first-section' }`}>
                                    
                                    <label 
                                        className="input-description" 
                                        htmlFor="userName"
                                    >{appLanguage.en ? 'Name: ' : 'Nombre: '} <b>{userNameAlert.alert ? (
                                        appLanguage.en ? userNameAlert.enText : userNameAlert.esText
                                    ) : "*"}</b></label>
                                    <input 
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                        className={`input-content ${userNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="text" 
                                        name="userName" 
                                        id="userName"
                                        maxLength="22"
                                        value={nuevoUsuario.userName}
                                        placeholder={appLanguage.en ? 'Type name...' : 'Escribir nombre...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <label 
                                        className="input-description" 
                                        htmlFor="userPhone"
                                    >{appLanguage.en ? 'Phone Number: ' : 'Número de teléfono: '} <b>{userPhoneAlert.alert ? (
                                        appLanguage.en ? userPhoneAlert.enText : userPhoneAlert.esText
                                    ) : "*"}</b></label>
                                    <input 
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                        className={`input-content ${userPhoneAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="text" 
                                        name="userPhone" 
                                        id="userPhone"
                                        value={phone}
                                        placeholder={appLanguage.en ? 'Type number...' : 'Escribir número...'}
                                        onChange={ e => onChange(e)}
                                        inputMode="numeric"
                                        maxLength={20}
                                        onBlur={handleInputBlur}
                                    />

                                            
                                    <label 
                                        className="input-description" 
                                        htmlFor="userID"
                                    >{appLanguage.en ? 'Email address: ' : 'Correo electrónico: '} <b>{userIdAlert.alert ? (
                                        appLanguage.en ? userIdAlert.enText : userIdAlert.esText
                                    ) : "*"}</b></label>
                                    <input 
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                        className={`input-content ${userIdAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="email" 
                                        name="userID" 
                                        id="userID" 
                                        value={nuevoUsuario.userID}
                                        placeholder={appLanguage.en ? 'Type email...' : 'Escribir correo...'}
                                        onChange={ e => onChange(e)}
                                    />
                                    
                                </div>

                                <div style={{paddingBottom: "40px"}} className={`${desktopView ? 'form-desktop-second-section' : 'form-mobile-second-section'}`}>
                                    
                                    <label 
                                        className="input-description" 
                                        htmlFor="userID"
                                    >{appLanguage.en ? 'Password: ' : 'Contraseña: '} <b>{passwordAlert.alert ? (
                                        appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                                    ) : "*"}</b></label>
                                    <input 
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                        className={`input-content ${passwordAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="password" 
                                        name="password"
                                        value={nuevoUsuario.password}
                                        placeholder={appLanguage.en ? 'Type password...' : 'Escribir contraseña...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <label 
                                        className="input-description" 
                                        htmlFor="userID"
                                    >{appLanguage.en ? 'Repeat password: ' : 'Repite la contraseña: '} <b>{repeatPasswordAlert.alert ? (
                                        appLanguage.en ? repeatPasswordAlert.enText : repeatPasswordAlert.esText
                                    ) : "*"}</b></label>
                                    <input
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}} 
                                        className={`input-content ${repeatPasswordAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="password" 
                                        name="repeatPassword" 
                                        value={nuevoUsuario.repeatPassword}
                                        placeholder={appLanguage.en ? 'Type password...' : 'Escribir contraseña...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <AgreeTermsAndCond customerAgree={customerAgree} setCustomerAgree={setCustomerAgree}/>

                                    <button 
                                        disabled={!customerAgree}
                                        className={`${customerAgree ? "outside-button-container-agree" : "button-disable-bc-cust-did-not-agree-terms-cond"}`}
                                        onClick={validar}
                                        type="submit"
                                    >
                                        <img 
                                            src={addDark}
                                            alt="Login button"
                                            className='outside-button-container-img-agree'
                                        />   
                                        <p className='outside-button-container-text-agree'>{appLanguage.en ? 'Create' : 'Crear'}</p> 
                                    </button>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>}
            </div>
        </Fragment>
    )
}

export default NewAccountPage;