import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import saleIconDark from "../../../icons/dollar-sign-dark.png";
import moreIconDark from "../../../icons/more-dark.png";

const SupplierViewHeaderMain = ({record, headerObservedRef}) => {
    const { appLanguage, appTheme, formatPhoneNumber } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
  
    const navigate = useNavigate();
    const location = useLocation();

    const copyAccountNumber = () => {
        const text = record.id;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The record number has been copied',
                    es : 'El número de registro ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyEmail = () => {
        const text = record.email;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The email has been copied',
                    es : 'El correo ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyPhoneNumber = () => {
        const text = record.phone;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The phone number has been copied',
                    es : 'El número de teléfono ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyName = () => {
        const text = record.name;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The name has been copied',
                    es : 'El nombre ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const onClickMoreDetails = () => {
        if(location.state) {
            navigate("/proveedor-mas-detalles", {state: location.state})
        }
    }

    return (
        <div 
            ref={headerObservedRef} 
            className="customer-view-main-header-main-container"
        >
            <div 
                className={`customer-view-main-header-main-background ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
            > 
                <div className={`customer-view-main-header-main`}>
                    <div 
                        className="customer-view-main-header-sections customer-view-main-header-sections-no-white-space"
                    >
                        <div className="customer-view-main-header-name-div">
                            <p style={{padding: "3px 8px"}}><b>{appLanguage.en ? "Supplier: " : "Proveedor: "}</b><span onClick={copyName} className="customer-view-main-header-data-p-hover">{record.name}</span></p>
                        </div>
                        <div className="customer-view-main-header-name-div">
                            <p style={{padding: "3px 8px"}}><b>{appLanguage.en ? "Record" : "Registro"} #</b> <span onClick={copyAccountNumber} className="customer-view-main-header-data-p-hover">{record.id}</span></p>
                        </div>
                    </div>
                    <div 
                        className="customer-view-main-header-section-no-over-flow"
                    >
                        {record.email.trim() === "" ? <p className="customer-view-main-header-data-p">{appLanguage.en ? "(no email)" : "(sin correo)"}</p> : <p onClick={copyEmail} className="customer-view-main-header-data-p-hover">{record.email}</p>}
                        {record.phone.trim() === "" ? <p className="customer-view-main-header-data-p">{appLanguage.en ? "(no phone)" : "(sin teléfono)"}</p> : <p onClick={copyPhoneNumber} className="customer-view-main-header-data-p-hover">{formatPhoneNumber(record.phone)}</p>}
                    </div>
                    <div className="customer-view-page-main-buttons-sect">
                        <button onClick={onClickMoreDetails} className="customer-view-page-main-button-elem">
                            <img src={moreIconDark} alt="More options" />
                            <p>{appLanguage.en ? "More" : "Más"}</p>
                        </button>
                        <button className="customer-view-page-main-button-elem">
                            <img src={saleIconDark} alt="Process transation" />
                            <p>{appLanguage.en ? "Buy" : "Comprar"}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierViewHeaderMain;