import { useContext, useEffect, useState, useRef, Fragment } from "react";
import "./salesTable.scss"
import { AppContext } from "../../../context/AppContext";

import emptyBox from "../../../img/empty-box.svg";
import emptySearch from "../../../img/empty-search.svg";

import { SideMenuContext } from "../../../context/SideMenuContext";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import { Link } from "react-router-dom";
import PageNavigator from "../pageNavigator/pageNavigator";
import { uid } from "uid/single";

const SalesTable = ({
    sales, 
    salesStatistics, 
    loading, 
    showConnectionError,
    headerHeight,
    pageNumber,
    navControlers,
    onChangePageNumber,
    showPageNav,
    onClickNext,
    onClickPrevious,
    tryAgain,
    filter,
    activeId,
    setActiveReference,
    onClickLink,
    onScroll
}) => {

    const { appTheme, appLanguage, formatRationalNumber, getTimeInString, getDateWithMonthShort } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const [ windowSize, setWindowSize ] = useState(null);

    const detectSize = () => {
        if(width > 730) {
            setWindowSize(3);
        } else {
            if(width > 530) {
                setWindowSize(2);
            } else {
                setWindowSize(1);
            }
        }
    }

    const [width, setWidth] = useState();

    useEffect(() => {
        detectSize();
    // eslint-disable-next-line 
    }, [width])

    const observedDiv = useRef(null);

    useEffect(() => {
        if (observedDiv) {
            if (!observedDiv.current) {
                return;
            }
        
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if(observedDiv.current.offsetWidth !== width) {
                        setWidth(observedDiv.current.offsetWidth); 
                    }
                } catch {}
            });
            
            resizeObserver.observe(observedDiv.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
    // eslint-disable-next-line 
    }, [observedDiv.current]);

    const borderDark = "1px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "1px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const getRefWidth = () => {
        if (windowSize === 3) {
            return "15%";
        } else {
            if (windowSize === 2) {
                return "20%";
            } else {
                return "30%";
            }
        }
    }

    const getAmountWidth = () => {
        if (windowSize === 3) {
            return "20%";
        } else {
            if (windowSize === 2) {
                return "26.66%";
            } else {
                return "40%";
            }
        }
    }

    const getTimeWidth = () => {
        if (windowSize === 3) {
            return "20%";
        } else {
            if (windowSize === 2) {
                return "26.66%";
            } else {
                return 0;
            }
        }
    }

    const getDateWidth = () => {
        if (windowSize === 3) {
            return "20%";
        } else {
            if (windowSize === 2) {
                return "26.66%";
            } else {
                return "30%";
            }
        }
    }
    
    const getArticlesWidth = () => {
        if (windowSize === 3) {
            return "35%";
        } else {
            if (windowSize === 2) {
                return 0;
            } else {
                return 0;
            }
        }
    }

    const [ refTitleStyle, setRefTitleStyle ] = useState({
        borderBottom: border2Dark,
        width: getRefWidth(),
        borderTopLeftRadius: "8px"
    });

    const [ amountTitleStyle, setAmountTitleStyle ] = useState({
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getAmountWidth(),
    });

    const [ timeTitleStyle, setTimeTitleStyle ] = useState({
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getTimeWidth(),
    });

    const [ dateTitleStyle, setDateTitleStyle ] = useState({
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getDateWidth(),
    });

    const [ articlesTitleStyle, setArticlesTitleStyle ] = useState({
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getArticlesWidth(),
    });

    const [ refSalStyle, setRefSalStyle ] = useState(appTheme.dark ? {
        borderBottom: border2Dark,
        width: getRefWidth()
    } : {
        borderBottom: border2Light,
        width: getRefWidth()
    });

    const [ amountSalStyle, setAmountSalStyle ] = useState(appTheme.dark ? {
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getAmountWidth()
    } : {
        borderBottom: border2Light,
        borderLeft: border2Light,
        width: getAmountWidth()
    });

    const [ timeSalStyle, setTimeSalStyle ] = useState(appTheme.dark ? {
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getDateWidth(),
    } : {
        borderBottom: border2Light,
        borderLeft: border2Light,
        width: getDateWidth(),
    });

    const [ dateSalStyle, setDateSalStyle ] = useState(appTheme.dark ? {
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getDateWidth(),
    } : {
        borderBottom: border2Light,
        borderLeft: border2Light,
        width: getDateWidth(),
    });

    const [ articlesSalStyle, setArticlesSalStyle ] = useState(appTheme.dark ? {
        borderBottom: border2Dark,
        borderLeft: border2Dark,
        width: getArticlesWidth(),
    } : {
        borderBottom: border2Light,
        borderLeft: border2Light,
        width: getArticlesWidth(),
    });

    useEffect(() => {
        setRefTitleStyle({
            borderBottom: border2Dark,
            width: getRefWidth(),
            borderTopLeftRadius: "8px"
        });

        setAmountTitleStyle({
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getAmountWidth(),
        });

        setTimeTitleStyle({
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getTimeWidth(),
        });

        setDateTitleStyle({
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getDateWidth(),
        });

        setArticlesTitleStyle({
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getArticlesWidth(),
        });

        setRefSalStyle(appTheme.dark ? {
            borderBottom: border2Dark,
            width: getRefWidth()
        } : {
            borderBottom: border2Light,
            width: getRefWidth()
        });
    
        setAmountSalStyle(appTheme.dark ? {
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getAmountWidth()
        } : {
            borderBottom: border2Light,
            borderLeft: border2Light,
            width: getAmountWidth()
        });

        setTimeSalStyle(appTheme.dark ? {
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getTimeWidth(),
        } : {
            borderBottom: border2Light,
            borderLeft: border2Light,
            width: getTimeWidth(),
        });
    
        setDateSalStyle(appTheme.dark ? {
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getDateWidth(),
        } : {
            borderBottom: border2Light,
            borderLeft: border2Light,
            width: getDateWidth(),
        });
    
        setArticlesSalStyle(appTheme.dark ? {
            borderBottom: border2Dark,
            borderLeft: border2Dark,
            width: getArticlesWidth(),
        } : {
            borderBottom: border2Light,
            borderLeft: border2Light,
            width: getArticlesWidth(),
        });
    // eslint-disable-next-line 
    }, [windowSize, appTheme]);

    const [ tableStyle, setTableStyle ] = useState(appTheme.dark ? {border: borderDark} : {border: borderLight});

    const [ productContainerStyle, setProductContainerStyle ] = useState({
        maxHeight: desktopView ? `calc(100vh - ${headerHeight + 206}px)` : `calc(100vh - ${headerHeight + 167}px)`,
        height: desktopView ? `calc(100vh - ${headerHeight + 206}px)` : `calc(100vh - ${headerHeight + 167}px)`,
        colorScheme: appTheme.dark ?  "dark" : "light",
    });

    const [ productItemsStyle, setProductItemsStyle ] = useState({
        minHeight: desktopView ? "calc(100vh - 334px)" : "calc(100vh - 385px)",
    });

    useEffect(() => {
        setTableStyle({
            border: appTheme.dark ? borderDark : borderLight,
        });
        setProductContainerStyle({
            maxHeight: desktopView ? `calc(100vh - ${headerHeight + 206}px)` : `calc(100vh - ${headerHeight + 167}px)`,
            height: desktopView ? `calc(100vh - ${headerHeight + 206}px)` : `calc(100vh - ${headerHeight + 167}px)`,
            colorScheme: appTheme.dark ?  "dark" : "light",
        });

        setProductItemsStyle({
            minHeight: desktopView ? "calc(100vh - 367px)" : "calc(100vh - 385px)",
        })
    }, [appTheme, desktopView, headerHeight]);

    const empty2 = sales ? (sales.length > 0 ? false : true) : true;

    return (
        <div ref={observedDiv} className={`sales-table-container ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} style={tableStyle}>
            <div className="sales-table-title-section unselectable">
                <div className="sales-table-title-item" style={refTitleStyle}>
                    <p>{appLanguage.en ? "Ref." : "Ref."}</p>
                </div>

                <div className="sales-table-title-item" style={amountTitleStyle} onClick={null}>
                    <p>{appLanguage.en ? "Amount" : "Monto"}</p>
                </div>

                {windowSize >= 2 ?
                    <div className="sales-table-title-item" style={timeTitleStyle} onClick={null}>
                        <p>{appLanguage.en ? "Time" : "Hora"}</p>
                    </div>
                : null}

                <div className="sales-table-title-item" style={dateTitleStyle} onClick={null}>
                    <p>{appLanguage.en ? "Date" : "Fecha"}</p>
                </div>

                {windowSize === 3 ?
                    <div className="sales-table-title-item" style={articlesTitleStyle}>
                        <p>{appLanguage.en ? "Items" : "Artículos"}</p>
                    </div>
                : null}

                <div className="sales-table-title-item" style={{borderTopRightRadius: "8px", width: "0.5em"}} />
            </div>

            <div onScroll={onScroll} className="sales-table-product-section-container" style={productContainerStyle}>
                <div style={productItemsStyle}>
                    {loading ? <div style={{marginTop: "50px"}}><Spinner/></div> : <Fragment>
                        {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                            {(!empty2) ? 
                                <Fragment>
                                    {sales ? <Fragment>
                                        {sales.map((sale) => {
                                            return (
                                                <Link 
                                                    key={sale.id} 
                                                    ref={sale.id === activeId ? setActiveReference : null}
                                                    onClick={() => onClickLink(sale)}
                                                    to={"/ver-detalles-de-venta"}
                                                    state={sale}
                                                    className="sales-table-product-section"  
                                                    style={appTheme.dark ? {color: "white"} : {color: "black"}}
                                                >
                                                    <div className="sales-table-product-item" style={refSalStyle} title={sale.id}> 
                                                        <div className="sales-table-type-and-id-div">
                                                            <p className="prod-tab-id-p">
                                                                {sale.id}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="sales-table-product-item" style={amountSalStyle} title={`RD$${sale.amount.toFixed(2)}`}>
                                                        <p>RD$ {formatRationalNumber(sale.amount)}</p>
                                                    </div>

                                                    {windowSize >= 2 ?
                                                        <div className="sales-table-product-item" style={timeSalStyle}>
                                                            <p>{getTimeInString(sale.date)}</p>
                                                        </div>
                                                    : null}
                                                    
                                                    <div className="sales-table-product-item" style={dateSalStyle}>
                                                        <p>{getDateWithMonthShort(new Date(sale.date))}</p>
                                                    </div>
                                                    
                                                    {windowSize === 3 ?
                                                        <div className="sales-table-product-item" style={articlesSalStyle}>
                                                            {sale.items.map((item) => {
                                                                return (
                                                                    <p key={uid()} >(<b>{item.quantity}:</b> {item.name})</p>
                                                                )
                                                            })}
                                                        </div>
                                                    : null}
                                                    
                                                </Link>
                                            );
                                        })}
                                    </Fragment> : null}
                                </Fragment> 
                            : 
                                <Fragment>
                                    {salesStatistics ? <Fragment>
                                        <NoSalessYet filter={filter} salesStatistics={salesStatistics} />
                                    </Fragment> : null}
                                </Fragment>
                            }
                        </Fragment>} 
                    </Fragment>}
                </div>

                {/** Aquí va la navegacion */}
                {loading || empty2 || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={onChangePageNumber}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"13px"}
                        elemmarginBottom={"8px"}
                    />
                }
            </div>
        </div>
    )
}

const NoSalessYet = ({filter, salesStatistics}) => {
    const { appLanguage } = useContext(AppContext);
    const [ noSales, setNoSales ] = useState(salesStatistics.all > 0 ? false : true);

    useEffect(() => {
        setNoSales(salesStatistics.all > 0 ? false : true);
    }, [filter, salesStatistics]);

    const [ enTitle, setEnTitle ] = useState("There are no sales yet");
    const [ esTitle, setEsTitle ] = useState("Aún no hay ventas");

    const [ enDesc, setEnDesc ] = useState("When you have completed a sale, it will be displayed in this section.");
    const [ esDesc, setEsDesc ] = useState("Cuando hayas completado alguna venta, se mostrará en esta sección.");

    useEffect(() => {
        if (noSales) {
            setEnTitle("There are no sales yet");
            setEsTitle("Aún no hay ventas");
            setEnDesc("When you have completed a sale, it will be displayed in this section.");
            setEsDesc("Cuando hayas completado alguna venta, se mostrará en esta sección.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by sale reference number, code of the products sold or record number of the customer to whom the sale was made.');
            setEsDesc('Puedes buscar por número de referencia de la venta, código de los productos vendidos o número de registro del cliente a quien se le realizó la venta.');
        }
    }, [noSales])

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noSales ? emptyBox : emptySearch } alt="No customers yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}

export default SalesTable;