import { useContext, Fragment, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { SideMenuContext } from '../../context/SideMenuContext';

import SoftAlert from '../soft-alert/softAlert';
import SoftLoading from '../soft-loading/softLoading';

import './navigation.scss';

import GoBackButton from './go-back-button/goBackButton';
import PageName from './page-name/pageName';
import MenuBurger from './menu-burger/menuBurger';
import QuickSearch from './quick-search/quickSearch';
import UserMenu from './user-menu/userMenu';
import MainOptionsDesktop from './main-options/mainOptionsDesktop';
import MainOptionsMobile from './main-options/mainOptionsMobile';
import RefreshButton from './go-back-button/refreshButton';
import CartBtn from './cart-btn/cartBtn';

const Navigation = () => {

    const { appTheme, showCartNumber, setShowCartNumber } = useContext(AppContext);
    const { desktopView, optimizeMobileView } = useContext(SideMenuContext);
    const { accountData, shoppingCart } = useContext(AuthContext);

    useEffect(() => {
        if (shoppingCart) {
            if (shoppingCart.items.length > 0) {
                setShowCartNumber(true);
            } else {
                setShowCartNumber(false);
            }
        } else {
            setShowCartNumber(false);
        }
    // eslint-disable-next-line
    }, [shoppingCart]);

    return (
        <nav className={`navigation-container unselectable ${appTheme.dark ? 'dar-pri-bg dar-box-sha-keep-hover' : 'lig-pri-bg lig-box-sha-keep-hover'}`} >
            {optimizeMobileView ? <Fragment>
                <GoBackButton />
                <RefreshButton />
                {desktopView ? null : <PageName />}
            </Fragment> : <Fragment>
                {desktopView ? <Fragment><GoBackButton /><RefreshButton /></Fragment> : <Fragment>
                    <Fragment>
                        <MenuBurger />
                        <RefreshButton />
                    </Fragment>
                    <MainOptionsMobile />
                </Fragment>}
            </Fragment>}

            {desktopView ? <Fragment>
                <div className='navigation-container-space' />
                <MainOptionsDesktop />
            </Fragment> : null}

            <div className='navigation-container-space' />

            {desktopView ? <Fragment>
                <CartBtn />
                {accountData ? (accountData.active ? <QuickSearch /> : null) : null}
                <UserMenu />
            </Fragment> : (
                optimizeMobileView ? 
                    (showCartNumber ? <CartBtn optimizedForMobile={true} /> : null)
                : <Fragment>
                    <CartBtn />
                    {accountData ? (accountData.active ? <QuickSearch /> : null) : null}
                    <UserMenu />
                </Fragment>
            )}
            <SoftAlert />
            <SoftLoading />
        </nav>
    )
}

export default Navigation;