import { useContext, Fragment, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import './sideMenuNotificationButton.scss';

const SideMenuNotificationButton = ({ imgIcon, description, onClickButton, goTo }) => {

    const { appTheme, isInsideFatherPath } = useContext(AppContext);
    const { setSideMenuOpen, setLockScreen } = useContext(SideMenuContext);
    const { userData } = useContext(AuthContext);

    const location = useLocation();

    const handlerClick = () => {
        onClickButton();
        setSideMenuOpen(false);
        setLockScreen(false);
    }

    const theCurrentPath = () => {
        if (isInsideFatherPath(goTo)) {
            return true;
        } else {
            return false;
        }
    }

    const [isCurrentPath, setIsCurrentPath] = useState(theCurrentPath());

    useEffect(() => {
        setIsCurrentPath(theCurrentPath());
        // eslint-disable-next-line
    }, [location]);

    return (
        <Fragment>
            <Link
                onClick={handlerClick}
                className={`side-menu-notification-button-container ${isCurrentPath ? `${appTheme.dark ? 'side-menu-notification-button-dark-focus' : 'side-menu-notification-button-light-focus'}` : `${appTheme.dark ? 'side-menu-notification-button-dark' : 'side-menu-notification-button-light'}`}`}
                to={goTo}
            >
                <img className='side-menu-notification-icon' src={imgIcon} alt='little logo' />
                <p className='side-menu-notification-description'>{description}</p>
                <div className='navigation-container-space' />

                {userData ?
                    userData.notificationCounter ?
                        <div className="side-menu-notification-counter-div"><span>{userData.notificationCounter}</span></div>
                    : null
                : null}
            </Link>
        </Fragment>
    )
}

export default SideMenuNotificationButton;