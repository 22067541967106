import { useContext } from 'react';
import { AppContext } from "../../../context/AppContext";
import { AlertContext } from '../../alert/alertContext';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../firebase'

import './do-not-verify-email.scss';

import cancelIconDark from '../../../icons/cross-white.png';
import cancelIconLight from '../../../icons/cross.png';

const DoNotCompData = () => {

    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);

    const { appTheme } = useContext(AppContext);
    const navigate = useNavigate();

    async function cancelVerifitacion() {
        try {
            await firebase.useSignOut();
            navigate('/auth/iniciar-sesion', { replace: true });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
        }
        setAlertActive(false);
    }

    const doNotVerify = () => {
        setAlertData({
            type : 'question',
            title: {
                en : 'Do not complete data',
                es : 'No completar datos'
            },
            code : 'confirm-log-cancel-verification',
            description : {
                en : "Do you want to leave your profile data incomplete? You must provide these details to continue.",
                es : '¿Quieres dejar los datos de tu perfil incompletos? Debe proporcionar estos datos para continuar.'
            }
        });
        setOnAgree(() => cancelVerifitacion);
        setAlertActive(true);
    }

    return (
        <button 
            className={`do-not-verify-email-button ${appTheme.dark ? "dar-sec-bg dar-box-sha-none-hover"  : 'lig-sec-bg lig-box-sha-none-hover '}`}
            onClick={doNotVerify}
        >
            <img 
                src={appTheme.dark ? cancelIconDark : cancelIconLight} 
                alt="menuBurger-img" 
            />
        </button>
    )
}

export default DoNotCompData;