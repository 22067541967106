import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useLocation } from "react-router-dom";

import "./quickSearch.scss";

import serchIconDark from '../../../icons/search-dark.png';
import serchIconLight from '../../../icons/search-light.png';

const QuickSearch = () => {

    const { appTheme, appPathSelected } = useContext(AppContext);

    const [ searchIconDark, setSearchIconDark ] = useState(serchIconDark);
    const [ searchIconLight, setSearchIconLight ] = useState(serchIconLight);

    useEffect(() => {
        if(appPathSelected.search) {
            setSearchIconDark(serchIconDark);// Focus
            setSearchIconLight(serchIconDark);// Focus
        } else {
            setSearchIconDark(serchIconDark);
            setSearchIconLight(serchIconLight);
        }
    }, [appPathSelected]);

    const location = useLocation();

    return (
        <Fragment>
            {location.pathname === '/buscador' ? 
                <div 
                    className={`nav-container-quick-search-button ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appPathSelected.search ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                    style={{cursor: "auto"}}
                >
                    <div className={`nav-container-quick-search-button-item`}>
                        <img
                            className={`icon-navigation`}
                            src={appTheme.dark ? searchIconDark : searchIconLight}
                            alt="bell-img"
                        />
                    </div>
                </div>
            : 
                <Link 
                    to={"/buscador"}
                    className={`nav-container-quick-search-button ${appTheme.dark ? "border-box-dark" : "border-box-light" } ${appPathSelected.search ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                >
                    <div className={`nav-container-quick-search-button-item`}>
                        <img
                            className={`icon-navigation`}
                            src={appTheme.dark ? searchIconDark : searchIconLight}
                            alt="bell-img"
                        />
                    </div>
                </Link>
            }
        </Fragment>
        
    )
}

export default QuickSearch;