import { useContext } from 'react';
import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { AppContext } from '../../context/AppContext';
import { SideMenuContext } from '../../context/SideMenuContext';

import Alert from '../../components/alert/alert';

import './outside.page.scss';

const OutSidePage = () => {

    const { appTheme, themeColor, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    return (
        <HelmetProvider>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <meta name="theme-color" content={`${themeColor}`} />
            </Helmet>
            <div className={`${appTheme.dark ? "dark-theme" : "light-theme"} App ${desktopView ? null : "unselectable" }`} >
                <div className='app-container'>
                    <Alert />
                    <Outlet />
                </div>
            </div>
        </HelmetProvider>
    );
}

export default OutSidePage;