import { Fragment, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsOption from '../../../components/settings-options/settingsOption';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';

import AuthorizedUsersList from '../../../components/authorized-users-list/authorizedUsersList';

import businessIcon from '../../../img/analysis.svg';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import giveKeyIconDark from '../../../icons/give-key-dark.png';
import giveKeyIconLight from '../../../icons/give-key-light.png';

import SettingsActionRequired from '../../../components/settings-action-required/settingsActionRequired';

import '../businessAccount.page.scss';

const AuthorizedUsersPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Authorized users' : 'Usuarios autorizados' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? 'Authorized users' : 'Usuarios autorizados'}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : ""}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {((adminAccess || managerAccess) && accountData) ?  <Fragment>
                                {accountData.active ? <Fragment> 
                                <SettingsOption
                                    description={appLanguage.en ? "Authorize access" : "Autorizar acceso"}
                                    icon={appTheme.dark ? giveKeyIconDark : giveKeyIconLight }
                                    goTo={"/autorizar-acceso"}
                                />
                                <AuthorizedUsersList/>
                                </Fragment> : 
                                    <SettingsActionRequired />
                                }
                            </Fragment> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AuthorizedUsersPage;