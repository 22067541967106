import { Fragment } from "react";

import './lockScreenTrans.scss';

const LockScreenTrans = ({lockScreen})  => {
    return (
        <Fragment>
            {lockScreen ? <div className='lockScreenContainer' /> : null}
        </Fragment>
    )
}

export default LockScreenTrans;