import { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../context/AppContext';

import './sideMenuDesktop.scss';
import SideMenuButton from '../buttons/side-menu-button/sideMenuButton';
import SideMenuNotificationButton from '../buttons/side-menu-notification-button/sideMenuNotificationButton';

import chooseIcon from '../../img/choose.svg';
import businessIcon from '../../img/analysis.svg';
import bellIcon from '../../img/bell.svg';
import moreOptIcon from '../../img/more-options.svg';
import themeIcon from '../../img/moon.svg';

import arrowIconDark from '../../icons/down-arrow-dark.png';
import arrowIconLight from '../../icons/down-arrow-light.png';
import { SideMenuContext } from '../../context/SideMenuContext';

const SideMenuDesktop = () => {

    const { appLanguage, appTheme, setAppTheme } = useContext(AppContext);
    const [ isThemeOpen, setIsThemeOpen ] = useState(false);
    const { isSideMenuMinimized, setIsSideMenuMinimized } = useContext(SideMenuContext);

    // To use with useEffect()
    let menuRef = useRef();
    
    // Data to control theme
    let formStateTemp ={};
    const getFormThemeState = () => {
        formStateTemp = {
            asInTheSystme : appTheme.asInTheSystem,
            dark: appTheme.asInTheSystem ? false : appTheme.dark,
            light : appTheme.asInTheSystem ? false : (appTheme.dark ? false : true)
        }
        return formStateTemp;
    }
    const [ themeformState, setThemeFormState ] = useState(getFormThemeState());

    useEffect(() => {
        setThemeFormState(getFormThemeState());
    // eslint-disable-next-line
    }, [appTheme]);

    const handleChangeTheme = event => {
        if(event.target.value === "light") {
            formStateTemp.asInTheSystme = false;
            formStateTemp.light = true;
            formStateTemp.dark = false;
        } else {
            if(event.target.value === "dark") {
                formStateTemp.asInTheSystme = false;
                formStateTemp.light = false;
                formStateTemp.dark = true;
            } else {
                formStateTemp.asInTheSystme = true;
                formStateTemp.light = false;
                formStateTemp.dark = false;
            }
        }

        setThemeFormState(formStateTemp);

        if(formStateTemp.asInTheSystme === true) {
            let firstTheme = {
                dark: false,
                asInTheSystem: true
            }
            if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                firstTheme.dark = true;
            } else {
                firstTheme.dark = false;
            }
            localStorage.setItem("theme", JSON.stringify(firstTheme));
            setAppTheme(firstTheme);
        } else {
            localStorage.setItem("theme", JSON.stringify({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            }));
            setAppTheme({
                dark: formStateTemp.dark,
                asInTheSystem: formStateTemp.asInTheSystme
            })
        }
    }

    const onClickTheme = () => {
        setIsThemeOpen(!isThemeOpen);
    }

    useEffect(() => {
        if(isThemeOpen) {
            setIsSideMenuMinimized(false);
            localStorage.setItem("isSideMenuMinimized", JSON.stringify(false));
        }
    // eslint-disable-next-line
    }, [isThemeOpen])

    const closeOptions = () => {
        setIsThemeOpen(false);
    }

    useEffect(() => {
        let handler = (e) => {
            if(!menuRef.current.contains(e.target)) {
                closeOptions();
            };
        };
        document.addEventListener("mousedown", handler);
        return() => {
            document.removeEventListener("mousedown", handler);
        }
    });

    const handleChangeMenuSize = () => {
        setIsSideMenuMinimized(!isSideMenuMinimized);
        setIsThemeOpen(false);
        localStorage.setItem("isSideMenuMinimized", JSON.stringify(!isSideMenuMinimized));
    }

    return (
        <div ref={menuRef} className={`side-menu-desktop-container ${appTheme.dark ? 'dar-sec-bg dark-scroollbar' : 'lig-sec-bg lig-scroollbar'} ${isSideMenuMinimized ? "minimized" : "maximized"}`}>
            <ul className="side-menu-options-desktop">
                
                <li className="side-menu-item-desktop">
                    <SideMenuButton 
                        description={isSideMenuMinimized ? null : (appLanguage.en ? 'Business account' : 'Cuenta de negocios')}
                        imgIcon={businessIcon}
                        onClickButton={closeOptions}
                        goTo={"/cuenta-de-negocios"}
                    />
                </li>
                <li className="side-menu-item-desktop">
                    <SideMenuButton 
                        description={isSideMenuMinimized ? null : (appLanguage.en ? 'Choose account' : 'Elegir cuenta')}
                        imgIcon={chooseIcon}
                        onClickButton={closeOptions}
                        goTo={"/elegir-cuenta"}
                    />
                </li>
                <li className="side-menu-item-desktop">
                    <SideMenuNotificationButton 
                        description={isSideMenuMinimized ? null : (appLanguage.en ? 'Notifications' : 'Notificaciones')}
                        imgIcon={bellIcon}
                        onClickButton={closeOptions}
                        goTo={"/notificaciones"}
                    />
                </li>
                <li className={`side-menu-item-desktop ${isThemeOpen ? 'open' : 'closed'} ${appTheme.dark ? "dark" : "light"}`}>
                    <SideMenuButton 
                        description={isSideMenuMinimized ? null : (appLanguage.en ? 'Theme' : 'Tema')}
                        imgIcon={themeIcon}
                        downArrow={true}
                        isOpen={isThemeOpen}
                        onClickButton={onClickTheme}
                    />
                    <form className={`side-menu-form-desktop ${isThemeOpen ? 'open' : 'closed'}`}>
                        <div className="side-menu-form-input-container-desktop">
                            <input  
                                style={{ colorScheme: appTheme.dark ? "dark" : "light"}}
                                type="radio" 
                                id="systemThemeDesktop" 
                                name="AppTheme"
                                checked={themeformState.asInTheSystme}
                                value="asInTheSystme"
                                onChange={handleChangeTheme}
                            />
                            <label htmlFor="systemThemeDesktop">{appLanguage.en ? 'Default' : 'Predeterminado'}</label>
                        </div>
                        <div className="side-menu-form-input-container-desktop">
                            <input  
                                style={{ colorScheme: appTheme.dark ? "dark" : "light"}}
                                type="radio" 
                                id="lightThemeDesktop" 
                                name="AppTheme"
                                checked={themeformState.light}
                                value="light"
                                onChange={handleChangeTheme}
                            />
                            <label htmlFor="lightThemeDesktop">{appLanguage.en ? 'Light' : 'Claro'}</label>
                        </div>
                        <div className="side-menu-form-input-container-desktop">
                            <input  
                                style={{ colorScheme: appTheme.dark ? "dark" : "light"}}
                                type="radio" 
                                id="darkThemeDesktop" 
                                name="AppTheme"
                                checked={themeformState.dark}
                                value="dark"
                                onChange={handleChangeTheme}
                            />
                            <label htmlFor="darkThemeDesktop">{appLanguage.en ? 'Dark' : 'Oscuro'}</label>
                        </div>
                    </form>
                </li>
                <li className="side-menu-item-desktop">
                    <SideMenuButton
                        description={isSideMenuMinimized ? null : (appLanguage.en ? 'More options' : 'Más opciones')}
                        imgIcon={moreOptIcon}
                        onClickButton={closeOptions}
                        goTo={"/ajustes"}
                    />
                </li>
            </ul>
            <div className='side-menu-options-space'/>
            <div className={`change-menu-size-container ${appTheme.dark ? "butt-dark" : "butt-light"}`}>
                <button 
                    onClick={handleChangeMenuSize}
                    className={`change-menu-size-button ${appTheme.dark ? "change-menu-size-button-dark" : "change-menu-size-button-light"}`}
                >
                    {isSideMenuMinimized ? null :<p>{appLanguage.en ? 'Minimize' : 'Minimizar'}</p>}  
                    <img 
                        className={`change-menu-size-img ${isSideMenuMinimized ? "change-menu-size-img-minimized" : "change-menu-size-img-maximized"}`} 
                        src={appTheme.dark ? arrowIconDark : arrowIconLight} 
                        alt='Arrow' 
                    />
                </button>
            </div>
        </div>
    );
}

export default SideMenuDesktop;