import "./startAndEndShift.scss";

import { Fragment, useContext, useEffect, useRef } from "react";
import { StartAndEndShiftContext } from "./startAndEndShiftContext";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import stopIconDark from "../../../icons/stop-button-dark.png";
import playIconDark from "../../../icons/play-button-dark.png";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";

const StartAndEndShift = ({ pickerWidth, onStartShift, onEndShift, loading }) => {
    const { appTheme, appLanguage, formatRationalNumber } = useContext(AppContext);
    const { alertActive, setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);
    const { shift } = useContext(AuthContext);
    const { startAndEndShiftActive, setStartAndEndShiftActive, cashAmount, setCashAmount, cashAmountAlert, setCashAmountAlert } = useContext(StartAndEndShiftContext);

    let menuRef = useRef();

    let inputRef = null;

    const setStartReference = (inputElement) => {
        inputRef = inputElement;
    }

    useEffect(() => {
        if (startAndEndShiftActive) {
            try {
                if (inputRef) {
                    // console.log(inputRef)
                    inputRef.focus();
                }
            } catch (error) {
                console.warn(error);
            }
        }
        // eslint-disable-next-line
    }, [startAndEndShiftActive]);

    useEffect(() => {
        if (startAndEndShiftActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setStartAndEndShiftActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setStartAndEndShiftActive(false);
    }

    const getProcessTitle = () => {
        if (shift) {
            return appLanguage.en ? "End shift" : "Terminar turno";
        } else {
            return appLanguage.en ? "Start shift" : "Iniciar turno";
        }
    }

    const onChangeCashAmount = (e) => {
        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setCashAmount(amount);
    }

    const onSaveProcess = (type) => {
        if (cashAmount.trim() === "") {
            setCashAmountAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        if (type === "start") {
            setAlertData({
                type: 'question',
                title: {
                    en: `Are you sure to start your shift?`,
                    es: `¿Estás seguro de iniciar tu turno?`
                },
                code: '',
                description: {
                    en: `The shift will be started with RD$ ${formatRationalNumber(cashAmount)} at the cash register.`,
                    es: `El turno será iniciado con RD$ ${formatRationalNumber(cashAmount)} en la caja resgistradora.`
                }
            });
            setOnAgree(() => onStartShift);
            setAlertActive(true);
        } else {

            const difference = Number(cashAmount) - shift.cashAmount;

            let descriptionEn = null;
            let descriptionEs = null;

            if (difference > 0) {
                descriptionEn = `⚠️ The cash register will close with a surplus of RD$ ${formatRationalNumber(difference)}`;
                descriptionEs = `⚠️ La caja cerrará con un sobrande de RD$ ${formatRationalNumber(difference)}`;
            } else {
                if (difference < 0) {
                    descriptionEn = `⚠️ The cash register will close with a shortage of RD$ ${formatRationalNumber(-1 * difference)}`;
                    descriptionEs = `⚠️ La caja cerrará con un faltante de RD$ ${formatRationalNumber(-1 * difference)}`;
                } else {
                    descriptionEn = `La caja cerrara de manera correcta`;
                    descriptionEs = `La caja cerrara de manera correcta`;
                }
            }

            setAlertData({
                type: 'question',
                title: {
                    en: `Are you sure to end your shift?`,
                    es: `¿Estás seguro de terminar tu turno?`
                },
                code: '',
                description: {
                    en: descriptionEn,
                    es: descriptionEs,
                }
            });
            setOnAgree(() => onEndShift);
            setAlertActive(true);
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSaveProcess();
        }
    }

    return (
        <div className='unselectable'>
            {startAndEndShiftActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${startAndEndShiftActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{display: "flex", justifyContent: "space-between", minHeight: "280px"}}>
                    {loading ? 
                        <div style={{marginTop: "40px"}}><Spinner /> </div> 
                    : 
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                <h4 style={{ marginLeft: "20px" }}>{getProcessTitle()}</h4>
                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                                </button>
                            </div>
                            <div style={{ margin: "8px 15px" }}>
                                {shift ? 
                                    <Fragment>
                                        <p>{ appLanguage.en ? "Cash registered in cash register" : "Efectivo registrado en caja registradora"}:</p>
                                        <p><b>RD$ {formatRationalNumber(shift.cashAmount)}</b></p>
                                        <br />
                                    </Fragment> 
                                : null}

                                <p> { appLanguage.en ? (shift ? "Real cash in cash register:" : "Defines cash in cash register:") : (shift ? "Efectivo real en caja registradora:" : "Define el efectivo en caja registradora:")} </p>
                                <input
                                    ref={setStartReference}
                                    value={cashAmount}
                                    onChange={(e) => onChangeCashAmount(e)}
                                    onKeyDown={e => handleKeyPress(e)}
                                    inputMode="numeric"
                                    maxLength={10}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="RD$ 0.00"
                                    style={{
                                        colorScheme: appTheme.dark ? "dark" : "light",
                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                        color: appTheme.dark ? "white" : "black"
                                    }}
                                    className={`cash-in-cash-reg-input ${cashAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "30px",
                                        marginBottom: "15px"
                                    }}
                                >
                                    {shift ?
                                        <button
                                            onClick={() => onSaveProcess("end")}
                                            className="cash-in-cash-reg-bottons-button-save"
                                            style={{ maxWidth: "190px" }}
                                        >
                                            <img src={stopIconDark} style={{ marginRight: "10px" }} alt="End shift" />
                                            {appLanguage.en ? <p>End</p> : <p>Terminar</p>}
                                        </button>
                                        :
                                        <button
                                            onClick={() => onSaveProcess("start")}
                                            className="cash-in-cash-reg-bottons-button-save"
                                            style={{ maxWidth: "190px" }}
                                        >
                                            <img src={playIconDark} style={{ marginRight: "10px" }} alt="Start shift" />
                                            {appLanguage.en ? <p>Start</p> : <p>Iniciar</p>}
                                        </button>
                                    }
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default StartAndEndShift;