import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../firebase/context";
import { AppContext } from "../../context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../context/SideMenuContext";
import { SoftAlertContext } from "../../components/soft-alert/softAlertContext";

import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";
import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";

import firebase from "../../firebase";
import { collection, query, limit, getCountFromServer, getDocsFromServer, where, orderBy, startAt, startAfter, doc, getDoc } from "firebase/firestore";

import ToolTitle from "../../appTools/appToolsComponents/tool-title/toolTitle";

import adjustmentIconDark from '../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../icons/adjustment-light.png';

import xCancelDark from "../../icons/cancel-dark.png";
import xCancelLight from "../../icons/cancel-light.png";

import deleteIconDark from '../../icons/closed-dark.png';
import edictIconDark from '../../icons/signature-dark.png';

import addImgDark from "../../icons/plus-mini-dark.png";
import addImgLight from "../../icons/plus-mini-light.png";

import crossIcon from "../../icons/cross.png";
import crossWhiteIcon from "../../icons/cross-white.png";
import magnifyingIcon from "../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../icons/bullet-point-light.png';
import CustomerListView from "../../appTools/appToolsComponents/customerListView/customerListView";

import "./customers.page.scss";
import { AlertContext } from "../../components/alert/alertContext";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const CustomersPage = () => {
    let observedRef = useRef(null);

    const location = useLocation();

    const { accountData, accessTo, customers, customersStatistics, setCustomerListener } = useContext(AuthContext);
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);

    const [showConnectionError, setShowConnectionError] = useState(false);

    // This will be save only in Ram Memory
    const [selecting, setSelecting] = useState(false);

    useEffect(() => {
        setActiveId(null);
    }, [selecting]);

    // In here al the IDs for the notifications selected will show
    const [customersSelected, setCustomersSelected] = useState([]);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setCustomerListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const recentlyOpenedAccounts = Array.isArray(customers) ? customers : [];

    const [docsCount, setDocsCount] = useState(null);

    const [loading, setLoading] = useState(true);

    const [longProcess, setLongProcess] = useState(false);

    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const getRecordById = (idToFind) => {
        const result = results.find(element => element.id === idToFind);
        return result;
    }

    const onClickLink = (id) => {
        if (selecting) {
            // Check if the notification is selected or not
            if (iAmSelected(id)) {
                const temNotSelected = customersSelected.filter(element => element.id !== id);
                setCustomersSelected(temNotSelected);
            } else {
                const temNotSelected = [...customersSelected];
                // 999 = Limit of 1,000
                if (temNotSelected.length <= 999) {
                    temNotSelected.push(getRecordById(id));
                    setCustomersSelected(temNotSelected);
                } else {
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'error',
                        text: {
                            en: 'You cannot select more than 1,000 records.',
                            es: 'No puedes seleccionar más de 1,000 registros.',
                        }
                    });
                }
            }
        } else {
            let newControler = null;

            if (controler) {
                newControler = {
                    ...controler,
                    "triggerSearch": false,
                }
            }

            const newState = {
                "results": results,
                "docsCount": docsCount,
                "controler": newControler,
                "pagesHistory": pagesHistory,
                "navControlers": navControlers,
                "showConnectionError": showConnectionError,
                "empty": empty,
                "showPageNav": showPageNav,
                "activeId": id,
                "searchingByTextActive": searchingByTextActive,
                "loading": false,
            }

            navigate(null, { replace: true, state: newState });
        }
    }

    useEffect(() => {
        if (location.state) {

            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            function removeObjectsWithIds(array, idsToRemove) {
                if (Array.isArray((array))) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                } else {
                    return null;
                }
            }

            if (location.controler) {
                if (
                    customers &&
                    page === 1 &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenedAccounts = Array.isArray(customers) ? customers : [];
                    // filtar
                    const res = recentlyOpenedAccounts;
                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                } else {
                    // filtar
                    const res = location.state.results;

                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                }
            } else {
                // filtar
                const res = location.state.results;

                if (itemsDeleted) {
                    const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                    setResults(filteredArray);
                } else {
                    setResults(res);
                }
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setResults(recentlyOpenedAccounts);

            setControler({
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (navControlers && customersStatistics) {
            if (
                customers &&
                navControlers.page === 1 &&
                controler.searchingText.trim() === ""
            ) {
                const recentlyOpenedAccounts = Array.isArray(customers) ? customers : [];
                setResults(recentlyOpenedAccounts);

                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                });
            }
        }

        if (customersStatistics && controler) {
            if (controler.searchingText.trim() === "") {
                if (customersStatistics.all > 0) {
                    setEmpty(false);
                    if (customersStatistics.all > 30) {
                        setShowPageNav(true);
                    } else {
                        setShowPageNav(false);
                    }
                } else {
                    setEmpty(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [customers, customersStatistics, docsCount]);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, results]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, results, width]);

    useEffect(() => {
        if (customersStatistics && controler) {
            if ((controler.searchingText.trim() === "")) {
                setDocsCount(customersStatistics.all);
            }
        }
    }, [customersStatistics, controler]);

    const [addIcon, setAddIcon] = useState(appTheme.dark ? addImgDark : addImgLight);
    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const [dropDownAddOpen, setDropDownAddOpen] = useState(false);
    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownAddOpen(false);
        setDropDownOptionsOpen(false);
    }

    let menuRef = useRef();
    let addRef = useRef();
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownOptionsOpen, appTheme]);

    useEffect(() => {
        if (dropDownAddOpen) {
            setAddIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setAddIcon(appTheme.dark ? addImgDark : addImgLight);
        }
    }, [dropDownAddOpen, appTheme]);

    const onClickAddButton = () => {
        dropDownAddOpen ? setDropDownAddOpen(false) : setDropDownAddOpen(true);
    }

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    useEffect(() => {
        if (controler) {
            if (
                pageNumber === 1 &&
                customers &&
                controler.searchingText.trim() === ""
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchSales(controler);
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    const searchSales = async (controler) => {
        setActiveId(null);

        if (
            customers &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) { return; }

        // eslint-disable-next-line
        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (searchingBy === "") {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                limit(30)
            );
        } else {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                where("searchKeys", "array-contains", searchingBy),
                limit(30)
            );
            // To get the docsCount to control the page nav
            qNoOrder = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                where("searchKeys", "array-contains", searchingBy),
            );
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc);
                }
            });

            // eslint-disable-next-line
            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setShowPageNav(customersStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);

                updateOrPushHistory({
                    "page": 1,
                    "startAt": res[0].lastTransactionTime,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].lastTransactionTime,
                    "endAtDocId": res[res.length - 1].id,
                });

            } else {
                setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
            // eslint-disable-next-line
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        setActiveId(null);
        blink();

        setControler({
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setResults(customers);

        setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            updateOrPushHistory({
                "page": pageN,
                "startAt": res[0].lastTransactionTime,
                "startAtDocId": res[0].id,
                "endAt": res[res.length - 1].lastTransactionTime,
                "endAtDocId": res[res.length - 1].id,
            });

            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickPrevious = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && searchingBy === "") {
            onResetAll();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                docSnap = await getDoc(doc(customerRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = null;
            let lastQueryNoOrder = null;

            if (searchingBy === "") {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
                // To get the docsCount to control the page nav
                lastQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                );
            }

            await goToPagePrevious(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = null;

                    newHistory = [
                        {
                            "page": 1,
                            "startAt": results[0].lastTransactionTime,
                            "startAtDocId": results[0].id,
                            "endAt": results[results.length - 1].lastTransactionTime,
                            "endAtDocId": results[results.length - 1].id,
                        },
                        {
                            "page": navControlers.page + 1,
                            "startAt": res[0].lastTransactionTime,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].lastTransactionTime,
                            "endAtDocId": res[res.length - 1].id,
                        }
                    ];

                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].lastTransactionTime,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].lastTransactionTime,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": navControlers.page + 1,
                    "startAt": res[0].lastTransactionTime,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].lastTransactionTime,
                    "endAtDocId": res[res.length - 1].id,
                });
            }
            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickNext = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            endAtDocId = lastProduct.id;
            startAfterOrAfterText = lastProduct.nameInsensitive;

        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                docSnap = await getDoc(doc(customerRef, endAtDocId));
            }

            if (searchingBy === "") {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );
                // To get the docsCount to control the page nav
                nextQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                );
            }

            await goToNextPage(nextQuery);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    useEffect(() => {
        if (dropDownMenuOpen) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setDropDownMenuOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownAddOpen) {
            let handler = (e) => {
                if (addRef.current) {
                    if (!addRef.current.contains(e.target)) {
                        setDropDownAddOpen(false);
                    };
                } else {
                    setDropDownAddOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (loading) {
            return;
        }
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
            setSelecting(false);
        }
    }

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const refreshSearch = async () => {
        if (
            customers &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenedAccounts = Array.isArray(customers) ? customers : [];
            setResults(recentlyOpenedAccounts);

            setNavControlers({
                "page": 1,
                "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && searchingBy === "") {
                onResetAll();
                return
            }

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                    docSnap = await getDoc(doc(customerRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/customers`),
                        orderBy("lastTransactionTime", "desc"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/customers`),
                        orderBy("lastTransactionTime", "desc"),
                        where("searchKeys", "array-contains", searchingBy),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                }

                await goToPagePrevious(lastQuery, navControlers.page);

                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const onClickCleanFilter = () => {
        setActiveId(null);
        setResults(recentlyOpenedAccounts);

        setControler({
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setNavControlers({
            "page": 1,
            "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
        });

        setShowConnectionError(false);

        setEmpty(customersStatistics ? (customersStatistics.all > 0 ? false : true) : true);
        setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);

        setPageNumber(1);

        setSearchingText("");

        setSearchingTextActive(false);

        setSelecting(false);

        blink();
    }

    const search = async () => {
        if (loading) {
            return;
        }
        if (searchingText.trim() !== "") {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
            setSelecting(false);
        }
    }


    const tryAgain = () => {
        setActiveId(null);
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    useEffect(() => {
        if (!selecting) {
            setCustomersSelected([]);
        }
    }, [selecting]);

    const onClickSelect = () => {
        if (!selecting && loading) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        } else {
            setSelecting(!selecting);
        }
    }

    const iAmSelected = (idToCheck) => {
        const exists = customersSelected.some(element => element.id === idToCheck);
        return exists;
    }

    async function deleteCustomers() {
        setAlertActive(false);

        setLoading(true);

        if (customersSelected.length > 1) {
            setLongProcess(true);
        }

        const idsToRemove = [];

        customersSelected.forEach((customer) => {
            idsToRemove.push(customer.id);
        })

        try {
            const res = await firebase.useDeleteCustomers({
                "customersSelected": idsToRemove,
                "accountId": accountData.id
            });

            setResults(res.customers);

            setControler({
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            if (res.statistics) {
                setNavControlers({
                    "page": 1,
                    "totalPage": res.statistics ? Math.ceil(res.statistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": res.statistics ? (Math.ceil(res.statistics.all / 30) > 1 ? true : false) : false
                });
                setEmpty(res.statistics ? (res.statistics.all > 0 ? false : true) : true);
                setShowPageNav(res.statistics ? (Math.ceil(res.statistics.all / 30) > 1 ? true : false) : false);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                });
                setEmpty(customersStatistics ? (customersStatistics.all > 0 ? false : true) : true);
                setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);
            }

            setShowConnectionError(false);
            setPageNumber(1);
            setSearchingText("");
            setSearchingTextActive(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'success',
                text: {
                    en: `${customersSelected.length > 1 ? "Records" : "Record"} deleted successfully.`,
                    es: `${customersSelected.length > 1 ? "Registros eliminados" : "Registro eliminado"} exitosamente.`
                }
            });

            setSelecting(false);
            setCustomersSelected([]);
            setLoading(false);
            setLongProcess(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setLongProcess(false);

            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to delete ${customersSelected.length > 1 ? "records" : "record"}`,
                    es: `Error al tratar de eliminar ${customersSelected.length > 1 ? "los registros" : "el registro"}`
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
        }
    }

    const onDelete = () => {
        if (customersSelected) {
            if (customersSelected.length > 0) {
                setAlertData({
                    type: 'question',
                    title: {
                        en: customersSelected.length > 1 ? 'Delete selected customers?' : 'Delete selected customer?',
                        es: customersSelected.length > 1 ? '¿Eliminar clientes seleccionados?' : '¿Eliminar cliente seleccionado?'
                    },
                    code: '',
                    description: {
                        en: customersSelected.length > 1 ? `Are you sure you want to delete ${customersSelected.length} customers?` : 'Are you sure you want to remove this customer?',
                        es: customersSelected.length > 1 ? `¿Estás seguro de que deseas eliminar ${customersSelected.length} clientes?` : '¿Estás seguro de que deseas eliminar este cliente?'
                    }
                });
                setOnAgree(() => deleteCustomers);
                setAlertActive(true);
            }
        }
    }

    const edictCustomers = () => {
        if (customersSelected) {
            if (customersSelected.length > 0) {
                navigate("/editar-clientes", { state: {editingRecords: customersSelected, currentRecords: customersSelected} });
            }
        }
    }

    const downloadInExcell = () => {
        if (customersSelected) {
            if (customersSelected.length > 0) {
                blink();

                const data = [];

                customersSelected.forEach((customer) => {
                    if (appLanguage.en) {
                        data.push({
                            "Record #": customer.id,
                            "Name": customer.name.trim() === "" ? null : customer.name,
                            "Phone": customer.phone.trim() === "" ? null : customer.phone,
                            "Email": customer.email.trim() === "" ? null : customer.email,
                            "Cédula or RNC": customer.rnc.trim() === "" ? null : customer.rnc,
                            "Address": customer.address.trim() === "" ? null : customer.address
                        });
                    } else {
                        data.push({
                            "Registro #": customer.id,
                            "Nombre": customer.name.trim() === "" ? null : customer.name,
                            "Teléfono": customer.phone.trim() === "" ? null : customer.phone,
                            "Correo electrónico": customer.email.trim() === "" ? null : customer.email,
                            "Cédula o RNC": customer.rnc.trim() === "" ? null : customer.rnc,
                            "Dirección": customer.address.trim() === "" ? null : customer.address
                        });
                    }
                });

                // Preprocess data to replace empty or null fields with '--'
                const processedData = data.map(item => {
                    const newItem = {};
                    for (const key in item) {
                        newItem[key] = item[key] !== null && item[key] !== undefined ? item[key] : '--';
                    }
                    return newItem;
                });

                // Create a new workbook and a worksheet
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(processedData);

                // Set column widths
                const colWidths = [
                    { wch: 15 },  // Record #
                    { wch: 30 },  // Name
                    { wch: 15 },  // Phone
                    { wch: 30 },  // Email
                    { wch: 20 },  // Cédula or RNC
                    { wch: 50 }   // Address
                ];
                ws['!cols'] = colWidths;

                // Add worksheet to workbook
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                // Create Excel file as a Blob
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([wbout], { type: 'application/octet-stream' });

                // Trigger the download using file-saver
                saveAs(blob, `${appLanguage.en ? "Customers" : "Clientes"}.xlsx`);

                setSelecting(false);
                setDropDownOptionsOpen(false);
            }
        }
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, docsCount, searchingByTextActive]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Customers' : 'Clientes'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && controler ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.customers ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Customers' : 'Clientes'}
                                        />
                                        : null}
                                    <div className="products-services-first-section">
                                        <div
                                            className="products-services-first-item"
                                            style={{
                                                maxWidth: "870px"
                                            }}
                                        >

                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                    onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    minWidth: "350px",
                                                    justifyContent: "flex-end"
                                                }}
                                            >
                                                {!selecting ?
                                                    <div ref={addRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickAddButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                width: "120px"
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "10px"
                                                                }}
                                                                src={addIcon}
                                                                alt="Add"
                                                            />
                                                            <p style={dropDownAddOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Add" : "Agregar"}</p>
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownAddOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "60px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "-50px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownAddOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/agregar-cliente"}
                                                                style={{
                                                                    width: "170px",
                                                                    marginTop: "20px"
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "A record" : "Un registro"} </p>
                                                            </Link>

                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/agregar-clientes"}
                                                                style={{
                                                                    width: "170px",
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Multiple records" : "Múltiples registros"} </p>
                                                            </Link>

                                                            <Link
                                                                className="invent-add-menu-btn"
                                                                to={"/importar-clientes-desde-excel"}
                                                                style={{
                                                                    width: "170px",
                                                                }}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Import from Excel" : "Importar desde Excel"} </p>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                    : null}

                                                {selecting ?
                                                    <div ref={optionsRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickOptionsButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                paddingLeft: "8px",
                                                                paddingRight: "8px",
                                                                width: "90px",
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "10px"
                                                                }}
                                                                src={optionsIcon}
                                                                alt="Options"
                                                            />
                                                            <p style={dropDownOptionsOpen ? { fontWeight: "bold" } : {}}>{customersSelected ? customersSelected.length : ""}</p>
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "45px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "-65px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        >

                                                            <button
                                                                style={{ marginTop: "20px" }}
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onDelete}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                    src={deleteIconDark}
                                                                    alt="Delete"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Delete" : "Eliminar"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={downloadInExcell}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download in Excel" : "Descargar en Excel"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={edictCustomers}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                    src={edictIconDark}
                                                                    alt="Edict"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Edit" : "Editar"} </p>
                                                            </button>

                                                        </div>
                                                    </div>
                                                    : null}

                                                <button
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    style={{
                                                        margin: "20px 10px 0 10px",
                                                        height: "38.4px"
                                                    }}
                                                    disabled={loading ? true : false}
                                                    onClick={onClickSelect}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selecting}
                                                        onChange={onClickSelect}
                                                        name='customerAccounts'
                                                        id='customerAccounts'
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light"
                                                        }}
                                                    />
                                                    <p>{appLanguage.en ? "Select" : "Seleccionar"}</p>
                                                </button>
                                            </div>

                                            {searchingByTextActive ?
                                                <div
                                                    className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <div className="filter-sales-details-data">
                                                        <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                        <p>{appLanguage.en ? "Sort by last interaction" : "Ordenar por última interacción"}</p>
                                                    </div>
                                                    <button
                                                        className="filter-sales-details-clean-filter-btn"
                                                        onClick={onClickCleanFilter}
                                                    >
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <CustomerListView
                                    observedRef={observedRef}
                                    longProcess={longProcess}
                                    onScroll={onScroll}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    showConnectionError={showConnectionError}
                                    tryAgain={tryAgain}
                                    empty={empty}
                                    results={results}
                                    activeId={activeId}
                                    setActiveReference={setActiveReference}
                                    onClickLink={onClickLink}
                                    pageNumber={pageNumber}
                                    navControlers={navControlers}
                                    showPageNav={showPageNav}
                                    onClickNext={onClickNext}
                                    onClickPrevious={onClickPrevious}
                                    selecting={selecting}
                                    iAmSelected={iAmSelected}
                                />
                            </Fragment> : <SectionForbidden sectionName={appLanguage.en ? "Customers" : "Clientes"} />}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default CustomersPage;