import { Fragment, useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSearchParams } from 'react-router-dom';
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import firebase from '../../../firebase/firebase';

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";
import magnifyingIcon from "../../../icons/magnifying-glass.png";

import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

import AccountDetails from "../accountDetails/accountDetails";
import AdminDetails from "../adminDetails/adminDetails";

import arrowIcon from "../../../icons/arrow-small-right-white.png";
import cancelIcon from "../../../icons/cancel-dark.png";
import planetImg from "../../../img/planet.svg";

import "./adminChooseAccount.scss";

const AdminChooseAccount = () => {

    const [ searchParams ] = useSearchParams();

    const { 
        accountSelected, 
        setAccountSelected, 
        setAccountData, 
        eliminateAllCustomers, 
        setAccessTo, 
        saveKeyInIndexedDB, 
        partiallyEliminateCustomers, 
    } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    const { appLanguage, appTheme } = useContext(AppContext);

    const accountNumber = searchParams.get("account");

    const [ controler, setControler ] = useState(accountNumber ? accountNumber : "");

    const [ loading, setLoading ] = useState(false);
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [ account, setAccount ] = useState(null);

    useEffect(() => {
        if (location.state) {
            eliminateAllCustomers();
            setControler(location.state);
            searchFor(location.state);
        }
    // eslint-disable-next-line
    }, []);

    let inputRef = null;

    const setRefernce = (inputElement)=>{
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }  
        } catch (error) {
            console.warn(error);
        }

        setControler("");
    }

    const handleChange = e => {
        setControler(e.target.value);
    }

    const search = e => {
        e.preventDefault();
        searchFor(controler);
    }

    const tryAgain = e => {
        e.preventDefault();
        searchFor(controler);
    }

    useEffect(() => {
        if (!location.state) {
            if(controler.trim() !== "") {
                eliminateAllCustomers();
                searchFor(controler);
            }
        }
    // eslint-disable-next-line
    }, []);

    async function searchFor(id) {
        setLoading(true);
        setShowConnectionError(false);
        
        try {
            const response = await firebase.useGetAccountDataPayment(id.trim());
            setAccount(response);
        } catch (error) {
            setLoading(false);
            console.error(error);
            setAccount(null);
        }

        setLoading(false);
    }

    const copyAccountNumber = () => {
        const text = account ? account.accountID : accountSelected.id;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Copied account number',
                    es : 'Número de cuenta copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyEmailNumber = () => {
        const text = account ? account.email : accountSelected.email;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Copied email',
                    es : 'Correo copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyPhoneNumber = () => {
        const text = account ? account.phone : accountSelected.phone;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Copied phone number',
                    es : 'Número de teléfono copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const enterToAccount = (data) => {

        const account = data.account;
        const accountRef = data.accountRef;

        localStorage.removeItem("itemsDeleted");

        localStorage.setItem("isAccountSelected", JSON.stringify(accountRef));
        setAccountSelected(accountRef);

        saveKeyInIndexedDB("accountData", account);
        setAccountData(account);
     
        const accessToAll = {
            // Default
            "sales": true,
            "products": true,
            "generalMetrics": true,
            "customerAccounts": true,
            // For the future, just in case
            "subscriptions": false,
            "suppliers": false,
            "cashFlow": false,
        }
        setAccessTo(accessToAll);
        saveKeyInIndexedDB("accessTo", accessToAll);

        partiallyEliminateCustomers();

        setSoftAlertActive(true);
        setSoftAlertData({
            type : 'sucess',
            text: {
                en : 'Account selected successfully',
                es : 'Cuenta selecionada con éxito',
            }
        });

        navigate('/', { replace: true });
    }

    const changeAccountSelected = () => {
        // accountRef it's used to disploy info about the account 
        // to the manager
        const data = {
            "account": account.data,
            "accountRef": {
                "doc": "manager",
                "id": account.accountID,
                "active": account.active,
                "displayName": account.displayName,
                "email": account.email,
                "name": account.name,
                "phone": account.phone,
                "pictureLocation": account.pictureLocation,
            },
        }

        enterToAccount(data);
    }

    const getAccountDetails = (accountSelected) => {
        const account = {
            "accountID": accountSelected.id,
            "active": accountSelected.active,
            "displayName": accountSelected.displayName,
            "email": accountSelected.email,
            "name": accountSelected.name,
            "phone": accountSelected.phone,
            "pictureLocation": accountSelected.pictureLocation, 
        }
        return account;
    }

    const closeAccount = () => {
        eliminateAllCustomers();

        setSoftAlertActive(true);
        setSoftAlertData({
            type : 'sucess',
            text: {
                en : 'Account closed successfully',
                es : 'Cuenta cerrada exitosamente',
            }
        });
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            search(e);
        }
    }

    const [searchInputFocus, setSearchInputFocus ] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ){
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ){
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    return (
        <div className="admin-choose-account-cont">
            {accountSelected ? 
                <Fragment>
                    {loading ? <Spinner/> :
                        <Fragment> {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : 
                            <Fragment>
                                    <div className="admin-choose-account-card-div">
                                        <div className={`account-details-selection-container  ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover" : "lig-sec-bg lig-box-sha-keep-hover"}`}>
                                            <AccountDetails account={getAccountDetails(accountSelected)} copyAccountNumber={copyAccountNumber} />
                                            <AdminDetails 
                                                account={getAccountDetails(accountSelected)} 
                                                copyEmailNumber={copyEmailNumber} 
                                                copyPhoneNumber={copyPhoneNumber}
                                            />
                                            <div className="account-datails-btn-section">
                                                <button className="lig-sec-bg" onClick={closeAccount}>
                                                    <img className="account-datails-img-exit-section" src={cancelIcon} alt="Access" /> {appLanguage.en ? "Exit" : "Salir"}  
                                                </button> 
                                            </div>
                                        </div>
                                    </div>
                            </Fragment>}
                        </Fragment>
                    }
                </Fragment> 
            : <Fragment>
                <p>{appLanguage.en ? "Enter account number" : "Ingrese el número de cuenta"}</p>
                <div className="div-search-input-home">
                    <input
                        ref={setRefernce}
                        onBlur={checkSearchInput}
                        onFocus={checkSearchInput}
                        type="search"
                        autoComplete="off"
                        autoCorrect="off"
                        className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg" }`}
                        value={controler}
                        onChange={e => handleChange(e)}
                        name="searchingText"
                        onKeyDown={e => handleKeyPress(e)}
                        maxLength={80}
                        placeholder={appLanguage.en ? "Enter account number here..." : "Ingrese el número de cuenta aquí..."}
                        style={appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    />
                    {controler === "" ? null : 
                        <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                            <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                            </button>
                        </div>
                    }
                    <button
                        className="search-button-home" 
                        type="submit"
                        onClick={search}
                    >
                        <img src={magnifyingIcon} alt="Search box" />
                    </button>
                </div>
                {loading ? <Spinner/> :
                    <Fragment> {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : 
                        <Fragment>
                            {account ? 
                                <div className="admin-choose-account-card-div">
                        
                                    <div className={`account-details-selection-container  ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover" : "lig-sec-bg lig-box-sha-keep-hover"}`}>
                                        <AccountDetails account={account} copyAccountNumber={copyAccountNumber} />
                                        <AdminDetails 
                                            account={account} 
                                            copyEmailNumber={copyEmailNumber} 
                                            copyPhoneNumber={copyPhoneNumber}
                                        />
                                        <div className="account-datails-btn-section">
                                            <button className="lig-sec-bg" onClick={changeAccountSelected} >
                                                {appLanguage.en ? "Open" : "Acceder"} <img className="account-datails-img-section" src={arrowIcon} alt="Access" />
                                            </button>
                                        </div>
                                    </div>
                                </div> 
                            : 
                                <div className="admin-choose-empty-card-div">
                                    <div className="autho-users-list-empty">
                                        <div className="autho-users-list-empty-img-div">
                                            <img className="autho-users-list-empty-img" src={planetImg} alt="Empty" />
                                        </div>
                                        <p><b>{appLanguage.en ? "Without results" : "Sin resultados"}</b></p>
                                    </div>
                                </div>
                            }
                        </Fragment>}
                    </Fragment>
                }  
            </Fragment>}
        </div>
    )
}

export default AdminChooseAccount;