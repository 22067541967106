import "./pictureProfileViewer.scss";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import crossIconDark from "../../../icons/cross-white.png";
import crossIconLight from "../../../icons/cross.png";

import downloadIconDark from "../../../icons/download-dark.png";
import downloadIconLight from "../../../icons/download-light.png";

import trashIconDark from "../../../icons/trash-white.png";
import trashIconLight from "../../../icons/trash.png";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import firebase from "../../../firebase";
import { AuthContext } from "../../../firebase/context";

const PictureProfileViewer = ({
    viewerWidth, 
    pictureProfileViewerActive, 
    setPictureProfileViewerActive, 
    setUploadingPicture
}) => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { alertActive, setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { userData, deleteKey, userPictures, setUserPictures } = useContext(AuthContext);

    let menuRef = useRef();

    useEffect(() => {
        if(pictureProfileViewerActive && menuRef.current) {
            let handler = (e) => {
                if(!menuRef.current.contains(e.target)) {
                    if (!alertActive) {
                        setPictureProfileViewerActive(false); 
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setPictureProfileViewerActive(false);
    }

    function onDownloadImage(src) {
        blick();
        const img = new Image();
        img.crossOrigin = "https://hazcuentas.com/";
        img.src = src;
        img.onload = () => {
          // create Canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          // for create tag anchor
          const a = document.createElement("a");
          a.download = userData.displayName;
          a.href = canvas.toDataURL("image/png");
          a.click();
        };
    }

    async function deletePicture() {
        setUploadingPicture(true);
        setPictureProfileViewerActive(false); 
        setAlertActive(false);

        try {
            await firebase.useDeleteUserPicture(userData.pictureLocation);
            setUserPictures(null);
            deleteKey("userPictures");
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The image has been deleted',
                    es: 'La imagen ha sido eliminada'
                }
            });
            setUploadingPicture(false);
            
        } catch (error) {
            setUploadingPicture(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to delete picture',
                    es: 'Error al intentar eliminar imagen'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onClickDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete image?`,
                es: `¿Eliminar imagen?`
            },
            code: '',
            description: {
                en: `Are you sure you want to delete this imagen?`,
                es: `¿Estás seguro de que deseas eliminar esta imagen?`
            }
        });
        setOnAgree(() => deletePicture);
        setAlertActive(true);
    }

    const [downloading, setDownloading] = useState(false);

    const blick = () => {
        setDownloading(true);
        setTimeout(() => {
            setDownloading(false);
        }, 1400);
    }

    return (
        <div className='unselectable'>
            {userPictures ? <Fragment>
                {pictureProfileViewerActive ? 
                    <div 
                        className='picture-profile-viewer-lock-screen' 
                        style={{
                            width: viewerWidth, 
                            minHeight: "calc(100vh - 65px)",
                            background: appTheme.dark ? "#202022" : "rgba(255, 255, 255)"
                        }} 
                    /> 
                : null }
                
                <div 
                    ref={menuRef} 
                    className={`picture-profile-viewer-card ${pictureProfileViewerActive ? 'active' : 'inactive' }`} 
                    style={{
                        width: viewerWidth, color: "white",
                        colorScheme: appTheme.dark ?  "dark" : "light"
                    }}
                >
                    <div 
                        className={`picture-profile-viewer-card-top-shadow`} 
                        style={{
                            boxShadow: appTheme.dark ? "3px 3px 3px 2px rgba(0, 0, 0, 0.3)" : "2px 2px 2px 1px rgba(0, 0, 0, 0.3)",
                        }}
                    />
                    <div
                        className="picture-profile-viewer-options-cont"
                    >
                        <button 
                            onClick={onClickClose}
                            className={appTheme.dark ? "picture-profile-viewer-btn-dark" : "picture-profile-viewer-btn-light"}
                            style={{
                                color: appTheme.dark ? "white" : "black",
                                paddingLeft: "20px"
                            }}
                        >
                            <img src={appTheme.dark ? crossIconDark : crossIconLight } alt="Close" />
                        </button>
                        <div style={{flexGrow: 4}} />
                        <button
                            onClick={onClickDelete}
                            className={appTheme.dark ? "picture-profile-viewer-btn-dark" : "picture-profile-viewer-btn-light"}
                            style={{
                                color: appTheme.dark ? "white" : "black",
                                marginRight: "20px"
                            }}
                        >
                            <img src={appTheme.dark ? trashIconDark : trashIconLight } alt="Trans" />
                            {appLanguage.en ? "Delete" : "Eliminar"}
                        </button>
                        <button
                            onClick={() => onDownloadImage(userPictures.d600x600)}
                            disabled={downloading ? true : false}
                            className={`picture-profile-viewer-btn-download ${downloading ? "skeleton-product" : ""} ${appTheme.dark ? "picture-profile-viewer-btn-dark" : "picture-profile-viewer-btn-light"}`}
                            style={{
                                color: appTheme.dark ? "white" : "black",
                            }}
                        >
                            {downloading ? null : <img src={appTheme.dark ? downloadIconDark : downloadIconLight} alt="Download" />}
                            {downloading ? `${appLanguage.en ? "Downloading..." : "Descargando..."}` : `${appLanguage.en ? "Download" : "Descargar"}`}
                        </button>
                    </div>
                    <div className="picture-profile-viewer-card-img">
                        <img src={userPictures.d600x600} alt="Product" />
                    </div>
                </div>
            </Fragment> : null}
        </div>
    );
}

export default PictureProfileViewer;