import { useContext } from 'react';

import './toolTitle.scss';
import { AppContext } from '../../../context/AppContext';

const ToolTitle = ({text, icon, text2}) => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className={`tool-title-header ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}>
            <img className='tool-title-container-header-img' src={icon} alt='Page name' />
            <h4>{text} {text2 ? <span style={{fontWeight: 400}}>{text2}</span> : ""}</h4>
        </div>
    );
}

export default ToolTitle;