import "./manageShift.page.scss";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { StartAndEndShiftContext } from "../../../appTools/appToolsComponents/startAndEndShift/startAndEndShiftContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import historyDarkIcon from "../../../icons/history-dark.png";

import stopIconDark from "../../../icons/stop-button-dark.png";
import playIconDark from "../../../icons/play-button-dark.png";
import StartAndEndShift from "../../../appTools/appToolsComponents/startAndEndShift/startAndEndShift";
import { AlertContext } from "../../../components/alert/alertContext";
import firebase from "../../../firebase";

const ManageShiftPage = () => {

    const { appTheme, appLanguage, formatRationalNumber, getTimeInString, getDateInString } = useContext(AppContext);
    const { accountData, accessTo, shift, managerAccess, saveKeyInIndexedDB, setShift } = useContext(AuthContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);
    const { setStartAndEndShiftActive, cashAmount, loading, setLoading } = useContext(StartAndEndShiftContext);
    const { setAlertActive, setAlertData, getErrorDescription } = useContext(AlertContext);

    const navigate = useNavigate();
    
    let pageObserverRef = useRef(null);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    const isSalesSup = () => {
        return managerAccess || accessTo.salesSup;
    }

    const onClickBotton = () => {
        setStartAndEndShiftActive(true);
    }

    const onStartShift = async () => {
        setAlertActive(false);
        setLoading(true);

        try {
            const tempCashAmount = Number(cashAmount);
            const res = await firebase.useChangeShift({
                businessId: accountData.id,
                recordedCash: 0,
                cashAmount: tempCashAmount,
                type: "start"
            });

            setShift(res);
            saveKeyInIndexedDB("shift", res);

            setLoading(false);
            setStartAndEndShiftActive(false);

            navigate("/carrito-de-compras", { replace: true });
            
        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));

            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to start shift`,
                    es: `Error al intentar iniciar turno`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onEndShift = async () => {
        setAlertActive(false);
        setLoading(true);

        try {
            const tempCashAmount = Number(cashAmount);
            const res = await firebase.useChangeShift({
                businessId: accountData.id,
                recordedCash: shift.cashAmount,
                cashAmount: tempCashAmount,
                type: "end"
            });

            setShift(res);
            saveKeyInIndexedDB("shift", res);

            setLoading(false);
            setStartAndEndShiftActive(false);

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));

            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to start shift`,
                    es: `Error al intentar iniciar turno`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (!accountData.useShifts) {
                navigate("/carrito-de-compras", { replace: true });
            }
        }
    // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Manage shifts" : "Gestionar turnos"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef}  className={`page-choose-account-item unselectable`}>
                            {accessTo.sales ? <Fragment>
                                <StartAndEndShift 
                                    pickerWidth={pageWidth}
                                    onStartShift={onStartShift}
                                    onEndShift={onEndShift}
                                    loading={loading}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Manage shifts" : "Gestionar turnos"}
                                    />
                                : null}

                                <div 
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 65px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light",
                                        overflow: "hidden",
                                        overflowY: "auto",
                                        
                                    }}
                                >

                                    <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>

                                        <div 
                                            className={`${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}
                                            style={{display: "flex", flexDirection: "column", width: "280px", minHeight: "50px", borderRadius: "8px", margin: "25px 0 40px 0", padding: "10px" }}
                                        >
                                            {shift ? 
                                                <Fragment>
                                                    <h3>{appLanguage.en ? "User on shift" : "Usuario en turno"}</h3>
                                                    <br />
                                                    <p style={{padding: "5px"}}>Inicio del turno: <br/> <b>{getTimeInString(shift.time)} - {getDateInString(new Date(shift.time))}</b></p>
                                                    <p style={{padding: "5px"}}>Ventas: <br/><b> {shift.sales}</b></p>
                                                    <p style={{padding: "5px"}}>Devoluciones: <br/> <b>{shift.returns}</b></p>
                                                    <p  style={{padding: "5px"}}>Efectivo en caja: <br/> <b>RD$ {formatRationalNumber(shift.cashAmount)}</b></p>
                                                </Fragment>
                                            : 
                                                <h3>{appLanguage.en ? "Shift not started" : "Turno no iniciado"}</h3>
                                            }
                                        </div>

                                        {shift ?
                                            <button
                                                onClick={onClickBotton}
                                                style={{
                                                    margin: "12px",
                                                    padding: "10px",
                                                    width: "300px",
                                                    borderRadius: "100px"
                                                }}
                                                className="new-customer-bottons-button-save"
                                            >
                                                <img style={{width: "20px", height: "20px"}} src={stopIconDark} alt="Save" />
                                                {appLanguage.en ? <p style={{fontSize: "16px"}} >End shift</p> : <p style={{fontSize: "16px"}}>Terminar turno</p>}
                                            </button>
                                        :
                                            <button
                                                onClick={onClickBotton}
                                                style={{
                                                    margin: "12px",
                                                    padding: "10px",
                                                    width: "300px",
                                                    borderRadius: "100px"
                                                }}
                                                className="new-customer-bottons-button-save"
                                            >
                                                <img style={{width: "20px", height: "20px"}} src={playIconDark} alt="Save" />
                                                {appLanguage.en ? <p style={{fontSize: "16px"}}>Start shift</p> : <p style={{fontSize: "16px"}}>Iniciar turno</p>}
                                            </button>
                                        }

                                        {isSalesSup() ? 
                                            <button
                                                onClick={null}
                                                style={{
                                                    margin: "12px",
                                                    marginTop: "18px",
                                                    padding: "10px",
                                                    width: "300px",
                                                    borderRadius: "100px"
                                                }}
                                                className="new-customer-bottons-button-save"
                                            >
                                                <img style={{width: "20px", height: "20px"}} src={historyDarkIcon} alt="Save" />
                                                {appLanguage.en ? <p style={{fontSize: "16px"}}>User history</p> : <p style={{fontSize: "16px"}}>Historial de usuarios</p>}
                                            </button>
                                        :
                                            <button
                                                onClick={null}
                                                style={{
                                                    margin: "12px",
                                                    marginTop: "18px",
                                                    padding: "10px",
                                                    width: "300px",
                                                    borderRadius: "100px"
                                                }}
                                                className="new-customer-bottons-button-save"
                                            >
                                                <img style={{width: "20px", height: "20px"}} src={historyDarkIcon} alt="Save" />
                                                {appLanguage.en ? <p style={{fontSize: "16px"}}>My history</p> : <p style={{fontSize: "16px"}}>Mi historial</p>}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                    : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ManageShiftPage;