import './spinner3.scss';
import SpinnerOutSideDark3 from './spinnerOutSideDark3';
import SpinnerOutSideLight3 from './spinnerOutSideLight3';
import { AppContext } from '../../context/AppContext';
import { useContext } from 'react';

const Spinner3 = () => {

    const { appTheme } = useContext(AppContext);

    return (
        <div className="spinner-conainer-general3">
            <div>
                {appTheme.dark ? <SpinnerOutSideDark3 /> : <SpinnerOutSideLight3 /> }
            </div>
        </div>
    );
}

export default Spinner3;