import { Fragment, useContext, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import Spinner from '../../../components/spinner/spinner';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../img/analysis.svg';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import SettingsActionRequired from '../../../components/settings-action-required/settingsActionRequired';

import '../businessAccount.page.scss';

const AccountActivityPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Account activity" : "Actividad de la cuenta"} </title>
            </Helmet>
            <div
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader2
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "Account activity" : "Actividad de la cuenta"}
                        />
                        : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ? <Fragment>
                                {loading ? <Spinner /> : <Fragment>
                                    {accountData.active ? <Fragment>
                                        <br/>
                                        <br/>
                                        <p style={{marginLeft: "30px"}}><b>Aqui se mostraran todas las actividades relevantes tales como:</b></p>
                                        <br/>
                                        <ol style={{marginLeft: "50px"}}>
                                            <li>Agregar, editar o remover un usuario autorizado</li>
                                            <li>Cancelacion de suscripcion</li>
                                            <li>Inicio de seccion</li>
                                            <li>Modificacion de productos o detalles de ventas</li>
                                            <li>Entre otros...</li>
                                        </ol>
                                    </Fragment> : <SettingsActionRequired />}
                                </Fragment>}
                            </Fragment> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AccountActivityPage;