import { Fragment, useContext, useEffect, useState } from "react";
import { SoftAlertContext } from "../soft-alert/softAlertContext";
import { AppContext } from "../../context/AppContext";
import { AlertContext } from '../alert/alertContext';
import { Link } from "react-router-dom";

import firebase from "../../firebase/firebase";
import { ref, getDownloadURL } from "firebase/storage";

import Spinner from '../spinner/spinner';

import "./bankAccTo.scss";

import wainingIcon from "../../icons/authUserAlert.png";
import error404 from "../../img/404-error.png";

import banreservasLogo from "../../img/banks/banreservas_64x64.png";
import paypalLogo from "../../img/banks/paypal_64x64.png";
import bhdLogo from "../../img/banks/bhd_64x64.png";
import qikLogo from "../../img/banks/qik_64x64.png";
import popularLogo from "../../img/banks/popular_64x64.png";
import defaultLogo from "../../img/banks/bank.png";

import uploadIconDark from "../../icons/upload-dark.png";

import checkIcon from "../../img/check.svg";

const BankAccTo = ({bill, accountId, onClickPicture }) => {
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appLanguage } = useContext(AppContext);

    const [ loadingPicture, setLoadingPicture ] = useState(()=> {
        if (bill.paymentArrangement ? (bill.paymentArrangement.pictureLocation ? true : false) : false) {
            return true;
        } else {
            return false;
        }
    });

    const [ loadingError, setLoadingError ] = useState(false);
    const [ pictureFile, setPictureFile ] = useState(true);
    const [ receiptError, setReceiptError ] = useState(bill.paymentArrangement ? bill.paymentArrangement.error : null);
    const [ bankImg, setBankImg ] = useState(defaultLogo);

    useEffect(() => {
        switch (bill.paymentArrangement.bank) {
            case 'BHD':
                setBankImg(bhdLogo);
                break;
            case 'Banreservas':
                setBankImg(banreservasLogo);
                break;
            case 'Popular':
                setBankImg(popularLogo);
                break;
            case 'Qik':
                setBankImg(qikLogo);
                break;
            case 'Paypal':
                setBankImg(paypalLogo);
                break;
            default:
                setBankImg(defaultLogo)
        }
        // eslint-disable-next-line
    }, [])

    const copyAccN = () => {
        const text = bill.paymentArrangement.account;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Copied account number',
                    es: 'Número de cuenta copiado',
                }
            })
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error copying number',
                    es: 'Error al copiar número',
                }
            });
        }
    }

    const copyName = () => {
        const text = bill.paymentArrangement.name;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Copied name',
                    es: 'Nombre copiado',
                }
            })
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error copying number',
                    es: 'Error al copiar nombre',
                }
            })
        }
    }

    const getReceiptPicture = async (locationPath) => {
        setLoadingPicture(true);
        try {
            const imageRef = ref(firebase.storage, locationPath);
            const url = await getDownloadURL(imageRef);
            const xhr = new XMLHttpRequest();

            xhr.responseType = 'blob';
            xhr.onload = () => {
                const blob = xhr.response;
                const fr = new FileReader();
                fr.readAsDataURL(blob);
                fr.addEventListener('load', () => {
                    const urlData = fr.result;
                    setPictureFile(urlData);
                    setLoadingPicture(false);
                    setLoadingError(false);
                            
                });
            };
            xhr.open('GET', url);
            xhr.send();
        } catch (error) {
            console.error(error);
            setLoadingPicture(false);
            setLoadingError(true);
        }
    }

    async function payByBankTransfer(file) {
        setLoadingPicture(true);
        const currentPath = bill.paymentArrangement.pictureLocation;
        try {
            await firebase.usePayByBankTransfer(file, accountId, currentPath);
            setAlertData({
                type: 'success',
                title: {
                    en: 'Receipt uploaded',
                    es: 'Recibo subido'
                },
                code: '',
                description: {
                    en: "Receipt uploaded successfully. Once we receive this transaction we will send you a confirmation email.",
                    es: "Recibo cargado exitosamente. Una vez que recibamos esta transacción, le enviaremos un correo electrónico de confirmación."
                }
            });
            setAlertActive(true);
            setReceiptError(null);
        } catch (error) {
            setLoadingPicture(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to upload image',
                    es: 'Error al intentar subir imagen'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onChangeImage = (file) => {
        if (file.type.startsWith('image/')) {
            payByBankTransfer(file);
        } else {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open image',
                    es: 'Error al intentar abrir la imagen',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid image.',
                    es: 'Por favor, seleccione una imagen válida.'
                }
            });
            setAlertActive(true);
        }
    }

    useEffect(() => {
        setReceiptError(bill.paymentArrangement ? bill.paymentArrangement.error : null);
        if (bill.paymentArrangement ? (bill.paymentArrangement.pictureLocation ? true : false) : false) {
            getReceiptPicture(bill.paymentArrangement.pictureLocation);
        }
    }, [bill])

    return (
        <div className="bank-account-to-container">
            {bill.paymentArrangement ? <Fragment>
                {receiptError ?
                    <div className="bank-account-to-error-section">

                        <div className="bank-account-to-error-section-div">
                            <img src={wainingIcon} alt="Warning" />
                            <p>Error: {receiptError}</p>
                        </div>

                        <Link
                            to={"/ayuda"}
                        >{appLanguage.en ? "Help" : "Ayuda"}</Link>
                    </div>
                    : null}

                <div className="bank-account-to-first-section">
                    <div className="bank-account-to-bank-logo-desp">
                        <div className="bank-account-to-bank-logo" >
                            <img src={bankImg} alt="bank" />
                        </div>
                        <div>
                            <p className="settings-bills-const-amount-to-pay-p">Banco {bill.paymentArrangement.bank}</p>
                            <p className="settings-bills-const-amount-to-pay-p">{bill.paymentArrangement.account}</p>
                        </div>
                    </div>
                    <div className="bank-account-to-bank-copy-cont">
                        <button
                            onClick={copyAccN}
                        >{appLanguage.en ? "Copy" : "Copiar"}</button>
                    </div>
                </div>
                <div className="bank-account-to-second-section">
                    <div className="bank-account-to-type">
                        <div className="bank-account-to-title">
                            <p className="bank-account-to-p-title"><b>{appLanguage.en ? "ACCOUNT TYPE:" : "TIPO DE CUENTA:"} </b></p>
                        </div>
                        <p>{appLanguage.en ? "Savings" : "Ahorros"}</p>
                    </div>
                    <div className="bank-account-to-currency">
                        <div className="bank-account-to-title">
                            <p className="bank-account-to-p-title"><b>{appLanguage.en ? "CURRENCY:" : "MONEDA:"}</b></p>
                        </div>
                        <p>RD$</p>
                    </div>
                    <hr />
                    <div className="bank-account-to-name">
                        <div className="bank-account-to-title">
                            <p className="bank-account-to-p-title"><b>{appLanguage.en ? "NAME:" : "NOMBRE:"}</b></p>
                            <p>{bill.paymentArrangement.name}</p>
                        </div>
                        <div className="bank-account-to-bank-copy-cont">
                            <button
                                onClick={copyName}
                            >{appLanguage.en ? "Copy" : "Copiar"}</button>
                        </div>
                    </div>
                    <hr />
                </div>
                {loadingPicture ? <div className="bank-account-to-third-section"><Spinner /></div> :
                    <div className="bank-account-to-third-section">
                        {(bill.paymentArrangement ? (bill.paymentArrangement.pictureLocation ? true : false) : false) ?
                            <Fragment>
                                {loadingError ?
                                    <div className="bank-account-individual-product-no-img-big-cont unselectable">
                                        <div className="bank-account-ind-prod-no-img-big-con-div">
                                            <img className="bank-account-individual-product-no-img-big" src={error404} alt="Product" />
                                        </div>
                                    </div>
                                :
                                    <div className="bank-account-individual-product-yes-img-big-cont unselectable">
                                        <div onClick={() => onClickPicture(pictureFile)} className="bank-account-ind-prod-yes-img-big-con-div">
                                            <img className={`bank-account-individual-product-yes-img-big`} src={pictureFile} alt="Product" />
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        : null}
                        {(bill.paymentArrangement ? (bill.paymentArrangement.pictureLocation ? true : false) : false) ?
                            <Fragment>
                                {receiptError ?
                                    <p className="bank-account-to-third-section-voucher-p"><img src={wainingIcon} alt="Check" />{appLanguage.en ? "Receipt uploaded successfully. However, there was a mistake. Once you verify everything, upload the receipt once again." : "Recibo cargado exitosamente. Sin embargo, hubo un error. Una vez que verifiques todo, sube el recibo una vez más."}</p>
                                    :
                                    <p className="bank-account-to-third-section-voucher-p"><img src={checkIcon} alt="Check" />{appLanguage.en ? "Receipt uploaded successfully. Once we receive this transaction we will send you a confirmation email." : "Recibo cargado exitosamente. Una vez que recibamos esta transacción, le enviaremos un correo electrónico de confirmación."}</p>
                                }
                            </Fragment>

                            :
                            <p className="bank-account-to-third-section-voucher-p">{appLanguage.en ? "Upload a photo or screenshot of the transaction receipt or voucher." : "Sube una foto o captura de pantalla del recibo o comprobante de la transacción."}</p>
                        }
                        <label htmlFor="inputTag" className="bank-account-to-bank-upload-butt">
                            <p>{appLanguage.en ? "Upload receipt" : "Subir recibo"}</p>
                            <img src={uploadIconDark} alt="Receipt" />
                            <input
                                id="inputTag"
                                type="file"
                                accept="image/*"
                                value=""
                                onChange={(event) => onChangeImage(event.target.files[0])}
                            />
                        </label>
                    </div>
                }
            </Fragment> : null}
        </div>
    )
}

export default BankAccTo;