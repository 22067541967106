import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { Link } from 'react-router-dom';

import nextIcon from '../../icons/arrow-small-right-white.png';
import returnIcon from '../../icons/cancel-dark.png';

import './settingsActionRequired.scss';

import roadBarrierImg from '../../img/road-barrier.svg';

const SettingsActionRequired = () => {

    const { appLanguage } = useContext(AppContext);
    const { accountData, eliminateAllCustomers } = useContext(AuthContext);

    const [ title, setTitle ] = useState(null);
    const [ despc, setDescription ] = useState(null);
    const [ buttonText, setButtonText ] = useState(null);
    const [ buttonLink, setButtonLink ] = useState(null);

    const getState = () => {
        if (accountData.status.type === "customer") {
            setTitle(appLanguage.en ? 'Reactivate your account' : 'Reactiva tu cuenta');
            setDescription(appLanguage.en ? 
                "You have canceled your subscription, but remember that you can return at any time and enjoy a tool that will make you more productive." 
            : 
                "Has cancelado tu suscripción, pero recuerda que puedes volver en cualquier momento y disfrutar de una herramienta que te hará más productivo."
            );
            setButtonText(appLanguage.en ? 'See more' : 'Ver más');
            setButtonLink('/detalles-del-plan');
            
        } else {
            if (accountData.status.type === "nopay") {
                setTitle(appLanguage.en ? 'Non-payment' : 'Falta de pago');
                setDescription(appLanguage.en ? 
                    "Your account has been disconnected because you have an unpaid balance. Please make the payment to reactivate the account." 
                : 
                    "Su cuenta ha sido desconectada porque tiene un saldo sin pagar. Por favor realice el pago para reactivar la cuenta."
                );
                setButtonText(appLanguage.en ? 'Review invoice' : 'Revisar factura');
                setButtonLink('/facturas');
            } else {
                if (accountData.status.type === "paymentPending") {
                    setTitle(appLanguage.en ? 'Payment required' : 'Pago requerido');
                    setDescription(appLanguage.en ? 
                        "You must pay the first month to activate your business account." 
                    : 
                        "Debes pagar el primer mes para activar tu cuenta de negocios."
                    );
                    setButtonText(appLanguage.en ? 'Review invoice' : 'Revisar factura');
                    setButtonLink('/facturas');
                } else {
                    setTitle(appLanguage.en ? 'Action required' : 'Acción requerida');
                    setDescription(appLanguage.en ? 
                        "This account has been disconnected by the Aztonia team. For more information contact us." 
                    : 
                        "Esta cuenta ha sido desconectada por el equipo de Aztonia. Para más información contactanos."
                    );
                    setButtonText(appLanguage.en ? 'Help' : 'Ayuda');
                    setButtonLink('/ayuda');
                }
            }
        }
    }
    
    useEffect(() => {
        getState();
    // eslint-disable-next-line
    }, [accountData, appLanguage]);

    const closeAccount = () => {
        eliminateAllCustomers();
    }

    return (
        <div className={`settings-action-required`}>
            <div className='settings-action-required-cont-img'>
                <div className='settings-action-required-cont-img-div'>
                    <img
                        src={roadBarrierImg}
                        className='settings-action-required-img'
                        alt='System upgrade'
                    />
                </div>
            </div>
            <div className='settings-action-required-cont-title'>
                <p><b>{appLanguage.en ? "*** Disconnected account ***" : "*** Cuenta desconectada ***"}</b></p>
            </div>
            <div className={`settings-action-required-cont-despcrition `}>
                <div className='settings-action-required-cont-despcrition-cont'>
                    <p><b>{title}</b></p>
                    <br/>
                    <p>{despc}</p>
                    <br/>
                </div>

                <div className='settings-action-required-cont-butt'>
                    <div>
                        <button
                            className='settings-action-required-butt'
                            onClick={closeAccount}
                        >
                            <img
                                src={returnIcon}
                                alt="Return button"
                                className='settings-action-required-butt-img'
                            />
                            <p className='settings-action-required-butt-text' >{appLanguage.en ? "Decline" : "Rechazar"}</p>
                        </button>
                    </div>
                    <Link style={{textDecoration: "none"}} to={buttonLink}>
                        <button
                            className='settings-action-required-butt'
                        >
                            <img
                                src={nextIcon}
                                alt="Next button"
                                className='settings-action-required-butt-img'
                            />
                            <p className='settings-action-required-butt-text' >{buttonText}</p>
                        </button>
                    </Link>
                </div>
            </div>
            
        </div>
    )
}

export default SettingsActionRequired;