import { Fragment, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import Spinner from "../../../components/spinner/spinner";

import deleteIcon from "../../../icons/closed-dark.png";
import editIcon from "../../../icons/signature-dark.png";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import firebase from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const SupplierDetails = ({record, setShowingEditingInfo}) => {
    const { appLanguage, appTheme, getDateInStringWithFullMonth, formatPhoneNumber } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);
    
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const copyAccountNumber = () => {
        const text = record.id;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The record number has been copied',
                    es : 'El número de registro ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyDate = () => {
        const text = getDateInStringWithFullMonth(new Date(record.createdAt));

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The date has been copied',
                    es : 'La fecha ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyEmail = () => {
        const text = record.email;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The email has been copied',
                    es : 'El correo ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyPhoneNumber = () => {
        const text = record.phone;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The phone number has been copied',
                    es : 'El número de teléfono ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyName = () => {
        const text = record.name;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The name has been copied',
                    es : 'El nombre ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyAddress = () => {
        const text = record.address;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The address has been copied',
                    es : 'La dirección ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const copyRNC = () => {
        const text = record.rnc;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'The RNC has been copied',
                    es : 'El RNC ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Copy error',
                    es : 'Error al copiar',
                }
            });
        }
    }

    const navigate = useNavigate();
 
    const [ loading, setLoading ] = useState(false);

    const onEdict = () => {
        setShowingEditingInfo(true);
    }

    async function deleteSupplier() {
        setLoading(true);
        setAlertActive(false);

        const accountId = accountData.id.toString();
        const supplierId = record.id;

        try {
            await firebase.useDeleteSupplier({
                "accountId": accountId,
                "supplierId": supplierId
            });

            setAlertData({
                type: 'success',
                title: {
                    en: 'The record has been deleted',
                    es: 'El registro ha sido eliminado'
                },
                code: '',
                description: {
                    en: `The record # ${record.id} from ${record.name} has been successfully deleted.`,
                    es: `El registro # ${record.id} de ${record.name} se han eliminado correctamente.`
                }
            });
            setAlertActive(true);

            const recordsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (recordsDeleted) {
                const recordsIdsDeleted = [...recordsDeleted];
                recordsIdsDeleted.push(supplierId);
                localStorage.setItem("itemsDeleted", JSON.stringify(recordsIdsDeleted));
            } else {
                localStorage.setItem("itemsDeleted", JSON.stringify([supplierId]));
            }

            navigate(-2);
            
        } catch (error)  {
            console.error(error);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error when trying to delete supplier',
                    es : 'Error al tratar de eliminar el proveedor'
                },
                code : "error",
                description : getErrorDescription("error")
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type : 'question',
            title: {
                en : 'Delete supplier record?',
                es : '¿Eliminar registro del cliente?'
            },
            code : '',
            description : {
                en : `Are you sure you want to remove record # ${record.id} from ${record.name}?`,
                es : `¿Estás seguro de que deseas eliminar el registro # ${record.id} de ${record.name}?`
            }
        });
        setOnAgree(() => deleteSupplier);
        setAlertActive(true);
    }

    return (
        <Fragment> { loading ? <div className="more-details-spinner-container"><Spinner /></div> :
            <div className={`customer-detaines-info-cont `}> 
                <br />

                <div className={`customer-more-details-section-container ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}>
                    <div className="customer-more-details-section-title-cont">
                        <p className="new-customer-title"><b>{appLanguage.en ? "Supplier details" : "Detalles del proveedor"}</b></p>
                    </div>
                    <br/>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Record # " : "Registro # "} </span> {record.id.trim() !== "" ? <span onClick={copyAccountNumber} className="customer-detaines-info-p-span-cont">{record.id}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Creation date: " : "Fecha de creación: "} </span> {record.id.trim() !== "" ? <span onClick={copyDate} className="customer-detaines-info-p-span-cont">{getDateInStringWithFullMonth(new Date(record.createdAt))}</span> : ""}</p> 
                    <br/>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Name: " : "Nombre: "} </span> {record.name.trim() !== "" ? <span onClick={copyName} className="customer-detaines-info-p-span-cont">{record.name}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Phone: " : "Teléfono: "} </span>{record.phone.trim() !== "" ? <span onClick={copyPhoneNumber} className="customer-detaines-info-p-span-cont">{formatPhoneNumber(record.phone)}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Email: " : "Correo: "} </span>{record.email.trim() !== "" ? <span onClick={copyEmail} className="customer-detaines-info-p-span-cont">{record.email}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "RNC: " : "RNC: "} </span>{record.rnc.trim() !== "" ? <span onClick={copyRNC} className="customer-detaines-info-p-span-cont">{record.rnc}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Address: " : "Dirección: "} </span>{record.address.trim() !== "" ? <span onClick={copyAddress} className="customer-detaines-info-p-span-cont">{record.address}</span> : ""}</p>
                </div>
                <div className="new-customer-bottons-container2">
                    <button 
                        onClick={e => onDelete(e)} 
                        className="new-customer-bottons-button-save"
                    >
                        <img src={deleteIcon} alt="Delete" />
                        {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                    </button>
                    <button 
                        onClick={e => onEdict(e)} 
                        className="new-customer-bottons-button-save"
                    >
                        <img src={editIcon} alt="Save" />
                        {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                    </button>
                </div>
                <br/>
            </div>
        }</Fragment>
    );
}

export default SupplierDetails;