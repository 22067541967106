import './spinnerOutSideLight.css';

const SpinnerOutSideLight = () => {
    return (
        <div className="sk-fading-circle-light">
            <div className="sk-circle1-light sk-circle-light"></div>
            <div className="sk-circle2-light sk-circle-light"></div>
            <div className="sk-circle3-light sk-circle-light"></div>
            <div className="sk-circle4-light sk-circle-light"></div>
            <div className="sk-circle5-light sk-circle-light"></div>
            <div className="sk-circle6-light sk-circle-light"></div>
            <div className="sk-circle7-light sk-circle-light"></div>
            <div className="sk-circle8-light sk-circle-light"></div>
            <div className="sk-circle9-light sk-circle-light"></div>
            <div className="sk-circle10-light sk-circle-light"></div>
            <div className="sk-circle11-light sk-circle-light"></div>
            <div className="sk-circle12-light sk-circle-light"></div>
        </div>
    );
}

export default SpinnerOutSideLight;