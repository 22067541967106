import { useContext, useState, Fragment, useEffect } from 'react';
import firebase from '../../firebase/firebase';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../alert/alertContext';
import { AuthContext } from '../../firebase/context';
import crossIcon from "../../icons/cross-white.png";
import saveIcon from "../../icons/check-dark.png";

import './settingsEdictAcc.scss';

import Spinner from '../spinner/spinner';

const SettingsEdictAcc = () => {

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, setAccountData } = useContext(AuthContext);
    const [ loadingInfo, setLoadingInfo ] = useState(false);

    const [ showCancel, setShowCancel ] = useState(false);
    const [ showSave, setShowSave ] = useState(false);

    const [businessAcc, setBusinessAcc] = useState({
        businessName: accountData.businessName,
        businessEmail: accountData.businessEmail,
    });

    const [businessNameAlert, setBusinessNameAlert] = useState({
        alert: false
    });

    const onChange = e => {
        if (e.target.name === "businessName") {
            setBusinessNameAlert({
                alert: false,
            });
        }

        setBusinessAcc({
            ...businessAcc,
            [e.target.name]: e.target.value
        });
    };

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    async function updateUser() {
        setLoadingInfo(true);
        setShowSave(false);
        setShowCancel(false);

        try {
            await firebase.useEdictAccount({
                businessId: accountData.id,
                businessName: businessAcc.businessName.trim(),
                businessEmail: businessAcc.businessEmail.trim(),
            });

            // Update local State
            setShowSave(false);
            setShowCancel(false);

            setAccountData({
                ...accountData,
                "businessName": businessAcc.businessName.trim(),
                "businessEmail": businessAcc.businessEmail.trim(),
            });

            localStorage.setItem("isAccountData", JSON.stringify({
                ...accountData,
                "businessName": businessAcc.businessName.trim(),
                "businessEmail": businessAcc.businessEmail.trim(),
            }));

            setAlertData({
                type: 'success',
                title: {
                    en: 'Updated data',
                    es: 'Datos actualizados'
                },
                code: '',
                description: {
                    en: 'Your account data has been successfully updated.',
                    es: 'Los datos de tu cuenta se han actualizado correctamente.'
                }
            });
            setAlertActive(true);
            setLoadingInfo(false);

        } catch (error) {
            setLoadingInfo(false);
            setShowCancel(true);
            setShowSave(true);

            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to update account',
                    es: 'Error al intentar actualizar la cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const validar = e => {
        e.preventDefault();

        if (businessAcc.businessName.trim() === "") {
            setBusinessNameAlert({
                alert: true
            });
            return;
        } else {
            if (businessAcc.businessEmail.trim() === "") {
                return;
            } else {
                if(!validateEmail(businessAcc.businessEmail.trim())) {
                    return;
                }
            }
        }

        updateUser();
    }

    const cancel = () => {
        setBusinessNameAlert({
            alert: false
        });
        setBusinessAcc({
            businessName: accountData.businessName,
            businessEmail: accountData.businessEmail,
        });
        setShowCancel(false);
        setShowSave(false);
    }

    useEffect(() => {
        if((accountData.businessName === businessAcc.businessName) && (accountData.businessEmail === businessAcc.businessEmail)) {
            setShowCancel(false);
            setShowSave(false);
        } else {
            setShowCancel(true);
            setShowSave(true);
        }
    // eslint-disable-next-line
    }, [businessAcc]);

    return (
        <div className={`settings-edict-acc-container unselectable ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <div className='settings-edict-acc-container-first'>
                {loadingInfo ?
                    <Fragment>
                        <Spinner />
                    </Fragment> :
                    <div  className='settings-edict-acc-input-container'>
                        <div className='settings-edict-acc-div-text'>
                            <div className='settings-edict-acc-div-text-div'>
                                <label htmlFor="businessName" className='settings-edict-acc-div-label'>
                                    {appLanguage.en ? 'Business name: ' : 'Nombre del negocio: '} <b>*</b>
                                </label>
                                <input
                                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                    className={`settings-edict-acc-input-text ${businessNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                    type="text"
                                    name="businessName"
                                    id="businessName"
                                    maxLength="22"
                                    value={businessAcc.businessName}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='settings-edict-acc-div-text'>
                            <div className='settings-edict-acc-div-text-div'>
                                <label htmlFor="businessEmail" className='settings-edict-acc-div-label'>
                                    {appLanguage.en ? 'Business email: ' : 'Correo del negocio: '} <b></b>
                                </label>
                                <input
                                    style={ appTheme.dark ? { colorScheme: "dark", border: "none", boxShadow: "none"} : {colorScheme: "light", border: "none", boxShadow: "none"}}
                                    className={`settings-edict-acc-input-text-read-only ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                    type="text"
                                    disabled
                                    name="businessEmail"
                                    id="businessEmail"
                                    value={businessAcc.businessEmail}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className='settings-edict-acc-cont-div-buttons'>
                    <div className="new-customer-bottons-container2">
                        <button 
                            onClick={cancel} 
                            disabled={!showCancel}
                            className="new-customer-bottons-button-save"
                            style={!showCancel ? {
                                backgroundColor: "#3f526698", 
                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                cursor: "default"
                            } : null}
                        >
                            <img src={crossIcon} alt="Cancel" />
                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                        </button>
                        <button 
                            onClick={validar}
                            disabled={!showSave}
                            className="new-customer-bottons-button-save"
                            style={!showSave ? {
                                backgroundColor: "#3f526698", 
                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                cursor: "default"
                            } : null}
                        >
                            <img src={saveIcon} alt="Save" />
                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsEdictAcc;