import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const MovementDetailsContext = createContext();

const MovementDetailsProvider = (props) => {

    const location = useLocation();

    const [ movementDetailsActive, setMovementDetailsActive ] = useState(false);
    const [ transaction, setTransaction] = useState(null);

    useEffect(() => {
        if (!movementDetailsActive) {
            setTransaction(null);
        }
    }, [movementDetailsActive]);

    useEffect(() => {
        if (movementDetailsActive) {
            if (location.pathname !== "/movimientos-del-producto") {
                setMovementDetailsActive(false);
            }
        }
    // eslint-disable-next-line
    }, [location])
 
    return (
        <MovementDetailsContext.Provider
            value={{ 
                movementDetailsActive,
                transaction,
                setMovementDetailsActive,
                setTransaction
            }}
        >
            {props.children}
        </MovementDetailsContext.Provider>
    )
}

export default MovementDetailsProvider;