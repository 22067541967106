import { Fragment, useContext, useEffect, useState} from "react";
import "./generalResults.scss";

import { Link } from "react-router-dom";

import { AppContext } from "../../../context/AppContext";

import arrowIconDark from '../../../icons/arrow-small-right-white.png';

import ProductMiniCard from "../productMiniCard/productMiniCard";
import CustomerCard from "../customerCard/customerCard";
import SupplierCard from "../supplierCard/supplierCard";

import SaleMiniCard from "../saleMiniCard/saleMiniCard";
import { AuthContext } from "../../../firebase/context";

const GeneralResults = ({results, activeId, setActiveReference, onClickLink, width, searchingText}) => {

    const {appLanguage} = useContext(AppContext);
    const { productsStatistics, customersStatistics, suppliersStatistics, salesStatistics } = useContext(AuthContext);

    const [ productsPageState, setProductsPageState ] = useState(results.products ? {
        "results": results.products.list,
        "filter": null,
        "docsCount": null,
        "controler": {
            "filter": "name",
            "category": "all",
            "priceFrom": null,
            "priceTo": null,
            "searchingText": searchingText,
            "triggerSearch": true,
        },
        "pagesHistory": [],
        "navControlers": {
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
        },
        "showConnectionError": false,
        "empty": false,
        "showPageNav": false,
        "activeId": null
    } : null);

    useEffect(() => {
        if (results.products) {
            setProductsPageState({
                "results": results.products.list,
                "filter": null,
                "docsCount": null,
                "controler": {
                    "filter": "name",
                    "category": "all",
                    "priceFrom": null,
                    "priceTo": null,
                    "searchingText": searchingText,
                    "triggerSearch": true,         
                },
                "pagesHistory": [],
                "navControlers": {
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                },
                "showConnectionError": false,
                "empty": false,
                "showPageNav": false,
                "activeId": null,
                "loading": true,
            });
        } else {
            setProductsPageState(null);
        }
    // eslint-disable-next-line
    }, [results, productsStatistics]);

    const [ customersPageState, setCustmomersPageState ] = useState(results.customers ? {
        "results": results.customers.list,
        "docsCount": null,
        "controler": {
            "searchingText": searchingText,
            "triggerSearch": true,
        },
        "pagesHistory": [],
        "navControlers": {
            "page": 1,
            "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
        },
        "showConnectionError": false,
        "empty": false,
        "showPageNav": false,
        "activeId": null,
        "loading": true,
    } : null);

    useEffect(() => {
        if (results.customers) {
            setCustmomersPageState({
                "results": results.customers.list,
                "docsCount": null,
                "controler": {
                    "searchingText": searchingText,
                    "triggerSearch": true,
                },
                "pagesHistory": [],
                "navControlers": {
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                },
                "showConnectionError": false,
                "empty": false,
                "showPageNav": false,
                "activeId": null,
                "loading": true,
            });
        } else {
            setCustmomersPageState(null);
        }
    // eslint-disable-next-line
    }, [results, customersStatistics]);

    const [ suppliersPageState, setSuppliersPageState ] = useState(results.suppliers ? {
        "results": results.suppliers.list,
        "docsCount": null,
        "controler": {
            "searchingText": searchingText,
            "triggerSearch": true,
        },
        "pagesHistory": [],
        "navControlers": {
            "page": 1,
            "totalPage": suppliersStatistics ? Math.ceil(suppliersStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": suppliersStatistics ? (Math.ceil(suppliersStatistics.all / 30) > 1 ? true : false) : false
        },
        "showConnectionError": false,
        "empty": false,
        "showPageNav": false,
        "activeId": null,
        "loading": true,
    } : null);

    useEffect(() => {
        if (results.suppliers) {
            setSuppliersPageState({
                "results": results.suppliers.list,
                "docsCount": null,
                "controler": {
                    "searchingText": searchingText,
                    "triggerSearch": true,
                },
                "pagesHistory": [],
                "navControlers": {
                    
                    "page": 1,
                    "totalPage": suppliersStatistics ? Math.ceil(suppliersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": suppliersStatistics ? (Math.ceil(suppliersStatistics.all / 30) > 1 ? true : false) : false
                },
                "showConnectionError": false,
                "empty": false,
                "showPageNav": false,
                "activeId": null,
                "loading": true,
            });
        } else {
            setSuppliersPageState(null);
        }
    // eslint-disable-next-line
    }, [results, suppliersStatistics]);

    const [ salesPageState, setSalesPageState ] = useState(results.sales ? {
        "results": results.sales.list,
        "filter": null,
        "docsCount": null,
        "authUsers": [],
        "controler": {
            "filter": "all",
            "dateFrom": null,
            "dateTo": null,
            "searchingText": searchingText,
            "triggerSearch": true,
        },
        "pagesHistory": [],
        "navControlers": {
            "page": 1,
            "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
        },
        "showConnectionError": false,
        "empty": false,
        "showPageNav": false,
        "activeId": null,
        "loading": true,
    } : null);

    useEffect(() => {
        if (results.sales) {
            setSalesPageState({
                "results": results.sales.list,
                "filter": null,
                "docsCount": null,
                "authUsers": [],
                "controler": {
                    "filter": "all",
                    "dateFrom": null,
                    "dateTo": null,
                    "searchingText": searchingText,
                    "triggerSearch": true,
                },
                "pagesHistory": [],
                "navControlers": {
                    "page": 1,
                    "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
                },
                "showConnectionError": false,
                "empty": false,
                "showPageNav": false,
                "activeId": null,
                "loading": true,
            });
        } else {
            setSalesPageState(null);
        }
    // eslint-disable-next-line
    }, [results, salesStatistics]);
    
    return (
        <div style={{paddingBottom: "60px"}}>
            {/** Products results */}
            {results.products ? <Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "30px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "calc(100% - 25px)",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                        }}
                    >
                        <p style={{fontSize: "18px"}}>{appLanguage.en ? "Results for products" : "Resultados para productos"} ({results.products.counter})</p>
                    </div>
                </div>
                <div 
                    className="products-cards-items"
                    style={{
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    {results.products.list.map((product) => {
                        return (
                            <ProductMiniCard
                                key={product.id}
                                product={product}
                                activeId={activeId}
                                setActiveReference={setActiveReference}
                                onClickLink={onClickLink}
                                containerWidth={width}
                                cardMaxWidth={"600px"}
                            />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <Link 
                        className="see-more-results-button-go"
                        state={productsPageState}
                        to={"/productos"}
                    >
                        <p>{appLanguage.en ? "See more" : "Ver más"}</p>
                        <img src={arrowIconDark} alt="Go" />
                    </Link>
                </div>
            </Fragment> : null}

            {/** Customer results */}
            {results.customers ? <Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "45px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "calc(100% - 25px)",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                        }}
                    >
                        <p style={{fontSize: "18px"}}>{appLanguage.en ? "Results for clients" : "Resultados para clientes"} ({results.customers.counter})</p>
                    </div>
                </div>
                <div 
                    className="products-cards-items"
                    style={{
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    {results.customers.list.map((customer) => {
                        return (
                            <CustomerCard
                                key={customer.id}
                                account={customer}
                                activeId={activeId}
                                setActiveReference={setActiveReference}
                                onClickLink={onClickLink}
                                selecting={false}
                                iAmSelected={false}
                            />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <Link 
                        className="see-more-results-button-go"
                        state={customersPageState}
                        to={"/clientes"}
                    >
                        <p>{appLanguage.en ? "See more" : "Ver más"}</p>
                        <img src={arrowIconDark} alt="Go" />
                    </Link>
                </div>
            </Fragment> : null}

            {/** Supplier results */}
            {results.suppliers ? <Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "45px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "calc(100% - 25px)",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                        }}
                    >
                        <p style={{fontSize: "18px"}}>{appLanguage.en ? "Results for suppliers" : "Resultados para proveedores"} ({results.suppliers.counter})</p>
                    </div>
                </div>
                <div 
                    className="products-cards-items"
                    style={{
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    {results.suppliers.list.map((supplier) => {
                        return (
                            <SupplierCard
                                key={supplier.id}
                                account={supplier}
                                activeId={activeId}
                                setActiveReference={setActiveReference}
                                onClickLink={onClickLink}
                                selecting={false}
                                iAmSelected={false}
                            />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <Link 
                        className="see-more-results-button-go"
                        state={suppliersPageState}
                        to={"/proveedores"}
                    >
                        <p>{appLanguage.en ? "See more" : "Ver más"}</p>
                        <img src={arrowIconDark} alt="Go" />
                    </Link>
                </div>
            </Fragment> : null}

            {/** Sales results */}
            {results.sales ? <Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "45px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "calc(100% - 25px)",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                        }}
                    >
                        <p style={{fontSize: "18px"}}>{appLanguage.en ? "Results for sales" : "Resultados para ventas"} ({results.sales.counter})</p>
                    </div>
                </div>
                <div 
                    className="products-cards-items"
                    style={{
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    {results.sales.list.map((sale) => {
                        return (
                            <SaleMiniCard
                                key={sale.id}
                                sale={sale}
                                activeId={activeId}
                                setActiveReference={setActiveReference}
                                onClickLink={onClickLink}
                                width={width}
                            />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center"
                    }}
                >
                    <Link 
                        className="see-more-results-button-go"
                        state={salesPageState}
                        to={"/ventas"}
                    >
                        <p>{appLanguage.en ? "See more" : "Ver más"}</p>
                        <img src={arrowIconDark} alt="Go" />
                    </Link>
                </div>
            </Fragment> : null}
            
        </div>
    );
}

export default GeneralResults;