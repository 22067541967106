import { useContext } from 'react';
import { AppContext } from "../../../context/AppContext";
import { SoftLoadingContext } from '../../soft-loading/softLoadingContext';

import './goBackButton.scss';

import refreshDark from '../../../icons/refresh-dark.png';
import refreshLight from '../../../icons/refresh-light.png';

const RefreshButton = () => {

    const { appTheme } = useContext(AppContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);

    const onReload = () => {
        setSoftLoadingActive(true);
        window.location.reload(false);
    }

    return (
        <button 
            className={`navigation-container-go-back  ${appTheme.dark ? 'border-box-dark navigation-container-go-back-dark' : 'border-box-light navigation-container-go-back-light'}`}
            onClick={onReload}
        >
            <div className={`navigation-container-go-back-item`}>
                <img 
                    className='icon-navigation' 
                    src={appTheme.dark ? refreshDark : refreshLight} 
                    alt="Refresh" 
                />
            </div>
        </button>
    )
}

export default RefreshButton;
