import "./productMiniCard.scss";
import { useNavigate } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import noPicture from "../../../img/no-picture.png";
import noReady from "../../../img/nothing.png";
import error404 from "../../../img/404-error.png";

import addImg from "../../../icons/add-to-cart-dark.png";

import removeImgLight from "../../../icons/remove-from-cart-light.png";

import firebase from "../../../firebase";
import {ref, getDownloadURL} from "firebase/storage";

const ProductMiniCard = ({ product, activeId, setActiveReference, onClickLink, containerWidth, cardMaxWidth}) => {

    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber} = useContext(AppContext);
    const { setPictureInMemory, removeFromShoppingCart, addToShoppingCart, accessTo, isItemInShoppingCart } = useContext(AuthContext);

    const [pictureData, setPictureData ] = useState(null);
    const [loadingError, setLoadingError] = useState(false);

    const navigate = useNavigate();

    const [noImgFile, setNotImgFile] = useState(() => {
        if (product.picturesLocation) {
            if (product.picturesLocation[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    });

    useEffect(() => {
        if (product.picturesLocation && product.id) {
            if (product.picturesLocation[0]) {
                const path = product.picturesLocation[0];

                if (!pictureData) {
                    checkIfImageExistInPr(path);
                } else {
                    if (path !== pictureData.location) {
                        checkIfImageExistInPr(path);
                    }
                }
            } else {
                setPictureData(null);
            }
        } else {
            setPictureData(null);
        }

        setNotImgFile(() => {
            if (product.picturesLocation) {
                if (product.picturesLocation[0]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        // eslint-disable-next-line
    }, [product]);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d300x300";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d300x300";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        let requestCounter = 6;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions !== "_600x600") {
                            setPictureData({
                                pictureFile: urlData,
                                location: path
                            });
                        }
                        setLoadingError(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    console.error(error);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const [ normalClassName, setNormalClassName] = useState(`product-mini-view-card-div unselectable ${appTheme.dark ? (isTouchEnabled() ? "product-mini-view-card-div-dark-NOHOVER" : "product-mini-view-card-div-dark-HOVER") : (isTouchEnabled() ? "product-mini-view-card-div-light-NOHOVER" : "product-mini-view-card-div-light-HOVER")}`);
    const [ focusClassName, setFocusClassName] = useState(`product-mini-view-card-div unselectable ${appTheme.dark ? (isTouchEnabled() ? "product-mini-view-focus-card-div-dark-NOHOVER" : "product-mini-view-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "product-mini-view-focus-card-div-light-NOHOVER" : "product-mini-view-focus-card-div-light-HOVER")}`);

    useEffect(() => {
        setNormalClassName(`product-mini-view-card-div unselectable ${appTheme.dark ? (isTouchEnabled() ? "product-mini-view-card-div-dark-NOHOVER" : "product-mini-view-card-div-dark-HOVER") : (isTouchEnabled() ? "product-mini-view-card-div-light-NOHOVER" : "product-mini-view-card-div-light-HOVER")}`);
        setFocusClassName(`product-mini-view-card-div unselectable ${appTheme.dark ? (isTouchEnabled() ? "product-mini-view-focus-card-div-dark-NOHOVER" : "product-mini-view-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "product-mini-view-focus-card-div-light-NOHOVER" : "product-mini-view-focus-card-div-light-HOVER")}`);
    // eslint-disable-next-line
    }, [appTheme]);

    const addToCart = () => {
        addToShoppingCart(product);
    }

    const removeFromCart = () => {
       removeFromShoppingCart(product.id);
    }

    const handleLinkClick = () => {
        onClickLink(product.id);
        navigate('/ver-producto', { state: product });
    }

    return (
        <div 
            key={product.id}
            ref={product.id === activeId ? setActiveReference : null}
            state={product}
            style={{maxWidth: cardMaxWidth}}
            className={product.id === activeId ? focusClassName : normalClassName}
        >
            {noImgFile && (!loadingError) ?
                <div onClick={handleLinkClick} className="products-mini-view-product-yes-img-cont">
                    <div style={{ width: "75px", height: "75px" }}>
                        <img className={` products-mini-view-product-yes-img`} src={pictureData ? pictureData.pictureFile : noReady} alt="Product" />
                    </div>
                </div>
                :
                <div onClick={handleLinkClick} className="products-mini-view-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                    <img className="products-mini-view-product-no-img" src={loadingError ? error404 : noPicture} alt="Product" />
                </div>
            }
            
            <div
                onClick={handleLinkClick}
                style={{
                    flexGrow: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "15px",
                    paddingRight: containerWidth > 650 ? "" : "60px"
                }}
            >
                <p className="products-individual-product-name products-mini-view-name-overflow"><b>{product.name}</b></p>
                <p className="products-individual-product-price" style={{ color: appTheme.dark ? "#a7d0ff" : "#2089ff" }}>RD$ {formatRationalNumber(product.finalPrice)}</p>
            </div>
            
            {containerWidth > 650 ? 
                <div 
                    onClick={handleLinkClick}
                    style={{
                        maxWidth: "50%",
                        minWidth: "50%",
                        paddingLeft: "10px",
                        paddingRight: "60px"
                    }}
                >
                    <p className="products-mini-view-texto-overflow" style={{padding: "8px"}}><b>{appLanguage.en ? "Description" : "Descripción"}</b>: {product.description ? product.description : "--"}</p>
                </div>
            : null}
            
            {accessTo.sales ?
                isItemInShoppingCart(product.id) ?
                    <button
                        onClick={removeFromCart}
                        className={isTouchEnabled() ? "remove-from-card-cart-btn-product-no-hover" : "remove-from-card-cart-btn-product"}
                        style={{
                            padding: "5px",
                            width: "50px",
                            minWidth: "50px",
                            height: "50px",
                            borderRadius: "100px",
                            marginLeft: "-50px",
                            marginTop: "11px"
                        }}
                    >
                        <img
                            src={removeImgLight}
                            alt="Remove from cart"
                            style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "2px"
                            }}
                        />
                    </button>
                :
                    <button
                        onClick={addToCart}
                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                        style={{
                            padding: "5px",
                            width: "50px",
                            minWidth: "50px",
                            height: "50px",
                            borderRadius: "100px",
                            marginLeft: "-50px",
                            marginTop: "11px"
                        }}
                    >
                        <img
                            src={addImg}
                            alt="Add to cart"
                            style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "2px"
                            }}
                        />
                    </button>
                : null
            }
            
        </div>
    );
}

export default ProductMiniCard;