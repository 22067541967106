import { useContext } from 'react';
import { AppContext } from "../../../context/AppContext";

import './goBackButton.scss';

import arrowLeftDark from '../../../icons/arrow-left-dark.png';
import arrowLeftLight from '../../../icons/arrow-left-light.png';

const GoBackButtonLock = ({setUsePassword}) => {

    const { appTheme } = useContext(AppContext);
    
    return (
        <button 
            className={`navigation-container-go-back ${appTheme.dark ? 'dar-sec-bg' : 'lig-sec-bg'}`}
            onClick={() => {
                setUsePassword(false);
            }}
        >
            <div className={`navigation-container-go-back-item ${appTheme.dark ? 'dar-box-sha-none-hover ' : 'lig-box-sha-none-hover '} ${appTheme.dark ? 'dar-pri-bg' : 'lig-pri-bg'}`}>
                <img 
                    className='icon-navigation' 
                    src={appTheme.dark ? arrowLeftDark : arrowLeftLight} 
                    alt="menuBurger-img" 
                />
            </div>
        </button>
    )
}

export default GoBackButtonLock;
