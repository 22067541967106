import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../firebase/context";

const UnverifiedUserRoutes = () => {
    const { auth } = useContext(AuthContext);
    return (
        auth ? <Navigate to="/" replace /> : <Outlet/>
    );
}

export default UnverifiedUserRoutes;