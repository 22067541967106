import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { Link } from 'react-router-dom';

import './settingsSubcripton.scss';
import subscriptionImg from '../../img/subscription-model.svg';

const SettingsSubcripton = ({bill}) => {

    const { appTheme, appLanguage, getPlanNameAbbre, formatRationalNumber } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);

    const [ isAnyExtraCharge, setIsAnyExtraCharge ] = useState(bill ? (bill.extraUser > 0 ? true : false) : null);

    const [ montlyCharges, setMontlyCharges ] = useState(null);

    useEffect(()=> {
        if(bill) {
            setIsAnyExtraCharge(bill.extraUser > 0 ? true : false);
            if (bill.extraUser > 0) {
                setMontlyCharges(bill.planPrice + (bill.extraUserPrice * bill.extraUser));
            } else {
                setMontlyCharges(bill.planPrice);
            }
        }
    // eslint-disable-next-line
    }, [bill]);

    return (
        <div className={`settings-subcription ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <div className='settings-subcription-cont-img'>
                <div className='settings-subcription-cont-img-div'>
                    <img 
                        src={subscriptionImg} 
                        className='settings-subcription-img'
                        alt='System upgrade' 
                    />
                </div>
                <p><b>{appLanguage.en ? "Thank you for choosing us" : "Gracias por elegirnos"}</b></p>
            </div>

            {bill ? 
                <div className='settings-subcription-cont-adv'>
                    <div className='settings-subcription-cont-adv-p-div'>
                        <p className='settings-subcription-cont-adv-p'>
                            {appLanguage.en ? "Current plan: " : "Plan actual: "}
                            {appLanguage.en ? <b>{getPlanNameAbbre(accountData.plan)}</b> : <b>{getPlanNameAbbre(accountData.plan)}</b>}
                        </p>
                        {isAnyExtraCharge ? 
                            <p className='settings-subcription-cont-adv-p'>
                                {appLanguage.en ? "Price: " : "Precio del plan: "}
                                RD$ <b>{formatRationalNumber(bill.planPrice)}</b>
                            </p>
                        : 
                            <p className='settings-subcription-cont-adv-p'>
                                {appLanguage.en ? "Extra charges: " : "Cargos extras: "}
                                <b>{bill.extraUser} </b> {appLanguage.en ? "authorized users" : "usuarios autorizados"} (RD$ <b>{formatRationalNumber(bill.extraUserPrice)}</b> {appLanguage.en ? "each" : "cada uno"})
                            </p>
                        }
                        
                    </div>
                    <div className='settings-subcription-cont-adv-p-div'>
                        {isAnyExtraCharge ? 
                            <p className='settings-subcription-cont-adv-p'>
                                {appLanguage.en ? "Extra charges: " : "Cargos extras: "}
                                <b>{bill.extraUser} </b> {appLanguage.en ? "authorized users" : "usuarios autorizados"} (RD$ <b>{formatRationalNumber(bill.extraUserPrice)}</b> {appLanguage.en ? "each" : "cada uno"})
                                <br/>
                            </p> 
                        : null}
                        <p className='settings-subcription-cont-adv-p'>
                            {appLanguage.en ? "Total monthly charge: " : "Cargo mensual total: "}
                            RD$ <b>{montlyCharges ? formatRationalNumber(montlyCharges) : "--"}</b>
                        </p>
                    </div>
                </div> 
            : null}
            
            <div className='settings-subcription-cont-butt'>
                <Link 
                    className='settings-subcription-cont-butt-link' 
                    to={"/cancelar-suscripcion"}
                >
                    <button
                        className='settings-subcription-butt'
                    >
                        {appLanguage.en ? "Cancel subscription" : "Cancelar suscripción"}
                    </button>
                </Link>
                <Link 
                    className='settings-subcription-cont-butt-link' 
                    to={"/detalles-del-plan"}
                >
                    <button
                        className='settings-subcription-butt'
                    >
                        {appLanguage.en ? "Plan details" : "Detalles del plan"}
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default SettingsSubcripton;