import { Fragment, useContext, useEffect, useState } from 'react';
import { uid } from 'uid';

import firebase from '../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";

import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsSubcripton from '../../../components/settings-subcripton/settingsSubcripton';

import Spinner from '../../../components/spinner/spinner';
import ConnectionError from "../../../components/connection-error/connectionError";

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../img/analysis.svg';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import SettingsActionRequired from '../../../components/settings-action-required/settingsActionRequired';

import '../businessAccount.page.scss';

const SubscriptionPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    const [ bill, setBill ] = useState(null);

    const [ loading, setLoading ] = useState(true);
    const [ showConnectionError, setConnectionError ] = useState(false);

    const [ effectControler, setEffectControler ] = useState(100000);

    useEffect(() => {
        if (accountData) {
            setLoading(true);

            const billRef = `accounts/${accountData.id}/billingDetails`;

            const unsub = onSnapshot(doc(firebase.db, billRef, "bill"), (doc) => {
                const billDataUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    if (bill) {
                        if (doc.data()) {
                            setBill(billDataUpdated);
                        }

                        setConnectionError(false);
                        setLoading(false);
                        
                    } else {
                        setBill(billDataUpdated);
                        setConnectionError(false);
                        setLoading(false);
                    }
                } else {
                    setConnectionError(true);
                    setLoading(false);
                }
            }, err =>{
                console.error(err);
                setConnectionError(true);
                setLoading(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    const onTryAgain = () => {
        setEffectControler(uid(6))
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción" } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ?  <Fragment>
                                {loading ? <Spinner /> : <Fragment>
                                    {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> : <Fragment>
                                        {accountData.active ? 
                                            <SettingsSubcripton bill={bill} />
                                        : 
                                            <SettingsActionRequired />
                                        }
                                    </Fragment>}
                                </Fragment>}
                            </Fragment> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SubscriptionPage;