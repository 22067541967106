import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context'

import { SideMenuContext } from "../../context/SideMenuContext";
import { SoftAlertContext } from '../../components/soft-alert/softAlertContext';

import { Helmet } from 'react-helmet-async';

import Spinner from '../../components/spinner/spinner';

import settingIconDark from '../../icons/settings-dark.png';
import settingIconLight from '../../icons/settings-light.png';

import SettingsHeader from "../../components/settings-header/settingsHeader";

import deleteIconDark from '../../icons/closed-dark.png';
import deleteIconLight from '../../icons/closed-light.png';

import adjustmentIconDark from '../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../icons/adjustment-light.png';

import xCancelDark from "../../icons/cancel-dark.png";
import xCancelLight from "../../icons/cancel-light.png";

import onIcon from "../../icons/on-button.png";
import offIcon from "../../icons/off-button.png";

import saveIcon from "../../icons/check-dark.png";

import emptyNotification from "../../img/empty-notification.svg";

import './notifications.page.scss';

import firebase from "../../firebase";
import { doc, getDoc, collection, query, orderBy, limit, startAfter, getDocsFromServer } from "firebase/firestore";

import Notifications from "../../appTools/appToolsComponents/notifications/notifications";
import { AlertContext } from "../../components/alert/alertContext";

const NotificationsPage = () => {
    const navigate = useNavigate();

    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { userData, notifications, setNotifications, setUserData, accountData } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const [ isNotEmpty, setIsNotEmpty ] = useState(notifications ? (notifications.length > 0 ? true : false) : false);

    const [unreadNotifications, setUnreadNotifications] = useState(null);
    
    // This will be save only in Ram Memory
    const [selecting, setSelecting] = useState(false);
    // In here al the IDs for the notifications selected will show
    const [notificationsSelected, setNotificationsSelected] = useState([]);
    // Loading the state in order to open the internal link
    const [loadingState, setLoadingState] = useState(null);
    // Loading the settings
    const [loadingSettings, setLoadingSettings] = useState(false);
    // Loading the notifications
    const [loadingNotifications, setLoadingNotifications] = useState(false);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [ loadingMoreNotifications, setLoadingMoreNotifications ] = useState(false);
    const [ lessThan10, setLessThan10 ] = useState(null);

    const [ noMoreNotifications, setNoMoreNotifications ] = useState(false);
    const [ notificationsHistory, setNotificationsHistory ] = useState(null);

    // notifications came from the AuthContext
    const [ moreNotifications, setMoreNotifications ] = useState([]);
    const [ notificationsDisplaying, setNotificationsDisplaying ] = useState([]);

    const updateNotifications = async () => {
        try {
            const res = await firebase.useUpdateNotificationStatus();
            
            if (unreadNotifications !== null) {
                const newUnreadNotifications = [...unreadNotifications, ...res];
                const uniqueArray = [...new Set(newUnreadNotifications)];
                setUnreadNotifications(uniqueArray);
            } else {
                if (res.length > 0) {
                    setUnreadNotifications(res);
                } else {
                    setUnreadNotifications(null);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (userData) {
            if (userData.notificationCounter) {
                updateNotifications();
            }
        }
    // eslint-disable-next-line
    }, [userData])

    useEffect(() => {
        if (!selecting) {
            setNotificationsSelected([]);
        }
    }, [selecting]);

    const onClickSelect = () => {
        if (!selecting && loadingState) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        } else {
           setSelecting(!selecting); 
        }
    }

    const iAmSelected = (id) => {
        return notificationsSelected.includes(id);
    }

    const onClickNotification = async (id) => {
        if (selecting) {
            // Check if the notification is selected or not
            if (iAmSelected(id)) {
                const temNotSelected = notificationsSelected.filter(item => item !== id);
                setNotificationsSelected(temNotSelected);
            } else {
                const temNotSelected = [...notificationsSelected];
                temNotSelected.push(id);
                setNotificationsSelected(temNotSelected);
            }
        } else {
            if (loadingState) {
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'error',
                    text: {
                        en: 'Error: try again.',
                        es: 'Error: inténtalo de nuevo.',
                    }
                });
            } else {
                const notification = notifications.find(item => item.id === id);
                if (notification) {
                    if (notification.internal) {
                        if (notification.accountId) {
                            if (notification.accountId === accountData?.id) {
                                if (notification.loadState) {
                                    switch (notification.loadState.functionName) {
                                        case "getAuthUserState":
                                            setLoadingState(id);
                                            try {
                                                const docRef = doc(
                                                    firebase.db, 
                                                    `accounts/${accountData.id}/authorizedUsers`, 
                                                    notification.loadState.data.authUserId
                                                );

                                                const docSnap = await getDoc(docRef);

                                                if (docSnap.exists()) {
                                                    const authUser = {
                                                        ...docSnap.data(),
                                                        id: docSnap.id,
                                                    }

                                                    if (authUser.status === "admin" || authUser.status === "removed") {
                                                        throw new Error("Auth user doesn't exists");
                                                    } else {
                                                        setLoadingState(null);
                                                        navigate('/ver-perfl-usuario', { state: authUser });
                                                    }
                                                } else {
                                                    throw new Error("Auth user doesn't exists");
                                                }
                                            } catch (error) {
                                                console.warn(error);
                                                setLoadingState(null);
                                                setSoftAlertActive(true);
                                                setSoftAlertData({
                                                    type: 'error',
                                                    text: {
                                                        en: 'Error: try again.',
                                                        es: 'Error: inténtalo de nuevo.',
                                                    }
                                                });
                                            }
                                            break;
                                        case "getProductState":
                                            setLoadingState(id);
                                            try {
                                                const docRef = doc(firebase.db, `accounts/${accountData.id}/products`, notification.loadState.data.productId);
                                                const docSnap = await getDoc(docRef);

                                                if (docSnap.exists()) {
                                                    const product = {
                                                        ...docSnap.data(),
                                                        id: docSnap.id,
                                                    }
                                                    setLoadingState(null);
                                                    navigate('/ver-producto', { state: product });
                                                } else {
                                                    throw new Error("Product doesn't exists");
                                                }
                                            } catch (error) {
                                                console.warn(error);
                                                setLoadingState(null);
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                } else {
                                    navigate(notification.link, {state: notification.state});
                                }
                            } else {
                                navigate("/elegir-cuenta", {state: notification.state});
                            }
                        } else {
                            navigate(notification.link);
                        }
                    } else {
                        window.open(notification.link, '_blank');
                    }
                }
            }
            
        }
    }

    const [tempNotificationSettings, setTempNotificationSittings] = useState(userData ? {
        "authorizedUserAlerts": userData.notificationSettings.authorizedUserAlerts,
        "inventoryAlerts": userData.notificationSettings.inventoryAlerts,
        "notificationByEmail": userData.notificationSettings.notificationByEmail,
        "notificationByPush": userData.notificationSettings.notificationByPush,
    } : null);

    useEffect(() => {
        setTempNotificationSittings(userData ? {
            "authorizedUserAlerts": userData.notificationSettings.authorizedUserAlerts,
            "inventoryAlerts": userData.notificationSettings.inventoryAlerts,
            "notificationByEmail": userData.notificationSettings.notificationByEmail,
            "notificationByPush": userData.notificationSettings.notificationByPush,
        } : null);
    }, [userData, dropDownMenuOpen]);

    const onScroll = () => {
        setDropDownMenuOpen(false);
    }

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    let menuRef = useRef();

    useEffect(() => {
        if (dropDownMenuOpen) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setDropDownMenuOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    const onClickOpcion = (option) => {
        setTempNotificationSittings({
            ...tempNotificationSettings,
            [option]: !tempNotificationSettings[option],
        });
    }

    useEffect(() => {
        if (tempNotificationSettings && userData) {
            if (
                tempNotificationSettings.authorizedUserAlerts === userData.notificationSettings.authorizedUserAlerts &&
                tempNotificationSettings.inventoryAlerts === userData.notificationSettings.inventoryAlerts &&
                tempNotificationSettings.notificationByEmail === userData.notificationSettings.notificationByEmail &&
                tempNotificationSettings.notificationByPush === userData.notificationSettings.notificationByPush
            ) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempNotificationSettings, userData]);

    async function updateNotificationSettings() {
        setLoadingSettings(true);

        try {
            const response = await firebase.useUpdateNotificationSettings(tempNotificationSettings);
            setUserData(response);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Settings saved successfully',
                    es: 'Ajustes guardados exitosamente'
                }
            });
            setDropDownMenuOpen(false);
            setLoadingSettings(false);
        } catch (error) {
            console.error(error);
            setLoadingSettings(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error saving settings',
                    es: 'Error al guardar ajustes',
                }
            });
        }
    }

    const onSaveSettings = () => {
        updateNotificationSettings();
    }

    async function deleteNotifications() {
        setAlertActive(false);
        setLoadingNotifications(true);

        try {
            const res = await firebase.useDeleteNotifications(notificationsSelected);

            if (res) {
                setNotifications(res);
                setNotificationsDisplaying(res);
                if (res.length < 10) {
                    setLessThan10(true);
                } else {
                    setLessThan10(false);
                }
                setIsNotEmpty(res.length > 0 ? true : false);
            } else {
                setNotifications([]);
                setNotificationsDisplaying([]);
                setLessThan10(true);
                setIsNotEmpty(false);
            }

            setNoMoreNotifications(false);
            setMoreNotifications([]);
            setNotificationsHistory(null);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `${notificationsSelected.length > 1 ? "Notifications" : "notification"} deleted successfully.`,
                    es: `${notificationsSelected.length > 1 ? "Notificaciones eliminadas" : "Notificación eliminada"} exitosamente.`
                }
            });

            setSelecting(false);
            setNotificationsSelected([]);
            setLoadingNotifications(false);
        } catch (error) {
            console.error(error);
            setLoadingNotifications(false);

            setAlertData({
                type : 'error',
                title: {
                    en : `Error when trying to delete ${notificationsSelected.length > 1 ? "notifications" : "notification"}`,
                    es : `Error al tratar de eliminar ${notificationsSelected.length > 1 ? "las notificaciones" : "la notificación"}`
                },
                code : "error",
                description : getErrorDescription("error")
            });
            setAlertActive(true);
        }
    }

    const onDelete = () => {
        if (notificationsSelected) {
            if (notificationsSelected.length > 0) {
                setAlertData({
                    type : 'question',
                    title: {
                        en : notificationsSelected.length > 1 ? 'Delete selected notifications?' : 'Delete selected notification?',
                        es : notificationsSelected.length > 1 ? '¿Eliminar notificaciones seleccionadas?' : '¿Eliminar notificación seleccionada?'
                    },
                    code : '',
                    description : {
                        en : notificationsSelected.length > 1 ? `Are you sure you want to delete ${notificationsSelected.length} notifications?` : 'Are you sure you want to remove this notification?',
                        es : notificationsSelected.length > 1 ? `¿Estás seguro de que deseas eliminar ${notificationsSelected.length} notificaciones?` : '¿Estás seguro de que deseas eliminar esta notificación?'
                    }
                });
                setOnAgree(() => deleteNotifications);
                setAlertActive(true);
            }
        }
    }

    useEffect(() => {
        if (notifications) {
            if (notifications.length < 10) {
                setLessThan10(true);
            } else {
                setLessThan10(false);
            }
            setIsNotEmpty(notifications.length > 0 ? true : false);
        } else {
            setIsNotEmpty(false);
        }
    }, [notifications]);

    const removeDuplicates = (array, property) => {
        const seen = new Set();
        return array.filter(obj => {
          const value = obj[property];
          if (!seen.has(value)) {
            seen.add(value);
            return true;
          }
          return false;
        });
    };

    useEffect(() => {
        if (notifications) {
            const newState = [...notifications, ...moreNotifications];
            const uniqueArray = removeDuplicates(newState, 'id');
            setNotificationsDisplaying(uniqueArray)
        }
    }, [notifications, moreNotifications]);

    const loadMoreNotifications = async () => {
        setLoadingMoreNotifications(true);
        try {
            if (!notificationsHistory) {

                const q = query(collection(firebase.db, `users/${userData.id}/notifications`), orderBy("time", "desc"), startAfter(notifications[notifications.length - 1].time), limit(10));
                const snapshot = await getDocsFromServer(q);
                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (!(res.length > 0)) {
                    setNoMoreNotifications(true);
                } else {
                    if (res.length < 10 ) {
                        setNoMoreNotifications(true);
                    }

                    setNotificationsHistory({
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });

                    setMoreNotifications([...moreNotifications, ...res]);
                }
            } else {
                const q = query(collection(firebase.db, `users/${userData.id}/notifications`), orderBy("time", "desc"), startAfter(notificationsHistory.endAt), limit(10));
                const snapshot = await getDocsFromServer(q);

                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (!(res.length > 0)) {
                    setNoMoreNotifications(true);
                } else {
                    if (res.length < 10 ) {
                        setNoMoreNotifications(true);
                    }

                    setNotificationsHistory({
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });

                    setMoreNotifications([...moreNotifications, ...res]);
                }
            }
        } catch (error) {
            console.warn(error);
        }
        setLoadingMoreNotifications(false);
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Notifications' : 'Notificaciones'} </title>
            </Helmet>
            <div
                className={`page-my-notifications-container unselectable ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                <div className="page-my-notifications-item">
                    {desktopView ?
                        <SettingsHeader
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? 'Notifications' : 'Notificaciones'}
                        />
                        : null}
                    <div className="page-my-notifications-options-container">
                        <div className="notification-list-container">
                            <div
                                className="nofificaton-list-first-section-cont"
                                style={{
                                    marginTop: desktopView ? "-61.8px" : 0,
                                }}
                            >
                                <div className="nofificaton-list-first-section">

                                    {selecting && isNotEmpty ?
                                        <button
                                            className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                            style={{
                                                marginRight: "15px",
                                                minWidth: "73px"
                                            }}
                                            disabled={loadingNotifications ? true : false}
                                            onClick={onDelete}
                                        >
                                            <img
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "8px"
                                                }}
                                                src={appTheme.dark ? deleteIconDark : deleteIconLight}
                                                alt="Delete"
                                            />
                                            <p>{notificationsSelected ? notificationsSelected.length : ""}  </p>
                                        </button>
                                        
                                        : null}

                                    {isNotEmpty ?
                                        <button
                                            className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                            style={{
                                                marginRight: "15px"
                                            }}
                                            disabled={loadingNotifications ? true : false}
                                            onClick={onClickSelect}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selecting}
                                                onChange={onClickSelect}
                                                name='customerAccounts'
                                                id='customerAccounts'
                                            />
                                            <p>{appLanguage.en ? "Select" : "Seleccionar"}</p>
                                        </button>
                                        : null}

                                    <div ref={menuRef} className="notification-filter-botton-container unselectable">
                                        <button
                                            onClick={onClickUserButton}
                                            className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                        >
                                            <img
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: "12px"
                                                }}
                                                src={filterIcon}
                                                alt="Filter"
                                            />
                                            <p>{appLanguage.en ? "Settings" : "Ajustes"}</p>
                                        </button>

                                        <span className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`} />

                                        <div
                                            style={{
                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)"
                                            }}
                                            className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                        >
                                            {loadingSettings ?
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        minHeight: "300px",
                                                        paddingTop: "50px"
                                                    }}
                                                >
                                                    <Spinner />
                                                </div>
                                                :
                                                tempNotificationSettings ?
                                                    <div
                                                        style={{
                                                            minHeight: "350px",
                                                        }}
                                                    >
                                                        <div className="drop-men-not-option-con">
                                                            <h3>General:</h3>

                                                            <button
                                                                onClick={() => onClickOpcion("notificationByPush")}
                                                                className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                                            >
                                                                <img src={tempNotificationSettings.notificationByPush ? onIcon : offIcon} alt="Check" />
                                                                <p>{appLanguage.en ? "Push Notifications" : "Notificaciones Push"}</p>
                                                            </button>

                                                            <button
                                                                onClick={() => onClickOpcion("notificationByEmail")}
                                                                className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                                            >
                                                                <img src={tempNotificationSettings.notificationByEmail ? onIcon : offIcon} alt="Check" />
                                                                <p>{appLanguage.en ? "By email" : "Por correo electrónico"}</p>
                                                            </button>
                                                        </div>

                                                        <div
                                                            className="drop-men-not-option-con"
                                                            style={{
                                                                marginTop: "35px"
                                                            }}
                                                        >
                                                            <h3>{appLanguage.en ? "Business account activity:" : "Actividad en cuenta de negocios:"}</h3>

                                                            <button
                                                                onClick={() => onClickOpcion("inventoryAlerts")}
                                                                className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                                            >
                                                                <img src={tempNotificationSettings.inventoryAlerts ? onIcon : offIcon} alt="Check" />
                                                                <p>{appLanguage.en ? "Inventory Alerts" : "Alertas de inventario"}</p>
                                                            </button>

                                                            <button
                                                                onClick={() => onClickOpcion("authorizedUserAlerts")}
                                                                className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                                            >
                                                                <img src={tempNotificationSettings.authorizedUserAlerts ? onIcon : offIcon} alt="Check" />
                                                                <p>{appLanguage.en ? "Authorized user alerts" : "Alertas de usuarios autorizados"}</p>
                                                            </button>
                                                        </div>

                                                        <div
                                                            className="drop-men-not-save-con"
                                                            style={{
                                                                marginTop: "35px"
                                                            }}
                                                        >
                                                            <button
                                                                onClick={onSaveSettings}
                                                                disabled={!showSave}
                                                                className="new-customer-bottons-button-save"
                                                                style={!showSave ? {
                                                                    backgroundColor: "#3f526698",
                                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                    cursor: "default"
                                                                } : null}
                                                            >
                                                                <img src={saveIcon} alt="Save" />
                                                                {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isNotEmpty ?
                                loadingNotifications ? 
                                    <div style={{marginTop: "90px"}}>
                                        <Spinner/> 
                                    </div>
                                :
                                    <Notifications
                                        notifications={notificationsDisplaying}
                                        selecting={selecting}
                                        onClickNotification={onClickNotification}
                                        iAmSelected={iAmSelected}
                                        onScroll={onScroll}
                                        loadingState={loadingState}
                                        loadingMoreNotifications={loadingMoreNotifications}
                                        loadMoreNotifications={loadMoreNotifications}
                                        lessThan10={lessThan10}
                                        noMoreNotifications={noMoreNotifications}
                                        unreadNotifications={unreadNotifications}
                                    />
                                :
                                <div className="no-notification-to-profile-msg-div">
                                    <img src={emptyNotification} alt="Notification bell" />
                                    <p><b>{appLanguage.en ? "You have no pending notifications" : "No tienes notificaciones pendientes"}</b></p>
                                    <p>{appLanguage.en ? "Once you receive notifications, they will be displayed here." : "Una vez que reciba las notificaciones, se mostrarán aquí."}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotificationsPage;