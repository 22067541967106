import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';

import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";
import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";

import "./dashboard.page.scss";
import { SideMenuContext } from "../../context/SideMenuContext";
import ConnectionError from "../../components/connection-error/connectionError";
import Spinner from "../../components/spinner/spinner";
import firebase from "../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import { AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ToolTitle from "../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../icons/bullet-point-light.png';

import tempMetricsIcon from "../../img/temp-metrics.svg";

const DashboardPage = () => {

    let observedRef = useRef(null);

    const { accountData, accessTo } = useContext(AuthContext);
    const { appLanguage, appTheme, formatRationalNumber, getDayForRecharts, getMonthForRecharts } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [loading, setLoading] = useState(true);

    const [statisticsListener, setStatisticsListener] = useState(null);
    const [generalStatistics, setGeneralStatistics] = useState([]);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [showingMonthFlow, setShowingMonthFlow] = useState(true);
    const [showingMonthSales, setShowingMonthSales] = useState(true);

    const [statisticsView, setStatisticsView] = useState("today");

    const [monthData, setMonthData] = useState([]);
    const [yearData, setYearData] = useState([]);

    const [monthDataFlow, setMonthDataFlow] = useState([]);
    const [yearDataFlow, setYearDataFlow] = useState([]);
 
    const [indicatorsFilters, setIndicatorsFilters] = useState([]);
    const [indicators, setIndicators] = useState({});

    const [todayString, setTodayString] = useState(null);

    const onChangeStatisticsView = (e) => {
        setStatisticsView(e.target.value);
    }

    const onClickShowMonthFlow = () => {
        setShowingMonthFlow(true);
    }

    const onClickShowYearFlow = () => {
        setShowingMonthFlow(false);
    }

    const onClickShowMonthSales = () => {
        setShowingMonthSales(true);
    }

    const onClickShowYearSales = () => {
        setShowingMonthSales(false);
    }

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 119.6 : 309.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const onTryAgain = () => {
        setLoading(true);
        getGeneralStatistics();
    }

    useEffect(() => {
        if (accountData && accessTo) {
            if (!generalStatistics.length > 0) {
                if (accessTo.metrics) {
                    setStatisticsListener(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, accessTo]);

    async function getGeneralStatistics() {
        setShowConnectionError(false);
        try {
            const res = await firebase.useGetGeneralIndicators({
                "businessId": accountData.id
            });
            if (res === null) {
                throw new Error("Statistics not found");
            }
            setGeneralStatistics(res);
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }
        setLoading(false);
    }

    // THen I have to listen to the product statistcs dos
    useEffect(() => {
        if (accountData && statisticsListener) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/productsStatistics`, "statisticsOfAllProducts"), (doc) => {
                if (doc.data()) {
                    getGeneralStatistics();
                } else {
                    setShowConnectionError(true);
                    setLoading(false);
                }
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [statisticsListener]);

    const [opacity, setOpacity] = useState({
        losses: 1,
        income: 1,
        earnings: 1,
    });

    const isvalidObject = (key) => {
        if (
            key === "income" ||
            key === "earnings" ||
            key === "returnRate" ||
            key === "quantitySold" ||
            key === "losses" ||
            key === "returns"
        ) {
            return false
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (generalStatistics.length > 0) {
            const newMonthData = [];
            const newYearData = [];

            const newMonthDataFlow = [];
            const newYearDataFlow = [];

            const newIndicatorsFilters = [];
            const newIndicators = {};

            let newTodayString = "";
            let newTodayObject = {};
            let currentPeriodData = null;

            const thisMonthStatistics = generalStatistics[generalStatistics.length - 1];

            for (let key of Object.keys(thisMonthStatistics)) {
                currentPeriodData = thisMonthStatistics[key];
            }

            for (let key of Object.keys(currentPeriodData)) {
                if (isvalidObject(key)) {
                    newTodayString = key;
                    newTodayObject = currentPeriodData[key];

                    newMonthData.push({
                        name: getDayForRecharts(key),
                        cantidad: currentPeriodData[key].quantitySold,
                    });

                    newMonthDataFlow.push({
                        name: getDayForRecharts(key),
                        "Ingresos": currentPeriodData[key].income,
                        "Pérdidas": currentPeriodData[key].losses,
                        "Ganancias": currentPeriodData[key].earnings,
                    });
                }
            }

            newIndicators[newTodayString] = newTodayObject;

            const monthsList = [];

            for (let [key] of Object.entries(generalStatistics)) {
                monthsList.push(generalStatistics[key])
            }

            for (let i = 0; i < monthsList.length; i++) {
                for (let key of Object.keys(monthsList[i])) {
                    if (isvalidObject(key)) {
                        newIndicatorsFilters.push(key);
                        newYearData.push({
                            name: getMonthForRecharts(key),
                            cantidad: monthsList[i][key].quantitySold,
                        });

                        newYearDataFlow.push({
                            name: getMonthForRecharts(key),
                            "Ingresos": monthsList[i][key].income,
                            "Pérdidas": monthsList[i][key].losses,
                            "Ganancias": monthsList[i][key].earnings,
                        });

                        const statisticsForMonth = monthsList[i][key];

                        newIndicators[key] = {
                            earnings: statisticsForMonth.earnings,
                            income: statisticsForMonth.income,
                            losses: statisticsForMonth.losses,
                            quantitySold: statisticsForMonth.quantitySold,
                            returnRate: statisticsForMonth.returnRate,
                            returns: statisticsForMonth.returns,
                        };
                    }
                }
            }

            setMonthData(newMonthData);
            setYearData(newYearData);

            setMonthDataFlow(newMonthDataFlow);
            setYearDataFlow(newYearDataFlow);

            setIndicators(newIndicators);
            setTodayString(newTodayString);
            setIndicatorsFilters(newIndicatorsFilters);
        }
        // eslint-disable-next-line
    }, [generalStatistics])

    const handleMouseEnter = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    };

    const handleMouseLeave = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    };

    const getQuantitySold = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].quantitySold;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].quantitySold;;
            } else {
                return 0;
            }
        }
    }

    const getReturnRate = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].returnRate;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].returnRate;;
            } else {
                return 0;
            }
        }
    }

    const getGrossIncome = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].income;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].income;;
            } else {
                return 0;
            }
        }
    }

    const getLosses = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].losses;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].losses;;
            } else {
                return 0;
            }
        }
    }

    const getEarnings = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].earnings;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].earnings;;
            } else {
                return 0;
            }
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Dashboard' : 'Métricas'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className="page-app-settings-item">
                            {accessTo.metrics ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "General metrics" : 'Métricas generales'}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 65px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                            <div ref={observedRef} className="product-details-page-container" style={{ marginTop: 0 }}>

                                                <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: "10px" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: "30px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginRight: "25px"
                                                            }}
                                                        >
                                                            <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Indicators for " : "Indicadores para "}</b></p>
                                                        </div>

                                                        <div style={{ height: "40px", display: "flex" }}>
                                                            <select
                                                                style={{
                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                    width: "100%",
                                                                    minWidth: "90px"
                                                                }}
                                                                className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                name="statisticsView"
                                                                value={statisticsView}
                                                                onChange={(e) => onChangeStatisticsView(e)}
                                                            >
                                                                <option value="today">
                                                                    {appLanguage.en ? "Today" : "Hoy"}
                                                                </option>

                                                                {indicatorsFilters.slice().reverse().map((period) => {
                                                                    return (
                                                                        <option key={period} value={period}>
                                                                            {getMonthForRecharts(period)}
                                                                        </option>
                                                                    )
                                                                })}

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <hr
                                                        style={{
                                                            backgroundColor: "rgba(109, 109, 109, 0.5490196078)",
                                                            height: "1px",
                                                            border: "none",
                                                        }}
                                                    />

                                                    {width > 700 ?
                                                        <div style={{ display: "flex", width: "100%", }}>
                                                            <div style={{ display: "flex", width: `100%`, flexWrap: "wrap", alignContent: "flex-start" }}>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity sold" : "Cantidad vendida"} {width > 385 ? <br /> : ""} <b>{getQuantitySold()}</b></p>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return rate" : "Tasa de devoluciones"} {width > 385 ? <br /> : ""} <b>{getReturnRate()}</b></p>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross income" : "Ingreso bruto"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getLosses())}</b></p>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <Fragment>
                                                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity sold" : "Cantidad vendida"}{width > 385 ? <br /> : ":"} <b>{getQuantitySold()}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return rate" : "Tasa de devoluciones"}{width > 385 ? <br /> : ":"} <b>{getReturnRate()}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross income" : "Ingreso bruto"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getLosses())}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>


                                                {true ? <Fragment>
                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div
                                                            style={{
                                                                width: "calc(100%)",
                                                                height: "180px",
                                                                paddingTop: "10px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}>

                                                            <img style={{width: "80px", height: "80px", margin: "10px"}} src={tempMetricsIcon} alt="Temp Metrics" />
                                                            <h4 style={{color: "gray", marginRight: "20px", textAlign: "center", marginBottom: "30px", marginTop: "20px"}}>
                                                                {appLanguage.en ? "We are developing new metrics that will soon help you optimize business management." : "Estamos desarrollando nuevas métricas que pronto te ayudarán a optimizar la gestión del negocio."}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Fragment> : <Fragment>
                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div style={{ display: "flex", flexWrap: "wrap" }}>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "30px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    marginBottom: "10px",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Cash flow" : "Flujo de dinero"}</b></p>
                                                            </div>

                                                            <div style={{ height: "40px", display: "flex" }}>
                                                                <div
                                                                    onClick={onClickShowMonthFlow}
                                                                    className={`products-indicators-sales-num-select-period ${showingMonthFlow ? "products-indicators-sales-num-select-period-hover" : null}`}
                                                                    style={{
                                                                        marginLeft: 0,
                                                                        marginRight: "15px"
                                                                    }}
                                                                >
                                                                    <label htmlFor="flowByDays">
                                                                        <input checked={showingMonthFlow} readOnly type='radio' id="flowByDays" name="flowByDays" value={showingMonthFlow} />
                                                                        {appLanguage.en ? "This month" : "Este mes"}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    onClick={onClickShowYearFlow}
                                                                    className={`products-indicators-sales-num-select-period ${!showingMonthFlow ? "products-indicators-sales-num-select-period-hover" : null}`}
                                                                >
                                                                    <label htmlFor="flowByMonths">
                                                                        <input checked={!showingMonthFlow} readOnly type='radio' id="flowByMonths" name="flowByMonths" value={!showingMonthFlow} />
                                                                        {appLanguage.en ? "Last 12 months" : "Últimos 12 meses"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "calc(100% + 18px)",
                                                                height: "180px",
                                                                paddingTop: "10px",
                                                                color: "black",
                                                                marginLeft: "-15px"
                                                            }}>
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <LineChart
                                                                    width={500}
                                                                    height={400}
                                                                    data={showingMonthFlow ? monthDataFlow : yearDataFlow}
                                                                    margin={{
                                                                        top: 5,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                >
                                                                    <CartesianGrid stroke="#88888877" strokeDasharray="3 3" />
                                                                    <XAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} dataKey="name" />
                                                                    <YAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} />
                                                                    <Tooltip />
                                                                    <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                                                                    <Line type="monotone" dataKey="Ingresos" strokeOpacity={opacity.income} stroke="#FABC3F" strokeWidth={2} />
                                                                    <Line type="monotone" dataKey="Pérdidas" strokeOpacity={opacity.losses} stroke="#C7253E" strokeWidth={2} />
                                                                    <Line type="monotone" dataKey="Ganancias" strokeOpacity={opacity.earnings} stroke="#82ca9d" strokeWidth={2} />
                                                                </LineChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>

                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div style={{ display: "flex", flexWrap: "wrap" }}>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "30px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    marginBottom: "10px",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Quantity sold for " : "Cantidad vendida para "}</b></p>
                                                            </div>

                                                            <div style={{ height: "40px", display: "flex" }}>
                                                                <div
                                                                    onClick={onClickShowMonthSales}
                                                                    className={`products-indicators-sales-num-select-period ${showingMonthSales ? "products-indicators-sales-num-select-period-hover" : null}`}
                                                                    style={{
                                                                        marginLeft: 0,
                                                                        marginRight: "15px"
                                                                    }}
                                                                >
                                                                    <label htmlFor="salesByDays">
                                                                        <input checked={showingMonthSales} readOnly type='radio' id="salesByDays" name="salesByDays" value={showingMonthSales} />
                                                                        {appLanguage.en ? "This month" : "Este mes"}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    onClick={onClickShowYearSales}
                                                                    className={`products-indicators-sales-num-select-period ${!showingMonthSales ? "products-indicators-sales-num-select-period-hover" : null}`}
                                                                    
                                                                >
                                                                    <label htmlFor="salesByMonths">
                                                                        <input checked={!showingMonthSales} readOnly type='radio' id="salesByMonths" name="salesByMonths" value={!showingMonthSales} />
                                                                        {appLanguage.en ? "Last 12 months" : "Últimos 12 meses"}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "calc(100% + 10px)",
                                                                height: "180px",
                                                                paddingTop: "10px",
                                                                color: "black",
                                                                marginLeft: "-8px"
                                                            }}>

                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <AreaChart
                                                                    width={500}
                                                                    height={400}
                                                                    data={showingMonthSales ? monthData : yearData}
                                                                    margin={{
                                                                        top: 10,
                                                                        right: 30,
                                                                        left: 0,
                                                                        bottom: 0,
                                                                    }}
                                                                >
                                                                    <CartesianGrid stroke="#88888877" strokeDasharray="3 3" />
                                                                    <XAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} dataKey="name" />
                                                                    <YAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} />
                                                                    <Tooltip />
                                                                    <Area type="monotone" dataKey="cantidad" stroke="#627890" fill="#627890" />
                                                                </AreaChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>

                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "30px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    marginBottom: "10px",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Frequent customers" : "Clientes frecuentes"}</b></p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "calc(100% + 18px)",
                                                                height: "180px",
                                                                paddingTop: "10px",
                                                                color: "black",
                                                                marginLeft: "-15px"
                                                            }}>
                                                            {/*
                                                                
                                                            */}
                                                        </div>
                                                    </div>
                                                </Fragment>}

                                                <div style={{ minHeight: "50px" }} />
                                            </div>
                                        }
                                    </Fragment>}
                                </div>
                            </Fragment>
                                : <SectionForbidden sectionName={appLanguage.en ? 'Dashboard' : 'Métricas'} />}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default DashboardPage;