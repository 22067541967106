import React, { createContext, useEffect, useState } from 'react';

export const AlertNewAuthUserContext = createContext();

const AlertNewAuthUserProvider = (props) => {

    const [ alertActiveAuth, setAlertActiveAuth ] = useState(false);
    const [ alertDataAuth, setAlertDataAuth ] = useState({
        type: '',
        title: {
            en: '',
            es: ''
        },
        code: '',
        price: null,
        pictureLocation: '',
        email:'', 
        description : {
            en: '',
            es: ''
        }
    });

    const onAgreeFuction = () => {
        return null;
    }

    const onCancelFuction = () => {
        return null;
    }

    const [ onAgreeAuth, setOnAgreeAuth ] = useState(() => onAgreeFuction);
    const [ onCancelAuth, setOnCancelAuth ] = useState(() => onCancelFuction);

    useEffect(() => {
        if(alertActiveAuth === false) {
            setAlertDataAuth({
                type :'',
                title: {
                    en: '',
                    es: ''
                },
                code: '',
                price: null,
                pictureLocation: '',
                email: '', 
                description : {
                    en: '',
                    es: ''
                }
            });
            setOnAgreeAuth(() => onAgreeFuction);
            setOnCancelAuth(() => onCancelFuction);
        }
    }, [alertActiveAuth]);

    return (
        <AlertNewAuthUserContext.Provider
            value={{ 
                alertActiveAuth,
                alertDataAuth,
                onAgreeAuth,
                onCancelAuth,
                setAlertActiveAuth,
                setAlertDataAuth,
                setOnAgreeAuth,
                setOnCancelAuth
            }}
        >
            {props.children}
        </AlertNewAuthUserContext.Provider>
    )
}

export default AlertNewAuthUserProvider;