import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";

import "./settingsSecurity.scss";

import securityIcon from "../../img/cyber-security.svg";

const SettingsSecurity = () => {

    const { appTheme, appLanguage } = useContext(AppContext);

    const emailEnText = `mailto:info@hazcuentas.com?subject=Assistance&body=Hello%20HazCuentas%20team.%20I%20am%20writing%20to%20you%20because%20I%20need%20assistance%20with%20my%20account%20`;
    const emailEsText = `mailto:info@hazcuentas.com?subject=Asistencia&body=Hola%20equipo%20de%20HazCuentas.%20Le%20escribo%20porque%20necesito%20ayuda%20con%20mi%20cuenta%20`;

    return (
        <div className={`settings-security ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <div className="settings-security-first-section-con">
                <div style={{
                    width: "80px",
                    height: "80px"
                }}>
                    <img 
                        src={securityIcon} 
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}  
                        alt='Cyber security' 
                    />
                </div>
                <p className="settings-security-first-section-title-p"><b>{appLanguage.en ? "Security" : "Seguridad"}</b></p>
            </div>
            {appLanguage.en ? 
                <div className="settings-security-second-section">
                    <p>Your security is vital to us, which is why we have clear policies to protect your data at all times. We invite you to read <Link className={`${appTheme.dark ? "settings-security-second-section-link-dark" : "settings-security-second-section-link-light"}`} to={"/politicas-de-privacidad"}>Privacy Policies</Link>.</p>
                    <br/>
                    <p>But remember that we also need you to achieve it. It is very important to have strong passwords and log out if you use the software on a device used by multiple people. In case you have forgotten to log out, you can change your profile password, this will force other devices to log out.</p>
                    <br/>
                    <p>In the event that you lose access to your account or notice suspicious activity, you can contact us via <a className={`${appTheme.dark ? "settings-security-second-section-link-dark" : "settings-security-second-section-link-light"}`} href={emailEnText} target='_blank' rel="noreferrer">info@hazcuentas.com</a>.</p>
                </div>
            :
                <div className="settings-security-second-section">
                    <p>Tu seguridad es vital para nosotros, por lo cual tenemos políticas claras para proteger tus datos en todo momento. Te invitamos a leer <Link className={`${appTheme.dark ? "settings-security-second-section-link-dark" : "settings-security-second-section-link-light"}`} to={"/politicas-de-privacidad"}>Políticas de Privacidad</Link>.</p>
                    <br/>
                    <p>Pero recuerda que necesitamos también de ti para lograrlo. Es muy importante tener contraseñas seguras y cerrar sesión si utilizas el software en un dispositivo utilizado por varias personas. En el caso de que hayas olvidado cerrar sesión puedes cambiar la contraseña de tu perfil, esto obligará a los demás dispositivos a cerrar sesión.</p>
                    <br/>
                    <p>En el caso de que pierdas acceso a tu cuenta o notes actividades sospechosas, puedes contactarnos a través de <a className={`${appTheme.dark ? "settings-security-second-section-link-dark" : "settings-security-second-section-link-light"}`} href={emailEsText} target='_blank' rel="noreferrer">info@hazcuentas.com</a>.</p>
                </div>
            }
            
        </div>
    );
}

export default SettingsSecurity;