import { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AuthContext } from '../../../../firebase/context';
import { AlertContext } from '../../../../components/alert/alertContext';
import { useNavigate } from 'react-router-dom';

import { AlertNewAuthUserContext } from '../../../../components/alertNewAuthUser/alertNewAuthUserContext';

import firebase from '../../../../firebase';

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';

import Spinner from '../../../../components/spinner/spinner';

import warningIcon from "../../../../img/warning.svg";

import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import businessIcon from '../../../../img/analysis.svg';
import addIconDark from '../../../../icons/plus-mini-dark.png';

import maintenanceIcon from "../../../../img/maintenance.svg";

import ConnectionError from '../../../../components/connection-error/connectionError';

import './authorizeAccess.page.scss';
import { SoftAlertContext } from '../../../../components/soft-alert/softAlertContext';

const AuthorizeAccessPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { setAlertDataAuth, setAlertActiveAuth, setOnAgreeAuth, setOnCancelAuth } = useContext(AlertNewAuthUserContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [ showConnectionError, setShowConnectionError ] = useState(false);
    const [ state, setState ] = useState({
        "limit": null,
        "isNextFree": null,
        "numberOfFreeUsers": null,
        "numberOfAuthUsers": null,
        "numberOfExtraUsers": null,
        "canAdd": null,
        "isPending": null
    });

    const [ showFrom, setShowForm ] = useState(true);

    const navigate = useNavigate();

    async function getNumberOfAuthUsers() {
        setLoadingState(true);
        setShowConnectionError(false);

        try {
            const respose = await firebase.useGetNumberOfAuthUsers({
                "businessId": accountData.id,
            });
            setState(respose);
            const canAdd = (respose.canAdd) && (!respose.isPending) ? true : false;
            setShowForm(canAdd);
        } catch (error) {
            console.error(error);
            setShowConnectionError(true);
        }
        setLoadingState(false);
        setLoading(false);
    }

    useEffect(() => {
        if(!(adminAccess || managerAccess))  {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    })

    const [ showDescription, setShowDescription ] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(true);

    const hanbleShowDescription = () => {
        setShowDescription(!showDescription);
    }

    const [ newUserState, setNewUserState ] = useState({
        name: "",
        phone: "",
        email: ""
    });

    const [ phone, setPhone ] = useState("");

    useEffect(() => {
        setPhoneAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const phoneValue = phone;
        let phoneFormatted = '';
        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }
        setPhone(phoneFormatted);

        if (phoneFormatted[0] === "+") {
            setNewUserState({
                ...newUserState,
                "phone": phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNewUserState({
                    ...newUserState,
                    "phone": "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
        }
    // eslint-disable-next-line
    }, [phone])

    const [ nameAlert, setNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ phoneAlert, setPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ emailAlert, setEmailAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const onChange = e => {
        if (e.target.name === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if (e.target.name === "phone") {
                setPhoneAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            } else {
                if (e.target.name === "email") {
                    setEmailAlert({
                        alert: false,
                        enText: '',
                        esText: ''
                    });
                }
            }
        }

        if (e.target.name === "phone") {
        
            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i]; 
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            setPhone(phoneFormatted);
        } else {
            setNewUserState({
                ...newUserState,
                [e.target.name] : e.target.value
            });
        }
    }

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNewUserState({
                ...newUserState,
                [e.target.name]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNewUserState({
                    ...newUserState,
                    [e.target.name]: "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
        }
    }

    const activateAlert = (data) => {
        setAlertDataAuth({
            type : data.extraCharge ? "extraUser" : "freeUser",
            title: {
                en : `Are you sure you want to add this user?`,
                es : `¿Está seguro de que deseas agregar este usuario?`
            },
            price: data.price,
            pictureLocation : data.pictureLocation,
            email : data.email,  
            description : {
                en : "",
                es : ""
            }
        });
        setOnAgreeAuth(() => saveData);
        setOnCancelAuth(() => cancelProcess);
        setAlertActiveAuth(true);
    }

    const saveData = () => {
        setAlertActiveAuth(false);
        addAuthorizedUser({
            ...newUserState,
            accountId: accountData.id
        });
    }

    const cancelProcess = () => {
        setNewUserState({
            name: "",
            phone: "",
            email: ""
        });
        setPhone("");
        getNumberOfAuthUsers();
    }

    const validar = e => {
        e.preventDefault();

        if (newUserState.name.trim()=== "") {
            setNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if(newUserState.phone.length < 10 && newUserState.phone !== "") {
                setPhoneAlert({
                    alert: true,
                    enText: '* The phone number must have at least 10 digits.',
                    esText: '* El teléfono debe tener al menos 10 dígitos'
                });
                return;
            } else {
                if (!validateEmail(newUserState.email.trim())) {
                    setEmailAlert({
                        alert: true,
                        enText: '* Email is not valid',
                        esText: '* El correo no es válido'
                    });
                    return;
    
                } else {
                    if (newUserState.email.trim() === "") {
                        setEmailAlert({
                            alert: true,
                            enText: '* Required field',
                            esText: '* Campo requerido'
                        });
                        return;
                    }
                }
            }
        }

        getEstimation({
            ...newUserState,
            accountId: accountData.id
        });
    }

    async function addAuthorizedUser(user) {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const respose = await firebase.useAddAuthorizedUser({
                "name": user.name.trim(),
                "phone": phone,
                "email": user.email.trim(),
                "accountId": user.accountId,
                "isNextFree": state.isNextFree,
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en: 'User successfully added',
                    es: 'Usuario agregado de manera exitosa'
                }
            });

            // I should receive de authorized user doc, not the account
            setLoading(false);
            setAlertActiveAuth(false);

            // We have to pass user data using location state
            navigate('/ver-perfl-usuario', { state: respose, replace: true });

        } catch (error) {
            const { code, details } = JSON.parse(JSON.stringify(error));
            let desc = '';

            if(details === "auth/user-not-found") {
                desc = getErrorDescription(details);
            } else {
                if(details === "already-in") {
                    desc = getErrorDescription(details);
                } else {
                    desc = getErrorDescription(code);
                }
            }

            setNewUserState({
                name: "",
                phone: "",
                email: ""
            });

            setPhone("");

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to add user',
                    es: 'Error al intentar agregar usuario'
                },
                code: code,
                description: desc
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    async function getEstimation(user) {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const respose = await firebase.useAddAuthUserCheck({
                "name": user.name.trim(),
                "phone": user.phone.trim(),
                "email": user.email.trim(),
                "accountId": user.accountId
            });
            setState({
                ...state,
                isNextFree: respose.extraCharge ? false : true,
            })
            activateAlert(respose);
        } catch (error) {
            const { code, details } = JSON.parse(JSON.stringify(error));
            let desc = '';

            if(details === "auth/user-not-found") {
                desc = getErrorDescription(details);
            } else {
                if(details === "already-in") {
                    desc = getErrorDescription(details);
                } else {
                    desc = getErrorDescription(code);
                }
            }

            setNewUserState({
                name: "",
                phone: "",
                email: ""
            });

            setPhone("");

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to add user',
                    es: 'Error al intentar agregar usuario'
                },
                code: code,
                description: desc
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        getNumberOfAuthUsers();
    // eslint-disable-next-line
    }, []);

    const tryAgain = () => {
        setLoadingState(true);
        getNumberOfAuthUsers();
    }

    useEffect(() => {
        if(accountData){
            if(!accountData.active) {
                navigate("/usuarios-autorizados", { replace: true })
            }
        }
    // eslint-disable-next-line 
    }, [accountData]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Authorize Access' : 'Autorizar acceso'} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader3
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/usuarios-autorizados"}
                            text2={appLanguage.en ? 'Authorized users' : 'Usuarios autorizados'}
                            text={appLanguage.en ? "Authorize access" : "Autorizar acceso"}
                        />
                    : null}
                    {loadingState ? <Spinner /> : <Fragment>
                    <div className="settings-options-container">
                        {loading ? <Spinner /> :
                            <div className="settings-options-container-item">
                                <SettingsTitleBus
                                    icon={businessIcon}
                                    firstText={accountData ? accountData.businessName : ""}
                                    secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                                />
                                {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                                    {(adminAccess || managerAccess) ? <section className='settings-options-container-item-cont' >
                                        {showFrom ? <Fragment>
                                            <div className='authorize-access-page-description'>
                                                <br/>
                                                {appLanguage.en ?
                                                    <div>
                                                        <p><b>{state.numberOfAuthUsers > state.numberOfFreeUsers ? state.numberOfFreeUsers : state.numberOfAuthUsers} of {state.numberOfFreeUsers}</b> free authorized users. </p>
                                                        <br/>
                                                        <p><b>{state.numberOfExtraUsers} </b> extra authorized users. <i>Monthly RD${state.extraUserPrice.toFixed(2)} charge for each additional authorized user.</i></p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p><b>{state.numberOfAuthUsers > state.numberOfFreeUsers ? state.numberOfFreeUsers : state.numberOfAuthUsers} de {state.numberOfFreeUsers}</b> usiarios autorizados gratis.</p>
                                                        <br/>
                                                        <p><b>{state.numberOfExtraUsers} usuarios autorizados extras</b>. <i>Cargo de RD${state.extraUserPrice.toFixed(2)} mensual por cada usuario autorizado extra.</i></p>
                                                    </div>
                                                }                                   
                                                <br/>
                                                <p>
                                                    <b>
                                                        {appLanguage.en ? "Add another person to your business account" : "Agregar a otra persona a tu cuenta de negocios" }
                                                        {showDescription ? 
                                                            <span 
                                                                onClick={() => hanbleShowDescription()} 
                                                                className='authorize-access-descripcion'
                                                            > - {appLanguage.en ? "Hide description" : "Ocultar descripción"}</span> 
                                                        :
                                                            <span 
                                                                onClick={() => hanbleShowDescription()} 
                                                                className='authorize-access-descripcion'
                                                            > + {appLanguage.en ? "Show description" : "Mostar descripción"}</span>
                                                        }  
                                                    </b>
                                                </p>
                                                <br/>
                                                {showDescription ? <Fragment>
                                                    {appLanguage.en ? <Fragment>
                                                        <p>People you add to your account will be able to manage the system, but you will remain the primary administrator and will have the ability to revoke their access at any time.</p>
                                                    </Fragment> : <Fragment>
                                                        <p>Las personas que agregues a tu cuenta podrán gestionar el sistema, pero tú seguirás siendo el administrador principal y tendrás la capacidad de revocar su acceso en cualquier momento.</p>
                                                    </Fragment>}
                                                    <br/>
                                                </Fragment> : null}
                                            </div>
                                            <div className='authorize-access-page-container'>
                                                <form className={'authorize-access-page-form'} >
                                                    <div className={`authorize-access-page-div ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
                                                        <label
                                                            className="authorize-access-input-description"
                                                            htmlFor="name"
                                                        >{appLanguage.en ? 'Name: ' : 'Nombre: '} <b>{ nameAlert.alert ? (
                                                            appLanguage.en ? nameAlert.enText : nameAlert.esText
                                                        ) : "*" }</b></label>
                                                        <input
                                                            style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                            className={`authorize-access-input-content ${nameAlert.alert ? 'authorize-access-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            placeholder={appLanguage.en ? 'Type Name...' : 'Digite Nombre...'}
                                                            onChange={ e => onChange(e)}
                                                        />
                                                        <label
                                                            className="authorize-access-input-description"
                                                            htmlFor="newPassword"
                                                        >{appLanguage.en ? 'Phone (optional): ' : 'Teléfono (opcional): '} <b>{phoneAlert.alert ? (
                                                                    appLanguage.en ? phoneAlert.enText : phoneAlert.esText
                                                                ) : null}</b></label>
                                                        <input
                                                            style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                            className={`authorize-access-input-content ${phoneAlert.alert ? 'authorize-access-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            type="text"
                                                            name="phone"
                                                            id="phome"
                                                            placeholder={appLanguage.en ? 'Type Phone...' : 'Digite Teléfono...'}
                                                            value={phone}
                                                            inputMode="numeric"
                                                            onChange={ e => onChange(e)}
                                                            maxLength={20}
                                                            onBlur={handleInputBlur}
                                                        />
                                                        <label
                                                            className="authorize-access-input-description"
                                                            htmlFor="newPassword2"
                                                        >{appLanguage.en ? 'Email: ' : 'Correo: '} <b>{emailAlert.alert ? (
                                                                    appLanguage.en ? emailAlert.enText : emailAlert.esText
                                                                ) : "*" }</b></label>
                                                        <input
                                                            style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                            className={`authorize-access-input-content ${emailAlert.alert ? 'authorize-access-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder={appLanguage.en ? 'Type Email...' : 'Digite Correo...'}
                                                            onChange={ e => onChange(e)}
                                                        />
                                                        <br />
                                                        <button
                                                            className={`authorize-access-button-container`}
                                                            onClick={validar}
                                                            type="submit"
                                                        >
                                                            <img
                                                                src={addIconDark}
                                                                alt="Login button"
                                                                className='authorize-access-button-container-img'
                                                            />
                                                            <p className='authorize-access-button-container-text'>{appLanguage.en ? 'Add to account' : 'Agregar a la cuenta'}</p>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </Fragment> : <Fragment>
                                            {state.isPending ? 
                                                <div className='authorize-access-page-alert-cont'>
                                                    <img src={maintenanceIcon} alt="alert" />
                                                    {appLanguage.en ? <Fragment>
                                                        <p><b>You cannot add users at this time</b></p>
                                                        <br/>
                                                        <p>This account is under maintenance at the moment, please try again later.</p>
                                                    </Fragment> : <Fragment>
                                                        <p><b>No puedes agregar usuarios en este momento</b></p>
                                                        <br/>
                                                        <p>Esta cuenta está en mantenimiento en este momento, inténtalo de nuevo más tarde.</p>
                                                    </Fragment>}
                                                </div>
                                            : 
                                                <div className='authorize-access-page-alert-cont'>
                                                    <img src={warningIcon} alt="alert" />
                                                    {appLanguage.en ? <Fragment>
                                                        <p><b>You cannot add more users</b></p>
                                                        <p><b>{state.numberOfAuthUsers} of {state.limit}</b> Authorized users added</p>
                                                        <br/>
                                                        <p>Only {state.limit} authorized users can be added per basic account. You can delete an existing authorized user to add another.</p>
                                                    </Fragment> : <Fragment>
                                                        <p><b>No puedes agregar más usuarios</b></p>
                                                        <p><b>{state.numberOfAuthUsers} de {state.limit}</b> Usiarios autorizados agregados</p>
                                                        <br/>
                                                        <p>Solo se pueden agregar {state.limit} usuarios autorizados por cuenta básica. Puede eliminar un usuario autorizado existente para agregar otro.</p>
                                                    </Fragment>}
                                                </div>
                                            }
                                                
                                        </Fragment>
                                            
                                        }
                                    </section> : null}
                                </Fragment> }
                            </div> 
                        }
                    </div> </Fragment>}
                </div>
            </div>
        </Fragment>
    )
}

export default AuthorizeAccessPage;