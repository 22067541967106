import { useContext, Fragment, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../firebase/context";
import { SideMenuContext } from "../../context/SideMenuContext";
import { uid } from "uid";

import firebase from '../../firebase';
import { collection, query, onSnapshot, orderBy, limit, getCountFromServer } from "firebase/firestore";

import Spinner from "../spinner/spinner";
import ConnectionError from "../connection-error/connectionError";

import emptyBox from '../../img/empty-box.svg';

import "./settingsPaymentHistory.scss";

import checkIcon from "../../img/check.svg";
import failedIcon from "../../img/failed.svg";

const SettingsPaymentHistory = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData } = useContext(AuthContext);

    const [ loading, setLoading ] = useState(true);

    const [ paymentHistory, setPaymentHistory ] = useState(null);

    const [ showConnectionError, setConnectionError ] = useState(false);

    const [ effectControler, setEffectControler ] = useState(100000);

    async function verifiedIfPaymentHistoryCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) { 
                setPaymentHistory([]);
                setConnectionError(false);
                setLoading(false);
            }
        } catch (error) {
            console.warn(error);
            setConnectionError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (accountData) {
            setLoading(true);

            const q = query(collection(firebase.db, `accounts/${accountData.id}/paymentHistory`), orderBy("date", "desc"), limit(8));

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfPaymentHistoryCollectionIsReallyEmpty(q)
                } else {
                    if (paymentHistory) {
                        setPaymentHistory(res);
                        setConnectionError(false);
                        setLoading(false);
                    } else {
                        setPaymentHistory(res);
                        setConnectionError(false);
                        setLoading(false);
                    }
                }
            }, err =>{
                console.error(err);
                setConnectionError(true);
                setLoading(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    const onTryAgain = () => {
        setEffectControler(uid(6))
    }

    return (
        <div className={`settings-payment-history ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <p><b>{appLanguage.en ? "Payment history" : "Historial de pagos"}</b></p>

            {loading ? <Spinner /> : <Fragment>
                {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> : <Fragment>
                    {paymentHistory ? <Fragment>
                        {paymentHistory.length > 0 ? 
                            <Fragment>
                                {paymentHistory.map((record) => {
                                    return (
                                        <PaymentRecord key={uid()} record={record} />
                                    );
                                })}
                                <div className='settings-payment-history-item-hr' />
                            </Fragment> 
                        :
                            <div className="settings-payment-history-empty-con">
                                <img src={emptyBox} alt="Empty box" />
                                <p>{appLanguage.en ? "Nothing to show" : "Nada que mostrar"}</p>
                            </div>  
                        }
                    </Fragment> : null}
                </Fragment>}
            </Fragment>}
        </div>
    );
}

const PaymentRecord = ({record}) => {

    const { getDateInString, formatRationalNumber, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext)

    const date = new Date(record.date);
    const result = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    const [ icon ] = useState(record.status === "success" ? checkIcon : failedIcon);

    const checkPaymentMethod = (paymentMethod) => {
        if (paymentMethod === "paypal") {
            return "PayPal";
        } else {
            if (paymentMethod === "Cash") {
                return appLanguage.en ? "Cash" : "Efectivo";
            } else {
                return paymentMethod
            }
        }
    }

    return (
        <Fragment>
            <div className='settings-payment-history-item-hr' />
            {desktopView ? 
                <div className="settings-payment-history-item-con">
                    <div className="settings-payment-history-status-icon-con">
                        <img className="settings-payment-history-status-icon" src={icon} alt="Transation status" />
                        <p style={{whiteSpace: "nowrap"}}>{record.status === "success" ? "OK" : "Error"}</p>
                    </div>
                    <div 
                        style={{
                            minWidth: "100px"
                        }}
                    >
                        <p style={{whiteSpace: "nowrap"}}><b>RD$ {formatRationalNumber(record.amount)}</b></p>
                        <p style={{whiteSpace: "nowrap"}}>Ref: {record.ref}</p>
                    </div>
                    <div 
                        style={{
                            minWidth: "170px"
                        }}
                    >
                        <p style={{whiteSpace: "nowrap"}}>{checkPaymentMethod(record.paymentMethod)}</p>
                        <p style={{whiteSpace: "nowrap"}}>{getDateInString(date)} - {result}</p>
                    </div>
                </div>
            : 
                <div className="settings-payment-history-item-con">
                    <div className="settings-payment-history-status-icon-con">
                        <img className="settings-payment-history-status-icon" src={icon} alt="Transation status" />
                        <p style={{whiteSpace: "nowrap"}}>
                            <b>RD$ {formatRationalNumber(record.amount)}</b>
                        </p>   
                    </div>
                    <div 
                        style={{
                            minWidth: "190px",
                            marginLeft: "30px"
                        }}
                    >
                        <p style={{whiteSpace: "nowrap"}}>Ref: {record.ref}</p>
                        <p style={{whiteSpace: "nowrap"}}>{checkPaymentMethod(record.paymentMethod)}</p>
                        <p style={{whiteSpace: "nowrap"}}>{getDateInString(date)} - {result}</p>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default SettingsPaymentHistory;