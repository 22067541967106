import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

import './pageName.scss';

const PageName = () => {

    const { getPageName } = useContext(AppContext);

    return (
        <p className='page-name-p'>{getPageName()}</p>
    );
}

export default PageName;