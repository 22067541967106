import { useContext, useEffect, useState } from "react";
import { uid } from "uid";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AlertContext } from "../../../components/alert/alertContext";

import firebase from "../../../firebase";
import { collection, query, onSnapshot, orderBy, limit, startAfter, getDocsFromServer, where, getCountFromServer } from "firebase/firestore";

import AccountProducts from "../accountProducts/accountProducts";
import AccountHistory from "../accountHistory/accountHistory";

import "./customerSubSection.scss";

const CustomerSubSection = ({customerAccount, minimize, headerHeight}) => {
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);

    const [ sales, setSales ] = useState([]);

    const [ loadingRecords, setLoadingRecords ] = useState(true);

    const [ recordsConnectionError, setRecordsConnectionError ] = useState(false);

    const [ showingProducts, setShowingProducts ] = useState(true);

    const [ loadingNotes, setLoadingNotes] = useState(false);
    const [ notesConnectionError, setNotesConnectionError ] = useState(false);
    
    const [ noteData, setNoteData ] = useState("");
    const [ createNewNote, setCreateNewNote ] = useState(false);

    // note navigator
    const [ loadingMoreNotes, setLoadingMoreNotes ] = useState(false);
    const [ lessThan10, setLessThan10 ] = useState(null);
    const [ noMoreNotes, setNoMoreNotes ] = useState(false);
    const [ notesHistory, setNoteHistory ] = useState(null);

    // There will be diferent array for the notes
    // Each array will have 10 or less notes
    // The first array will be listening
    // The other will load only if necesary
    const [ notes, setNotes ] = useState([]);
    const [ moreNotes, setMoreNotes ] = useState([]);
    const [ notesDisplaying, setNotesDisplaying ] = useState([]);

    const removeDuplicates = (array, property) => {
        const seen = new Set();
        return array.filter(obj => {
          const value = obj[property];
          if (!seen.has(value)) {
            seen.add(value);
            return true;
          }
          return false;
        });
      };

    useEffect(() => {
        const newState = [...notes, ...moreNotes];
        const uniqueArray = removeDuplicates(newState, 'id');
        setNotesDisplaying(uniqueArray)
    }, [notes, moreNotes]);

    const loadMoreNotes = async () => {
        setLoadingMoreNotes(true);
        try {
            if (!notesHistory) {

                const q = query(collection(firebase.db, `accounts/${accountData.id}/customersNotes`), where("customer", "==", customerAccount.id), orderBy("time", "desc"), startAfter(notes[notes.length - 1].time), limit(10));
                const snapshot = await getDocsFromServer(q);
                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (!(res.length > 0)) {
                    setNoMoreNotes(true);
                } else {
                    if (res.length < 10 ) {
                        setNoMoreNotes(true);
                    }

                    setNoteHistory({
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });

                    setMoreNotes([...moreNotes, ...res]);
                }
            } else {
                const q = query(collection(firebase.db, `accounts/${accountData.id}/customersNotes`), where("customer", "==", customerAccount.id), orderBy("time", "desc"), startAfter(notesHistory.endAt), limit(10));
                const snapshot = await getDocsFromServer(q);

                const res = [];

                snapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (!(res.length > 0)) {
                    setNoMoreNotes(true);
                } else {
                    if (res.length < 10 ) {
                        setNoMoreNotes(true);
                    }

                    setNoteHistory({
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });

                    setMoreNotes([...moreNotes, ...res]);
                }
            }
        } catch (error) {
            console.warn(error);
        }
        setLoadingMoreNotes(false);
    }

    const [ notesEffectControler, setNotesEffectControler ] = useState("10000");

    async function verifiedIfNotesCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) { 
                setNotes([]);
                setNotesConnectionError(false);
                setLoadingNotes(false);
            }
        } catch (error) {
            console.warn(error);
            setLoadingNotes(false);
            setNotesConnectionError(true);
        }
    }

    useEffect(() => {
        if (accountData && customerAccount) {
            setLoadingNotes(true);
            const q = query(collection(firebase.db, `accounts/${accountData.id}/customersNotes`), where("customer", "==", customerAccount.id), orderBy("time", "desc"), limit(10));

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        });
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfNotesCollectionIsReallyEmpty(q)
                } else {
                    if (res.length < 10) {
                        setLessThan10(true);
                    } else {
                        setLessThan10(false);
                    }
              
                    if (notes) {
                        // console.log("Customer notes change has been update");
                        setNotes(res);
                        setNotesConnectionError(false);
                        setLoadingNotes(false);
                    } else {
                        // console.log("Customer notes getted for the first time");
                        setNotes(res);
                        setNotesConnectionError(false);
                        setLoadingNotes(false);
                    }
                }
            }, err =>{
                console.error(err);
                setNotesConnectionError(true);
                setLoadingNotes(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [notesEffectControler]);

    const tryAgainNotes = () => {
        setNotesEffectControler(uid());
    }
  
    async function onClickSave() {
        setLoadingNotes(true);
        const accountId = accountData.id.toString();
        const customerId = customerAccount.id;

        try {
            const res = await firebase.useSetCustomerNote({
                "accountId": accountId,
                "customerId": customerId,
                "note": noteData,
            });

            const currentNotes = [...notes];
            currentNotes.unshift(res);
            setNotes(currentNotes);

            // Resetear aqui
            if (currentNotes.length < 10) {
                setNoMoreNotes(true);
            } else {
                setNoMoreNotes(false);
            }

            setMoreNotes([]);
            setNoteHistory(null);

            setNoteData("");
            setCreateNewNote(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Note created successfully',
                    es : 'Nota creada exitosamente',
                }
            });
            
            setLoadingNotes(false);
            return true;

        } catch (error)  {
            console.error(error);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error when trying to create note',
                    es : 'Error al tratar de crear la nota'
                },
                code : "error",
                description : getErrorDescription("error")
            });
            setAlertActive(true);

            setLoadingNotes(false);
            return false;
        }
    }

    async function deleteNote() {
        setLoadingNotes(true);
        setAlertActive(false);

        const accountId = accountData.id.toString();
        const customerId = customerAccount.id;
        const toBeDeleted = JSON.parse(localStorage.getItem('toBeDeleted'));

        try {
            await firebase.useDeleteCustomerNote({
                "accountId": accountId,
                "customerId": customerId,
                "noteId": toBeDeleted,
            });

            const currentNotes = notes.filter(obj => obj.id !== toBeDeleted);;
            setNotes(currentNotes);

            const currentMoreNotes = moreNotes.filter(obj => obj.id !== toBeDeleted);
            setMoreNotes(currentMoreNotes);

            setNoteData("");
            setCreateNewNote(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Note deleted successfully',
                    es : 'Nota eliminada exitosamente',
                }
            });
            
            setLoadingNotes(false);
        } catch (error)  {
            console.error(error);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error when trying to delete note',
                    es : 'Error al tratar de eliminar la nota'
                },
                code : "error",
                description : getErrorDescription("error")
            });
            setAlertActive(true);
            setLoadingNotes(false);
        }

        localStorage.removeItem("toBeDeleted");
    }

    const onClickDelete = (id) => {
        localStorage.setItem("toBeDeleted", JSON.stringify(id));

        setAlertData({
            type : 'question',
            title: {
                en : 'Delete note?',
                es : 'Eliminar nota'
            },
            code : '',
            description : {
                en : `Are you sure you want to delete the selected note?`,
                es : `¿Estás seguro de que deseas eliminar la nota seleccionada?`
            }
        });
        setOnAgree(() => deleteNote);
        setAlertActive(true);
    }

    const [ effectRecordsControler, setEffectRecordsControler ] = useState(100000);

    async function verifiedIfSalesCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) { 
                setSales([]);
                setLoadingRecords(false);
                setRecordsConnectionError(false);
            }
        } catch (error) {
            console.warn(error);
            setLoadingRecords(false);
            setRecordsConnectionError(true);
        }
    }

    useEffect(() => {
        if (accountData && customerAccount && accessTo) {
            if (accessTo.sales) {
                setLoadingRecords(true);

                const q = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`), 
                    where("customerId", "==", customerAccount.id),
                    limit(10),
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            res.push({
                                ...doc.data(),
                                "id": doc.id,
                            });
                        }
                    });

                    if (querySnapshot.empty) {
                        verifiedIfSalesCollectionIsReallyEmpty(q);
                    } else {
                        setSales(res);
                        setLoadingRecords(false);
                        setRecordsConnectionError(false);
                    }
                }, err =>{
                    console.error(err);
                    setLoadingRecords(false);
                    setRecordsConnectionError(true);
                });

                return () => unsub(); 
            }
            
        }
    // eslint-disable-next-line
    }, [effectRecordsControler]);

    const tryRecordsAgain = () => {
        setEffectRecordsControler(uid(6))
    }

    return (
        <div className="customer-sub-section-main-cont">

            {minimize ? 
                <div 
                    className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                >
                    <div className="customer-sub-section-title1">
                        <button 
                            onClick={() => setShowingProducts(true)}
                            className={`customer-sub-section-button1 ${showingProducts ? "customer-sub-section-button-active" : "customer-sub-section-button-inactive"} ${appTheme.dark ? "dark-text" : "light-text"}`}
                        >
                            {appLanguage.en ? "Transactions" : "Transacciones"}
                        </button>
                        <button 
                            onClick={() => setShowingProducts(false)}
                            className={`customer-sub-section-button2 ${showingProducts ? "customer-sub-section-button-inactive" : "customer-sub-section-button-active"} ${appTheme.dark ? "dark-text" : "light-text"}`}
                        >
                            {appLanguage.en ? "Customer history" : "Historial del cliente"}
                        </button>
                    </div>

                    {showingProducts ? 
                        <AccountProducts 
                            headerHeight={headerHeight}
                            minimize={minimize} 
                            sales={sales}
                            loadingRecords={loadingRecords}
                            recordsConnectionError={recordsConnectionError}
                            tryRecordsAgain={tryRecordsAgain}
                            noTransactionsMsg={appLanguage.en ? "Once you make sales or returns, they will be displayed here." : "Una vez realices ventas o devoluciones, estas se mostrarán aquí."}
                        /> 
                    :
                        <AccountHistory
                            headerHeight={headerHeight}
                            number={1}
                            notesDisplaying={notesDisplaying}
                            minimize={minimize} 
                            loadingNotes={loadingNotes}
                            notesConnectionError={notesConnectionError}
                            tryAgainNotes={tryAgainNotes}
                            createNewNote={createNewNote}
                            setCreateNewNote={setCreateNewNote}
                            noteData={noteData}
                            setNoteData={setNoteData}
                            onClickSave={onClickSave}
                            onClickDelete={onClickDelete}
                            loadingMoreNotes={loadingMoreNotes}
                            loadMoreNotes={loadMoreNotes}
                            lessThan10={lessThan10}
                            noMoreNotes={noMoreNotes}
                        />
                    }
 
                </div>
            :
                <div className="customer-sub-section-div">
                    <div 
                        className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                        style={{
                            margin: "10px 8px 20px 20px",
                        }}
                    >
                        <p className="customer-sub-section-title2">{appLanguage.en ? "Transactions" : "Transacciones"}</p>
                        <AccountProducts
                            headerHeight={headerHeight} 
                            minimize={minimize} 
                            sales={sales}
                            loadingRecords={loadingRecords}
                            recordsConnectionError={recordsConnectionError}
                            tryRecordsAgain={tryRecordsAgain}
                            noTransactionsMsg={appLanguage.en ? "Once you make sales or returns from customer, they will be displayed here." : "Una vez que realice ventas o devoluciones del cliente, estas se mostrarán aquí."}
                        />
                    </div>
                    <div 
                        className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                        style={{
                            margin: "10px 20px 20px 8px",
                        }}
                    >
                        <p className="customer-sub-section-title2">{appLanguage.en ? "Customer history" : "Historial del cliente"}</p>
                        <AccountHistory 
                            headerHeight={headerHeight}
                            number={2}
                            notesDisplaying={notesDisplaying}
                            minimize={minimize} 
                            loadingNotes={loadingNotes}
                            notesConnectionError={notesConnectionError}
                            tryAgainNotes={tryAgainNotes}
                            createNewNote={createNewNote}
                            setCreateNewNote={setCreateNewNote}
                            noteData={noteData}
                            setNoteData={setNoteData}
                            onClickSave={onClickSave}
                            onClickDelete={onClickDelete}
                            loadingMoreNotes={loadingMoreNotes}
                            loadMoreNotes={loadMoreNotes}
                            lessThan10={lessThan10}
                            noMoreNotes={noMoreNotes}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default CustomerSubSection;