import { Fragment, useContext, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AuthContext } from '../../../../firebase/context';
import { AlertContext } from '../../../../components/alert/alertContext';
import { useNavigate } from 'react-router-dom';

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import SettingsParagraphs from '../../../../components/settings-paragraphs/settingsParagraphs';

import Spinner from '../../../../components/spinner/spinner';

import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import questionIcon from '../../../../img/question.svg';

import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../../img/analysis.svg';

import SettingsActionRequired from '../../../../components/settings-action-required/settingsActionRequired';

import "./cancelSubscription.page.scss";
import firebase from '../../../../firebase/firebase';

const CancelSubscriptionPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData, adminAccess, managerAccess, setAccountData, setAccountSelected } = useContext(AuthContext);

    const [ loading, setLoading ] = useState(false);

    const esParagraphs = [
        "Estas a punto de cancelar su suscripciónen Aztonia, recuerda que los datos de su cuenta permaneceran aquí en caso de que desees regresar. Si no deseas que conservemos los datos de esta cuenta, puede eliminarlos en: Cuenta de negocios > Editar cuenta > Eliminar cuenta de negocios."
    ];
    const enParagraphs = [
        "You are about to cancel your subscription at Aztonia, remember that your account details will remain here in case you want to return. If you do not want us to retain this account data, you can delete it at: Business account > Edit account > Delete business account."
    ];

    const navigate = useNavigate();

    const [ reasonRef, setReasonRef ] = useState(null);
    const setReasonReference = (inputElement)=>{
        setReasonRef(inputElement);
    }

    const [ passwordRef, setPasswordRef ] = useState(null);
    const setPasswordReference = (inputElement)=>{
        setPasswordRef(inputElement);
    }

    const [data, setData ] = useState({
        password: "",
        reason: "",
        comments: ""
    });

    const [passwordAlert, setPasswordAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [reasonAlert, setReasonAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    const cancelPlanConfirmation = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Cancel subscription',
                es: 'Cancelar suscripción'
            },
            code: 'confirm-log-out',
            description: {
                en: `Are you sure you want to cancel the plan for "${accountData.businessName}"?`,
                es: `¿Estás seguro de que quieres cancelar el plan para "${accountData.businessName}"?`
            }
        });
        setOnAgree(() => cancelPlan);
        setAlertActive(true);
    }

    const onChange = e => {
        if (e.target.name === "password") {
            setPasswordAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if (e.target.name === "reason") {
                setReasonAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }
        
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    async function cancelPlan() {
        setAlertActive(false);
        setLoading(true);

        try {
            await firebase.useConfirmPassword(data.password);
            await firebase.useCancelPlan({
                "accountId": accountData.id,
                "reason": data.reason,
                "comments": data.comments
            });

            localStorage.removeItem("isAccountSelected");
            localStorage.removeItem("isAccountData");

            setAccountSelected(null);
            setAccountData(null);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Subscription canceled',
                    es: 'Suscripción cancelada'
                },
                code: '',
                description: {
                    en: 'The subscription for this account has been successfully canceled.',
                    es: 'La suscripción de esta cuenta se ha cancelado correctamente.'
                }
            });
            setAlertActive(true);
            
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to cancel subscription',
                    es: 'Error al intentar cancelar suscripción'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
        setLoading(false);
    }

    const validar = e => {
        e.preventDefault();

        if (data.reason.trim() === "") {
            setReasonAlert({
                alert: true,
                enText: '*** You must choose a reason ***',
                esText: '*** Debes elegir una razón ***'
            });
            if (reasonRef) {
                reasonRef.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        } else {
            if (data.password.trim()=== "") {
                setPasswordAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                if (passwordRef) {
                    passwordRef.scrollIntoView({ behavior: 'smooth' });
                }
                return;
            } else {
                if(data.password.length < 8) {
                    setPasswordAlert({
                        alert: true,
                        enText: '* Password must be at least 8 characters',
                        esText: '* La contraseña debe ser de al menos 8 caracteres'
                    });
                    return;
                }
            }
        }

        cancelPlanConfirmation();
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Cancel subscription" : "Cancelar suscripción"} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader3
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/suscripcion"}
                            text2={appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción"}
                            text={appLanguage.en ? "Cancel subscription" : "Cancelar suscripción"}
                        />
                        : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ? <Fragment>
                                {accountData.active ? <Fragment>
                                    {loading ? <Spinner /> : <Fragment>  
                                        <div className='cancel-subs-question-del-container'>
                                            <img className='cancel-subs-question-del-img' src={questionIcon} alt='Warning' />
                                            <p>{appLanguage.en ? <b>Confirm plan cancellation</b> : <b>Confirmar cancelación del plan</b>}</p>
                                        </div>

                                        <SettingsParagraphs
                                            paragraphs={appLanguage.en ? enParagraphs : esParagraphs}
                                        />

                                        <div 
                                            style={{
                                                marginTop: "-20px",
                                                minHeight: "25px"
                                            }} 
                                            ref={setReasonReference}
                                        />

                                        <form className={`cancel-subs-form-page-container `} >
                                            <div className='cancel-subs-reasons-del-container'>
                                                    <b>
                                                        <p>{appLanguage.en ? "Why do you want to cancel? " : "¿Por qué deseas cancelar? "}
                                                            {reasonAlert.alert ? 
                                                                <span style={{color: appTheme.dark ? "rgba(255, 0, 0, 0.8)" : "red"}}>{appLanguage.en ? reasonAlert.enText : reasonAlert.esText}</span> 
                                                                : 
                                                                " *"
                                                            }
                                                        </p>
                                                    </b>

                                                    <div className='cancel-subs-reasons-radio-input'>
                                                        <input type="radio" id="notNeeded" name="reason" value="notNeeded" onChange={e => onChange(e)}/>
                                                        <label htmlFor="notNeeded">{appLanguage.en ? "I don't need this product" : "No necesito este producto"}</label>
                                                    </div>
                                                    <div className='cancel-subs-reasons-radio-input'>
                                                        <input type="radio" id="malfunction" name="reason" value="malfunction" onChange={e => onChange(e)}/>
                                                        <label htmlFor="malfunction">{appLanguage.en ? "Malfunction" : "Mal funcionamiento"}</label>
                                                    </div>
                                                    <div className='cancel-subs-reasons-radio-input'>
                                                        <input type="radio" id="fewFunctions" name="reason" value="fewFunctions" onChange={e => onChange(e)}/>
                                                        <label htmlFor="fewFunctions">{appLanguage.en ? "Few useful functions" : "Pocas funciones útiles"}</label>
                                                    </div>
                                                    <div className='cancel-subs-reasons-radio-input'>
                                                        <input type="radio" id="veryExpensive" name="reason" value="veryExpensive" onChange={e => onChange(e)}/>
                                                        <label htmlFor="veryExpensive">{appLanguage.en ? "The price is too high" : "El precio es muy alto"}</label>
                                                    </div>
                                                    <div className='cancel-subs-reasons-radio-input'>
                                                        <input type="radio" id="doesNotApply" name="reason" value="doesNotApply" onChange={e => onChange(e)}/>
                                                        <label htmlFor="doesNotApply"> {appLanguage.en ? "None of the above" : "Ninguna de las anteriores"} </label>
                                                    </div>
                                                    <br/>
                                                <b>{appLanguage.en ? <p>Do you want to tell us something else?</p> : <p>¿Nos quieres decir algo más?</p> }</b>
                                                <textarea 
                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light"}}
                                                    className={`cancel-subs-reasons-text-input ${appTheme.dark ? "dar-sec-bg input-border-box-dark" : "lig-sec-bg input-border-box-light"}`} 
                                                    id="comments" 
                                                    name="comments" 
                                                    rows="5"
                                                    placeholder={appLanguage.en ? "Leave your comments here..." : "Deja tus comentarios aquí..."}
                                                    maxLength ={250}
                                                    onChange={e => onChange(e)}
                                                >
                                                </textarea>

                                            </div>

                                            <div className={`cancel-subs-form-page-item`}>
                                                <label
                                                    className="cancel-subs-form-page-description"
                                                    htmlFor="password"
                                                    ref={setPasswordReference}
                                                >{appLanguage.en ? 'Your profile password : ' : 'La contraseña de tu perfil : '} <b>{passwordAlert.alert ? (
                                                        appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                                                    ) : "*"}
                                                    </b></label>
                                                <input
                                                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                    className={`cancel-subs-form-page-input ${passwordAlert.alert ? 'cancel-subs-form-page-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder={appLanguage.en ? 'Enter your profile password...' : 'Ingrese la contraseña de su perfil...'}
                                                    onChange={e => onChange(e)}
                                                />
                                                <br />
                                                <button
                                                    className={`cancel-subs-form-page-button`}
                                                    onClick={validar}
                                                >
                                                    <p className='cancel-subs-form-page-text'><b>{appLanguage.en ? 'Cancel subscription' : 'Cancelar suscripción'}</b></p>
                                                </button>
                                            </div>
                                        </form>
                                    </Fragment>}
                                </Fragment> : 
                                    <SettingsActionRequired />
                                }
                            </Fragment> : null }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CancelSubscriptionPage;