import { Fragment, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../firebase/context';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AlertContext } from '../../../components/alert/alertContext';
import { useNavigate, Link } from 'react-router-dom';
import firebase from '../../../firebase/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

import Spinner from '../../../components/spinner/spinner';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitle from '../../../components/settings-title/settingsTitle';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import sendIconDark from '../../../icons/send-dark.png';

import '../myProfile.page.scss';
import './changePassword.page.scss';

const ChangePasswordPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { userData, userPictures } = useContext(AuthContext);

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [ loading, setLoading ] = useState(false);

    const [ passwordState, setPasswordState ] = useState({
        oldPassword: "",
        newPassword: "",
        newPassword2: ''
    });

    const [ oldPasswordAlert, setOldPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ newPasswordAlert, setNewPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ newPassword2Alert, setNewPassword2Alert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if (e.target.name === "oldPassword") {
            setOldPasswordAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if (e.target.name === "newPassword") {
                setNewPasswordAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            } else {
                if (e.target.name === "newPassword2") {
                    setNewPassword2Alert({
                        alert: false,
                        enText: '',
                        esText: ''
                    });
                }
            }
        }

        setPasswordState({
            ...passwordState,
            [e.target.name] : e.target.value
        })
    }

    const navigate = useNavigate();

    async function changePassword() {
        

        try {
            await firebase.useUpdatePassword(passwordState.oldPassword, passwordState.newPassword2);
            setLoading(false);
            // Show sucess alert
            setAlertData({
                type : 'success',
                title: {
                    en : 'Password changed',
                    es : 'Contraseña cambiada'
                },
                code : '',
                description : {
                    en : "Your profile password has been successfully changed.",
                    es : "La contraseña de su perfil ha sido cambiada de manera exitosa."
                }
            });
            setAlertActive(true);
            // Go the home
            navigate('/', { replace: true });

        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            if((code === 'auth/wrong-password') || (code === "auth/invalid-credential")) {
                setOldPasswordAlert({
                    alert: true,
                    enText: '* Wrong password',
                    esText: '* Contraseña incorrecta'
                });
            }
            setPasswordState({
                oldPassword: "",
                newPassword: "",
                newPassword2: ''
            })

            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to change password',
                    es : 'Error al intentar cambiar contraseña'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const validar = async (e) => {
        e.preventDefault();

        if (passwordState.oldPassword.trim()=== "") {
            setOldPasswordAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;

        } else {
            if(passwordState.oldPassword.length < 8) {
                setOldPasswordAlert({
                    alert: true,
                    enText: '* Password must be at least 8 characters',
                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                });
                return;
            } else {
                if (passwordState.newPassword.trim()=== "") { //
                    setNewPasswordAlert({
                        alert: true,
                        enText: '* Required field',
                        esText: '* Campo requerido'
                    });
                    return;
                } else {
                    if(passwordState.newPassword.length < 8) {
                        setNewPasswordAlert({
                            alert: true,
                            enText: '* Password must be at least 8 characters',
                            esText: '* La contraseña debe ser de al menos 8 caracteres'
                        });
                        return;
                    } else {

                        if (passwordState.newPassword2.trim()=== "") { //
                            setNewPassword2Alert({
                                alert: true,
                                enText: '* Required field',
                                esText: '* Campo requerido'
                            });
                            return;
                        } else {
                            if(passwordState.newPassword2.length < 8) {
                                setNewPassword2Alert({
                                    alert: true,
                                    enText: '* Password must be at least 8 characters',
                                    esText: '* La contraseña debe ser de al menos 8 caracteres'
                                });
                                return;
                            } else {
                                if (passwordState.newPassword.trim() !== passwordState.newPassword2) {
                                    setNewPassword2Alert({
                                        alert: true,
                                        enText: '* Both passwords must be the same',
                                        esText: '* Ambas contraseñas deben ser iguales'
                                    });
                                    return;
                                }
                            }
                        }          
                    }
                }
            }
        }

        try {
            setLoading(true);
            await signInWithEmailAndPassword(firebase.auth, userData.email.trim(), passwordState.oldPassword);
            setTimeout(() => {
                changePassword();
            }, 1000);
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            if((code === 'auth/wrong-password') || (code === "auth/invalid-credential")) {
                setOldPasswordAlert({
                    alert: true,
                    enText: '* Wrong password',
                    esText: '* Contraseña incorrecta'
                });
            }
            setPasswordState({
                oldPassword: "",
                newPassword: "",
                newPassword2: ''
            })

            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to change password',
                    es : 'Error al intentar cambiar contraseña'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Change password' : 'Cambiar contraseña' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "My Profile" : "Mi perfil"}
                            textLink={"/mi-perfil"}
                            text2={appLanguage.en ? "Change password" : "Cambiar contraseña"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={userPictures ? userPictures.d140x140 : '/profile.svg'}
                                firstText={userData ? userData.displayName : "--"}
                                secondText={userData ? userData.email : "--"}
                            />
                            
                            {loading ? <Spinner /> : <Fragment>
                                <div className='change-password-page-container'>
                                    <form className={'change-password-page-form'} >
                                        <div className={`change-password-page-div ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
                                            <label 
                                                className="change-password-input-description" 
                                                htmlFor="oldPassword"
                                            >{appLanguage.en ? 'Old password: ' : 'Contraseña vieja: '} <b>{oldPasswordAlert.alert ? (
                                                appLanguage.en ? oldPasswordAlert.enText : oldPasswordAlert.esText
                                            ) : "*"}</b></label>
                                            <input 
                                                style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                className={`change-password-input-content ${oldPasswordAlert.alert ? 'change-password-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                type="password" 
                                                name="oldPassword" 
                                                id="oldPassword"
                                                value={passwordState.oldPassword}
                                                placeholder={appLanguage.en ? 'Type password...' : 'Digite contraseña...'}
                                                onChange={ e => onChange(e)}
                                            />

                                            <label 
                                                className="change-password-input-description" 
                                                htmlFor="newPassword"
                                            >{appLanguage.en ? 'New password: ' : 'Nueva contraseña: '} <b>{newPasswordAlert.alert ? (
                                                appLanguage.en ? newPasswordAlert.enText : newPasswordAlert.esText
                                            ) : "*"}</b></label>
                                            <input 
                                                style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                className={`change-password-input-content ${newPasswordAlert.alert ? 'change-password-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                type="password" 
                                                name="newPassword" 
                                                id="newPassword"
                                                value={passwordState.newPassword}
                                                placeholder={appLanguage.en ? 'Type password...' : 'Digite contraseña...'}
                                                onChange={ e => onChange(e)}
                                            />

                                                    
                                            <label 
                                                className="change-password-input-description" 
                                                htmlFor="newPassword2"
                                            >{appLanguage.en ? 'Repeat password: ' : 'Repite la contraseña: '} <b>{newPassword2Alert.alert ? (
                                                appLanguage.en ? newPassword2Alert.enText : newPassword2Alert.esText
                                            ) : "*"}</b></label>
                                            <input 
                                                style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                className={`change-password-input-content ${newPassword2Alert.alert ? 'change-password-input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                type="password" 
                                                name="newPassword2" 
                                                id="newPassword2" 
                                                value={passwordState.newPassword2}
                                                placeholder={appLanguage.en ? 'Type password...' : 'Digite contraseña...'}
                                                onChange={ e => onChange(e)}
                                            />

                                            <br/>
                                            <button 
                                                className={`change-password-button-container`}
                                                onClick={validar}
                                                type="submit"
                                            >
                                                <img 
                                                    src={sendIconDark}
                                                    alt="Login button"
                                                    className='change-password-button-container-img'
                                                />   
                                                <p className='change-password-button-container-text'>{appLanguage.en ? 'Send' : 'Enviar'}</p> 
                                            </button>

                                            <Link to="/auth/has-olvidado-tu-password" className={`forgot-password-span ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                                <span>{appLanguage.en ? "Forgot password?" : "¿Has olvidado tú contraseña?"}</span>
                                            </Link>

                                        </div>
                                    </form>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ChangePasswordPage;