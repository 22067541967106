import { Fragment, useContext } from "react";
import { SoftAlertContext } from "../soft-alert/softAlertContext";
import { AppContext } from "../../context/AppContext";

import "./paypalAccTo.scss";

import paypalLogo from "../../img/banks/paypal_64x64.png";
import shareIcon from "../../icons/share-dark.png";
import copyIcon from "../../icons/copy.png";

const PaypalAccTo = ({bill}) => {

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appLanguage } = useContext(AppContext);

    const copyLink = () => {
        const text = bill.paymentArrangement.paypalLink;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'PayPal link copied',
                    es : 'Enlace de PayPal copiado',
                }
            })
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Error copying link',
                    es : 'Error al copiar enlace',
                }
            });
        }
    }

    return(
        <div className="bank-account-to-container-paypal ">
            {bill.paymentArrangement ? <Fragment>
                <div className="bank-account-to-first-section">
                    <div className="bank-account-to-bank-logo-desp">
                        <div className="bank-account-to-bank-logo" >
                            <img src={paypalLogo} alt="bank" />
                        </div>
                        <div>
                            <p className="settings-bills-const-amount-to-pay-p settings-bills-word-break">
                                {appLanguage.en ? 
                                    "Open the PayPal link to pay your HasCuentas invoice. If you have already made the payment, the information will be updated by our team shortly." 
                                : 
                                    "Abre el enlace de PayPal para pagar tu factura de HasCuentas. Si ya has realizado el pago, nuestro equipo actualizará la información en breve."
                                }
                            </p>
                        </div>
                    </div>
                </div>
                <div className="settings-bills-paypal-buttons">
                    <button 
                        className="settings-bills-paypal-button"
                        onClick={copyLink}
                    >
                        {appLanguage.en ? "Copy link" : "Copiar enlace"}
                        <img src={copyIcon} alt="Copy" />
                    </button>
                    <a 
                        className="settings-bills-paypal-button"
                        href={bill.paymentArrangement.paypalLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {appLanguage.en ? "Open link" : "Abrir enlace"}
                        <img src={shareIcon} alt="Open" />
                    </a>
                </div>
            </Fragment> : null }
        </div>  
    )
}

export default PaypalAccTo;