import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import { useNavigate } from "react-router-dom";
import { uid } from "uid";
import firebase from "../../../firebase";

import crossIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import "./customerEditingInfo.scss";
import Spinner from "../../../components/spinner/spinner";

const CustomerEditingInfo = ({customerAccount, setCustomerAccount, setShowingDetails}) => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { accountData, updateCustomerInShoppingCart } = useContext(AuthContext);
    
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();

    const [ disabled, setDisabled ] = useState(true);

    const inputsNames = {
        "name": uid(8),
        "phone": uid(8),
        "email": uid(8),
        "rnc": uid(8),
        "address": uid(8)
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.name:
                return "name";
            case inputsNames.phone:
                return "phone";
            case inputsNames.email:
                return "email";
            case inputsNames.rnc:
                return "rnc";
            case inputsNames.address:
                return "address";
            default:
                return uid;
        }
    } 

    const [ newCustomer, setNewCustomer ] = useState({
        "name": customerAccount.name,
        "phone": customerAccount.phone,
        "email": customerAccount.email,
        "rnc": customerAccount.rnc,
        "address": customerAccount.address,
    });

    const [ phone, setPhone ] = useState(customerAccount.phone);

    const [ nameAlert, setNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ phoneAlert, setPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ emailAlert, setEmailAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ rncAlert, setRncAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length !== 13 ) {
            return true;
        } else {
            return false;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(newCustomer.rnc.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        

    const onChangeForm = e => {
        if(getInputName(e.target.name) === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "phone") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "email") {
            setEmailAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "rnc") {
            setRncAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (getInputName(e.target.name) === "phone") {
            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i]; 
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            
            setPhone(phoneFormatted);

            if (phoneFormatted.trim() === "") {
                setNewCustomer({
                    ...newCustomer,
                    "phone": ""
                });
            }
        } else {
            if (getInputName(e.target.name) === "rnc") {
                let newRNC = "";
                let newRNC2 = "";

                const targetValue = e.target.value;

                for (let i = 0; i < targetValue.length; i++) {
                    if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                        newRNC += targetValue[i];
                    }
                }

                for (let j = 0; j < newRNC.length && j < 11; j++) {
                    if (j === 3 || j === 10 ) {
                        newRNC2 += "-";
                        newRNC2 += newRNC[j];
                    } else {
                        newRNC2 += newRNC[j];
                    }
                }

                setNewCustomer({
                    ...newCustomer,
                    "rnc": newRNC2
                })

            } else {
                setNewCustomer({
                    ...newCustomer,
                    [getInputName(e.target.name)]: e.target.value
                })
            }
            
        }
    }

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNewCustomer({
                ...newCustomer,
                [getInputName(e.target.name)]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNewCustomer({
                    ...newCustomer,
                    [getInputName(e.target.name)]: "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
            
        }
    }

    const onSave = e => {
        e.preventDefault();

        const customer = {...newCustomer};

        if (customer.name.trim() === "") {
            setNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        }

        if (getPhoneError(customer.phone)) {
            setPhoneAlert({
                alert: true,
                enText: ' Phone is not valid',
                esText: ' El número de teléfono no es válido'
            });
            return;
        } 
        
        if (getEmailError(customer.email)) {
            setEmailAlert({
                alert: true,
                enText: ' Email is not valid',
                esText: ' El correo no es válido'
            }); 
            return;
        } 

        if (getRNCError(customer.rnc)) {
            setRncAlert({
                alert: true,
                enText: ' Cédula or RNC is not valid',
                esText: ' La cédula o RNC no es válido'
            });
            return;
        }
        
        edictCustomer(customer);
    }

    const onCancel = e => {
        e.preventDefault();
        setShowingDetails(true);
    }

    async function edictCustomer(customer) {
        setLoading(true);

        try {
            const response = await firebase.useEditCustomer({
                ...customer,
                "id": customerAccount.id,
                "businessId": accountData.id
            });

            setCustomerAccount(response);
            updateCustomerInShoppingCart(response);

            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Customer account edited',
                    es : 'Cuenta del cliente editada',
                }
            });

            navigate(null, { replace: true, state: {...response} });
            setShowingDetails(true);
            
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to edit the customer account',
                    es: 'Error al intentar editar la cuenta del cliente'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {
        let noChanges = true;
        if(newCustomer.name.trim() === customerAccount.name.trim()) {
            if(phone.trim() === customerAccount.phone.trim()) {
                if(newCustomer.email.trim() === customerAccount.email.trim()) {
                    if(newCustomer.rnc.trim() === customerAccount.rnc.trim()) {
                        if(newCustomer.address.trim() === customerAccount.address.trim()) {
                            noChanges = true;
                        } else {
                            noChanges = false;
                        }
                    } else {
                        noChanges = false;
                    }
                } else {
                    noChanges = false;
                }
            } else {
                noChanges = false;
            }
        } else {
            noChanges = false;
        }

        setDisabled(noChanges);
    // eslint-disable-next-line
    }, [newCustomer, phone])

    return (
        <Fragment> { loading ? <div className="more-details-spinner-container"><Spinner /></div> :
            <form 
                autoComplete="off"
                autoCorrect="off"
                className="new-customer-form"
                style={{
                    colorScheme: appTheme.dark ? "dark" :"light",
                }}
            >
                <p className="new-customer-title"><b>{appLanguage.en ? "Editing client " : "Editando cliente "} {customerAccount.id}</b> </p>

                <p className="new-customer-label">{appLanguage.en ? "Name: " : "Nombre: "}<b>{nameAlert.alert ? (
                    appLanguage.en ? nameAlert.enText : nameAlert.esText
                ) : "*"}</b></p>
                <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.name} >{inputsNames.name}</label>
                <input 
                    name={inputsNames.name}
                    id={inputsNames.name}
                    value={newCustomer.name}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                    maxLength={40}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "Example: Juan Pérez" : "Ejemplo: Juan Pérez"}
                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${nameAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                />

                <p className="new-customer-label">{appLanguage.en ? "Phone: " : "Teléfono: "}<b>{phoneAlert.alert ? (
                    appLanguage.en ? phoneAlert.enText : phoneAlert.esText
                ) : ""}</b></p>
                <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.phone} >{inputsNames.phone}</label>
                <input 
                    name={inputsNames.phone}
                    id={inputsNames.phone}
                    inputMode="numeric"
                    value={phone}
                    onBlur={handleInputBlur}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                    maxLength={16}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "Example: +1 (809) 995-8646" : "Ejemplo: +1 (809) 995-8646"}
                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${phoneAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                />

                <p className="new-customer-label">{appLanguage.en ? "Email: " : "Correo: "}<b>{emailAlert.alert ? (
                    appLanguage.en ? emailAlert.enText : emailAlert.esText
                ) : ""}</b></p>
                <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.email} >{inputsNames.email}</label>
                <input 
                    name={inputsNames.email}
                    id={inputsNames.email}
                    value={newCustomer.email}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                    maxLength={40}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "Example: juanperez@gmail.com" : "Ejemplo: juanperez@gmail.com"}
                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${emailAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                />

                <p className="new-customer-label">{appLanguage.en ? "Cédula or RNC: " : "Cédula o RNC: "}<b>{rncAlert.alert ? (
                    appLanguage.en ? rncAlert.enText : rncAlert.esText
                ) : ""}</b></p>
                <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.rnc} >{inputsNames.rnc}</label>
                <input 
                    name={inputsNames.rnc}
                    id={inputsNames.rnc}
                    inputMode="numeric"
                    value={newCustomer.rnc}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "Example: 123-1234567-1" : "Ejemplo: 123-1234567-1"}
                    style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${rncAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`} 
                />

                <p className="new-customer-label">{appLanguage.en ? "Address: " : "Dirección: "}</p>
                <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.address} >{inputsNames.address}</label>
                <textarea 
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "Example: Calle Mella, Enriquillo, Santo Domingo..." : "Ejemplo: Calle Mella, Enriquillo, Santo Domingo..."}
                    style={ appTheme.dark ? {colorScheme: "dark"} : {colorScheme: "light"}}
                    className={`new-custmer-textarea-address ${appTheme.dark ? "new-custmer-textarea-dark dar-sec-bg" : "new-custmer-textarea-light lig-sec-bg" }`}
                    id={inputsNames.address}
                    name={inputsNames.address}
                    value={newCustomer.address}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                    maxLength={200}
                />

                <div 
                    className="new-customer-bottons-container2" 
                    style={{marginBottom: "10px"}}
                >
                    <button 
                        onClick={e => onCancel(e)} 
                        className="new-customer-bottons-button-save"
                    >
                        <img src={crossIcon} alt="Cancel" />
                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                    </button>
                    <button 
                        onClick={e => onSave(e)}
                        disabled={disabled}
                        className="new-customer-bottons-button-save"
                        style={disabled ? {
                            backgroundColor: "#3f526698", 
                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                            cursor: "default"
                        } : null}
                    >
                        <img src={saveIcon} alt="Save" />
                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                    </button>
                </div>
                <br/>
            </form> 
        }</Fragment>
    );
}

export default CustomerEditingInfo;