import { Fragment, useContext, useState, useEffect } from "react";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../components/alert/alertContext";

import firebase from "../../firebase";
import { Helmet } from 'react-helmet-async';

import OutsideNav from "../../components/outside-nav/outsideNav";

import signInDark from '../../icons/check-dark.png';

import Spinner from "../../components/spinner/spinner";
import DoNotCompData from "../../components/navigation/go-back-button/do-not-complete-data";
import AgreeTermsAndCond from "../../components/agreeTermsAndCond/agreeTermsAndCond";

const CompleteDataPage = () => {

    const [ loading, setLoading ] = useState(true);
    
    const [ customerAgree, setCustomerAgree ] = useState(false);

    const { appTheme, appLanguage } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setUserData, user, userData } = useContext(AuthContext);

    const navigate = useNavigate();

    const [ nuevoUsuario, setNuevoUsuario ] = useState({
        userName : "",
        userPhone : ""
    });

    const [ phone, setPhone ] = useState("");

    useEffect(() => {
        let ignore = false;

        let name = '';

        if (user) {
            if (user.displayName) {
                for (let i = 0; ((i < user.displayName.length) && (i < 22)); i++) {
                    name += user.displayName[i];
                }
            }
        }

        setNuevoUsuario({
            ...nuevoUsuario,
            userName : user ? name : ""
        })

        async function checkUserDataExicts() { 
            setLoading(true);
            try {
                const response = await firebase.useCheckUserDataExicts();
                if(response === true) {
                    navigate('/', { replace: true });
                } else {
                    // if(response === null) {
                    //     setLoading(true);
                    // } else {
                        setLoading(false);
                    // }
                }
            } catch (error) { 
                console.log(error);
                setLoading(false);
            } 
        }
        if (!ignore) checkUserDataExicts();
        return () => {
            ignore = true;
        };
    // eslint-disable-next-line
    }, [userData]);

    async function completeUserData(user) {
        setLoading(true);
        
        try {
            const response = await firebase.useCompleteData(user);
            setUserData(response);

            navigate('/elegir-cuenta', { replace: true });
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to create account',
                    es : 'Error al intentar crear cuenta'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const [ userNameAlert, setUserNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ userPhoneAlert, setUserPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if(e.target.name === "userName") {
            setUserNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if(e.target.name === "userPhone") {
                setUserPhoneAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }

        if (e.target.name === "userPhone") {
        
            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i]; 
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            setPhone(phoneFormatted);
        } else {
            setNuevoUsuario({
                ...nuevoUsuario,
                [e.target.name] : e.target.value
            })
        }
    }

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNuevoUsuario({
                ...nuevoUsuario,
                [e.target.name]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNuevoUsuario({
                    ...nuevoUsuario,
                    [e.target.name]: "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
            
        }
    }

    const  validar = e => {
        e.preventDefault();

        if(nuevoUsuario.userName.trim() === "") {
            setUserNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if(nuevoUsuario.userPhone.trim() === "") {
                setUserPhoneAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }
        }

        const userData = {
            displayName: nuevoUsuario.userName,
            displayNameInsensitive: nuevoUsuario.userName.toLocaleLowerCase(),
            phoneNumber: nuevoUsuario.userPhone,
            email: user.email.toLocaleLowerCase(),
            uid : user.uid,
            agreeWithTermsAndConditions: customerAgree
        }

        completeUserData(userData);
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'You have to complete this form' : 'Tienes que completar este formulario' } </title>
            </Helmet>
            <div  
                className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} 
                style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
            >
                <div className="outside-nav-space" />
                <OutsideNav />
                {loading ? 
                    <div style={{marginTop: "80px"}}><Spinner/></div>
                : <Fragment>
                    <div className="login-page-card-container">
                        <div className={`login-page-card-mobile-view`}>
                            <div className="login-page-card-title">
                                <DoNotCompData/>
                                <h3>{appLanguage.en ? 'You have to complete this form' : 'Tienes que completar este formulario'}</h3>
                            </div>
                            <form  style={{paddingBottom: "40px"}}  className={`login-page-form-mobile`} >
                                <div className={`form-mobile-first-section`}>
                                    <label 
                                        className="input-description" 
                                        htmlFor="userName"
                                    >{appLanguage.en ? 'Name : ' : 'Nombre : '} <b>{userNameAlert.alert ? (
                                        appLanguage.en ? userNameAlert.enText : userNameAlert.esText
                                    ) : "*"}</b></label>
                                    <input 
                                        style={{ colorScheme: appTheme.dark ? "dark" : "light"}}
                                        className={`input-content ${userNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="text" 
                                        name="userName" 
                                        id="userName"
                                        maxLength="22"
                                        value={nuevoUsuario.userName}
                                        placeholder={appLanguage.en ? 'Type name...' : 'Escribir nombre...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <label 
                                        className="input-description" 
                                        htmlFor="userPhone"
                                    >{appLanguage.en ? 'Phone Number : ' : 'Número de teléfono : '} <b>{userPhoneAlert.alert ? (
                                        appLanguage.en ? userPhoneAlert.enText : userPhoneAlert.esText
                                    ) : "*"}</b></label>

                                    <input 
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                        className={`input-content ${userPhoneAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="text" 
                                        name="userPhone" 
                                        id="userPhone"
                                        value={phone}
                                        placeholder={appLanguage.en ? 'Type number...' : 'Escribir número...'}
                                        onChange={ e => onChange(e)}
                                        inputMode="numeric"
                                        maxLength={20}
                                        onBlur={handleInputBlur}
                                    />

                                    <label 
                                        className="input-description" 
                                    >{appLanguage.en ? 'Email address : ' : 'Correo electrónico : '}</label>
                                    <label 
                                        className="input-description-email" 
                                    ><b>{user ? user.email : "..."}</b></label>

                                    <AgreeTermsAndCond customerAgree={customerAgree} setCustomerAgree={setCustomerAgree}/>
                                    
                                    <button 
                                        disabled={!customerAgree}
                                        className={` ${customerAgree ? "outside-button-container-agree" : "button-disable-bc-cust-did-not-agree-terms-cond"}`}
                                        onClick={validar}
                                        type="submit"
                                    >
                                        <img 
                                            src={signInDark}
                                            alt="Login button"
                                            className='outside-button-container-img-agree'
                                        />   
                                        <p className='outside-button-container-text-agree'>{appLanguage.en ? 'Save' : 'Guardar'}</p> 
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>}
            </div>
        </Fragment>
    )
}

export default CompleteDataPage;