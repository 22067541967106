import "./saleView.page.scss";
import { Fragment, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const SaleViwePage = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "View sale details" : "Ver detalles de venta" }</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                {appLanguage.en ? "View sale details" : "Ver detalles de venta"} 

                            </Fragment> : null}
                        </div>
                    : null }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SaleViwePage;