
import './settingsTitleBus.scss';

const SettingsTitleBus = ({icon, firstText, secondText}) => {
    return (
        <div className='settiongs-title-bus-option'>
            <img src={icon} alt='Business' />
            <div className='settiongs-title-bus-option-texts'>
                <p><b>{firstText}</b></p>
                <p>{secondText}</p>
            </div>
        </div>
    )
}

export default SettingsTitleBus;