import { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../firebase/context';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitle from '../../../components/settings-title/settingsTitle';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import SettingsSecurity from '../../../components/settings-security/settingsSecurity';

import '../myProfile.page.scss';

const SecurityPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { userData, userPictures } = useContext(AuthContext);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Security' : 'Seguridad' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "My Profile" : "Mi perfil"}
                            textLink={"/mi-perfil"}
                            text2={appLanguage.en ? "Security" : "Seguridad"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={userPictures ? userPictures.d140x140 : '/profile.svg'}
                                firstText={userData ? userData.displayName : "--"}
                                secondText={userData ? userData.email : "--"}
                            />

                            <SettingsSecurity />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SecurityPage;