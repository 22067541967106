import { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';

import './mainOptionsDesktop.scss';

import homeDarkFocus from '../../../icons/mail-optiones-icons/home-dark-focus.png';
import homeDarkNormal from '../../../icons/mail-optiones-icons/home-dark-normal.png';
import homeLightNormal from '../../../icons/mail-optiones-icons/home-light-normal.png';

import metricsDarkFocus from '../../../icons/mail-optiones-icons/metrics-dark-focus.png';
import metricsDarkNormal from '../../../icons/mail-optiones-icons/metrics-dark-normal.png';
import metricsLightNormal from '../../../icons/mail-optiones-icons/metrics-light-normal.png';

import newUserDarkFocus from '../../../icons/mail-optiones-icons/new-user-dark-focus.png';
import newUserDarkNormal from '../../../icons/mail-optiones-icons/new-user-dark-normal.png';
import newUserLightNormal from '../../../icons/mail-optiones-icons/new-user-light-normal.png';

const MainOptionsDesktop = () => {
    const [ homeIconDark, setHomeIconDark ] = useState(homeDarkNormal);
    const [ metricsIconDark, setMetricsIconDark ]  = useState(metricsDarkNormal);
    const [ newUserIconDark, setNewUserIconDark ] = useState(newUserDarkNormal);

    const [ homeIconLight, setHomeIconLight ] = useState(homeLightNormal);
    const [ metricsIconLight, setMetricsIconLight ]  = useState(metricsLightNormal);
    const [ newUserIconLight, setNewUserIconLight ] = useState(newUserLightNormal);

    const { appTheme, appLanguage, appPathSelected } = useContext(AppContext);

    useEffect(() => {
        if(appPathSelected.home) {
            setHomeIconDark(homeDarkFocus);// Focus
            setMetricsIconDark(metricsDarkNormal);
            setNewUserIconDark(newUserDarkNormal);

            setHomeIconLight(homeDarkFocus);// Focus
            setMetricsIconLight(metricsLightNormal);
            setNewUserIconLight(newUserLightNormal);
            
        } else {
            if(appPathSelected.metrics) {
                setHomeIconDark(homeDarkNormal);  
                setMetricsIconDark(metricsDarkFocus);// Focus
                setNewUserIconDark(newUserDarkNormal);

                setHomeIconLight(homeLightNormal); 
                setMetricsIconLight(metricsDarkFocus);// Focus
                setNewUserIconLight(newUserLightNormal);
                
            } else {
                if (appPathSelected.newUser || appPathSelected.addCustomer || appPathSelected.viewCustomer || appPathSelected.moreDepails) {
                    setHomeIconDark(homeDarkNormal);  
                    setMetricsIconDark(metricsDarkNormal);
                    setNewUserIconDark(newUserDarkFocus);// Focus

                    setHomeIconLight(homeLightNormal); 
                    setMetricsIconLight(metricsLightNormal);
                    setNewUserIconLight(newUserDarkFocus);// Focus
                } else {
                    setHomeIconDark(homeDarkNormal);  
                    setMetricsIconDark(metricsDarkNormal);
                    setNewUserIconDark(newUserDarkNormal); 

                    setHomeIconLight(homeLightNormal); 
                    setMetricsIconLight(metricsLightNormal);
                    setNewUserIconLight(newUserLightNormal);
                }
            }
        }
    }, [appPathSelected]);

    const location = useLocation();

    return (
        <div className='main-options-container'>
            { location.pathname === '/' ? 
                <div
                    className={`main-options-link ${appPathSelected.home ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`} `}
                >
                    <img src={appTheme.dark ? homeIconDark : homeIconLight} alt='probando' />
                    {appLanguage.en ? 'Home' : 'Inicio'}
                </div>
            : 
                <Link 
                    to={'/'} 
                    className={`main-options-link ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appPathSelected.home ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`} `}
                >
                    <img src={appTheme.dark ? homeIconDark : homeIconLight} alt='probando' />
                    {appLanguage.en ? 'Home' : 'Inicio'}
                </Link>
            }

            { location.pathname === '/metricas' ? 
                <div
                    className={`main-options-link ${appPathSelected.metrics ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                >
                    <img src={appTheme.dark ? metricsIconDark : metricsIconLight} alt='probando' />
                    {appLanguage.en ? 'Dashboard' : 'Métricas'}
                </div>
            :   
                <Link 
                    to={'/metricas'} 
                    className={`main-options-link ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appPathSelected.metrics ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                >
                    <img src={appTheme.dark ? metricsIconDark : metricsIconLight} alt='probando' />
                    {appLanguage.en ? 'Dashboard' : 'Métricas'}
                </Link>
            }

            { location.pathname === '/clientes' ? 
                <div 
                    className={`main-options-link ${(appPathSelected.newUser || appPathSelected.addCustomer || appPathSelected.viewCustomer || appPathSelected.moreDepails) ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                >
                    <img src={appTheme.dark ? newUserIconDark : newUserIconLight} alt='probando' />
                    {appLanguage.en ? 'Customers' : 'Clientes'}
                </div>
            :
                <Link 
                    to={'/clientes'} 
                    className={`main-options-link ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${(appPathSelected.newUser || appPathSelected.addCustomer || appPathSelected.viewCustomer || appPathSelected.moreDepails) ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? 'main-options-link-dark' : 'main-options-link-light'}`}`}
                >
                    <img src={appTheme.dark ? newUserIconDark : newUserIconLight} alt='probando' />
                    {appLanguage.en ? 'Customers' : 'Clientes'}
                </Link>
            }
        </div>
    );
}

export default MainOptionsDesktop;