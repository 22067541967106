import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { uid } from "uid";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import CustomerViewHeaderMain from "../../../appTools/appToolsComponents/customerViewHeaderMain/customerViewHeaderMain";
import CustomerSubSection from "../../../appTools/appToolsComponents/customerSubSection/customerSubSection";

import "./customerView.page.scss";

const CustomerViewPage = () => {
    const { appLanguage, appTheme } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const navigate = useNavigate();
    const location = useLocation();

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();
    const [headerWidht, setHeaderWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                    if (headerObservedRef.current.offsetWidth !== headerWidht) {
                        setHeaderWidth(headerObservedRef.current.offsetWidth);
                    }
                } catch {}
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
    // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, isSideMenuMinimized]);

    const [ customerAccount, setCustomerAccount ] = useState(null);

    const [ minimize, setMinimize ] = useState(() => {
        if(headerWidht > 700) {
            return false;
        } else {
            return true;
        }
    });

    const detectSize = () => {
        if(headerWidht > 700) {
            setMinimize(false);
        } else {
            setMinimize(true);
        }
    }

    useEffect(() => {
        detectSize();
    // eslint-disable-next-line
    }, [headerWidht]);

    const [ request, setRequest] = useState("0000");

    useEffect(() => {
        if(location.state) {
            const customerAccountsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));
            
            if (customerAccountsDeleted) {
                const customerId = location.state.id;
                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true; 
                        }
                    }
                    return false;
                }

                if (containsString(customerAccountsDeleted, customerId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        if (location.pathname === "/ver-cliente") {
                            navigate(-1);
                        }
                    } else {
                        navigate('/clientes', { replace: true });
                    }
                } else {
                    setRequest(uid());
                    setCustomerAccount(location.state);
                }
            } else {
                setRequest(uid());
                setCustomerAccount(location.state);
            }
        } else {
            navigate("/clientes", { replace: true })
        }
    // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.customers)) {
                navigate("/clientes", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    // Now I have to listeng to the customer doc
    useEffect(() => {
        if (customerAccount && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/customers`, customerAccount.id), (doc) => {
                const accountUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete accountUpdated.searchKeys;

                if (doc.data()) {
                    setCustomerAccount(accountUpdated);
                    navigate(null, { replace: true, state: accountUpdated});
                }
            }, err => {
                console.error(err);
            });
            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [request]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'View customer' : 'Ver cliente'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && customerAccount ? <Fragment>
                    {accountData.active ? 
                        <div className="page-app-settings-item">
                            {accessTo.customers ? <Fragment>
                                <CustomerViewHeaderMain 
                                    customerAccount={customerAccount} 
                                    headerObservedRef={headerObservedRef} 
                                /> 
                                <CustomerSubSection 
                                    customerAccount={customerAccount} 
                                    minimize={minimize}  
                                    headerHeight={headerHeight}
                                />
                            </Fragment> : null}
                        </div>
                    : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default CustomerViewPage;