import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const IsAppLock = () => {

    const { appLock } = useContext(AppContext);

    return (
        appLock ? null : <Outlet/>
    );
}

export default IsAppLock;