import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import "./addingProducts.page.scss";

import InventoryCreateTable from "../../../appTools/appToolsComponents/inventoryCreateTable/inventoryCreateTable";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";


import { useLocation, useNavigate } from "react-router-dom";

const AddingProductsPage = () => {

    let observedRef = useRef(null);

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [ fieldAlert, setFieldAlert ] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [ fieldAlertRef, setFieldAlertRef ] = useState(null);
    const fieldAlertReference = (inputElement)=>{
        setFieldAlertRef(inputElement);
    }

    const [ maximumQuantity, setMaximumQuantity ] = useState(30);

    const [ duplicatedBarcodes, setDuplicatedBarcodes ] = useState([]);
    const [ duplicatedInTable, setDuplicatedInTables ] = useState([]);

    const removeThisDuplicatedFromTable = (barcodeToRemoved) => {
        let temDuplicatedInTable = [...duplicatedInTable];
        let newDuplicatedInTable = temDuplicatedInTable.filter(item => item !== barcodeToRemoved);
        setDuplicatedInTables(newDuplicatedInTable);
    }

    const location = useLocation();
    const [newRecords, setNewRecords] = useState(null);

    useEffect(() => {
        if (newRecords) {
            setNewRecords(() => {
                const records = [];
                for (let i = 0; i < newRecords.length && i < maximumQuantity; i++) {
                    records.push(newRecords[i]);
                }
                return records;
            });
        } else {
            setNewRecords(() => {
                const records = [];
                for (let i = 0; i < 30 && i < maximumQuantity; i++) {
                    const record = {
                        "number": i + 1,
                        "name": "",
                        "category": "general",
                        "rate": "perUnit",
                        "description": "",
                        "quantity": "",
                        "location": "",
                        "barcode": "",
                        "priceWithoutTax": "",
                        "itbisPercentage": "0%",
                        "itbisAmount": "",
                        "finalPrice": "",
                    }
                    records.push(record);
                }
                return records;
            });
        }
    // eslint-disable-next-line
    }, [maximumQuantity]);

    useEffect(() => {
        if (location.state) {
            if (location.state.newRecords) {
                const tempRecords = location.state.newRecords;
                setNewRecords(tempRecords);
            }
        }
        // eslint-disable-next-line 
    }, []);

    const [productLimits, setProductLimits] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showConnectionError, setShowConnectionError] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const navigate = useNavigate();

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "description"
            case 3:
                return "barcode"
            case 4:
                return "location"
            case 5:
                return "quantity"
            case 6:
                return "category"
            case 7:
                return "rate"
            case 8:
                return "priceWithoutTax"
            case 9:
                return "itbisPercentage"
            case 10:
                return "itbisAmount"
            case 11:
                return "finalPrice"
            default:
                break;
        }
    }

    const checkIfRecordEmpty = (recordToCheck) => {
        if (recordToCheck) {
            const name = recordToCheck[getColumName(1)];
            const desc = recordToCheck[getColumName(2)];
            const barc = recordToCheck[getColumName(3)];
            const loca = recordToCheck[getColumName(4)];
            const quan = recordToCheck[getColumName(5)];
            const pric = recordToCheck[getColumName(8)];
            const aitb = recordToCheck[getColumName(10)];
            const fpri = recordToCheck[getColumName(11)];

            if (
                name === "" &&
                desc === "" &&
                barc === "" &&
                loca === "" &&
                quan === "" &&
                pric === "" &&
                aitb === "" &&
                fpri === "" 
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'end',
                behavior: "smooth"
            });

        }
    // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const isBarcodeAlert = (barcode) => {
        if (duplicatedBarcodes.includes(Number(barcode))) {
            return true;
        } else {
            return false;
        }
    }

    const isAnyBarcodeAlert = (newRecords) => {
        if (newRecords) {
            let isAnyBarcode = false
            newRecords.forEach(record => {
                if (isBarcodeAlert(record.barcode.trim())) {
                    isAnyBarcode = true;
                }
            });
            return isAnyBarcode;
        } else {
            return false
        }
    }

    async function getProductLimit() {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/products`);
            const snapshot = await getCountFromServer(coll);

            setMaximumQuantity(snapshot.data().count >= planSnap.data().products ? 0 : planSnap.data().products - snapshot.data().count);

            setProductLimits({
                "limit": planSnap.data().products,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().products ? false : true
            });
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoading(false);
    }

    async function createProducts(recordsToCreate) {
        setLoading(true);

        try {
            const res = await firebase.useCreateProducts({
                "recordsToCreate": recordsToCreate,
                "businessId": accountData.id
            });
            
            if (res.msg === "Limit exceeded") {
                setProductLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                setMaximumQuantity(res.current >= res.limit ? 0 : res.limit - res.current);
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to create the products`,
                        es: `Error al intentar crear los productos`
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more products to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más productos a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (res.msg === "Quota exceeded") {
                    setProductLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    setMaximumQuantity(res.current >= res.limit ? 0 : res.limit - res.current);
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Error trying to create the products`,
                            es: `Error al intentar crear los productos`
                        },
                        code: "Quota exceeded",
                        description: {
                            en : 'The number of products you are trying to add exceeds the established limit.',
                            es : 'La cantidad de productos que estás intentando agregar excede el límite establecido.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else {
                    if (res.msg === "Duplicate barcodes") {
                        setProductLimits({
                            "limit": res.limit,
                            "current": res.current,
                            "createNew": true
                        });
                        setMaximumQuantity(res.current >= res.limit ? 0 : res.limit - res.current);
                        setDuplicatedBarcodes(res.duplicatedBarcodes);
                        setAlertData({
                            type: 'error',
                            title: {
                                en: `Error trying to create the products`,
                                es: `Error al intentar crear los productos`
                            },
                            code: "Duplicate barcodes",
                            description: {
                                en : 'It is not possible to assign a barcode that is already associated with another product.',
                                es : 'No es posible asignar un código de barras que ya esté asociado a otro producto.'
                            }
                        });
                        setAlertActive(true);
                        setLoading(false);
                    } else {
                        setAlertData({
                            type : 'success',
                            title: {
                                en : 'The products have been created successfully',
                                es : 'Los productos han sido creados de manera exitosa'
                            },
                            code : '',
                            description : {
                                en : `If you want to add images to these products, you must search for each product separately.`,
                                es : `Si deseas agregar imágenes a estos productos, debe buscar cada producto por separado.`
                            }
                        });
                        setAlertActive(true);
                        navigate('/inventario', { replace: true });
                    }
                    
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to create the products`,
                    es: `Error al intentar crear los productos`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onTryAgain = e => {
        e.preventDefault();
        getProductLimit();
    }

    useEffect(() => {
        getProductLimit();
        // eslint-disable-next-line
    }, []);

    const checkIfDuplicated = (barcode) => {
        if (barcode.trim() !== "") {
            let barcodeCounter = 0;
            for (let i = 0; i < newRecords.length; i++) {
                const barcodeToCheck = newRecords[i].barcode.trim();
                if (barcodeToCheck !== ""){
                    if (barcodeToCheck === barcode.trim()) {
                        barcodeCounter++;
                    }
                }
            }
            if (barcodeCounter > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getLastRecordWithBarcodeRepeted = () => {
        const array1 = [...duplicatedBarcodes];
        const array2 = [...duplicatedInTable];

        const combinedArray = array1.concat(array2)
        const allRepetedBarcodes = [...new Set(combinedArray)];

        for (let i = newRecords.length - 1; i >= 0; i--) {
            // Retorna el numero primer elemento con codigo de barra repetido
            if (allRepetedBarcodes.includes(newRecords[i].barcode.trim())) {
                return newRecords[i].number;
            }
        }
        
        return false;
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        const recordsToCreate = [];
        const barcodeDuplicatedInTable = []

        for (let i = 0; i < newRecords.length; i++) {
            const record = newRecords[i];
            if (!checkIfRecordEmpty(record)) {
                if (record.name.trim() === "") {
                    setFieldAlert({
                        x: i + 1,
                        y: 1,
                        en: "The name cannot be empty",
                        es: "El nombre no puede estar vacío", 
                    });
                    setMakeSticky(false);
                    setTimeout(() => {
                        if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                            setMakeSticky(true);
                        }
                    }, 100);
                    return;
                } else {
                    if (checkIfDuplicated(record.barcode)) {
                        barcodeDuplicatedInTable.push(record.barcode.trim());
                    } else {
                        if (record.priceWithoutTax.trim() === "") {
                            setFieldAlert({
                                x: i + 1,
                                y: 11,
                                en: "The price cannot be empty",
                                es: "El precio no puede estar vacío",
                            });
                            return;
                        }
                    }
                    
                }
                recordsToCreate.push(record);
            }
        }

        if (barcodeDuplicatedInTable.length > 0) {
            setDuplicatedInTables(barcodeDuplicatedInTable);

            setFieldAlert({
                x: getLastRecordWithBarcodeRepeted(),
                y: 3,
                en: "The barcode cannot be duplicated",
                es: "El código de barras no puede estar repetido",
            });

            return;
        }

        if (isAnyBarcodeAlert(newRecords)) {
            return;
        }

        if (recordsToCreate.length > 0) {
            createProducts(recordsToCreate);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'No records to save',
                    es: 'No hay registros para guardar',
                }
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        const newState = {
            "newRecords": newRecords,
        }

        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [newRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Add products' : 'Agregar productos'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.inventory ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Add products' : 'Agregar productos'}
                                        />
                                    : null}
                                    <div className="inven-first-secct-conte">
                                        {productLimits && productLimits.createNew ?
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ? 
                                                    <div
                                                        className="invent-adding-product-alert-text-p" 
                                                        style={{flexGrow: 4}}
                                                    >
                                                        <img src={warningIcon} alt="" />
                                                        <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                    </div>
                                                :
                                                    isAnyBarcodeAlert(newRecords) ? 
                                                        <div
                                                            className="invent-adding-product-alert-text-p" 
                                                            style={{flexGrow: 4}}
                                                        >
                                                            <img src={warningIcon} alt="" />
                                                            <p>{appLanguage.en ? 
                                                                "It is not possible to assign a barcode that is already associated with another product."
                                                            : 
                                                                "No es posible asignar un código de barras que ya esté asociado a otro producto."
                                                            }</p>
                                                        </div>
                                                    :
                                                        productLimits ? 
                                                            <div
                                                                className="invent-adding-product-alert-text-p" 
                                                                style={{flexGrow: 4}}
                                                            >
                                                                <p>{productLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {productLimits.limit.toLocaleString()} {appLanguage.en ? "products" : "productos"}</p>
                                                            </div>
                                                        : null
                                                }
                                                <div className="inven-first-section-div-btn" style={{ marginBottom: "15px"}}>
                                                    <button
                                                        onClick={goBack}
                                                        className="new-customer-bottons-button-save"
                                                    >
                                                        <img src={crossWhiteIcon} alt="Cancel" />
                                                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                    </button>
                                                    <button 
                                                        onClick={onClickSave}
                                                        className="new-customer-bottons-button-save"
                                                    >
                                                        <img src={saveIcon} alt="Save" />
                                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                    </button>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <InventoryCreateTable
                                    onScroll={onScroll}
                                    makeSticky={makeSticky}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    fieldAlert={fieldAlert} 
                                    setFieldAlert={setFieldAlert}
                                    newRecords={newRecords} 
                                    setNewRecords={setNewRecords}
                                    fieldAlertReference={fieldAlertReference}
                                    getColumName={getColumName}
                                    checkIfRecordEmpty={checkIfRecordEmpty}
                                    productLimits={productLimits}
                                    showConnectionError={showConnectionError}
                                    onTryAgain={onTryAgain}
                                    duplicatedBarcodes={duplicatedBarcodes}
                                    duplicatedInTable={duplicatedInTable}
                                    removeThisDuplicatedFromTable={removeThisDuplicatedFromTable}
                                />
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default AddingProductsPage;