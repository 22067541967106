import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../firebase/context";

const PrivateRoutes = () => {
    const { auth } = useContext(AuthContext);
    return (
        auth ? <Outlet/> : <Navigate to="/auth/iniciar-sesion" replace />
    );
}

export default PrivateRoutes;